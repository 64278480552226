import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexPage } from './pages/index/index.component';
import { ActionsPage } from './pages/actions/actions.page';
import { UniqueLinkActionsResolver } from './resolvers/unique-link-actions.resolver';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: IndexPage,
        data: {
          background: '#F9F9F9',
          animation: 'One',
          title: 'Collections',
          back: '/collections',
        },
      },
      {
        path: 'actions/:id',
        component: ActionsPage,
        resolve: { linkData: UniqueLinkActionsResolver },
        data: {
          animation: 'Two',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CollectionsLinkRoutingModule {}
