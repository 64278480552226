import { createReducer, on } from '@ngrx/store';
import { logOut } from '../auth/auth.actions';
import { Collection, collectionsState } from './collections.interfaces';
import {
  createCollectionFailure,
  createCollectionLinkFailure,
  createCollectionLinkStart,
  createCollectionLinkSuccess,
  createCollectionStart,
  createCollectionSuccess,
  getCollectionInfoFailure,
  getCollectionInfoStart,
  getCollectionInfoSuccess,
  getCollectionLinkInfoFailure,
  getCollectionLinkInfoStart,
  getCollectionLinkInfoSuccess,
  getCollectionsFeesFailure,
  getCollectionsFeesStart,
  getCollectionsFeesSuccess,
  getMoreUserCollectionsFailure,
  getMoreUserCollectionsStart,
  getMoreUserCollectionsSuccess,
  getUserCollectionsFailure,
  getUserCollectionsStart,
  getUserCollectionsSuccess,
  resetCollectionInfo,
  resetNewCollection,
  updateCollectionInfo,
} from './collections.actions';
import { produce } from 'immer';

const initialState: collectionsState = {
  userCollections: null,
  topUpUser: null,
  collectionsFees: null,
  collectionLinkInfo: null,
  pendingCollectionInfo: null,
  collectionInfo: null,
  isDirty: false,
  isLoading: {
    createCollection: false,
    getUserCollections: false,
    getMoreUserCollections: false,
    getCollectionsFees: false,
    getCollectionLinkInfo: false,
    getPendingCollectionInfo: false,
  },
  errorMessages: {
    createCollection: '',
    getUserCollections: '',
    getMoreUserCollections: '',
    getCollectionsFees: '',
    getCollectionLinkInfo: '',
  },
};

export const collectionsReducer = createReducer(
  initialState,
  //----------START----------
  on(
    createCollectionStart,
    produce((state) => {
      state.isLoading['createCollection'] = true;
    })
  ),
  on(
    createCollectionLinkStart,
    produce((state) => {
      state.isLoading['createCollection'] = true;
    })
  ),
  on(
    getUserCollectionsStart,
    produce((state) => {
      state.isLoading['getUserCollections'] = true;
      state.errorMessages['getUserCollections'] = '';
    })
  ),
  on(
    getMoreUserCollectionsStart,
    produce((state) => {
      state.isLoading['getMoreUserCollections'] = true;
      state.errorMessages['getMoreUserCollections'] = '';
    })
  ),
  on(
    getCollectionsFeesStart,
    produce((state) => {
      state.isLoading['getCollectionsFees'] = true;
      state.errorMessages['getCollectionsFees'] = '';
    })
  ),
  on(
    getCollectionLinkInfoStart,
    produce((state) => {
      state.isLoading['getCollectionLinkInfo'] = true;
      state.errorMessages['getCollectionLinkInfo'] = '';
    })
  ),
  on(
    getCollectionInfoStart,
    produce((state) => {
      state.isLoading['getPendingCollectionInfo'] = true;
    })
  ),
  //----------SUCCESS----------
  on(
    createCollectionSuccess,
    produce((state, { payload }) => {
      state.topUpUser = payload ? payload : state.topUpUser;
      state.isLoading['createCollection'] = false;
      state.errorMessages['createCollection'] = '';
    })
  ),
  on(
    createCollectionLinkSuccess,
    produce((state) => {
      state.isLoading['createCollection'] = false;
      state.errorMessages['createCollection'] = '';
    })
  ),
  on(
    getUserCollectionsSuccess,
    produce((state, { payload }) => {
      let currentStoreUserCollections = state?.userCollections?.results;
      let newUserCollections: Collection[];

      // check if there's already collection in the store before comparing them with new collections
      if (currentStoreUserCollections?.length) {
        newUserCollections = payload.results.map((collection) => {
          if (
            !!!currentStoreUserCollections.find((currentStoreCollection) => currentStoreCollection.id === collection.id)
          ) {
            return (collection = { ...collection, newTransaction: true });
          } else {
            return collection;
          }
        });
      } else {
        newUserCollections = payload.results;
      }

      state.userCollections = {
        ...payload,
        results: newUserCollections,
      };
      state.isLoading['getUserCollections'] = false;
      state.errorMessages['getUserCollections'] = '';
    })
  ),
  on(
    getMoreUserCollectionsSuccess,
    produce((state, { payload }) => {
      state.userCollections.next = payload['next'];
      state.userCollections.results = [...state.userCollections['results'], ...payload['collections']];
      state.isLoading['getMoreUserCollections'] = false;
      state.errorMessages['getMoreUserCollections'] = '';
    })
  ),
  on(
    getCollectionsFeesSuccess,
    produce((state, { payload }) => {
      state.collectionsFees = payload;
      state.isLoading['getCollectionsFees'] = false;
      state.errorMessages['getCollectionsFees'] = '';
    })
  ),
  on(
    getCollectionLinkInfoSuccess,
    produce((state, { payload }) => {
      state.collectionLinkInfo = payload;
      state.isLoading['getCollectionLinkInfo'] = false;
      state.errorMessages['getCollectionLinkInfo'] = '';
    })
  ),
  on(
    getCollectionInfoSuccess,
    produce((state, { payload }) => {
      state.pendingCollectionInfo = payload;
      state.isLoading['getPendingCollectionInfo'] = false;
    })
  ),
  //----------FAILURE----------
  on(
    createCollectionFailure,
    produce((state, { error }) => {
      state.isLoading['createCollection'] = false;
      state.errorMessages['createCollection'] = error;
    })
  ),
  on(
    createCollectionLinkFailure,
    produce((state) => {
      state.isLoading['createCollection'] = false;
    })
  ),
  on(
    getUserCollectionsFailure,
    produce((state, { error }) => {
      state.isLoading['getUserCollections'] = false;
      state.errorMessages['getUserCollections'] = error;
    })
  ),
  on(
    getMoreUserCollectionsFailure,
    produce((state, { error }) => {
      state.isLoading['getMoreUserCollections'] = false;
      state.errorMessages['getMoreUserCollections'] = error;
    })
  ),
  on(
    getCollectionsFeesFailure,
    produce((state, { error }) => {
      state.isLoading['getCollectionsFees'] = false;
      state.errorMessages['getCollectionsFees'] = error;
    })
  ),
  on(
    getCollectionLinkInfoFailure,
    produce((state, { error }) => {
      state.isLoading['getCollectionLinkInfo'] = false;
      state.errorMessages['getCollectionLinkInfo'] = error;
    })
  ),
  on(
    getCollectionInfoFailure,
    produce((state) => {
      state.isLoading['getPendingCollectionInfo'] = false;
    })
  ),
  //----------OTHER----------
  on(
    logOut,
    produce((state) => {
      state.userCollections = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
    })
  ),
  on(
    updateCollectionInfo,
    produce((state, { payload }) => {
      state.isDirty = true;
      state.collectionInfo = {
        ...payload,
      };
    })
  ),
  on(
    resetCollectionInfo,
    produce((state) => {
      state.isDirty = false;
      state.collectionInfo = null;
    })
  ),
  on(
    resetNewCollection,
    produce((state, { payload: { collectionId } }) => {
      let newUserCollections = state.userCollections.results.map((collection) =>
        collection.id === collectionId ? (collection = { ...collection, newTransaction: false }) : collection
      );

      state.userCollections.results = newUserCollections;
    })
  )
);
