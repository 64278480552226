import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[getElementId]',
  standalone: true,
})
export class GetElementIdDirective implements OnInit {
  /***
   * To determine the description to be included with the ID
   */
  @Input()
  getElementIdDescription: string;
  private elementRef: ElementRef<HTMLElement>;

  constructor(private ref: ElementRef<HTMLElement>) {
    this.elementRef = ref;
  }

  ngOnInit(): void {
    let desc;
    const formControlName = this.elementRef.nativeElement.getAttribute('formControlName');

    if (formControlName) {
      desc = formControlName;
    } else if (this.getElementIdDescription) {
      desc = this.getElementIdDescription;
    } else {
      throw new Error(`An element does not have a unique ID`);
    }

    this.elementRef.nativeElement.setAttribute('data-test-id', desc);
  }
}
