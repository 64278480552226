import { createAction, props } from '@ngrx/store';
import TYPES from './kyc.types';
import { CountryIdentityDoc, CreatePersonalProfileBody, CreatePersonalProfileResponse } from './kycStateInterface';

// CREATE USER KYC PROFILE
export const createUserKycStart = createAction(
  TYPES.CREATE_USER_KYC_START,
  props<{ payload: CreatePersonalProfileBody; extras: { unverifiedBusiness?: boolean; isResubmit: boolean } }>()
);
export const createUserKycSuccess = createAction(
  TYPES.CREATE_USER_KYC_SUCCESS,
  props<{ payload: CreatePersonalProfileResponse; extras: { isResubmit: boolean } }>()
);
export const createUserKycFailure = createAction(TYPES.CREATE_USER_KYC_FAILURE, props<{ error: string }>());

// GET USER KYC
export const getUserKycStart = createAction(TYPES.GET_USER_KYC_START);
export const getUserKycSuccess = createAction(
  TYPES.GET_USER_KYC_SUCCESS,
  props<{ payload: CreatePersonalProfileResponse }>()
);
export const getUserKycFailure = createAction(TYPES.GET_USER_KYC_FAILURE);

// GET COUNTRY USER ID DOCS
export const getCountryUserIdDocsStart = createAction(TYPES.GET_COUNTRY_ID_DOCS_START, props<{ country?: string }>());
export const getCountryUserIdDocsFailure = createAction(TYPES.GET_COUNTRY_ID_DOCS_FAILURE);
export const getCountryUserIdDocsSuccess = createAction(
  TYPES.GET_COUNTRY_ID_DOCS_SUCCESS,
  props<{ payload: Array<CountryIdentityDoc> }>()
);
