import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { logOut } from 'src/app/ngrx/auth/auth.actions';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { IntercomService } from '../../services/intercom.service';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-user-suspension',
  templateUrl: './user-suspension.component.html',
  styleUrls: ['./user-suspension.component.scss'],
  standalone: true,
  imports: [BeyButtonComponent, AsyncPipe],
})
export class UserSuspensionComponent implements OnInit, AfterViewInit {
  intercomAvailable$: Observable<boolean>;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private store: Store,
    private intercomUtil: IntercomService
  ) {
    if (!this.router.getCurrentNavigation()?.extras?.state?.['access']) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.intercomAvailable$ = this.intercomUtil.isAvailable$;
  }

  onLogoutClick(): void {
    this.store.dispatch(logOut());
    this.router.navigate(['/']);
  }

  onReachOutToBeyonic(): void {
    this.intercomUtil.show();
  }

  ngAfterViewInit(): void {
    this.dialog.closeAll();
  }
}
