import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogModule,
} from '@angular/material/legacy-dialog';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';

@Component({
  selector: 'app-bey-transaction-exceeds-limit-content',
  templateUrl: './bey-transaction-exceeds-limit-component-content.component.html',
  styleUrls: ['./bey-transaction-exceeds-limit-component-content.component.scss'],
  standalone: true,
  imports: [MatLegacyDialogModule, BeyButtonComponent],
})
export class BeyTransactionExceedsLimitComponentContent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { first_name: string; last_name: string },
    public dialogRef: MatDialogRef<BeyTransactionExceedsLimitComponentContent>
  ) {}

  ngOnInit(): void {}
}
