import { Pipe, PipeTransform } from '@angular/core';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
dayjs.extend(AdvancedFormat);

@Pipe({
  name: 'dateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string | Date, customFormat?: string, ...args: unknown[]): string {
    return dayjs(value).format(customFormat || 'Do MMMM, YYYY');
  }
}
