import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { toggleHeadlessStepperStep } from 'src/app/ngrx/misc/misc.actions';
import { MiscService } from 'src/app/ngrx/misc/misc.service';
import { BeyButtonComponent } from '../../../bey-button/bey-button.component';
import { BeyNumberedStepCircleComponent } from '../../../bey-numbered-step-circle/bey-numbered-step-circle.component';

@Component({
  selector: 'app-upload-business-documents-drawer-content',
  templateUrl: './upload-business-documents-drawer-content.component.html',
  styleUrls: ['./upload-business-documents-drawer-content.component.scss'],
  standalone: true,
  imports: [BeyNumberedStepCircleComponent, BeyButtonComponent],
})
export class UploadBusinessDocumentsDrawerContentComponent implements OnInit {
  constructor(public miscService: MiscService, private store$: Store) {}

  ngOnInit(): void {}

  proceedForNextStep() {
    this.store$.dispatch(toggleHeadlessStepperStep({ currentStep: 2 }));
    this.miscService.closeDrawer();
  }
}
