import { Pipe, PipeTransform } from '@angular/core';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';

@Pipe({
  name: 'relativeTime',
  standalone: true,
})
export class RelativeTimePipe implements PipeTransform {
  constructor() {
    dayjs.extend(relativeTime);
    dayjs.extend(updateLocale);
  }

  transform(value: Date | string, withOutSuffix: boolean = false, customLocaleMessage: boolean = true): string {
    if (customLocaleMessage) {
      dayjs.updateLocale('en', {
        relativeTime: {
          future: 'in %s',
          past: '%s ago',
          s: 'few secs',
          m: '1 min',
          mm: '%d mins',
          h: '1 hr',
          hh: '%d hrs',
          d: '1 d',
          dd: '%d d',
          M: '1 mo',
          MM: '%d mos',
          y: '1 y',
          yy: '%d yrs',
        },
      });
    }

    if (value) {
      return dayjs(value).fromNow(withOutSuffix);
    }

    return '-';
  }
}
