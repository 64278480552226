<div class="px-3 pt-4 lg:pl-10 w-full max-w-[1000px] lg:mt-[80px]">
  <bey-page-header class="block mb-6 lg:hidden"></bey-page-header>

  <div class="font-bold text-[32px] hidden lg:block">Collections</div>
  <div class="text-base font-medium my-7 lg:text-2xl lg:mb-10">How do you want to collect?</div>

  <a
    routerLink="/links/create"
    data-test-id="beyonic-link"
    class="bg-white rounded-lg ease-in-out duration-300 transition-all border border-gray-200 flex items-center justify-between py-2.5 px-3 lg:py-6 cursor-pointer mt-4 hover:bg-gray-50"
    HasPermission
    [expectedPermission]="['business_org_beyonic_links_create']">
    <div class="flex flex-col">
      <div
        class="text-[8px] w-6 h-6 lg:text-base float-left bg-[#B0DACE] px-2 rounded-full -ml-1 lg:ml-4 text-green-600 font-semibold flex items-center justify-center lg:w-10 lg:h-10">
        New
      </div>
      <div class="flex items-center w-full lg:px-6">
        <div class="w-8">
          <img src="assets/images/icons/message-chat-square.svg" alt="Link Collect" />
        </div>
        <div class="flex flex-col ml-8 lg:text-xl max-w-md">
          <strong>Beyonic link</strong>
          <span class="text-lg">Create a link for any situation</span>
        </div>
      </div>
    </div>
  </a>

  <a
    routerLink="/collections-link"
    data-test-id="beyonic-unique-link"
    class="bg-white ease-in-out duration-300 transition-all rounded-lg border border-gray-200 flex items-center justify-between py-2.5 px-3 lg:py-6 cursor-pointer mt-4 hover:bg-gray-50"
    HasPermission
    [expectedPermission]="['business_org_unique_links_create']">
    <div class="flex items-center w-full lg:px-6">
      <img src="assets/images/icons/collect.svg" alt="Prompt Collect" class="w-8" />
      <div class="flex flex-col ml-8 lg:text-xl max-w-lg">
        <strong>Single use link</strong>
        <span class="block max-w-[500px] text-lg">Share a link for specific payment</span>
      </div>
    </div>
  </a>

  <a
    routerLink="create-collection"
    data-test-id="beyonic-prompt"
    class="bg-white ease-in-out duration-300 transition-all rounded-lg border border-gray-200 flex items-center justify-between py-2.5 px-3 lg:py-6 cursor-pointer mt-4 hover:bg-gray-50"
    HasPermission
    [expectedPermission]="['business_org_collections_create']">
    <div class="flex items-center w-full lg:px-6">
      <img src="assets/images/icons/Profile-svg.svg" alt="Link Collect" class="w-8" />
      <div class="flex flex-col ml-8 lg:text-xl max-w-lg">
        <strong>Collection prompt</strong>
        <span class="block max-w-[430px] text-lg"> No data required, only USSD </span>
      </div>
    </div>
  </a>
</div>
