<div
  class="w-full flex flex-col rounded-xl bg-blue-500 px-3 py-5 mb-5 relative md:px-8"
  style="box-shadow: 3px 4px 6px 0px #00456b3d">
  <a class="mb-3" (click)="onBackClick()" getElementId getElementIdDescription="back_arrow"
    ><svg class="w-5 h-5 md:w-7 md:h-7" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.44002 10.05L16.24 10.05"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M9.46002 4L3.76002 10.05L9.46002 16"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" /></svg
  ></a>

  <div class="flex items-center mb-3">
    <span class="text-white text-lg font-medium mr-2 md:text-2xl">{{ (routeData$ | async)?.['title'] }}</span>
  </div>
  <span class="text-white text-base font-normal lg:text-lg">{{ (routeData$ | async)?.['description'] }}</span>
  <svg
    class="absolute top-[-10px] right-[150px]"
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="-1" y="14" width="30" height="30" rx="4" transform="rotate(-30 -1 14)" fill="#A1D1F5" fill-opacity="0.1" />
  </svg>
  <svg
    class="absolute bottom-[15px] right-[-10px]"
    width="61"
    height="61"
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      y="50.1884"
      width="51.4281"
      height="51.4281"
      rx="4"
      transform="rotate(-77.3939 0 50.1884)"
      fill="#DFE7FC"
      fill-opacity="0.2" />
  </svg>
</div>
