import { Component, OnDestroy, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { createCollectionLinkStart } from '../../../../ngrx/collections/collections.actions';
import { takeUntil } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BeyTransactionExceedsLimitComponentContent } from '../../components/bey-transaction-exceeds-limit-content/bey-transaction-exceeds-limit-component-content.component';
import { BeyFeatureInfoCardComponent } from '../../../shared/components/bey-feature-info-card/bey-feature-info-card.component';
import { BeyCreateCollectionFormComponent } from '../../../shared/components/bey-create-collection-form/bey-create-collection-form.component';
import { BeyPageHeaderComponent } from '../../../shared/components/bey-page-header/bey-page-header.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  standalone: true,
  imports: [BeyPageHeaderComponent, BeyCreateCollectionFormComponent, BeyFeatureInfoCardComponent],
})
export class IndexPage implements OnDestroy {
  stop$: Subject<void> = new Subject<void>();

  constructor(
    private store$: Store,
    private matDialog: MatDialog,
    @Optional() public limitDialogRef: MatDialogRef<BeyTransactionExceedsLimitComponentContent>
  ) {}

  showConfirmDialog(form) {
    this.limitDialogRef = this.matDialog.open(BeyTransactionExceedsLimitComponentContent, {
      maxWidth: '90%',
      data: {
        first_name: form.first_name,
        last_name: form.last_name,
      },
    });

    this.limitDialogRef
      .afterClosed()
      .pipe(takeUntil(this.stop$))
      .subscribe((proceed: boolean) => {
        if (proceed) {
          this.store$.dispatch(
            createCollectionLinkStart({
              payload: {
                ...form,
              },
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
