import { createAction, props } from '@ngrx/store';
import { collectionsTypes } from './collections.types';
import {
  Collection,
  CollectionInfo,
  CollectionsFees,
  collectionsState,
  CreateCollectionLinkResponse,
  CreateCollectionRequestBody,
  TopUpUser,
} from './collections.interfaces';
import { ErrorType } from '../misc/misc.interface';

//------------CREATE COLLECTION------------
export const createCollectionStart = createAction(
  collectionsTypes.CREATE_COLLECTION_START,
  props<{ payload: CreateCollectionRequestBody }>()
);
export const createCollectionSuccess = createAction(
  collectionsTypes.CREATE_COLLECTION_SUCCESS,
  props<{ payload: TopUpUser }>()
);
export const createCollectionFailure = createAction(collectionsTypes.CREATE_COLLECTION_FAILURE, props<ErrorType>());
//------------CREATE PARTIAL REQUEST COLLECTION LINK------------
export const createCollectionLinkStart = createAction(
  collectionsTypes.CREATE_PARTIAL_REQUEST_START,
  props<{ payload: CreateCollectionRequestBody }>()
);

export const createCollectionLinkSuccess = createAction(
  collectionsTypes.CREATE_PARTIAL_REQUEST_SUCCESS,
  props<{ payload: CreateCollectionLinkResponse }>()
);

export const createCollectionLinkFailure = createAction(collectionsTypes.CREATE_PARTIAL_REQUEST_FAILURE);
//------------GET USER COLLECTIONS------------
export const getUserCollectionsStart = createAction(
  collectionsTypes.GET_USER_COLLECTIONS_START,
  props<{ payload: { businessId: number } }>()
);
export const getUserCollectionsSuccess = createAction(
  collectionsTypes.GET_USER_COLLECTIONS_SUCCESS,
  props<{ payload: collectionsState['userCollections'] }>()
);
export const getUserCollectionsFailure = createAction(
  collectionsTypes.GET_USER_COLLECTIONS_FAILURE,
  props<ErrorType>()
);
//------------GET MORE USER COLLECTIONS------------
export const getMoreUserCollectionsStart = createAction(
  collectionsTypes.GET_MORE_USER_COLLECTIONS_START,
  props<{ payload: { url: string } }>()
);
export const getMoreUserCollectionsSuccess = createAction(
  collectionsTypes.GET_MORE_USER_COLLECTIONS_SUCCESS,
  props<{ payload: { collections: Collection[]; next: string } }>()
);
export const getMoreUserCollectionsFailure = createAction(
  collectionsTypes.GET_MORE_USER_COLLECTIONS_FAILURE,
  props<ErrorType>()
);
//------------GET COLLECTIONS FEES------------
export const getCollectionsFeesStart = createAction(
  collectionsTypes.GET_COLLECTIONS_FEES_START,
  props<{ payload: { useOpenEndpoint?: boolean } }>()
);
export const getCollectionsFeesSuccess = createAction(
  collectionsTypes.GET_COLLECTIONS_FEES_SUCCESS,
  props<{ payload: CollectionsFees }>()
);
export const getCollectionsFeesFailure = createAction(
  collectionsTypes.GET_COLLECTIONS_FEES_FAILURE,
  props<ErrorType>()
);
//------------GET COLLECTION LINK INFO------------
export const getCollectionLinkInfoStart = createAction(
  collectionsTypes.GET_COLLECTION_LINK_INFO_START,
  props<{ payload: { id?: string; type?: string; short_name?: string } }>()
);
export const getCollectionLinkInfoSuccess = createAction(
  collectionsTypes.GET_COLLECTION_LINK_INFO_SUCCESS,
  props<{ payload: CreateCollectionLinkResponse }>()
);
export const getCollectionLinkInfoFailure = createAction(
  collectionsTypes.GET_COLLECTION_LINK_INFO_FAILURE,
  props<ErrorType>()
);

//------------GET COLLECTION INFO------------
export const getCollectionInfoStart = createAction(
  collectionsTypes.GET_COLLECTION_INFO_START,
  props<{ code?: string; link_id?: string }>()
);

export const getCollectionInfoSuccess = createAction(
  collectionsTypes.GET_COLLECTION_INFO_SUCCESS,
  props<{ payload: any }>()
);

export const getCollectionInfoFailure = createAction(collectionsTypes.GET_COLLECTION_INFO_FAILURE);

//------------OTHERS------------
export const updateCollectionInfo = createAction(
  collectionsTypes.UPDATE_COLLECTION_INFO,
  props<{ payload: CollectionInfo }>()
);
export const resetCollectionInfo = createAction(collectionsTypes.RESET_COLLECTION_INFO);
export const resetNewCollection = createAction(
  collectionsTypes.RESET_NEW_COLLECTION,
  props<{ payload: { collectionId: number } }>()
);
