<svg
  [attr.height]="height + 'px'"
  [attr.width]="width + 'px'"
  viewBox="0 0 46 46"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  (click)="onClick.emit()">
  <circle cx="23" cy="23" r="23" [attr.fill]="strokeFillColor.stroke" />
  <circle cx="23.0017" cy="22.9997" r="18.8806" [attr.fill]="strokeFillColor.fill" />
  <path
    d="M23.0011 29.2471H13.4641C12.4634 29.2516 11.5365 28.817 11.0352 28.1084C10.5339 27.3998 10.5352 26.5257 11.0386 25.8181L15.8071 19.0681L20.5756 12.3181C21.0776 11.6109 22.0016 11.1758 23.0011 11.1758C24.0005 11.1758 24.9245 11.6109 25.4266 12.3181L30.1951 19.0681L34.9636 25.8181C35.4669 26.5257 35.4682 27.3998 34.9669 28.1084C34.4657 28.817 33.5387 29.2516 32.5381 29.2471H23.0011Z"
    [attr.fill]="strokeFillColor.stroke" />
  <path
    d="M22.9989 26.8066C23.8829 26.8066 24.5994 26.1351 24.5994 25.3066C24.5994 24.4782 23.8829 23.8066 22.9989 23.8066C22.115 23.8066 21.3984 24.4782 21.3984 25.3066C21.3984 26.1351 22.115 26.8066 22.9989 26.8066Z"
    [attr.fill]="strokeFillColor.fill" />
  <path d="M22.8828 17.2461V21.6201" [attr.stroke]="strokeFillColor.fill" stroke-width="2" stroke-linecap="round" />
</svg>
