<div
  *ngIf="newTransactions && !(getUserPaymentsIsLoading$ | async) && !(getUserCollectionsIsLoading$ | async)"
  class="hidden bg-yellow-200 py-1 px-10 rounded-full text-base font-normal text-gray-900 max-w-fit mx-auto lg:block">
  {{ newTransactions }} new
</div>
<div class="flex flex-col rounded-lg py-6 mb-7">
  <div class="flex items-center justify-between mb-3 px-1.5">
    <span class="text-black text-xs font-medium">Recent transactions</span>
    <a
      *ngIf="!showAllTransactions && !!(combined | async)?.length"
      getElementId
      getElementIdDescription="see_all"
      class="text-gray-700 font-medium text-xs cursor-pointer underline"
      (click)="router.navigate(['/transactions'])">
      See all
    </a>
  </div>

  <div *ngIf="(getUserCollectionsIsLoading$ | async) || (getUserPaymentsIsLoading$ | async); else notLoading">
    <div class="flex gap-y-2 flex-col">
      <bey-recent-transactions-item-skeleton
        *ngFor="let i of [].constructor(6)"></bey-recent-transactions-item-skeleton>
    </div>
  </div>

  <ng-template #notLoading>
    <div
      class="relative rounded-lg overflow-hidden lg:rounded-none"
      *ngIf="!!(combined | async)?.length; else noTransactions">
      <table class="w-full lg:border-separate lg:border-spacing-x-0 lg:border-spacing-y-1">
        <tbody>
          <tr
            class="h-full border-b border-gray-100 cursor-pointer bg-white"
            *ngFor="let transaction of combined | async; let lastItem = last; let index = index"
            home-transaction-item
            (click)="transactionItmClick(transaction)"
            [isAnimation]="isAnimating"
            [transaction]="transaction"
            [ngClass]="{
              'last-item': lastItem,
              'new-transaction-item': transaction.newTransaction
            }"></tr>
        </tbody>
      </table>

      <bey-recent-transactions-item-skeleton
        *ngIf="
          (getMoreUserCollectionsIsLoading$ | async) || (getMoreUserPaymentsIsLoading$ | async)
        "></bey-recent-transactions-item-skeleton>
      <div
        *ngIf="!((getMoreUserCollectionsIsLoading$ | async) || (getMoreUserPaymentsIsLoading$ | async))"
        class="w-full absolute bottom-0"
        observeVisibility
        (visible)="onBottomSeen()">
        &nbsp;
      </div>
      <div
        *ngIf="this.showAllTransactions && combinedArrayLength >= 250"
        class="w-full flex items-center justify-center lg:py-9">
        <span class="text-base text-gray-600"
          >Need to see older records? Please check your previous month’s statement. Statements are automatically sent to
          your email each month.</span
        >
      </div>
    </div>
    <ng-template #noTransactions>
      <div class="w-full mx-auto flex flex-col items-center mt-10">
        <img src="assets/images/no-transactions.png" alt="no transactions" class="mb-3" />
        <span class="text-lg text-gray-800 font-medium mb-2.5 text-center">No transactions yet</span>
        <span class="text-base font-normal text-gray-500 text-center"
          >After your first transaction, you will be able to <br />
          view it here</span
        >
      </div>
    </ng-template>
  </ng-template>
</div>
