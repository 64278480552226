import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryFilterPipe',
  standalone: true,
})
export class CountryFilterPipe implements PipeTransform {
  transform(items: Array<any>, filter: string): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter(
      (v) =>
        v.label?.toLowerCase().includes(filter?.toLowerCase()) ||
        v?.value?.toLowerCase().includes(filter?.toLowerCase())
    );
  }
}
