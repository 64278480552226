<div class="h-full relative">
  <bey-with-initials-header
    [name]="businessInfo?.active_merchant_name"
    [backButton]="backButton"
    (onBack)="location.back()"
    class="lg:hidden"></bey-with-initials-header>

  <div class="hidden lg:block bg-blue-500 h-[385px] fix relative">
    <div class="flex justify-between px-16 pt-10 items-center">
      <div>
        <img src="assets/images/logo/full-white.svg" alt="logo" width="155" height="55" class="w-40" />
      </div>
      <div *ngIf="!!!(userDetails$ | async)" class="relative z-20 text-white text-xl py-2 leading-[30px] font-medium">
        Already have an account?
        <button routerLink="/login" class="pl-1 pr-6 underline">Login</button>
      </div>
    </div>

    <svg
      class="absolute left-16 top-16"
      width="35"
      height="32"
      viewBox="0 0 35 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="26.4577"
        height="25.3646"
        rx="4"
        transform="matrix(0.883372 -0.468673 0.531994 0.846748 -1 11.4004)"
        fill="#A1D1F5"
        fill-opacity="0.1" />
    </svg>

    <svg
      class="absolute left-64 top-26"
      width="103"
      height="95"
      viewBox="0 0 103 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="80.1362"
        height="86.5029"
        rx="4"
        transform="matrix(0.236463 -0.97164 0.979529 0.201302 -0.703125 77.8027)"
        fill="#DFE7FC"
        fill-opacity="0.14" />
    </svg>

    <svg
      class="absolute left-2/4 top-2/4"
      width="35"
      height="32"
      viewBox="0 0 35 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="26.4577"
        height="25.3646"
        rx="4"
        transform="matrix(0.883372 -0.468673 0.531994 0.846748 -1 11.4004)"
        fill="#A1D1F5"
        fill-opacity="0.1" />
    </svg>

    <svg
      class="absolute left-3/4 top-3/4"
      width="139"
      height="128"
      viewBox="0 0 139 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="107.842"
        height="116.41"
        rx="4"
        transform="matrix(0.236463 -0.97164 0.979529 0.201302 0 104.783)"
        fill="#DFE7FC"
        fill-opacity="0.14" />
    </svg>

    <svg
      class="absolute right-4 top-1/4"
      width="162"
      height="149"
      viewBox="0 0 162 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="117.96"
        height="113.086"
        rx="4"
        transform="matrix(0.883372 -0.468673 0.531994 0.846748 -1.3125 54.2832)"
        fill="#A1D1F5"
        fill-opacity="0.1" />
    </svg>
  </div>

  <div class="mt-[42px] text-center lg:mb-20 mx-auto">
    <div class="text-gray-500 leading-[19px] font-medium">{{ businessInfo?.active_merchant_name }}</div>
    <div class="text-blue-400 mt-[5px] leading-[19px] font-medium max-w-[95%] mx-auto">
      <a
        [href]="staticLink"
        class="w-min mx-auto border-b border-solid border-blue-400 leading-8 break-words block whitespace-nowrap"
        >{{ staticLink }}</a
      >
    </div>

    <div
      class="w-full bg-white border border-gray-100 rounded-lg mx-auto px-4 pt-2 text-left mt-11 lg:pt-6 lg:px-6 lg:shadow-xl max-w-[500px]">
      <div class="flex space-x-4 items-center text-bey-red">
        <bey-exclamation-icon variant="danger"></bey-exclamation-icon>
        <span class="font-medium leading-[19px] lg:text-xl lg:leading-[29px]">Expired Link</span>
      </div>
      <div class="text-sm leading-[16px] text-gray-800 mt-4 mb-7 lg:text-lg lg:leading-[23px]">
        The link you received from {{ businessInfo?.active_merchant_name }} has expired. You can send them an equal
        amount via their Onafriq link above, or ask them to resend the link.
      </div>
    </div>
  </div>

  <bey-sign-up-callout
    header="Is sending and receiving payments a hassle?"
    body="Onafriq makes it so much easier."
    linkText="Start your free trial"
    classList="mt-14 max-w-[500px]"
    (trialStart)="handleSignUp()"></bey-sign-up-callout>
</div>
