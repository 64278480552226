<div class="flex flex-col py-8 px-4 items-center">
  <span class="text-gray-800 text-2xl font-medium mb-4">{{
    data?.['amount'] ? 'Top up Initiated' : 'Unable to Top Up'
  }}</span>
  <svg
    *ngIf="data?.['amount']; else failedIcon"
    width="111"
    height="109"
    viewBox="0 0 111 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="46.6583" cy="62.3417" r="46.6583" fill="#99DBFF" />
    <g clip-path="url(#clip0_1355_10337)">
      <path
        d="M92.9642 24.0107C92.6272 23.6788 92.125 23.5794 91.6871 23.7583L25.0447 50.9813C24.6153 51.1568 24.3282 51.5669 24.3104 52.0305C24.2927 52.4942 24.5475 52.9252 24.9624 53.133L41.7663 61.5509L46.1815 73.3516C46.3549 73.8151 46.7974 74.1198 47.2885 74.1198C47.3062 74.1198 47.324 74.1195 47.3416 74.1187C47.6196 74.1063 47.874 73.9949 48.072 73.8189L48.074 73.8214L54.6303 67.9954L71.7654 76.5785C71.9316 76.6618 72.113 76.7038 72.2949 76.7038C72.4383 76.7038 72.582 76.6776 72.7187 76.625C73.0289 76.506 73.2747 76.2618 73.3958 75.9524L93.2354 25.2839C93.4077 24.8434 93.3012 24.3427 92.9642 24.0107ZM83.6573 29.5929L61.1215 46.0919L34.5064 49.6705L83.6573 29.5929ZM76.3494 37.8739L63.7216 49.5813L62.9985 47.6486L76.3494 37.8739ZM29.4505 52.7365L57.1291 49.0147L42.8661 59.4569L29.4505 52.7365ZM48.5539 63.8854L47.1172 69.1046L44.2305 61.3891L61.0165 49.0995L61.8471 51.3193L48.89 63.3321C48.7287 63.4817 48.6121 63.6732 48.5539 63.8854ZM49.4883 69.4004L50.4514 65.9017L52.3534 66.8543L49.4883 69.4004ZM51.7484 63.9067L65.3305 51.3146L70.5591 73.3293L51.7484 63.9067ZM72.5719 71.5708L67.3224 49.4678L89.1504 29.2306L72.5719 71.5708Z"
        fill="#006FAB" />
      <circle cx="89.0034" cy="57.6367" r="2.7446" fill="#31B1F7" />
      <circle cx="38.8169" cy="31.759" r="2.7446" fill="#31B1F7" />
    </g>
    <line
      x1="36.8662"
      y1="74.2652"
      x2="23.2943"
      y2="87.8371"
      stroke="#006FAB"
      stroke-width="3"
      stroke-linecap="round" />
    <line
      x1="25.8877"
      y1="63.2868"
      x2="21.7356"
      y2="67.4389"
      stroke="#006FAB"
      stroke-width="3"
      stroke-linecap="round" />
    <line
      x1="59.8271"
      y1="82.3398"
      x2="57.2434"
      y2="84.9235"
      stroke="#006FAB"
      stroke-width="3"
      stroke-linecap="round" />
    <line
      x1="51.7559"
      y1="89.9487"
      x2="49.1721"
      y2="92.5324"
      stroke="#006FAB"
      stroke-width="3"
      stroke-linecap="round" />
    <line
      x1="18.0361"
      y1="93.0854"
      x2="15.4524"
      y2="95.6691"
      stroke="#006FAB"
      stroke-width="3"
      stroke-linecap="round" />
    <circle cx="106.647" cy="31.3669" r="3.92086" fill="#CAECFF" />
    <circle cx="86.2588" cy="2.35252" r="2.35252" fill="#CAECFF" />
    <defs>
      <clipPath id="clip0_1355_10337">
        <rect width="69.0072" height="69.0072" fill="white" transform="translate(24.3096 15.6835)" />
      </clipPath>
    </defs>
  </svg>

  <ng-template #failedIcon>
    <img src="../../../../../assets/images/failed-top-up.png" />
  </ng-template>

  <span class="text-base my-5 text-gray-800 font-normal text-center"
    >{{
      data?.['amount']
        ? 'Your top up has been initiated. We will confirm the successful top up via SMS in a moment.'
        : 'Your top up was unsuccessful. Please check your mobile money balance and try again. '
    }}
  </span>
  <span *ngIf="data?.['amount']" class="text-base mb-5 text-gray-800 font-normal text-center"
    >Your wallet will be credited
    <span class="font-medium whitespace-nowrap">
      {{ data?.['amount'] | currency: data?.['currency'] + ' ' }}</span
    ></span
  >
  <mat-dialog-actions>
    <bey-button
      [placeholder]="data?.['amount'] ? 'Got it' : 'Resend prompt'"
      color="yellow"
      [mat-dialog-close]="true"
      [small]="true"></bey-button>
  </mat-dialog-actions>
</div>
