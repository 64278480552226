import { Component, ContentChildren, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { selectDrawerData, selectDrawerIsOpen } from 'src/app/ngrx/misc/misc.selectors';
import { MiscService } from 'src/app/ngrx/misc/misc.service';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'bey-drawer',
  templateUrl: './bey-drawer.component.html',
  styleUrls: ['./bey-drawer.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgFor, AsyncPipe],
})
export class BeyDrawerComponent implements OnInit, OnDestroy {
  isOpen$: Observable<boolean>;
  hideOnWeb: boolean;

  @ContentChildren('content')
  contents: QueryList<any>;

  subs$: Subscription = new Subscription();

  constructor(private store$: Store, public miscService: MiscService) {}

  ngOnInit(): void {
    this.isOpen$ = this.store$.select(selectDrawerIsOpen);

    this.subs$.add(
      this.store$.select(selectDrawerData).subscribe((data) => {
        this.hideOnWeb = data?.['hideOnWeb'];
      })
    );
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
