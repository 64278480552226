import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, skipWhile } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectUserKycDetails } from '../../../ngrx/KYC/kyc.selectors';
import { map } from 'rxjs/operators';
import { getUserKycStart } from '../../../ngrx/KYC/kyc.actions';
import { selectUserDetails } from '../../../ngrx/user/user.selectors';
import { User } from 'src/app/ngrx/user/user.interfaces';
import { MiscService } from 'src/app/ngrx/misc/misc.service';

@Injectable({
  providedIn: 'root',
})
export class HasKycGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store, private router: Router, private miscService: MiscService) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.statusCheck();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.statusCheck();
  }

  statusCheck() {
    // ASYNC GUARD
    return this.store.pipe(
      select(selectUserKycDetails),
      skipWhile((userKycState) => {
        /// If the state on the store is NULL then we dispatch action to populate it
        // This usually happens if you refresh the page and since guards run before NgRx actions we need this
        if (!userKycState) {
          this.store.dispatch(getUserKycStart());
        }
        // When the state of KYC changes from null ===> Object then proceed guard logic
        return !userKycState;
      }),

      map((userKycInfo) => {
        // check if the user has any info
        if (userKycInfo.status === 'new') {
          this.router.navigate(['/registration/kyc']);
          return false;
        }
        // in case of the KYC rejection
        else if (userKycInfo.status === 'rejected') {
          this.router.navigate(['/registration/kyc/rejected']);
          return false;
        } else if (userKycInfo.business) {
          // this prevents looping since the business on KYC is populated on the store before the last else statement
          return true;
        } else {
          //   check if the user has business ID
          //    if the user already provided their partial KYB info otherwise redirect to KYB registration page
          let isMember: boolean = this.miscService.isMember();
          let businessId: number;
          let involvedBusiness: User['businesses'][0];

          this.store
            .select(selectUserDetails)
            .subscribe((user) => {
              let { businesses, business_id } = user;
              businessId = business_id;
              involvedBusiness = businesses[0];
            })
            .unsubscribe();

          // if the user doesn't have business then we route him for the kyb process
          // but ONLY if he's a business owner not a member
          if (!(isMember || businessId)) {
            this.router.navigate(['/registration/kyb']);
            return false;
            // if a member already uploaded his kyc and they're still pending,
            // then we block him from accessing other pages and we redirect him to '/accept-invite/under-review'
          } else if (isMember && userKycInfo.status !== 'approved') {
            this.router.navigate(['/accept-invite/under-review'], { state: { businessName: involvedBusiness.name } });
            return true;
          }

          return true;
        }
      })
    );
  }
}
