<div class="w-full h-full overflow-y-scroll flex flex-col">
  <div class="bg-blue-50">
    <img
      src="assets/images/backgrounds/now-onafriq.svg"
      class="object-fit object-bottom h-full w-full max-w-[70%] mx-auto" />
  </div>
  <div class="w-full flex flex-col items-center max-w-96 mx-auto p-3">
    <span class="text-black font-medium text-lg lg:text-2xl mb-4 mt-10 text-center">Beyonic is now Onafriq!</span>
    <span class="text-black text-base mb-10 text-center"
      >We have updated Beyonic to fully match our brand, Onafriq. Get the same value with a fresh new look ✨
    </span>
    <bey-button
      (onClick)="onButtonClick.emit()"
      placeholder="Got it"
      class="w-full mb-10"
      classList="w-full py-3"></bey-button>
  </div>
</div>
