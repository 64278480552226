import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PaymentsService } from '../../../ngrx/payments/payments.service';
import { CollectionsService } from '../../../ngrx/collections/collections.service';

@Injectable({
  providedIn: 'root',
})
export class SuccessfulTransactionResolver implements Resolve<boolean> {
  constructor(
    private paymentsService: PaymentsService,
    private collectionsService: CollectionsService,
    private router: Router
  ) {}

  // todo remove when the new public payments endpoint is added
  // a temp function to transform to unified version
  mapTransaction(transaction) {
    const {
      code,
      amount,
      amount_currency,
      description,
      redacted_contact,
      contact,
      network_reference,
      formatted_payment_date,
      payment_date,
      business,
      public_business_data,
      beyonic_link,
    } = transaction;

    const { first_name, last_name, bank_account_or_number } = redacted_contact || contact;

    return {
      id: code,
      business_id: 1,
      business_name: business?.active_merchant_name || public_business_data?.active_merchant_name,
      amount: amount,
      amount_currency: amount_currency,
      description: description,
      contact: {
        first_name,
        last_name,
        bank_account_or_number,
      },
      link_id: network_reference,
      payment_request_id: 1,
      formatted_payment_date: formatted_payment_date,
      payment_date: payment_date,
      expiry_date: new Date(),
      is_active: true,
      status: 'successful',
      beyonic_link,
    };
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // Param map has route params e.g. /:code and queryParamMap has access to ?code=XXXX
    const code = route.queryParamMap.get('code') || route.paramMap.get('code');
    const link_id = route.queryParamMap.get('link_id');
    const type = route.paramMap.get('type');
    if (!code && !link_id) {
      return of(null);
    }

    if (type === 'payments' || type === 'p') {
      return this.paymentsService.fetchPaymentInfoByCode(code).pipe(
        map((d) => {
          return this.mapTransaction(d);
        }),
        catchError((e) => {
          console.error(e);
          this.router.navigate(['/error']);
          return EMPTY;
        })
      );
    } else if (['collections', 'bl', 'sl', 'c'].includes(type)) {
      return this.collectionsService.getCollectionInfoByCode(code).pipe(
        map((d) => {
          return this.mapTransaction(d);
        }),
        catchError((e) => {
          console.error(e);
          this.router.navigate(['/error']);
          return of(null);
        })
      );
    } else if (type === 'ul') {
      return this.collectionsService.getCollectionInfoByLinkId(link_id).pipe(
        map((d) => {
          return this.mapTransaction(d);
        }),
        catchError((e) => {
          console.error(e);
          this.router.navigate(['/error']);
          return of(null);
        })
      );
    }

    return of(null);
  }
}
