<div class="wrapper bg-gray-800 relative mt-[-16px] mx-[-12px] lg:m-0" [ngClass]="{ 'bg h-20': !noBackground }">
  <div
    class="flex flex-col justify-center pt-[41px] pb-[35px] pl-[27px] lg:px-16 h-full max-w-screen-2xl mx-auto"
    [ngClass]="{ '!p-0': noBackground, 'lg:min-h-[320px]': !noBackground }">
    <div class="flex justify-between items-center hidden lg:flex lg:px-4 lg:my-3">
      <div>
        <img src="/assets/images/logo/full-white.svg" class="w-[174px]" alt="beyonic logo" />
      </div>
      <div class="text-white">
        <span>Already have an account?</span>
        <button (click)="onLoginClick()" class="h-[46px] mx-2 underline" *ngIf="!!!(userDetails$ | async)">
          Login
        </button>
      </div>
    </div>
    <div class="bg-gray-50 [&_*]:!text-gray-800">
      <div class="lg:max-w-[540px] lg:mx-auto">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
