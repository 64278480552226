import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { BeyTabComponent } from './bey-tab/bey-tab.component';
import { xAxisSwipeDirection } from '../../../../../assets/utility';
import { NgFor, NgClass } from '@angular/common';

@Component({
  selector: 'section[bey-tab-group]',
  templateUrl: './bey-tab-group.component.html',
  styleUrls: ['./bey-tab-group.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass],
})
export class BeyTabGroupComponent implements OnInit, AfterContentInit, AfterViewInit {
  /****
   * List of tabs passed down via content projection
   */
  @ContentChildren(BeyTabComponent)
  tabs: QueryList<BeyTabComponent>;

  /***
   * Element Reference
   */
  @ViewChild('wrapper')
  wrapper: ElementRef;

  /***
   * Initial tab controlled by the parent
   */
  @Input()
  initialTab: number;

  public tabIndex: number = 0;
  public warpWidth: number = 0;

  constructor() {}

  ngOnInit(): void {}

  public ngAfterContentInit(): void {
    // set initial page index
    if (this.initialTab) {
      this.selectTab(this.initialTab);
    }

    // get all active tabs
    let activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(0);
    }
  }

  selectTab(i) {
    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => (tab.active = false));

    let tab = this.tabs.toArray()[i];

    // activate the tab the user has clicked on.
    tab.active = true;
    this.tabIndex = i;
  }

  get numberOfTabs() {
    return this.tabs?.length;
  }

  get barWidth() {
    return this.warpWidth / this.numberOfTabs;
  }

  get left() {
    if (this.tabIndex === 0) {
      return '0%';
    } else {
      return (this.tabIndex / this.numberOfTabs) * 100 + '%';
    }
  }

  onSwipe(event) {
    const dir: 'l' | 'r' = xAxisSwipeDirection(event);
    let id: number;

    if (dir === 'l') {
      id = this.tabIndex + 1 < this.numberOfTabs ? this.tabIndex + 1 : null;
    } else {
      id = this.tabIndex - 1 >= 0 ? this.tabIndex - 1 : null;
    }

    if (id !== null) {
      this.selectTab(id);
    }
  }

  ngAfterViewInit(): void {
    Promise.resolve().then(() => (this.warpWidth = this.wrapper?.nativeElement.offsetWidth));
  }
}
