<div [@triggerName]="prepareRoute(outlet)" class="h-full">
  <div class="h-full w-full bg-white fixed top-0 z-100" *ngIf="isLoading">
    <mat-progress-bar color="primary" mode="indeterminate"> </mat-progress-bar>

    <div class="flex justify-center items-center h-full">
      <img src="assets/images/logo/onafriq-black.svg" alt="beyonic logo" class="h-20" />
    </div>
  </div>

  <router-outlet #outlet="outlet"></router-outlet>
</div>
