import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TransactionsUtilityService } from 'src/app/modules/shared/services/transactions-utility.service';
import { CreatePaymentRequestBody } from 'src/app/ngrx/payments/payments.interfaces';
import { ApplyRoundingRulesPipe } from '../../../shared/pipes/apply-rounding-rules.pipe';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { NgIf, TitleCasePipe, CurrencyPipe } from '@angular/common';
import { retrieveOperatingCountry } from '../../../../../assets/utility/shared';

@Component({
  selector: 'app-post-create-payment-dialog-content',
  templateUrl: './post-create-payment-dialog-content.component.html',
  styleUrls: ['./post-create-payment-dialog-content.component.scss'],
  standalone: true,
  imports: [NgIf, BeyButtonComponent, MatLegacyDialogModule, TitleCasePipe, CurrencyPipe, ApplyRoundingRulesPipe],
})
export class PostCreatePaymentDialogContentComponent implements OnInit {
  currency: string;
  country: string;
  currencyPipeDigitalInfo: string;

  constructor(
    public transactionsUtility: TransactionsUtilityService,
    @Inject(MAT_DIALOG_DATA) public data: { payload: CreatePaymentRequestBody; success: boolean }
  ) {
    this.currency = data?.payload?.currency || 'UGX';
    this.country = retrieveOperatingCountry(this.currency, 'currency')?.name;
    this.currencyPipeDigitalInfo = this.transactionsUtility.retrieveCurrencyPipeDigitalInfo(this.country);
  }

  ngOnInit(): void {}

  get dialogTitle() {
    if (this.data?.['success']) {
      return 'Request successful';
    }

    return this.data?.['type'] === 'bank' ? 'Something went wrong' : 'Insufficient funds';
  }
}
