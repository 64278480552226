<nav
  class="hidden lg:flex h-full flex-col relative justify-between bg-[#2F3235] w-[200px] rounded-br-[75px] after:bg-gray-100 after:inset-0 after:absolute after:-z-1">
  <div class="overflow-y-auto">
    <img width="144" src="/assets/images/logo/onafriq.svg" alt="Beyonic logo" class="my-7 mx-auto" />

    <a
      class="home"
      routerLinkActive="active"
      getElementId
      getElementIdDescription="home"
      routerLink="/"
      [routerLinkActiveOptions]="{ exact: true }">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.8186 2.30345C10.5258 2.07574 10.3794 1.96189 10.2178 1.91812C10.0752 1.87951 9.92484 1.87951 9.78221 1.91812C9.62057 1.96189 9.47418 2.07574 9.18141 2.30345L3.52949 6.69939C3.15168 6.99324 2.96278 7.14017 2.82669 7.32417C2.70614 7.48716 2.61633 7.67077 2.56169 7.866C2.5 8.08639 2.5 8.3257 2.5 8.80433V14.8334C2.5 15.7669 2.5 16.2336 2.68166 16.5901C2.84144 16.9037 3.09641 17.1587 3.41002 17.3185C3.76654 17.5001 4.23325 17.5001 5.16667 17.5001H6.83333C7.06669 17.5001 7.18337 17.5001 7.2725 17.4547C7.3509 17.4147 7.41464 17.351 7.45459 17.2726C7.5 17.1835 7.5 17.0668 7.5 16.8334V11.3334C7.5 10.8667 7.5 10.6334 7.59083 10.4551C7.67072 10.2983 7.79821 10.1708 7.95501 10.0909C8.13327 10.0001 8.36662 10.0001 8.83333 10.0001H11.1667C11.6334 10.0001 11.8667 10.0001 12.045 10.0909C12.2018 10.1708 12.3293 10.2983 12.4092 10.4551C12.5 10.6334 12.5 10.8667 12.5 11.3334V16.8334C12.5 17.0668 12.5 17.1835 12.5454 17.2726C12.5854 17.351 12.6491 17.4147 12.7275 17.4547C12.8166 17.5001 12.9333 17.5001 13.1667 17.5001H14.8333C15.7668 17.5001 16.2335 17.5001 16.59 17.3185C16.9036 17.1587 17.1586 16.9037 17.3183 16.5901C17.5 16.2336 17.5 15.7669 17.5 14.8334V8.80433C17.5 8.3257 17.5 8.08639 17.4383 7.866C17.3837 7.67077 17.2939 7.48716 17.1733 7.32417C17.0372 7.14017 16.8483 6.99324 16.4705 6.69939L10.8186 2.30345Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>

      <span>Home</span>
    </a>

    <div
      class="flex flex-col"
      HasPermission
      [expectedPermission]="['business_org_payments_create', 'business_org_payments_view']"
      [strictCheck]="false">
      <div (click)="onTransferClick()" class="cursor-pointer flex items-center justify-between w-full p-3">
        <div class="flex items-center gap-3">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.33325 14.1667H16.6666M16.6666 14.1667L13.3333 10.8333M16.6666 14.1667L13.3333 17.5M16.6666 5.83333H3.33325M3.33325 5.83333L6.66659 2.5M3.33325 5.83333L6.66659 9.16667"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>

          <span class="text-white">Transfer</span>
        </div>
        <bey-chevron
          class="origin-center"
          strokeFillColor="#ffffff"
          [width]="20"
          [height]="20"
          [direction]="transferMenuIsOpen ? 'up' : 'down'"></bey-chevron>
      </div>

      <div
        [ngClass]="transferMenuIsOpen ? 'max-h-48' : 'max-h-1'"
        class="overflow-hidden transition-all duration-500 ease-in-out">
        <a
          class="home"
          routerLinkActive="active"
          getElementId
          getElementIdDescription="send-money"
          routerLink="/payments"
          [routerLinkActiveOptions]="{ exact: true }"
          HasPermission
          [expectedPermission]="['business_org_payments_create']">
          <span class="ml-8">Send Money</span>
        </a>

        <a
          routerLinkActive="active"
          getElementId
          getElementIdDescription="bulk-payments"
          routerLink="/payments/bulk"
          [routerLinkActiveOptions]="{ exact: true }"
          HasPermission
          [expectedPermission]="['business_org_payments_view']">
          <span class="ml-8">Bulk Payments</span>
        </a>
      </div>
    </div>

    <a routerLinkActive="active" getElementId getElementIdDescription="transactions" routerLink="/transactions">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.6666 9.1665H6.66659M8.33325 12.4998H6.66659M13.3333 5.83317H6.66659M16.6666 5.6665V14.3332C16.6666 15.7333 16.6666 16.4334 16.3941 16.9681C16.1544 17.4386 15.772 17.821 15.3016 18.0607C14.7668 18.3332 14.0667 18.3332 12.6666 18.3332H7.33325C5.93312 18.3332 5.23306 18.3332 4.69828 18.0607C4.22787 17.821 3.84542 17.4386 3.60574 16.9681C3.33325 16.4334 3.33325 15.7333 3.33325 14.3332V5.6665C3.33325 4.26637 3.33325 3.56631 3.60574 3.03153C3.84542 2.56112 4.22787 2.17867 4.69828 1.93899C5.23306 1.6665 5.93312 1.6665 7.33325 1.6665H12.6666C14.0667 1.6665 14.7668 1.6665 15.3016 1.93899C15.772 2.17867 16.1544 2.56112 16.3941 3.03153C16.6666 3.56631 16.6666 4.26637 16.6666 5.6665Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>

      <span>History</span>
    </a>
    <a routerLink="/links" routerLinkActive="active" getElementId getElementIdDescription="links">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.5896 15.3034L9.41104 16.4819C7.78386 18.1091 5.14567 18.1091 3.51848 16.4819C1.8913 14.8547 1.8913 12.2165 3.51848 10.5893L4.697 9.4108M15.3036 10.5893L16.4821 9.4108C18.1093 7.78361 18.1093 5.14542 16.4821 3.51824C14.8549 1.89106 12.2167 1.89106 10.5896 3.51824L9.41104 4.69675M7.08363 12.9167L12.917 7.08337"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>

      <span>Links</span>
    </a>

    <a routerLinkActive="active" getElementId getElementIdDescription="profile" routerLink="/profile">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_563_3816)">
          <path
            d="M4.43033 16.1985C4.93727 15.0042 6.12085 14.1665 7.50008 14.1665H12.5001C13.8793 14.1665 15.0629 15.0042 15.5698 16.1985M13.3334 7.9165C13.3334 9.75745 11.841 11.2498 10.0001 11.2498C8.15913 11.2498 6.66675 9.75745 6.66675 7.9165C6.66675 6.07555 8.15913 4.58317 10.0001 4.58317C11.841 4.58317 13.3334 6.07555 13.3334 7.9165ZM18.3334 9.99984C18.3334 14.6022 14.6025 18.3332 10.0001 18.3332C5.39771 18.3332 1.66675 14.6022 1.66675 9.99984C1.66675 5.39746 5.39771 1.6665 10.0001 1.6665C14.6025 1.6665 18.3334 5.39746 18.3334 9.99984Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_563_3816">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <span>Profile</span>
    </a>

    <a
      routerLink="/billing"
      routerLinkActive="active"
      getElementId
      getElementIdDescription="billing"
      HasPermission
      [expectedPermission]="['business_org_billing_attempts_view']">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.75 11.6667H13.7583M2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V7.5C17.5 6.57953 16.7538 5.83333 15.8333 5.83333L4.16667 5.83333C3.24619 5.83333 2.5 5.08714 2.5 4.16667ZM2.5 4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H14.1667M14.1667 11.6667C14.1667 11.8968 13.9801 12.0833 13.75 12.0833C13.5199 12.0833 13.3333 11.8968 13.3333 11.6667C13.3333 11.4365 13.5199 11.25 13.75 11.25C13.9801 11.25 14.1667 11.4365 14.1667 11.6667Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>

      <span>Billing</span>
    </a>
    <a target="_blank" href="https://apphelp.onafriq.com/en/" getElementId getElementIdDescription="faq">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_563_3870)">
          <path
            d="M7.57508 7.49984C7.771 6.94289 8.15771 6.47326 8.66671 6.17411C9.17571 5.87497 9.77416 5.76562 10.3561 5.86543C10.938 5.96524 11.4658 6.26777 11.846 6.71944C12.2262 7.17111 12.4343 7.74277 12.4334 8.33317C12.4334 9.99984 9.93342 10.8332 9.93342 10.8332M10.0001 14.1665H10.0084M18.3334 9.99984C18.3334 14.6022 14.6025 18.3332 10.0001 18.3332C5.39771 18.3332 1.66675 14.6022 1.66675 9.99984C1.66675 5.39746 5.39771 1.6665 10.0001 1.6665C14.6025 1.6665 18.3334 5.39746 18.3334 9.99984Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_563_3870">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <span>FAQ</span>
    </a>
  </div>
</nav>
