import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { Wallet, WalletsState } from './wallets.interfaces';

const selectWallets = (state: AppState) => {
  return state.wallets;
};

//---------1ST LEVEL SELECTORS---------
export const selectUserWallet = createSelector(selectWallets, (state: WalletsState) => state.userWallet);

export const selectUserTransactionLimits = createSelector(selectUserWallet, (state) => state?.transaction_limits);

export const selectUserBillings = createSelector(selectWallets, (state: WalletsState) => state?.userBillings?.results);

export const selectUserNextBillings = createSelector(selectWallets, (state: WalletsState) => state.userBillings?.next);

export const selectUserBalance = createSelector(selectWallets, (state: WalletsState) => state.userBalance);

export const selectSubscriptionInfo = createSelector(selectWallets, (state: WalletsState) => state.subscriptionInfo);

export const selectWalletLimitsShownAlert = createSelector(
  selectWallets,
  (state: WalletsState) => state.walletLimitsShownAlert
);

export const selectWalletsIsLoading = createSelector(selectWallets, (state: WalletsState) => state.isLoading);

export const selectWalletsErrorMessages = createSelector(selectWallets, (state: WalletsState) => state.errorMessages);

//---------2ND LEVEL SELECTORS---------
export const selectUserWalletCurrency = createSelector(selectUserWallet, (wallet: Wallet) => wallet?.unit_currency);

export const selectGetUserWalletIsLoading = createSelector(
  selectWalletsIsLoading,
  (isLoading) => isLoading['getUserWallet']
);
export const selectGetUserWalletErrorMessage = createSelector(
  selectWalletsErrorMessages,
  (errorMessages) => errorMessages['getUserWallet']
);

export const selectGetUserWalletsIsLoading = createSelector(
  selectWalletsIsLoading,
  (isLoading) => isLoading['getUserWallets']
);
export const selectGetUserWalletsErrorMessage = createSelector(
  selectWalletsErrorMessages,
  (errorMessages) => errorMessages['getUserWallets']
);

export const selectGetUserTransactionsIsLoading = createSelector(
  selectWalletsIsLoading,
  (isLoading) => isLoading['getUserTransactions']
);
export const selectGetUserTransactionsErrorMessage = createSelector(
  selectWalletsErrorMessages,
  (errorMessages) => errorMessages['getUserTransactions']
);

export const selectGetUserBalanceIsLoading = createSelector(
  selectWalletsIsLoading,
  (isLoading) => isLoading['getUserBalance']
);
export const selectGetUserBalanceErrorMessage = createSelector(
  selectWalletsErrorMessages,
  (errorMessages) => errorMessages['getUserBalance']
);

export const selectGetUserBillingsIsLoading = createSelector(
  selectWalletsIsLoading,
  (isLoading) => isLoading['getUserBillings']
);
export const selectGetUserBillingsErrorMessage = createSelector(
  selectWalletsErrorMessages,
  (errorMessages) => errorMessages['getUserBillings']
);

export const selectGetSubscriptionPriceIsLoading = createSelector(
  selectWalletsIsLoading,
  (isLoading) => isLoading['getSubscriptionPrice']
);
export const selectGetSubscriptionPriceErrorMessage = createSelector(
  selectWalletsErrorMessages,
  (errorMessages) => errorMessages['getSubscriptionPrice']
);

export const selectSevenDaysSummary = createSelector(
  selectUserWallet,
  (state: Wallet) => state?.seven_day_transactions_summary
);

export const selectPayNowIsLoading = createSelector(selectWalletsIsLoading, (isLoading) => isLoading['payNow']);

export const selectPayNowError = createSelector(selectWalletsErrorMessages, (e) => e['payNow']);

export const selectPayNowBillingAttempt = createSelector(selectWallets, (billings) => billings.userBillings?.payNow);
