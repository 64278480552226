import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BeyContactSelectorInterface } from '../../../../../assets/interfaces/bey-contact-selector.interface';
import parsePhoneNumber from 'libphonenumber-js/min';
import { getUserContactsStart } from '../../../../ngrx/contacts/contacts.actions';
import { Store } from '@ngrx/store';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { selectUserDetails } from '../../../../ngrx/user/user.selectors';
import { FormUtilityService } from 'src/app/modules/core/services/form-utility.service';
import { NameInitialsPipe } from '../../pipes/name-initials.pipe';
import { BeyFieldErrorMessageComponent } from '../bey-field-error-message/bey-field-error-message.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NgIf, NgClass, NgFor, TitleCasePipe } from '@angular/common';
import { GetElementIdDirective } from '../../directives/get-element-id.directive';
import { FormatPhonePipe } from '../../pipes/format-phone.pipe';

/****
 *  Optionally we can use [(ngModel)]="var" on this component
 */
const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BeyContactSelectorComponent),
  multi: true,
};

@Component({
  selector: 'bey-contact-selector',
  templateUrl: './bey-contact-selector.component.html',
  styleUrls: ['./bey-contact-selector.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    SpinnerComponent,
    NgFor,
    BeyFieldErrorMessageComponent,
    TitleCasePipe,
    NameInitialsPipe,
    FormatPhonePipe,
    GetElementIdDirective,
  ],
})
export class BeyContactSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private searchTextChanged$ = new Subject<string>();
  private subscriptions$ = new Subscription();

  /**
   * Contact options following BeyContactSelectorInterface interface
   */
  @Input()
  options: Array<BeyContactSelectorInterface>;

  /***
   * Make the selector a readonly state
   */
  @Input()
  readonly: boolean = false;

  /***
   * The name of the property that we're using to find the option's name from the options array
   */
  @Input()
  bindLabel: string = 'label';

  /***
   * Hide the validation feedback if some other method is used to show that
   */
  @Input()
  hideValidationFeedback: boolean = false;

  /***
   * Toggle spinner on the component
   */
  @Input()
  isLoading: boolean = false;

  /***
   * This will toggle the filler element to fill the space created by the label on the Country code sister component
   */
  @Input()
  labelPresent: boolean = false;

  /****
   * placeholder which shows a content inside the component
   */
  @Input()
  placeholder: string = '';

  /****
   * label which shows a content above the component
   */
  @Input()
  label: string;

  /***
   * if it's true then the component here isn't used for phone number field
   */
  @Input()
  notPhoneNumber: boolean = false;

  /****
   * formControl instance
   */
  @Input()
  control: AbstractControl;

  displayError: boolean = false;
  errorMessage: string = '';

  public focused: boolean = false;
  public inputFieldValue = null;

  //The internal data model
  private innerValue: any = null;

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  private userLoggedIn: boolean = false;

  constructor(private store$: Store, private formUtility: FormUtilityService) {
    store$
      .select(selectUserDetails)
      .subscribe((user) => {
        this.userLoggedIn = !!user;
      })
      .unsubscribe();
  }

  ngOnInit(): void {
    // only filter if the user is logged in
    if (this.userLoggedIn) {
      this.subscriptions$.add(
        this.searchTextChanged$
          .pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap((query) => this.getFilteredContacts(query))
          )
          .subscribe()
      );
    }

    if (!!this.control) {
      this.subscriptions$.add(
        this.control.valueChanges.subscribe(() => {
          this.updateErrorStatus();
        })
      );
    }
  }

  updateErrorStatus(): void {
    if (this.control) {
      this.control.markAsTouched();
      this.errorMessage = this.formUtility.getFieldErrorMessage(this.control);
      this.displayError = this.formUtility.getFieldValidation(this.control);
    }
  }

  // Opens the options menu
  toggleFocus(isOut: boolean = false) {
    this.focused = !this.focused;

    // Check if the user de-focus the component and validate the input and check if it's numeric
    if (isOut) {
      this.onBlur();

      if (!this.notPhoneNumber) {
        // only set the value of the component when entered value is a number
        if (/^\d+$/.test(this.inputFieldValue)) {
          this.value = this.inputFieldValue;
        }
      } else {
        this.value = this.inputFieldValue;
      }
    }
  }

  updateInputVal(e) {
    const { value = '' } = e.target;
    this.inputFieldValue = value;
    // Rest the selected value
    if (!value) {
      this.value = null;
      this.searchTextChanged$.next(null);
    } else {
      this.value = value;
      this.searchTextChanged$.next(value);
    }
  }

  selectContact(contact: any) {
    //  if the contact is selected from the list the value of the component should be whatever provided by bind value prop of the contact otherwise just normal number value
    this.innerValue = contact;
    // only number without country code
    if (!this.notPhoneNumber) {
      const parsedNumber = parsePhoneNumber(contact['value']);
      this.inputFieldValue = parsedNumber?.nationalNumber || '';
    } else {
      this.inputFieldValue = contact['value'];
    }
    this.onChangeCallback(contact);
  }

  // filter using the API and make this default for selector that's running as number not user name
  getFilteredContacts(q) {
    //   dispatch get contact with the filter
    this.store$.dispatch(
      getUserContactsStart({
        search: q,
      })
    );

    return of(null);
  }

  // Accessor of the component value ------NOT THE INNER INPUT FIELD THE ACTUAL VALUE SELECTED BY THE USER-------
  get value(): any {
    return this.innerValue;
  }

  // //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
    this.updateErrorStatus();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
    // Set the inner input field value provided from the upper level component
    if (this.value) {
      const contact = this.options.find((c) => c.value === this.value);
      this.inputFieldValue = this.value;
      contact && (this.innerValue = contact);
    }
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
