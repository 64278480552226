import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GetUserBeyonicLinkResponse } from 'src/app/ngrx/beyonic-link/beyonic-link.interfaces';
import { FormUtilityService } from '../../../../../core/services/form-utility.service';
import { TransactionsUtilityService } from '../../../../services/transactions-utility.service';
import { WalletsService } from '../../../../../../ngrx/wallets/wallets.service';
import { validateAmountAgainstLimits } from '../../../../../../../assets/utility/form.validators';
import { BeyButtonComponent } from '../../../../components/bey-button/bey-button.component';
import { BeyFieldErrorMessageComponent } from '../../../../components/bey-field-error-message/bey-field-error-message.component';
import { BeyQuantitySelectorComponent } from '../../../../components/bey-quantity-selector/bey-quantity-selector.component';
import { BeyTotalAmountComponent } from '../../../../components/bey-total-amount/bey-total-amount.component';
import { NgClass, NgIf } from '@angular/common';
import { GetElementIdDirective } from '../../../../directives/get-element-id.directive';
import { retrieveOperatingCountry } from '../../../../../../../assets/utility/shared';

@Component({
  selector: 'bey-payment-info',
  templateUrl: './bey-payment-info.component.html',
  styleUrls: ['./bey-payment-info.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    NgIf,
    BeyTotalAmountComponent,
    BeyQuantitySelectorComponent,
    BeyFieldErrorMessageComponent,
    BeyButtonComponent,
    GetElementIdDirective,
  ],
})
export class BeyPaymentInfoComponent implements OnInit, OnDestroy {
  formData: UntypedFormGroup;
  country: string;
  pipeSettings: string;
  stop$ = new Subject<void>();

  /*****
   * Beyonic Link info
   */
  @Input()
  linkInfo: GetUserBeyonicLinkResponse;

  /*****
   * Merchant active name
   */
  @Input()
  activeMerchantName: string;

  /*****
   * Preview mode
   */
  @Input()
  isPreview: boolean = false;

  @Output()
  onNext: EventEmitter<{ formGroup: UntypedFormGroup; step: number }> = new EventEmitter<{
    formGroup: UntypedFormGroup;
    step: number;
  }>();

  @Output()
  previewQtyChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    public formUtility: FormUtilityService,
    private transactionsUtility: TransactionsUtilityService,
    private walletsService: WalletsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.formData = new UntypedFormGroup({
      unit_amount: new UntypedFormControl(this.linkInfo?.unit_amount || null),
      quantity: new UntypedFormControl(1, [Validators.required, Validators.min(1)]),
    });

    const amtField = this.formData.get('unit_amount');

    if (this.linkInfo?.unit_amount) {
      // limits validation on the amount
      amtField.addAsyncValidators([
        validateAmountAgainstLimits(this.walletsService, {
          business_id: this.linkInfo?.business_id,
          activeMerchantName: this.activeMerchantName,
          currency: this.linkInfo?.unit_amount_currency,
        }),
      ]);
    }

    // detect the changes manually and re-render the UI
    this.formData.statusChanges.pipe(takeUntil(this.stop$)).subscribe((_) => {
      this.cdr.detectChanges();
    });

    this.country = retrieveOperatingCountry(this.linkInfo?.unit_amount_currency, 'currency')?.name;
    this.pipeSettings = this.transactionsUtility.retrieveCurrencyPipeDigitalInfo(this.country);

    //  prevent checking against the limits and other validators
    if (this.isPreview) {
      for (let control in this.formData.controls) {
        this.formData.controls[control].disable();
      }

      // if the unit amount provided then emit the event once in case the quantity doesn't change
      if (this.linkInfo?.unit_amount) {
        this.previewQtyChange.emit(Number(this.linkInfo?.unit_amount));
      }
      //  submit the value to the parent on every change in case its preview
      amtField.valueChanges.pipe(takeUntil(this.stop$)).subscribe((val) => {
        this.previewQtyChange.emit(val);
      });
    }
  }

  get qty(): number {
    return this.formData.get('quantity').value;
  }

  handleSubmit(): void {
    // prevent event on preview
    if (this.isPreview) return;

    if (this.formData.valid) {
      this.onNext.emit({
        formGroup: this.formData,
        step: 0,
      });
    } else {
      this.formUtility.validateAllFormFields(this.formData);
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
