<div class="xb-amount flex flex-col w-full">
  <span class="text-sm font-bold">Amount</span>
  <div
    class="flex items-center border rounded-md lg:rounded border-solid focus-within:border-gray-500 border-[#C4C4C4] px-2 py-0.5">
    <img
      *ngIf="_fromCountryInfo; else notSelected"
      class="rounded-full min-h-[20px] mr-2"
      width="20"
      height="20"
      [src]="'https://flagcdn.com/' + _fromCountryInfo?.isoCode?.toLowerCase() + '.svg'"
      loading="lazy"
      [alt]="_fromCountryInfo?.isoCode" />

    <ng-template #notSelected>
      <img
        class="rounded-full min-h-[20px] mr-2"
        width="20"
        height="20"
        src="/assets/images/white-flag.png"
        loading="lazy"
        alt="default flag" />
    </ng-template>

    <span class="font-bold text-sm text-gray-500 mr-2.5 min-w-[30px]">{{ _fromCountryInfo?.currency }}</span>
    <span class="text-gray-200 text-xl">|</span>
    <div class="flex flex-col pl-2 w-full">
      <span class="text-xs text-gray-800 font-normal">
        You will <ng-container *ngIf="renderForMerchant; else normal"> collect </ng-container>
        <ng-template #normal> pay</ng-template>
      </span>
      <input
        type="text"
        pattern="[0-9]*"
        inputmode="numeric"
        [disabled]="!(_fromCountryInfo && _toCountryInfo) || (getFxRateIsLoading$ | async) || disabled"
        [name]="name"
        class="outline-none text-gray-600 text-base font-normal w-full py-0.5 h-[28px]"
        [(ngModel)]="inputFieldValue"
        (ngModelChange)="handleModelChange($event)"
        (blur)="onBlur()"
        [id]="id"
        currencyMask
        [options]="{
          prefix: '',
          align: 'left',
          thousands: ',',
          decimal: '.',
          precision: 0,
          allowNegative: false
        }" />
    </div>
  </div>
  <div
    class="w-auto h-[14px] border-l-2 border-gray-200 ml-5 flex flex-col justify-evenly transition-all duration-500 origin-top"></div>
  <div
    class="flex items-center border rounded-md lg:rounded border-solid focus-within:border-gray-500 border-[#C4C4C4] px-2 py-0.5">
    <img
      class="rounded-full min-h-[20px] mr-2"
      width="20"
      height="20"
      [src]="
        _toCountryInfo?.isoCode
          ? 'https://flagcdn.com/' + _toCountryInfo?.isoCode?.toLowerCase() + '.svg'
          : '/assets/images/white-flag.png'
      "
      loading="lazy" />
    <span class="font-bold text-sm text-gray-500 mr-2.5 min-w-[30px]">{{ _toCountryInfo?.currency || '' }}</span>
    <span class="text-gray-200 text-xl">|</span>
    <div class="flex flex-col pl-2">
      <span class="text-xs text-gray-800 font-normal">
        <ng-container *ngIf="renderForMerchant; else n3"> Sender</ng-container>
        <ng-template #n3>Merchant</ng-template>

        will <ng-container *ngIf="renderForMerchant; else n2"> pay</ng-container>
        <ng-template #n2> receive </ng-template>
      </span>
      <spinner [diameter]="23" *ngIf="getFxRateIsLoading$ | async; else fxRate"></spinner>
      <ng-template #fxRate>
        <span class="text-gray-600 text-base font-normal h-[28px]">{{
          inputFieldValue | calculateFx : renderForMerchant | currency : ' '
        }}</span>
      </ng-template>
    </div>
  </div>

  <bey-field-error-message *ngIf="!hideValidationFeedback" [displayError]="displayError" [errorMessage]="errorMessage">
  </bey-field-error-message>
</div>
