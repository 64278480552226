<bey-header-with-content [noBackground]="true" *ngIf="!forceMobile"></bey-header-with-content>
<div class="h-full relative bg-white lg:bg-transparent">
  <div class="min-h-[700px]">
    <div
      class="mx-auto bg-white"
      [ngClass]="{
        'lg:shadow-2xl lg:mt-16 lg:px-6 lg:rounded-2xl': !forceMobile,
        'lg:w-[600px]': !linkPhoto && !isPreview,
        'lg:max-w-4xl': linkPhoto
      }">
      <div bey-headless-stepper [currentStep]="currentStep$ | async">
        <ng-template #step>
          <div class="bg h-[90px]" *ngIf="!linkPhoto" [ngClass]="{ 'lg:hidden': !isPreview }"></div>
          <div
            class="flex flex-col justify-between lg:gap-x-8"
            [ngClass]="{ 'lg:flex-row ': linkPhoto && !forceMobile, 'lg:py-8': !forceMobile }">
            <div
              class="flex flex-col"
              [ngClass]="{ 'lg:w-5/12': linkPhoto && !forceMobile, 'lg:flex-col-reverse': !forceMobile }">
              <div class="w-full cursor-pointer relative overflow-hidden" *ngIf="linkPhoto">
                <div class="w-full h-full absolute z-1 left-0 blur-lg grayscale-[0.6] scale-105 lg:hidden">
                  <picture *ngIf="!photoRejected; else photoRejectedImg">
                    <!-- Display if image properties for new links are present -->
                    <ng-container *ngIf="linkInfo.public_photo_urls || linkInfo.photo?.[0]?.photo_webp">
                      <source
                        type="image/webp"
                        [srcset]="
                          linkInfo.public_photo_urls?.public_photo_400_webp_url || linkInfo.photo[0].photo_400_webp
                        " />
                      <source
                        [srcset]="linkInfo.public_photo_urls?.public_photo_400_url || linkInfo.photo[0].photo_400" />
                    </ng-container>

                    <img
                      [src]="
                        linkInfo.public_photo_urls?.public_browse_file_name_url || linkInfo.public_photo_url || linkInfo?.photo?.[0]?.browse_file_name
                      "
                      [alt]="linkInfo.name"
                      class="w-full h-full object-cover object-center" />
                  </picture>
                  <ng-template #photoRejectedImg
                    ><img
                      src="assets/images/no-media.png"
                      alt="placeholder"
                      class="w-full h-full object-cover object-center"
                  /></ng-template>
                </div>

                <div
                  [ngClass]="{ 'lg:rounded-2xl lg:mt-6 lg:mx-0': !forceMobile }"
                  class="mx-auto lg:w-full max-h-[50vh] sm:max-w-[50vw] xl:max-w-unset lg:max-h-[350px] relative z-2 overflow-hidden">
                  <ng-container #lazyLoadContainer></ng-container>
                </div>

                <div
                  class="bg-gray-200 animate-pulse h-[50vh] md:h-[350px]"
                  [ngClass]="{ 'lg:rounded-2xl  lg:mt-6': !forceMobile }"
                  *ngIf="isLoadingViewer"></div>
              </div>
              <div class="flex">
                <div
                  class="relative left-2 -top-2.5 flex text-[#2F3235] bg-gray-100 w-[80px] h-[80px] border-gray-100 border-[3px] rounded-full justify-center items-center font-medium text-[38px]">
                  {{ businessInfo.active_merchant_name | nameInitials }}
                </div>
                <div class="pt-3 pl-4 w-8/12">
                  <div class="text-gray-800 font-bold">
                    {{ businessInfo?.active_merchant_name }}
                  </div>

                  <a
                    [href]="bl"
                    class="break-words block underline underline-offset-8 font-bold text-blue-400 font-medium text-sm"
                    [ngClass]="isPreview || forceMobile ? 'pointer-events-none !cursor-default' : ''"
                    >{{ bl }}</a
                  >
                </div>
              </div>
            </div>

            <bey-payment-info
              class="block"
              [ngClass]="{ ' lg:w-6/12': linkPhoto && !forceMobile }"
              [linkInfo]="linkInfo"
              (onNext)="handleStepData($event)"
              (previewQtyChange)="previewQtyChange($event)"
              [activeMerchantName]="businessInfo?.active_merchant_name"
              [isPreview]="isPreview"></bey-payment-info>
          </div>
        </ng-template>
        <ng-template #step>
          <bey-client-info
            [linkInfo]="linkInfo"
            (onNext)="handleStepData($event)"
            (onPrevious)="back()"
            [totalInfo]="totalInfo"
            [bl]="bl"
            [businessInfo]="businessInfo"
            [isPreview]="isPreview"></bey-client-info>
        </ng-template>
      </div>
    </div>
  </div>
</div>
