import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/min';
import { catchError, Observable, of, Subject, switchMap, takeUntil, timer, map, throwError, retryWhen } from 'rxjs';
import { TransactionsUtilityService } from 'src/app/modules/shared/services/transactions-utility.service';
import { CollectionsFees } from 'src/app/ngrx/collections/collections.interfaces';
import { TransactionLimits, Wallet } from 'src/app/ngrx/wallets/wallets.interfaces';
import { environment } from '../../environments/environment';
import { CROSS_BORDER_OPERATING_COUNTRIES, ENV_NAMES } from '../const';
import { AbstractControlContainsWarning } from '../interfaces';
import { WalletsService } from '../../app/ngrx/wallets/wallets.service';
import { BeyonicLinkService } from 'src/app/ngrx/beyonic-link/beyonic-link.service';
import { PaymentsFees } from 'src/app/ngrx/payments/payments.interfaces';
import { HttpErrorResponse } from '@angular/common/http';

export function confirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup | FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({
        mustMatch: true,
      });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function fourDigitsValidator(control: UntypedFormControl): {
  [key: string]: boolean;
} {
  if (control?.value?.toString()?.length != 4) {
    return { '4DigitsError': true };
  } else return null;
}

export function noLeadingSpaces(control: UntypedFormControl): {
  [key: string]: boolean;
} {
  const val = control.value;
  const exp = new RegExp(/(^\s+$|^\s+(\w|[$&+,:;=?@#|'<>.^*()%!-~-+-\s])+$)/);
  return exp.test(val) ? { leadingSpace: true } : null;
}

// todo redefine what isGroup means and maybe change the name to something that makes sense
/****
 *
 * @param isGroup
 * @param isoCode Default country code used to validate the number. It allows validating numbers in any form
 */
export function phoneNumberValidator(isGroup: boolean = false, isoCode: CountryCode = null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    /*
      This is for field where the country code is separated from the mobile phone
      @@@ params
      code : String  e.g. :+218
      phone_number : String e.g. : 0925xxxxxxx
     */
    const expression = new RegExp(/(^800)(\d{8})$/);
    if (!isGroup) {
      let { code, phone_number } = control?.value;
      code = typeof code === 'object' ? code?.countryCode : code; // this to accommodate the values provided by the country code selector in case no binding value was provided

      if (environment.name !== ENV_NAMES.PROD && expression.test(`${code?.slice(1)}${phone_number}`)) {
        return null; // toll-free number is valid only on non production env
      } else if (isValidPhoneNumber(`${code}${phone_number}`)) {
        return checkTelcoValidity(`${code}${phone_number}`);
      } else {
        return { phoneNumberError: true };
      }
    } else {
      //      only test the toll free if the environment is DEV
      const num = String(control.value);

      if (environment.name !== ENV_NAMES.PROD && expression.test(num.slice(1))) {
        return null; // toll-free number is valid only on non production env
      } else {
        if (!num) {
          return { phoneNumberError: true };
        }
        // If we have iso code for the country of origin we use it to validate the number
        const result = isoCode ? isValidPhoneNumber(num, isoCode) === false : isValidPhoneNumber(`${num}`) === false;
        return result ? { phoneNumberError: true } : null;
      }
    }
  };
}

export function numericValueOnly(control: UntypedFormControl): {
  [key: string]: boolean;
} {
  const val = control.value;
  const exp = new RegExp(/^[0-9]*$/);

  return exp.test(val) ? null : { numericValueOnly: true };
}

export function checkFunds(
  transaction: { type: string; phone?: string; bank_id?: number },
  wallet$: Observable<Wallet>,
  transactionsUtility: TransactionsUtilityService,
  componentDestroyed: Subject<void>
): ValidatorFn {
  return (control: AbstractControlContainsWarning): ValidationErrors | null => {
    let balance: number;
    let fee: number;
    wallet$.pipe(takeUntil(componentDestroyed)).subscribe((wallet) => (balance = wallet?.balance));
    fee = transactionsUtility.calculateFee({ ...transaction, amount: control.value });

    control.utility = { fee, balance };

    if (transactionsUtility.calculateFee({ ...transaction, amount: control.value }) + control.value > balance) {
      return { 'p-insufficientFunds': true };
    } else {
      return null;
    }
  };
}

export function transactionAmountValidation(
  form: UntypedFormGroup,
  fees: CollectionsFees | PaymentsFees,
  transactionsUtility: TransactionsUtilityService,
  merchantCountryIsoCode: string,
  renderForMerchant: boolean = false,
  phone?: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!fees) {
      // if no fees exist then the endpoint to get the fees failed
      // return error that ask the user to refresh the page and try again
      return { recaptcha: true };
    }
    const { value } = control;

    if ((value?.amount && typeof value === 'object') || (value && typeof value !== 'object')) {
      let amount;
      if (typeof value === 'object') {
        // this amount was received from normal input field
        amount = value.amount;
      } else {
        // this amount was received from bey-cross-border component, looks like this { currency: string: value: number }
        amount = value;
      }

      let phoneNumber;
      // we check if we manually invoked the phone number, otherwise we fetch it from the form
      if (phone) {
        phoneNumber = phone;
      } else {
        let { code, phone_number } = form.get('phone').value;
        if (typeof code === 'object') {
          code = code?.value;
        }
        phoneNumber = code + phone_number;
      }

      const triggeredKey = Object.keys(fees).find((key) => {
        const expression = new RegExp(key);
        return expression.test(phoneNumber);
      });

      let feesObject = fees[triggeredKey];

      if (!feesObject?.['collection_limits']) {
        if (feesObject?.['country'] === 'KE') {
          feesObject = {
            ...feesObject,
            collection_limits: {
              min: 1,
              max: 150000,
            },
          };
        } else if (feesObject?.['country'] === 'UG') {
          feesObject = {
            ...feesObject,
            collection_limits: {
              min: 500,
              max: 5000000,
            },
          };
        }
      }

      // todo this is temp because I can't calculate the fees for my Airtel number
      if (!feesObject) return null;
      const { currency } = feesObject;

      let { max, min } = feesObject['collection_limits'] || feesObject['payment_limits'];

      if (feesObject['country'].toLowerCase() !== merchantCountryIsoCode && renderForMerchant) {
        amount = transactionsUtility.calculateFx(amount, renderForMerchant);
      }

      if (min) {
        // Check if the transaction amount is less than MNO limit
        if (amount < min) {
          return {
            'xb.min': true, // nvm the confusing name it's not related to Cross Boarder
            value: min,
            currency: currency,
          };
        }
      }

      if (max) {
        // Check if the transaction amount is greater than MNO limit
        if (amount > max) {
          return {
            'xb.max': true, // nvm the confusing name it's not related to Cross Boarder
            value: max,
            currency: currency,
          };
        }
      }

      return null;
    }
    // else {
    //   return {
    //     'xb.required': true, // nvm the confusing name it's not related to Cross Boarder
    //   };
    // }

    return null;
  };
}

export function checkTransactionLimits(
  userTransactionLimits: TransactionLimits,
  notRequired: boolean = false,
  isCollection: boolean = false
): ValidatorFn {
  return (control: AbstractControlContainsWarning): ValidationErrors | null => {
    if (userTransactionLimits) {
      let balance, fee;
      if (control.utility) {
        fee = control.utility['fee'];
        balance = control.utility['balance'];
      }

      let { available_payments_transaction_value } = userTransactionLimits;

      let returnedFunc = getErrorOrWarning(control, userTransactionLimits, isCollection, notRequired);

      if (isCollection) {
        return returnedFunc('available_collections_transaction_value');
      } else if (balance - fee >= available_payments_transaction_value) {
        return returnedFunc('available_payments_transaction_value');
      } else {
        return returnedFunc('balanceMinusFee');
      }
    }
    return {};
  };
}

export function checkTopUpLimits(available_wallet_value: number, exceeded_wallet_limit: boolean): ValidatorFn {
  return (control: AbstractControlContainsWarning): ValidationErrors | null => {
    let amount = control.value;

    if (exceeded_wallet_limit) {
      return { 'p-walletLimitReached': true };
    } else if (amount > available_wallet_value) {
      return { topUpLimitExceeded: Math.trunc(available_wallet_value) };
    }

    return null;
  };
}

/****
 * Validate user encoded amount against limits on the fly
 * @param walletsService
 * @param businessInfo
 * @param XB
 */
export function validateAmountAgainstLimits(
  walletsService: WalletsService,
  businessInfo: { business_id: number; activeMerchantName: string; currency: string },
  XB: boolean = false
): AsyncValidatorFn {
  const retryTimes: number = 3;

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return timer(500).pipe(
      switchMap(() =>
        walletsService
          .businessLimitsCheck({
            amount: XB ? control?.value?.calculatedAmount : control.value,
            amount_currency: XB ? control?.value?.calculatedAmountCurrency : businessInfo?.currency,
            business_id: businessInfo.business_id,
          })
          .pipe(
            map(({ is_valid }) => {
              if (!is_valid) {
                return { exceedsOpenWalletLimit: true, activeMerchantName: businessInfo.activeMerchantName };
              }

              return null;
            }),
            retryWhen((error) => {
              return error.pipe(
                switchMap((err: HttpErrorResponse, count: number) => {
                  if (count < retryTimes) {
                    return of(err);
                  }

                  return throwError(() => err);
                })
              );
            }),
            catchError((e) => {
              console.error(e);
              if (e instanceof HttpErrorResponse) {
                return of({
                  networkError: true,
                });
              }
              // todo update this to reflect the actual error cause
              return of({ recaptcha: true });
            })
          )
      )
    );
  };
}

export function differentFileValidator(otherFile: File): (control: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control.value;
    if (
      file &&
      otherFile &&
      file.name === otherFile.name &&
      file.size === otherFile.size &&
      file.type === otherFile.type
    ) {
      return { sameFile: true };
    }
    return null;
  };
}

export function checkBeyonicLinkName(beyonicLinkService: BeyonicLinkService, business_id: number): AsyncValidatorFn {
  const retryTimes: number = 3;

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return timer(500).pipe(
      switchMap(() =>
        beyonicLinkService.checkBeyonicLinkName({ business_id, name: control['value'] }).pipe(
          map(() => null),
          retryWhen((error) => {
            return error.pipe(
              switchMap((err: HttpErrorResponse, count: number) => {
                if (count < retryTimes) {
                  return of(err);
                }

                return throwError(() => err);
              })
            );
          }),

          catchError((e) => {
            console.error(e);
            if (e instanceof HttpErrorResponse) {
              return of({
                networkError: true,
              });
            }

            return of({ beyonicLinkName: true });
          })
        )
      )
    );
  };
}
//------ UTILITY FUNCTIONS ------
let getErrorOrWarning =
  (
    control: AbstractControlContainsWarning,
    userTransactionLimits: TransactionLimits,
    isCollection: boolean,
    notRequired: boolean = false
  ) =>
  (
    available_transaction_type:
      | 'available_payments_transaction_value'
      | 'available_collections_transaction_value'
      | 'balanceMinusFee'
  ): ValidationErrors | null => {
    // reset warnings initially
    control.warnings = null;

    let amount = control.value,
      balance,
      fee;
    if (control.utility) {
      fee = control.utility['fee'];
      balance = control.utility['balance'];
    }

    let { daily_transaction_limit, exceeded_wallet_limit, wallet_balance_limit } = userTransactionLimits;

    // set the available transaction value
    let available_transaction_value =
      available_transaction_type !== 'balanceMinusFee'
        ? userTransactionLimits[available_transaction_type]
        : balance - fee;

    // getting rid of the decimals
    available_transaction_value = Math.trunc(available_transaction_value);

    // (daily_transaction_limit - available_transaction_value) is the amount that the user transacted depends on the transaction type,
    // then we add the amount to it
    let transactedValuePlusAmount = daily_transaction_limit - available_transaction_value + amount;

    // check if user has exceeded his wallet limit
    if (exceeded_wallet_limit && isCollection) {
      return { 'p-walletLimitReached': true };
    }

    // check if the transaction was a collection and the amount he tries to collect is greater than the wallet balance limit (this will fail all the time)
    if (available_transaction_type === 'available_collections_transaction_value' && amount > wallet_balance_limit) {
      return {
        transactionsLimitExceededCollection: available_transaction_value,
      };
    }

    // check if user doesn't have available value to transact
    if (
      available_transaction_type === 'balanceMinusFee'
        ? userTransactionLimits['available_payments_transaction_value'] === 0
        : available_transaction_value === 0
    ) {
      return { 'p-noAvailableTransactionValue': true };
    }
    // if the user's amount that he's trying to transact exactly equals the available_transaction_value,
    // then we display a warning telling that he reached the limit but didn't exceed it yet
    if (amount === available_transaction_value) {
      control.warnings = {
        transactionsLimitReached: true,
      };
      // check if user exceeded available_transaction_value
    } else if (amount > available_transaction_value) {
      // if it wasn't required then we display a warning
      if (notRequired) {
        // make the warning in red in case the value exceeds the limits
        control.warnings = {
          [available_transaction_type === 'available_collections_transaction_value'
            ? 'transactionsLimitExceededCollection'
            : 'transactionsLimitExceeded']: available_transaction_value,
          renderedAsError: true,
        };
      } else {
        // otherwise it should be an error
        return {
          // the available transaction value is the value that we're going to display in the error message
          [available_transaction_type === 'available_collections_transaction_value'
            ? 'transactionsLimitExceededCollection'
            : 'transactionsLimitExceeded']: available_transaction_value,
        };
      }
      // check if user exceeded the waning zone (80%)
    } else if (transactedValuePlusAmount >= daily_transaction_limit * 0.8) {
      // adding the remaining value to be show in the warning message
      control.warnings = {
        transactionsLimitWarning: Math.abs(
          available_transaction_type !== 'balanceMinusFee'
            ? amount - available_transaction_value
            : available_transaction_value - amount
        ),
      };
    }

    return null;
  };

/******
 *  This method checks for a valid phone number if it's one of the supported telcos or not
 */
export const checkTelcoValidity = (phoneNumber: string): { [key: string]: any } | null => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  const country = CROSS_BORDER_OPERATING_COUNTRIES.find((country) => country.isoCode === parsedPhoneNumber.country);
  const supportedTelcosForCountry = country?.supportedTelcos;

  if (!supportedTelcosForCountry || !supportedTelcosForCountry.length) {
    return null;
  }

  const telcoIsSupported: boolean = supportedTelcosForCountry.some((telco) =>
    new RegExp(telco.expression).test(parsedPhoneNumber.number)
  );

  if (!telcoIsSupported) {
    const supportedTelcosArray: Array<string> = supportedTelcosForCountry.map((telco) => telco.name);
    const supportedTelcosVerb: string = supportedTelcosArray.length > 1 ? 'are' : 'is';
    const supportedTelcosString: string =
      supportedTelcosArray.length > 2
        ? supportedTelcosArray
            .join(', ')
            .replace(
              supportedTelcosArray[supportedTelcosArray.length - 2] + ',',
              supportedTelcosArray[supportedTelcosArray.length - 2] + ', and '
            )
        : supportedTelcosArray.join(' and ');

    return {
      unsupportedTelco: true,
      country: country.label,
      supportedTelcos: supportedTelcosString,
      verb: supportedTelcosVerb,
    };
  }

  return null;
};

/*****
 * ID number pattern validator for KYC purposes
 * @param pattern
 * @constructor
 */
export const KycDocNumValidator = (pattern: RegExp | string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const val = control.value;
    const exp = new RegExp(pattern);
    return exp.test(val) ? null : { kycDocNumInvalid: true };
  };
};
