import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  BankPaymentsFees,
  CreatePaymentRequestBody,
  PaymentsFees,
  RecentPaymentItem,
  RecentPaymentResponse,
} from './payments.interfaces';
import { TransactionReceiptResponse } from '../wallets/wallets.interfaces';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private http: HttpClient) {}

  getRecentPayments(business_id: number) {
    let params = new HttpParams();

    params = params.append('ordering', '-created');
    params = params.append('limit', 6);

    return this.http.get<RecentPaymentResponse>(`${environment.API_URL}payments/business/${business_id}/payments/`, {
      params,
    });
  }

  getPaymentInfo(paymentId: number, businessId: number) {
    return this.http.get<RecentPaymentItem>(
      `${environment.API_URL}payments/business/${businessId}/payments/${paymentId}/`
    );
  }

  getMoreUserPayments(url: string) {
    return this.http.get<RecentPaymentResponse>(url);
  }

  createPayment(requestBody: CreatePaymentRequestBody) {
    return this.http.post<any>(environment.API_URL + 'payments/', requestBody);
  }

  getBanks() {
    return this.http.get<any[]>(`${environment.API_URL}banks/`);
  }

  getBankPaymentsFees() {
    return this.http.get<BankPaymentsFees>(`${environment.API_URL}payments/fees/banks/`);
  }

  getPaymentsFees() {
    return this.http.get<PaymentsFees>(`${environment.API_URL}payments/fees/mobile_money/`);
  }

  fetchPaymentInfoByCode(code: string) {
    return this.http.get<TransactionReceiptResponse>(
      `${environment.API_URL}payments/public/payments/${code}/customer-receipt/`
    );
  }
}
