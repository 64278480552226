import { createSelector } from '@ngrx/store';
import { ContactState } from './contacts.interface';
import { AppState } from '../root-store.module';

// ----- 1st Level
const selectContacts = (state: AppState) => state.contacts;
// ----- 1st Level ----- END

// ----- 2nd Level ----- START
export const selectContactsList = createSelector(selectContacts, (state: ContactState) => state.contacts); // actual set of contacts
export const selectContactsConfig = createSelector(selectContacts, ({ config }) => config);
export const selectContactsLoadingState = createSelector(selectContacts, ({ isLoading }) => isLoading);
export const selectContactsMisc = createSelector(selectContacts, (state: ContactState) => state.misc);
export const selectActiveContact = createSelector(selectContacts, (state: ContactState) => state.selectedContact);
// ----- 2nd Level ----- END

// ----- 3rd Level ----- START
export const selectGetContactsLoading = createSelector(selectContactsLoadingState, ({ getContacts }) => getContacts);
export const selectGetMoreUserContactsLoading = createSelector(
  selectContactsLoadingState,
  ({ getMoreContacts }) => getMoreContacts
);
export const selectContactsConfigNext = createSelector(selectContactsConfig, (config) => config.next);
export const selectPhoneBookHeaderTitle = createSelector(selectContactsMisc, ({ headerTitle }) => headerTitle);
export const selectPhoneBookRedirectURL = createSelector(selectContactsMisc, ({ redirectPath }) => redirectPath);
// ----- 3rd Level ----- END
