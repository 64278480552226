import { createReducer, on } from '@ngrx/store';
import * as ContactActions from './contacts.actions';
import { ContactState } from './contacts.interface';
import { produce } from 'immer';

const initState: ContactState = {
  selectedContact: null,
  misc: {
    headerTitle: 'Send to existing contact',
    redirectPath: null,
  },
  config: {},
  contacts: [],
  isLoading: {
    createContact: false,
    getContacts: false,
    getMoreContacts: false,
  },
  errorMessages: {
    createContact: '',
    getUserContacts: '',
  },
};

export const contactsReducer = createReducer(
  initState,
  // START ACTIONS --- START
  on(
    ContactActions.getUserContactsStart,
    produce((state) => {
      state.isLoading.getContacts = true;
    })
  ),

  on(
    ContactActions.getMoreUserContactsStart,
    produce((state) => {
      state.isLoading.getMoreContacts = true;
    })
  ),
  // START ACTIONS --- END

  // SUCCESS ACTIONS --- START
  on(
    ContactActions.getUserContactsSuccess,
    produce((state, { payload }) => {
      state.contacts = payload.results;
      state.isLoading.getContacts = false;
      state.errorMessages.getUserContacts = '';
      state.config = {
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    })
  ),

  on(
    ContactActions.getMoreUserContactsSuccess,
    produce((state, { payload }) => {
      state.contacts = [...state.contacts, ...payload.results];
      state.isLoading.getMoreContacts = false;
      state.errorMessages.getUserContacts = '';
      state.config = {
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    })
  ),
  // SUCCESS ACTIONS --- END

  // FAILURE ACTIONS --- START
  on(
    ContactActions.getUserContactsFailure,
    produce((state, { payload }) => {
      state.isLoading.getContacts = false;
      state.errorMessages.getUserContacts = payload.error;
    })
  ),
  // FAILURE ACTIONS --- END

  // MISC ACTIONS --- START
  on(
    ContactActions.setPhoneBookMisc,
    produce((state, { payload }) => {
      state.misc = {
        headerTitle: payload.headerTitle,
        redirectPath: payload.URL,
      };
    })
  ),
  on(
    ContactActions.setActiveContact,
    produce((state, { payload }) => {
      state.selectedContact = payload;
    })
  ),
  on(
    ContactActions.resetActiveContact,
    produce((state) => {
      state.selectedContact = null;
    })
  )
  // MISC ACTIONS --- END
);
