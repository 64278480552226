import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { GetUserBeyonicLinkResponse } from 'src/app/ngrx/beyonic-link/beyonic-link.interfaces';
import { FormUtilityService } from '../../../../../core/services/form-utility.service';
import {
  transactionAmountValidation,
  noLeadingSpaces,
  phoneNumberValidator,
  validateAmountAgainstLimits,
} from '../../../../../../../assets/utility/form.validators';
import {
  CROSS_BORDER_OPERATING_COUNTRIES,
  SPECIAL_CHARACTERS_REGEX_VALIDATION,
  SPECIAL_CHARACTERS_REGEX_VALIDATION_FOR_DESCRIPTION_FIELD,
} from '../../../../../../../assets/const';
import { getUserLocation } from '../../../../../../../assets/utility';
import { selectProcessBeyonicLinkIsLoading } from '../../../../../../ngrx/beyonic-link/beyonic-link.selectors';
import { GetBusinessInfoByShortNameResponse } from '../../../../../../ngrx/collections/collections.interfaces';
import { selectCollectionsFees } from 'src/app/ngrx/collections/collections.selectors';
import { TransactionsUtilityService } from 'src/app/modules/shared/services/transactions-utility.service';
import { WalletsService } from 'src/app/ngrx/wallets/wallets.service';
import { selectFXRate } from 'src/app/ngrx/misc/misc.selectors';
import { TruncatePipe } from '../../../../pipes/truncate.pipe';
import { NameInitialsPipe } from '../../../../pipes/name-initials.pipe';
import { BeyButtonComponent } from '../../../../components/bey-button/bey-button.component';
import { SpinnerComponent } from '../../../../components/spinner/spinner.component';
import { BeyCrossBorderAmountComponent } from '../../../../components/bey-cross-border-amount/bey-cross-border-amount.component';
import { BeyCountrySelectorComponent } from '../../../../components/bey-country-selector/bey-country-selector.component';
import { BeyInputComponent } from '../../../../components/bey-input/bey-input.component';
import { BeyDisplayTotalAmountComponent } from '../../../../components/bey-display-total-amount/bey-display-total-amount.component';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { GetElementIdDirective } from '../../../../directives/get-element-id.directive';
import { selectUserWallet } from 'src/app/ngrx/wallets/wallets.selectors';
import { retrieveOperatingCountry } from 'src/assets/utility/shared';
import { BeyonicCountry } from 'src/assets/interfaces';
import { BeyCountrySelectorOptionInterface } from 'src/assets/interfaces/bey-country-selector.interface';

@Component({
  selector: 'bey-client-info',
  templateUrl: './bey-client-info.component.html',
  styleUrls: ['./bey-client-info.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    BeyDisplayTotalAmountComponent,
    ReactiveFormsModule,
    BeyInputComponent,
    BeyCountrySelectorComponent,
    BeyCrossBorderAmountComponent,
    SpinnerComponent,
    BeyButtonComponent,
    AsyncPipe,
    NameInitialsPipe,
    TruncatePipe,
    GetElementIdDirective,
  ],
})
export class BeyClientInfoComponent implements OnInit, OnDestroy, OnChanges {
  /***
   * Force render mobile view because we're dynamically
   * rendering this component on preview (Beyonic Link process)
   */
  @Input()
  forceMobile: boolean = false;

  @Input()
  linkInfo: GetUserBeyonicLinkResponse;

  @Input()
  businessInfo: GetBusinessInfoByShortNameResponse;

  @Input()
  bl: string;

  /****
   * Total amount to be displayed to the user before proceeding to payment
   */
  @Input()
  totalInfo: { amount: number; currency: string } = {
    amount: 0,
    currency: '',
  };

  /*****
   * Preview mode
   */
  @Input()
  isPreview: boolean = false;

  @Output()
  onNext: EventEmitter<{ formGroup: UntypedFormGroup; step: number }> = new EventEmitter<{
    formGroup: UntypedFormGroup;
    step: number;
  }>();

  @Output()
  onPrevious: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('lazyLoadContainer', { read: ViewContainerRef })
  lazyLoadContainer: ViewContainerRef;

  form: UntypedFormGroup;
  parsedPhoneNumber: { nationalNumber: string; dialingCode: string } = { nationalNumber: null, dialingCode: null };
  countries: Array<BeyCountrySelectorOptionInterface> = [];
  isLoadingProcessing$: Observable<boolean>;
  linkPhoto: string;
  isDomesticCollection: boolean = true;
  isLoadingViewer: boolean = true;
  photoRejected: boolean;

  xbFromCountryInfo$: BehaviorSubject<BeyCountrySelectorOptionInterface> = new BehaviorSubject(null);
  xbToCountryInfo: BeyonicCountry;

  subs$: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    public formUtility: FormUtilityService,
    private route: ActivatedRoute,
    private store: Store,
    private transactionsUtility: TransactionsUtilityService,
    private walletsService: WalletsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.xbToCountryInfo = retrieveOperatingCountry(this.businessInfo.country, 'isoCode');

    this.form = new UntypedFormGroup({
      first_name: new UntypedFormControl({ value: this.linkInfo?.contact?.first_name, disabled: false }, [
        Validators.required,
        noLeadingSpaces,
        Validators.pattern(SPECIAL_CHARACTERS_REGEX_VALIDATION),
      ]),
      last_name: new UntypedFormControl({ value: this.linkInfo?.contact?.last_name, disabled: false }, [
        Validators.required,
        noLeadingSpaces,
        Validators.pattern(SPECIAL_CHARACTERS_REGEX_VALIDATION),
      ]),
      amount: new UntypedFormControl(null, [Validators.required]),
      phone: this.fb.group(
        {
          code: new UntypedFormControl('', [Validators.required]),
          phone_number: new UntypedFormControl('', [Validators.required]),
        },
        {
          validators: [phoneNumberValidator(false)],
        }
      ),
      note: new UntypedFormControl('', [
        noLeadingSpaces,
        Validators.minLength(3),
        Validators.maxLength(200),
        Validators.pattern(SPECIAL_CHARACTERS_REGEX_VALIDATION_FOR_DESCRIPTION_FIELD),
      ]),
    });

    // force the amount field to display any error if the amount is already specified
    if (this.linkInfo?.unit_amount) {
      this.form.get('amount').markAsTouched();
    }

    // disable all validators in preview mode
    if (this.isPreview) {
      for (let control in this.form.controls) {
        this.form.controls[control].disable();
      }
    }

    if (this.linkInfo?.contact?.phone) {
      const parsedPhone = parsePhoneNumber(this.linkInfo?.contact?.phone);

      this.parsedPhoneNumber['dialingCode'] = `+${parsedPhone.countryCallingCode}`;
      this.parsedPhoneNumber['nationalNumber'] = `+${parsedPhone.nationalNumber}`;

      this.form.get('phone.code').setValue(this.parsedPhoneNumber['dialingCode']);
      this.form.get('phone.phone_number').setValue(parsedPhone.nationalNumber);
    }

    // if the business is verified and can receive XB then add all the supported XB countries to the list
    // otherwise only add the payee's country
    this.countries = [];
    if (this.businessInfo.approved_for_cxb_transactions) {
      this.countries = CROSS_BORDER_OPERATING_COUNTRIES;
    } else {
      this.countries.push(
        CROSS_BORDER_OPERATING_COUNTRIES.find((country) => country.isoCode === this.xbToCountryInfo.isoCode)
      );
    }

    // add collection amount validator to the amount field
    this.subs$.add(
      this.store.select(selectCollectionsFees).subscribe((fees) => {
        this.form
          .get('amount')
          .setValidators([
            Validators.required,
            transactionAmountValidation(this.form, fees, this.transactionsUtility, this.xbToCountryInfo.isoCode),
          ]);
        this.form.get('amount').updateValueAndValidity();
      })
    );

    // add an error for amount field to user to refresh the page
    // NOTE: a validator to does this caused many issue, so this is a temporary solution
    this.subs$.add(
      this.store.select(selectFXRate).subscribe((rate) => {
        if (!!!rate) {
          this.form.get('amount').setErrors({ recaptcha: true });
        } else {
          this.form.get('amount').updateValueAndValidity();
        }
      })
    );

    // update the initiator info once the user selects a country code
    this.subs$.add(
      this.form.get('phone.code').valueChanges.subscribe((v) => {
        this.xbFromCountryInfo$.next(v);
        let amountField = this.form.get('amount');

        // detect if it's a domestic collection or not
        if (!v) {
          amountField.patchValue(null);
          this.isDomesticCollection = true;
        } else {
          this.isDomesticCollection = v?.value === this.xbToCountryInfo.countryCode;
        }

        // check against the limits for the amount that the user will add if the merchant didn't specify an amount
        if (!this.linkInfo?.unit_amount) {
          amountField.clearAsyncValidators();
          amountField.setAsyncValidators([
            validateAmountAgainstLimits(
              this.walletsService,
              {
                business_id: this.linkInfo?.business_id,
                activeMerchantName: this.businessInfo.active_merchant_name,
                currency: this.xbToCountryInfo.currency,
              },
              !this.isDomesticCollection
            ),
          ]);
        }

        // if the amount is specified in the link then patch it to the amount component
        if (v && this.linkInfo.unit_amount) {
          this.subs$.add(
            this.store.select(selectFXRate).subscribe((rate) => {
              if (rate) {
                let calculatedAmount = this.transactionsUtility.calculateFx(this.totalInfo.amount, true);
                this.form
                  .get('amount')
                  .patchValue({ currency: this.form.value['amount']['currency'], amount: calculatedAmount });
              }
            })
          );
        }
      })
    );

    this.isLoadingProcessing$ = this.store.select(selectProcessBeyonicLinkIsLoading);

    this.photoRejected = this.linkInfo?.photo?.[0] && !this.linkInfo.photo[0].show_photo;
    this.linkPhoto = this.photoRejected
      ? 'assets/images/no-media-1.png'
      : this.linkInfo.public_photo_urls?.public_browse_file_name_url ||
        this.linkInfo.public_photo_url ||
        this.linkInfo?.photo?.[0]?.browse_file_name;

    if (this.linkPhoto) {
      // lazy load image viewer component
      this.loadViewer();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // we detect if user actually made change we he went back to the previous page
    if (
      !changes['totalInfo']?.['firstChange'] &&
      JSON.stringify(changes['totalInfo']?.previousValue) !== JSON.stringify(changes['totalInfo']?.currentValue)
    ) {
      // fetch user collection to pre-select from countries array
      getUserLocation()
        .then((result) => {
          if (result?.['country']) {
            const userCountry = this.countries.find((c) => c.label === result?.['country']);
            let countryCodeControl = this.form.get('phone.code');
            let phoneControl = this.form.get('phone');

            // disable phone group control so that validations do not run when setValue() is called
            phoneControl.disable();
            if (userCountry) {
              // if the user country is detected then setting the value will re-force the XB amount to recalculate
              countryCodeControl.setValue(userCountry);
            } else {
              // we manually add the country code again to force the XB amount component to recalculate
              countryCodeControl.setValue(countryCodeControl.value);
            }
            // emitEvent is false so that valueChanges doesn't emit and update validations
            phoneControl.enable({ emitEvent: false });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  submit(): void {
    // disable default event on preview
    if (this.isPreview) return;

    if (this.form.valid) {
      this.onNext.emit({
        formGroup: this.form,
        step: 1,
      });
    } else {
      this.formUtility.validateAllFormFields(this.form);
    }
  }

  private async loadViewer() {
    const { BeyImageViewerComponent } = await import(
      '../../../../components/bey-image-viewer/bey-image-viewer.component'
    );
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BeyImageViewerComponent);
    const componentRef = this.lazyLoadContainer.createComponent(componentFactory);
    const instance = componentRef.instance;

    instance.imageThumb = this.linkPhoto;
    instance.imageSrc = this.linkPhoto;
    instance.imageAlt = this.linkInfo.name;
    instance.linkInfo = this.linkInfo;
    instance.photoRejected = this.photoRejected;
    this.isLoadingViewer = false;

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
