import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';

const selectMisc = (state: AppState) => {
  return state.misc;
};

// First level
const selectAnimatedSidebar = createSelector(selectMisc, (state) => state.animatedSidebar);
export const selectFXRate = createSelector(selectMisc, (state) => state?.fxRate);
export const selectSnackbarDetails = createSelector(selectMisc, (state) => state.snackbar);
export const selectTargetedRoutesToShowPendingKycBanner = createSelector(
  selectMisc,
  (state) => state?.targetedRoutesToShowPendingKycBanner
);
const selectDrawer = createSelector(selectMisc, (state) => state.drawer);
const selectFlexibleDialog = createSelector(selectMisc, (state) => state.flexibleDialog);
const selectRegistrationStepper = createSelector(selectMisc, (state) => state.headlessStepper);
const selectIsLoading = createSelector(selectMisc, (state) => state.isLoading);
export const selectSlidingSidebarIsOpen = createSelector(
  selectMisc,
  (state) => state.slidingSidebarOpen
); /* This for non animated boring version*/

export const selectReceiptInfo = createSelector(selectMisc, (d) => d.receiptInfo);
// Second level
export const selectDrawerIsOpen = createSelector(selectDrawer, (drawer) => drawer.isOpen);
export const selectDrawerData = createSelector(selectDrawer, (drawer) => drawer.data);
export const selectDrawerComponent = createSelector(selectDrawer, (drawer) => drawer.component);
export const selectDocumentIsPrinting = createSelector(selectIsLoading, (isLoading) => isLoading['documentPrint']);
export const selectGetRatesIsLoading = createSelector(selectIsLoading, (isLoading) => isLoading['getRates']);
export const selectCurrentHeadlessStepperStep = createSelector(selectRegistrationStepper, (state) => state.currentStep);
export const selectSnackbarIsOpen = createSelector(selectSnackbarDetails, (state) => state.show);

export const selectAnimatedSidebarIsOpen = createSelector(selectAnimatedSidebar, (state) => state.isOpen);
export const selectAnimatedSidebarIsOver = createSelector(selectAnimatedSidebar, (state) => state.isOver);
export const selectDrawerPayload = createSelector(selectAnimatedSidebar, (state) => state.payload);
export const selectDrawerHeader = createSelector(selectAnimatedSidebar, (state) => state.header);
export const selectDrawerHasContentProjection = createSelector(
  selectAnimatedSidebar,
  (state) => state.contentProjection
);
export const selectGenerateReceiptLoading = createSelector(selectIsLoading, (isLoading) => isLoading['receiptPrint']);

export const selectFlexibleDialogIsOpened = createSelector(
  selectFlexibleDialog,
  (flexibleDialog) => flexibleDialog['isOpened']
);
export const selectFlexibleDialogIsExpanded = createSelector(
  selectFlexibleDialog,
  (flexibleDialog) => flexibleDialog['isExpanded']
);
export const selectFlexibleDialogIsMinimized = createSelector(
  selectFlexibleDialog,
  (flexibleDialog) => flexibleDialog['isMinimized']
);
