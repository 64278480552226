import { Component } from '@angular/core';
import { BeyFeatureInfoCardComponent } from '../../../shared/components/bey-feature-info-card/bey-feature-info-card.component';
import { BeyCreateCollectionFormComponent } from '../../../shared/components/bey-create-collection-form/bey-create-collection-form.component';
import { BeyPageHeaderComponent } from '../../../shared/components/bey-page-header/bey-page-header.component';
import { PageInfoComponent } from '../../../shared/components/page-info/page-info.component';

@Component({
  selector: 'collections-create-collection',
  templateUrl: './create-collection.component.html',
  styleUrls: ['./create-collection.component.scss'],
  standalone: true,
  imports: [PageInfoComponent, BeyPageHeaderComponent, BeyCreateCollectionFormComponent, BeyFeatureInfoCardComponent],
})
export class CreateCollectionPage {
  constructor() {}
}
