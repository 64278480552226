import { Pipe, PipeTransform } from '@angular/core';
import { CreateCollectionRequestBody } from 'src/app/ngrx/collections/collections.interfaces';
import { CreatePaymentRequestBody } from 'src/app/ngrx/payments/payments.interfaces';
import { TransactionsUtilityService } from '../services/transactions-utility.service';

@Pipe({
  name: 'calculateFee',
  standalone: true,
})
export class CalculateFeePipe implements PipeTransform {
  constructor(private transactionUtility: TransactionsUtilityService) {}

  transform(value: CreatePaymentRequestBody | CreateCollectionRequestBody, ...args: unknown[]): string {
    return this.transactionUtility.calculateFee(value)?.toFixed(3);
  }
}
