import { Component, OnInit } from '@angular/core';
import { BeyButtonComponent } from '../bey-button/bey-button.component';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-bey-profile-under-review-dialog',
  templateUrl: './bey-profile-under-review-dialog.component.html',
  styleUrls: ['./bey-profile-under-review-dialog.component.scss'],
  standalone: true,
  imports: [MatLegacyDialogModule, BeyButtonComponent],
})
export class BeyProfileUnderReviewDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
