import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCurrentRoute } from 'src/app/ngrx/auth/auth.selectors';
import { selectPendingKyc } from 'src/app/ngrx/KYC/kyc.selectors';
import { selectTargetedRoutesToShowPendingKycBanner } from 'src/app/ngrx/misc/misc.selectors';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'pending-kyc-banner',
  templateUrl: './pending-kyc-banner.component.html',
  styleUrls: ['./pending-kyc-banner.component.scss'],
  standalone: true,
  imports: [NgIf, AsyncPipe],
})
export class PendingKycBannerComponent {
  targetedRoutesToShowPendingKycBanner: Observable<Array<string>>;
  pendingKyc$: Observable<boolean>;
  currentRoute$: Observable<string>;

  constructor(private store$: Store) {
    this.pendingKyc$ = this.store$.select(selectPendingKyc);
    this.currentRoute$ = this.store$.select(selectCurrentRoute);
    this.targetedRoutesToShowPendingKycBanner = this.store$.select(selectTargetedRoutesToShowPendingKycBanner);
  }
}
