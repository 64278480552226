<div class="flex flex-col py-4 items-center text-center">
  <svg
    *ngIf="data?.['success']; else failedIcon"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.4508 21.0004C39.4508 31.1901 31.1904 39.4504 21.0008 39.4504C10.8111 39.4504 2.55078 31.1901 2.55078 21.0004C2.55078 10.8108 10.8111 2.55036 21.0008 2.55036C24.1535 2.5421 27.2545 3.35181 30.0008 4.90042M14.4508 19.6504L20.3258 25.5254L39.0508 8.27542"
      stroke="#31B1F7"
      stroke-width="3.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>

  <ng-template #failedIcon>
    <img src="../../../../../assets/images/warning.png" width="46" height="46" />
  </ng-template>

  <span
    class="my-3 text-lg text-black font-medium"
    >{{ data?.['success'] ? 'Collection prompt sent' : 'We couldn’t send your prompt'}}</span
  >

  <span *ngIf="data?.['success']; else failedContent" class="text-gray-500 text-base font-normal my-4">
    <ng-container
      *ngIf="data?.['payload']?.type === 'collection-link'|| data?.['payload']?.type === 'collection-link-static'; else normal">
      Collection prompt for
      <span class="font-semibold">
        {{ data?.['payload']?.displayAmount | applyRoundingRules: country : 'collection' : false | currency: currency + ' ' : 'symbol' : currencyPipeDigitalInfo }}
      </span>
      has been sent to your phone.
    </ng-container>
    <ng-template #normal>
      Your request for
      <span class="font-semibold">
        {{ data?.['payload']?.displayAmount | applyRoundingRules: country : 'collection' : false | currency: currency + ' ' : 'symbol' : currencyPipeDigitalInfo }}
      </span>
      has been sent to
      <span class="font-semibold">
        {{ data?.['payload']?.['first_name'] }} {{ data?.['payload']?.['last_name'] }}
        {{ data?.['payload']?.['phone'] }}
      </span>
      Please wait as they authorize this transaction.
    </ng-template>
  </span>

  <ng-template #failedContent>
    <span class="text-gray-700 text-base my-4">
      Your request for
      <span class="font-semibold">
        {{ data?.['payload']?.displayAmount | applyRoundingRules: country:'collection':false | currency: currency + ' ' : 'symbol' : currencyPipeDigitalInfo }}</span
      >

      <ng-container *ngIf="data?.['payload']?.name; else normal">
        to <span class="font-semibold">{{ data?.['payload']?.name }} </span></ng-container
      >
      <ng-template #normal>
        from
        <span class="font-semibold"
          >{{ data?.['payload']?.first_name | titlecase }} {{ data?.['payload']?.last_name | titlecase }}
        </span>
      </ng-template>

      is unsuccessful.

      <span *ngIf="data?.error?.['status'] === 400 else normalErr">
        <br />
        Link expired
      </span>
      <ng-template #normalErr> Please try again. </ng-template>
    </span>
  </ng-template>

  <bey-button [mat-dialog-close]="true" placeholder="Done" color="yellow" [small]="true"></bey-button>
</div>
