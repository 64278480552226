import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, EMPTY, map, switchMap } from 'rxjs';
import { BEYONIC_COUNTRIES } from 'src/assets/const';
import { CollectionsService } from '../../../ngrx/collections/collections.service';
import { BeyToastService } from '../../shared/services/bey-toast.service';
import { select, Store } from '@ngrx/store';
import { selectUserDetails } from '../../../ngrx/user/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class UniqueLinkActionsResolver implements Resolve<any> {
  constructor(
    private collectionsService: CollectionsService,
    private router: Router,
    private toast: BeyToastService,
    private store: Store
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.paramMap.get('id');

    return this.store.pipe(
      select(selectUserDetails),
      switchMap((user) => {
        const {
          businesses: [business],
        } = user;

        return this.collectionsService.getUniqueLinkInfoForAuthUser(id, business.id);
      }),
      map((info) => {
        const amount_currency = BEYONIC_COUNTRIES.find(
          (country) => country.name === info?.['business']?.['country']
        ).currency;

        return [{ ...info, amount_currency }];
      }),
      catchError((err) => {
        console.error(err);
        this.toast.open(err?.error?.detail, 'error');
        this.router.navigate(['/error']);

        return EMPTY;
      })
    );
  }
}
