import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { LazyLoadImageDirective } from '../../directives/lazy-load-image.directive';

@Component({
  selector: 'bey-name-check-dialog',
  templateUrl: './bey-name-check-dialog.component.html',
  styleUrls: ['./bey-name-check-dialog.component.scss'],
  standalone: true,
  imports: [LazyLoadImageDirective, MatLegacyDialogModule],
})
export class BeyNameCheckDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
