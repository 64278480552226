import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CollectionsService } from '../../../../ngrx/collections/collections.service';
import { BeyToastService } from '../../../shared/services/bey-toast.service';
import { Observable } from 'rxjs';
import { selectUserDetails } from '../../../../ngrx/user/user.selectors';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { GetBusinessInfoByShortNameResponse } from '../../../../ngrx/collections/collections.interfaces';
import { Location, NgIf, AsyncPipe } from '@angular/common';
import { BeySignUpCalloutComponent } from '../../../shared/components/bey-signup-callout/bey-signup-callout.component';
import { BeyExclamationIconComponent } from '../../../shared/components/icons/bey-exclamation-icon/bey-exclamation-icon.component';
import { BeyHeaderWithInitialsComponent } from '../../../shared/components/bey-header-with-initials/bey-header-with-initials.component';

@Component({
  selector: 'app-unique-link-expired',
  templateUrl: './unique-link-expired.component.html',
  styleUrls: ['./unique-link-expired.component.scss'],
  standalone: true,
  imports: [
    BeyHeaderWithInitialsComponent,
    NgIf,
    RouterLink,
    BeyExclamationIconComponent,
    BeySignUpCalloutComponent,
    AsyncPipe,
  ],
})
export class UniqueLinkExpiredComponent implements OnInit {
  userDetails$: Observable<any>;
  businessInfo: GetBusinessInfoByShortNameResponse;
  public backButton: boolean;

  /****
   * Business Info   passed as a prop from the link preview
   */
  @Input()
  businessInfoProp: string;

  constructor(
    private collectionsService: CollectionsService,
    private toast: BeyToastService,
    private router: Router,
    private store: Store,
    private route: ActivatedRoute,
    public location: Location
  ) {
    this.userDetails$ = store.select(selectUserDetails);

    const data = this.router.getCurrentNavigation();

    this.backButton = data?.extras?.state?.['backButton'];
  }

  ngOnInit(): void {
    const { businessInfo } = this.route.snapshot.data;

    this.businessInfo = businessInfo || this.businessInfoProp;
  }

  get staticLink() {
    return environment.URL + '/sl/' + this.businessInfo.active_merchant_name;
  }

  handleSignUp(): void {
    if (environment.is_pay) {
      window.open(`${environment.URL}/signup`, '_blank');
    } else {
      this.router.navigate(['/signup']);
    }
  }
}
