import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bey-warning-icon',
  templateUrl: './bey-warning-icon.component.html',
  styleUrls: ['./bey-warning-icon.component.scss'],
  standalone: true,
})
export class BeyWarningIconComponent implements OnInit {
  /*****
   *  Height of the icon default to 16px
   */
  @Input()
  height: number = 16;

  /*****
   * Width of the icon default to 16px
   */
  @Input()
  width: number = 16;

  /***
   * Icon click event emitter to parent
   */
  @Output()
  onClick: EventEmitter<null> = new EventEmitter<null>();

  /*****
   * This determines the color of the icon
   * Primary ---- Black
   * Danger ---- Red
   */
  @Input()
  variant: 'primary' | 'danger' | 'warning' = 'primary';

  constructor() {}
  strokeFillColor: { stroke: string; fill: string };

  ngOnInit(): void {
    switch (this.variant) {
      case 'danger':
        this.strokeFillColor = {
          stroke: '#D90C34',
          fill: '#FFF',
        };
        break;
      case 'primary':
        this.strokeFillColor = {
          stroke: '#00456b',
          fill: '#FFF',
        };
        break;
      case 'warning':
        this.strokeFillColor = {
          stroke: '#FF9D41',
          fill: '#FFEEDF',
        };
        break;
    }
  }
}
