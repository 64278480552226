import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retryWhen, concatMap } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BeyNetworkErrDialogContentComponent } from '../components/bey-network-err-dialog-content/bey-network-err-dialog-content.component';
import { Router } from '@angular/router';

@Injectable()
export class FailRetryAgainInterceptor implements HttpInterceptor {
  retryTimes: number = 3;

  constructor(private dialog: MatDialog, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((error) =>
        error.pipe(
          concatMap((err: HttpErrorResponse, count: number) => {
            // HTTP CODES https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
            // Network disconnected error status is ZERO
            if (this.router.url !== '/suspended') {
              if (
                ![0, 400, 401, 403, 404, 405, 409, 413, 422, 423, 429, 501, 503, 504].includes(err.status) &&
                count < this.retryTimes
              ) {
                return of(err);
              }

              // show dialog and ask the user to refresh the page
              if (count === this.retryTimes) {
                this.dialog.closeAll();
                this.dialog.open(BeyNetworkErrDialogContentComponent, {
                  maxWidth: '90vw',
                });
              }
            }

            return throwError(() => err);
          })
        )
      )
    );
  }
}
