import { Injectable, OnDestroy } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';

//todo refactor and use Angular CDK in the future
@Injectable({
  providedIn: 'root',
})
export class BeyToastService implements OnDestroy {
  private toastSub$: Subscription = new Subscription();

  constructor(private toast: MatSnackBar) {}

  open(
    message?: string,
    variant: 'white' | 'blue' | 'error' = 'blue',
    actionTitle?: string,
    actionFunction?: () => void,
    top?: boolean,
    permanent?: boolean,
    marginBottom?: boolean
  ) {
    this.toastSub$.add(
      this.toast
        .open(message ? message : 'Something went wrong, please try again', actionTitle ? actionTitle : null, {
          duration: permanent ? 7000 : 3000,
          panelClass: [variant === 'error' ? 'blue' : variant, marginBottom ? 'snack-bar-margin-bottom' : 'null'],
          verticalPosition: top ? 'top' : 'bottom',
        })
        .onAction()
        .subscribe(actionFunction)
    );
  }

  ngOnDestroy(): void {
    this.toastSub$.unsubscribe();
  }
}
