import { createSelector } from '@ngrx/store';

const selectPayments = (store) => store.payments;
const selectRecentPayments = createSelector(selectPayments, ({ recentPayments }) => recentPayments);
export const selectPaymentInfo = createSelector(selectPayments, ({ paymentInfo }) => paymentInfo);
export const selectBankPaymentInfo = createSelector(selectPayments, ({ bankPaymentInfo }) => bankPaymentInfo);
export const selectPaymentsIsLoading = createSelector(selectPayments, ({ isLoading }) => isLoading);
export const selectPaymentsErrorMessages = createSelector(selectPayments, ({ errorMessages }) => errorMessages);
export const selectPaymentsFees = createSelector(selectPayments, ({ paymentsFees }) => paymentsFees);
export const selectBankPaymentsFees = createSelector(selectPayments, ({ bankPaymentsFees }) => bankPaymentsFees);

export const selectLoadingRecentPayments = createSelector(selectRecentPayments, ({ isLoading }) => isLoading);
export const selectRecentPaymentsData = createSelector(selectRecentPayments, (data) => data.data);
export const selectRecentPaymentsResults = createSelector(selectRecentPaymentsData, (data) => data.results);

export const selectPaymentsNext = createSelector(selectRecentPaymentsData, ({ next }) => next);

//--------CREATE PAYMENT--------
export const selectCreatePaymentIsLoading = createSelector(
  selectPaymentsIsLoading,
  (isLoading) => isLoading['createPayment']
);
export const selectCreatePaymentErrorMessage = createSelector(
  selectPaymentsErrorMessages,
  (errorMessages) => errorMessages['createPayment']
);

//--------GET MORE USER PAYMENTS--------
export const selectGetMoreUserPaymentsIsLoading = createSelector(
  selectPaymentsIsLoading,
  (isLoading) => isLoading['getMoreUserPayments']
);
export const selectGetMoreUserPaymentsErrorMessage = createSelector(
  selectPaymentsErrorMessages,
  (errorMessages) => errorMessages['getMoreUserPayments']
);
//--------GET PAYMENTS FEES--------
export const selectGetPaymentsFeesIsLoading = createSelector(
  selectPaymentsIsLoading,
  (isLoading) => isLoading['getPaymentsFees']
);
export const selectGetPaymentsFeesErrorMessage = createSelector(
  selectPaymentsErrorMessages,
  (errorMessages) => errorMessages['getPaymentsFees']
);

// ------------ BANK LIST
export const selectGetBankList = createSelector(selectPayments, ({ bankList }) => bankList);
export const selectBankListLoading = createSelector(selectPaymentsIsLoading, ({ bankList }) => bankList);
