import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { CollectionsService } from '../../../ngrx/collections/collections.service';
import { catchError } from 'rxjs/operators';
import { BeyToastService } from '../services/bey-toast.service';

@Injectable({
  providedIn: 'root',
})
export class ShortNameResolver implements Resolve<Observable<string>> {
  constructor(private collectionService: CollectionsService, private toast: BeyToastService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const short_name = route.paramMap.get('id') || route.paramMap.get('short_name');

    return this.collectionService.getBusinessFromShortName(short_name).pipe(
      catchError((err) => {
        if (err.status === 422) {
          return this.router.navigate([`/closed`]);
        }

        this.toast.open(err.error.detail, 'error');
        this.router.navigate(['/error']);

        return EMPTY;
      })
    );
  }
}
