import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { NgClass } from '@angular/common';

@Component({
  selector: 'bey-network-state',
  templateUrl: './bey-network-state.component.html',
  styleUrls: ['./bey-network-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass],
})
export class BeyNetworkStateComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { variant: 'offline' | 'online' }
  ) {}

  ngOnInit(): void {}
}
