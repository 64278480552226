import { Pipe, PipeTransform } from '@angular/core';
import { roundAmountByCountry } from '../../../../assets/utility/shared';

@Pipe({
  name: 'applyRoundingRules',
  standalone: true,
})
export class ApplyRoundingRulesPipe implements PipeTransform {
  transform(
    value: number | string,
    country: string,
    transactionType: 'collection' | 'payment' | 'fee',
    isXB: boolean = false
  ): number {
    return roundAmountByCountry(value, country, transactionType, isXB);
  }
}
