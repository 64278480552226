import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { selectUserDetails } from '../../../../ngrx/user/user.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import {
  CollectionInfo,
  CollectionInfoByCodeResponse,
  GetBusinessInfoByShortNameResponse,
} from '../../../../ngrx/collections/collections.interfaces';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { NameInitialsPipe } from '../../../shared/pipes/name-initials.pipe';
import { BeySignUpCalloutComponent } from '../../../shared/components/bey-signup-callout/bey-signup-callout.component';
import { NgIf } from '@angular/common';
import { BeyCreateCollectionFormComponent } from '../../../shared/components/bey-create-collection-form/bey-create-collection-form.component';
import { BeyHeaderWithInitialsComponent } from '../../../shared/components/bey-header-with-initials/bey-header-with-initials.component';
import parsePhoneNumber from 'libphonenumber-js/min';

@Component({
  selector: 'app-static-link',
  templateUrl: './static-link.component.html',
  styleUrls: ['./static-link.component.scss'],
  standalone: true,
  imports: [
    BeyHeaderWithInitialsComponent,
    BeyCreateCollectionFormComponent,
    NgIf,
    BeySignUpCalloutComponent,
    NameInitialsPipe,
  ],
})
export class StaticLinkPage implements OnInit, OnDestroy {
  stop$ = new Subject<void>();
  userLoggedIn: boolean;
  short_name: string;
  businessInfo: GetBusinessInfoByShortNameResponse;
  retriedCollectionInfo: CollectionInfo | null; // failed collection info
  merchantUssdCode: string;

  constructor(private store$: Store, private router$: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    // Get the business info from the resolver
    const {
      data: { businessInfo },
      paramMap,
    } = this.route.snapshot;

    // Business doesn't exist redirect to error page
    if (businessInfo instanceof HttpErrorResponse) {
      this.router$.navigate(['/error']);
      return;
    }

    this.businessInfo = businessInfo;
    this.short_name = paramMap.get('id');
    this.retriedCollectionInfo = this.handleRetryCollection();
    this.store$
      .select(selectUserDetails)
      .pipe(takeUntil(this.stop$))
      .subscribe((user) => {
        this.userLoggedIn = !!user;
      });

    const { merchant_code, ussd_code } = this.businessInfo;
    if (merchant_code && ussd_code) {
      // remove # from code
      const code = ussd_code.slice(0, ussd_code.length - 1);
      this.merchantUssdCode = `${code}*1*${merchant_code}#`;
    }
  }

  handleRetryCollection(): CollectionInfo | null {
    const { failedCollectionInfo }: { failedCollectionInfo?: CollectionInfoByCodeResponse } = this.route.snapshot.data;

    if (!failedCollectionInfo) {
      return null;
    }

    const parsedPhone = parsePhoneNumber(failedCollectionInfo?.contact?.phone);
    return {
      code: `+${parsedPhone.countryCallingCode}`,
      phone_number: parsedPhone.nationalNumber,
      phone: failedCollectionInfo.contact.phone,
      first_name: failedCollectionInfo?.contact?.first_name,
      last_name: failedCollectionInfo?.contact?.last_name,
      description: failedCollectionInfo.description,
      amount: Number(failedCollectionInfo.amount),
      type: 'local',
    };
  }

  handleSignUp() {
    const queryParams = {
      utm_source: this.businessInfo.active_merchant_name,
      utm_medium: 'collectionlink',
      utm_campaign: 'normal',
    };

    if (environment.is_pay) {
      const url = new URL(`${environment.URL}/signup`);

      url.search = new URLSearchParams(queryParams).toString();
      window.open(url, '_blank');
    } else {
      this.router$.navigate(['/signup'], {
        queryParams,
      });
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
