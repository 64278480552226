import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { FormUtilityService } from 'src/app/modules/core/services/form-utility.service';
import { Tokens } from 'src/app/ngrx/auth/auth.interfaces';
import { selectTokens } from 'src/app/ngrx/auth/auth.selectors';
import { getRatesStart } from 'src/app/ngrx/misc/misc.actions';
import { selectGetRatesIsLoading } from 'src/app/ngrx/misc/misc.selectors';
import { BeyCountrySelectorOptionInterface } from 'src/assets/interfaces/bey-country-selector.interface';
import { TransactionsUtilityService } from '../../services/transactions-utility.service';
import { CalculateFxPipe } from '../../pipes/calculate-fx.pipe';
import { BeyFieldErrorMessageComponent } from '../bey-field-error-message/bey-field-error-message.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { CurrencyMaskModule } from 'ng2-currency-mask-beyonic';
import { NgIf, AsyncPipe, CurrencyPipe } from '@angular/common';
import { BeyonicCountry } from 'src/assets/interfaces';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BeyCrossBorderAmountComponent),
  multi: true,
};

@Component({
  selector: 'bey-cross-border-amount',
  templateUrl: './bey-cross-border-amount.component.html',
  styleUrls: ['./bey-cross-border-amount.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    CurrencyMaskModule,
    FormsModule,
    SpinnerComponent,
    BeyFieldErrorMessageComponent,
    AsyncPipe,
    CurrencyPipe,
    CalculateFxPipe,
  ],
})
export class BeyCrossBorderAmountComponent implements OnInit, OnDestroy {
  subs$: Subscription = new Subscription();
  getFxRateIsLoading$: Observable<boolean>;

  _fromCountryInfo: {
    currency: string;
    isoCode: string;
  };
  _toCountryInfo: {
    currency: string;
    isoCode: string;
  };
  isLoggedIn: boolean;

  /***
   *  Input field name attribute
   */
  @Input()
  name: string;

  /***
   *  Input field ID attribute
   */
  @Input()
  id: string = '';

  /***
   *  Boolean value to disable component
   */
  @Input()
  disabled: boolean;

  /****
   *  Render the fields differently in case of merchant view
   */
  @Input()
  renderForMerchant: boolean = false;

  /***
   * Country where the money should come from
   * This is changing based on the country selected by the user
   */
  @Input()
  fromCountry$: Observable<BeyCountrySelectorOptionInterface>;

  /****
   * Country info where the money should go
   * This is static and always points to the wallet country
   */
  @Input()
  toCountry: BeyonicCountry;

  /***
   * Hide the validation feedback if some other method is used to show that
   */
  @Input()
  hideValidationFeedback: boolean = false;

  /****
   * formControl instance
   */
  @Input()
  control: AbstractControl;

  displayError: boolean = false;
  warning: boolean = false;
  errorMessage: string = '';

  constructor(
    private store$: Store,
    private transactionsUtility: TransactionsUtilityService,
    private formUtility: FormUtilityService
  ) {}

  ngOnInit(): void {
    this.getFxRateIsLoading$ = this.store$.select(selectGetRatesIsLoading);
    this.subs$.add(this.store$.select(selectTokens).subscribe((tokens: Tokens) => (this.isLoggedIn = !!tokens)));

    this.subs$.add(
      this.fromCountry$.subscribe((val) => {
        if (val) {
          this.getRates({ currency: this.toCountry.currency, isoCode: this.toCountry.isoCode }, val.currency);
          // As a merchant I should be able to type the value amount in UGX
          // As a client I should be able to type the value in whatever currency provided by from Observable
          if (this.renderForMerchant) {
            this._fromCountryInfo = { currency: this.toCountry.currency, isoCode: this.toCountry.isoCode };
            this._toCountryInfo = { currency: val.currency, isoCode: val.isoCode };
          } else {
            this._fromCountryInfo = { currency: val.currency, isoCode: val.isoCode };
            this._toCountryInfo = { currency: this.toCountry.currency, isoCode: this.toCountry.isoCode };
          }

          // Update the component value to reflect the changed init currency
          this.onChangeCallback({
            currency: val.currency,
            amount: this.inputFieldValue,
            calculatedAmount: this.transactionsUtility.calculateFx(this.inputFieldValue, this.renderForMerchant),
            calculatedAmountCurrency: this.toCountry?.currency,
          });
        } else {
          // Update the component to reflect null state when init country is removed
          this.onChangeCallback({
            currency: null,
            amount: null,
            calculatedAmount: null,
            calculatedAmountCurrency: null,
          });
          this.inputFieldValue = null;
        }
      })
    );

    if (!!this.control) {
      this.subs$.add(
        this.control.valueChanges.subscribe(() => {
          this.updateErrorStatus();
        })
      );
    }
  }

  updateErrorStatus(): void {
    if (this.control) {
      this.control.markAsTouched();
      this.warning = this.formUtility.checkFieldWarnings(this.control);
      this.errorMessage = this.formUtility.getFieldErrorMessage(this.control);
      this.displayError = this.formUtility.getFieldValidation(this.control);
    }
  }

  getRates(fromCountry: { isoCode: string; currency: string }, toCountryCurrency: string) {
    if (fromCountry.isoCode === toCountryCurrency) return; //prevent fetching exchange rates if the source and destination are the same

    if (fromCountry && toCountryCurrency) {
      const data = {
        currentUserCountry: fromCountry,
        toCountryCurrency,
      };

      this.store$.dispatch(
        getRatesStart({
          payload: {
            ...data,
            useOpenEndpoint: !this.isLoggedIn,
          },
        })
      );
    } else {
      this.value = null;
      this.inputFieldValue = null;
    }
  }

  //The internal data model
  private innerValue: any = '';
  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};
  public inputFieldValue = null;

  get value(): any {
    return this.innerValue;
  }

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.inputFieldValue = value?.['amount'];
      this.value = {
        currency: this._fromCountryInfo?.currency,
        amount: value?.['amount'],
        calculatedAmount: this.transactionsUtility.calculateFx(value?.['amount'], this.renderForMerchant),
        calculatedAmountCurrency: this.toCountry?.currency,
      };
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
    if (this.value) {
      this.value = {
        currency: this._fromCountryInfo?.currency,
        amount: this.inputFieldValue,
        calculatedAmount: this.transactionsUtility.calculateFx(this.inputFieldValue, this.renderForMerchant),
        calculatedAmountCurrency: this.toCountry?.currency,
      };
    }
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  handleModelChange(e) {
    if (e !== this.value?.amount) {
      this.value = {
        currency: this._fromCountryInfo?.currency,
        amount: this.inputFieldValue,
        calculatedAmount: this.transactionsUtility.calculateFx(this.inputFieldValue, this.renderForMerchant),
        calculatedAmountCurrency: this.toCountry?.currency,
      };
    }
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
