import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionsUtilityService } from 'src/app/modules/shared/services/transactions-utility.service';
import { Store } from '@ngrx/store';
import { selectAnimatedSidebarIsOpen } from '../../../../../ngrx/misc/misc.selectors';
import { Observable } from 'rxjs';
import { Transaction } from 'src/app/ngrx/wallets/wallets.interfaces';
import { ApplyRoundingRulesPipeParameters } from 'src/assets/interfaces';
import { ApplyRoundingRulesPipe } from '../../../pipes/apply-rounding-rules.pipe';
import { RelativeTimePipe } from '../../../pipes/relative-time.pipe';
import { NgIf, AsyncPipe, TitleCasePipe, CurrencyPipe } from '@angular/common';
import { FormatPhonePipe } from '../../../pipes/format-phone.pipe';
import { HasPermissionDirective } from '../../../../auth/directives/has-permission.directive';

@Component({
  selector: 'tr[home-transaction-item]',
  templateUrl: './transaction-item.component.html',
  styleUrls: ['./transaction-item.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    TitleCasePipe,
    CurrencyPipe,
    RelativeTimePipe,
    ApplyRoundingRulesPipe,
    FormatPhonePipe,
    HasPermissionDirective,
  ],
})
export class TransactionItemComponent implements OnInit {
  @Input()
  transaction: Transaction;

  @Input()
  isAnimation: boolean = false;

  /***
   * On some pages we need to keep the status visible
   * e.g. Beyonic link related transactions
   */
  @Input()
  keepStatusVisible: boolean = false;

  sidebarIsOpen$: Observable<boolean>;
  roundingPipeParameters: ApplyRoundingRulesPipeParameters;
  currencyPipeDigitalInfo: string;
  transactionColorAndStatus: { color: string; status: string };
  expectedPermissions: Array<string> = []; // List of permissions required to render DOM elements (For Transaction)

  constructor(public transactionUtility: TransactionsUtilityService, private router: Router, private store: Store) {
    this.sidebarIsOpen$ = store.select(selectAnimatedSidebarIsOpen);
  }

  ngOnInit(): void {
    this.roundingPipeParameters = this.transactionUtility.retrieveApplyRoundingRulesPipeParameters(this.transaction);
    this.transactionColorAndStatus = this.transactionUtility.getStatusAndColor(this.transaction['status']);
    this.currencyPipeDigitalInfo = this.transactionUtility.retrieveCurrencyPipeDigitalInfo(
      this.transaction.business.country
    );

    if (this.transaction.type === 'collections') {
      this.expectedPermissions = ['business_org_collections_create'];
    } else if (this.transaction.type === 'payments') {
      this.expectedPermissions = ['business_org_payments_create'];
    }
  }

  retryTransaction(): void {
    this.transactionUtility.resendTransaction(this.transaction);
  }
}
