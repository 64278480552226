<svg
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class="transition-all duration-300"
  [ngClass]="{
    'rotate-360': direction === 'right',
    '-rotate-180': direction === 'left',
    '-rotate-90': direction === 'up',
    'rotate-90': direction === 'down'
  }">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.13998 13.8827C6.73509 13.5479 6.71134 12.9839 7.08695 12.623L10.805 9.05042L7.09881 5.61044C6.71626 5.25537 6.72907 4.69111 7.12741 4.35012C7.52575 4.00913 8.15879 4.02054 8.54133 4.37561L13.5551 9.02923L8.5532 13.8354C8.17759 14.1964 7.54488 14.2175 7.13998 13.8827Z"
    [attr.fill]="strokeFillColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.13998 13.8827C6.73509 13.5479 6.71134 12.9839 7.08695 12.623L10.805 9.05042L7.09881 5.61044C6.71626 5.25537 6.72907 4.69111 7.12741 4.35012C7.52575 4.00913 8.15879 4.02054 8.54133 4.37561L13.5551 9.02923L8.5532 13.8354C8.17759 14.1964 7.54488 14.2175 7.13998 13.8827Z"
    [attr.fill]="strokeFillColor" />
</svg>
