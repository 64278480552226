import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'bey-back-arrow',
  templateUrl: './bey-back-arrow.component.html',
  styleUrls: ['./bey-back-arrow.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class BeyBackArrowComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Output()
  onClick: EventEmitter<null> = new EventEmitter<null>();

  /***
   * Change the cursor to loading state
   */
  @Input()
  isLoading: boolean = false;

  /***
   * Change the cursor to blocked state
   */
  @Input()
  isBlocked: boolean = false;

  /*****
   * Height of the chevron default value is 20
   */
  @Input()
  height: number = 20;

  /*****
   * Width of the chevron default value is 20
   */
  @Input()
  width: number = 20;

  /***
   * Color of the arrow
   */
  @Input()
  strokeFillColor: string = '#4B4B4B';
}
