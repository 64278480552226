import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TransactionsUtilityService } from 'src/app/modules/shared/services/transactions-utility.service';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { NgIf, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-post-top-up-dialog-content',
  templateUrl: './post-top-up-dialog-content.component.html',
  styleUrls: ['./post-top-up-dialog-content.component.scss'],
  standalone: true,
  imports: [NgIf, MatLegacyDialogModule, BeyButtonComponent, CurrencyPipe],
})
export class PostTopUpDialogContentComponent implements OnInit {
  constructor(
    public transactionsUtility: TransactionsUtilityService,
    @Inject(MAT_DIALOG_DATA) public data: { amount: string; currency: string }
  ) {}

  ngOnInit(): void {}
}
