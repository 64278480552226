import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Operation } from '../../interfaces/operation.interface';
import { GetElementIdDirective } from '../../../shared/directives/get-element-id.directive';

@Component({
  selector: 'home-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss'],
  standalone: true,
  imports: [GetElementIdDirective],
})
export class OperationComponent implements OnInit {
  @Input() operation: Operation;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  clickHandler(): void {
    this.router.navigate(
      [this.operation.destination],
      this.operation.state ? { state: { [this.operation.state]: true } } : {}
    );
  }
}
