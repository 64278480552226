import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, EMPTY, throwError, concat, toArray } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CollectionsService } from '../../../ngrx/collections/collections.service';
import { CreateCollectionLinkResponse } from '../../../ngrx/collections/collections.interfaces';
import { BeyToastService } from '../../shared/services/bey-toast.service';
import { Store } from '@ngrx/store';
import { selectUserDetails } from '../../../ngrx/user/user.selectors';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UniqueLinkResolverResolver implements Resolve<CreateCollectionLinkResponse> {
  userDetails$: Observable<any>;

  constructor(
    private collectionsService: CollectionsService,
    private router: Router,
    private toast: BeyToastService,
    private store: Store
  ) {
    this.userDetails$ = this.store.select(selectUserDetails);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    //  get the data from server using link_id
    const uniqueLinkId = route.queryParams?.['link_id'] || route.paramMap.get('link_id');
    const short_name = route.paramMap.get('short_name');
    //  resolve both http requests to get the info
    const toResolve = [];
    uniqueLinkId && toResolve.push(this.collectionsService.getCollectionLinkInfo(uniqueLinkId));
    short_name && toResolve.push(this.collectionsService.getBusinessInfoByShortName(short_name));
    uniqueLinkId && toResolve.push(of(uniqueLinkId));
    short_name && toResolve.push(of(short_name));

    return concat(...toResolve).pipe(
      catchError((e) => {
        if (e.status === 422) {
          if (e.error?.error_code === 'Col422-4') {
            this.router.navigate([`/successful/ul/${short_name}`], {
              queryParams: { link_id: uniqueLinkId },
            });
          } else if (e.error?.error_code === 'Col422-3') {
            //   redirect to link closed page
            this.router.navigate([`/closed/${short_name}`]);
          } else {
            this.router.navigate(['/error']);
          }

          return throwError(e);
        }
        // Handle other errors
        this.toast.open(e.error.detail, 'error');
        this.userDetails$
          .subscribe((userInfo) => this.router.navigate([userInfo ? '/collections' : '/error']))
          .unsubscribe();

        return EMPTY;
      }),

      toArray()
    );
  }
}
