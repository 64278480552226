import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
export const routeTransitionAnimations = trigger('triggerName', [
  transition('One => Two, Two => Three, Three => Four', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100vh',
      }),
    ]),
    query(':enter', [style({ right: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('0.7s ease-out', style({ right: '100%', opacity: 0 }))]),
      query(':enter', [animate('0.7s ease-out', style({ right: '0%', opacity: 1 }))]),
    ]),
    query(':enter', animateChild()),
  ]),
  transition('Four => Three, Three => Two, Two => One, Three => One', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
      }),
    ]),
    query(':enter', [style({ left: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('0.7s ease-out', style({ left: '100%', opacity: 0 }))]),
      query(':enter', [animate('0.7s ease-out', style({ left: '0%', opacity: 1 }))]),
    ]),
    query(':enter', animateChild()),
  ]),
  // Fade from the main pages into the pages with steps
  transition('* <=> *, FadeOut <=> FadeIn', [
    // css styles at start of transition
    style({ opacity: 0 }),

    // animation and styles at end of transition
    animate('0.4s', style({ opacity: 1 })),
  ]),
]);
