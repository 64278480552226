import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { setActiveContact } from '../../../../ngrx/contacts/contacts.actions';
import { resetBankPaymentInfo } from '../../../../ngrx/payments/payments.actions';
import { Store } from '@ngrx/store';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';

@Component({
  selector: 'app-post-bank-payment-dialog-content',
  templateUrl: './post-bank-payment-dialog-content.component.html',
  styleUrls: ['./post-bank-payment-dialog-content.component.scss'],
  standalone: true,
  imports: [BeyButtonComponent],
})
export class PostBankPaymentDialogContentComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PostBankPaymentDialogContentComponent>,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {}

  close() {
    this.store.dispatch(setActiveContact({ payload: null }));
    this.store.dispatch(resetBankPaymentInfo());
    this.dialogRef.close();
    this.router.navigate(['/']);
  }
}
