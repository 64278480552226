<div
  *ngIf="
    !!(userWallet$ | async)?.billing_status &&
    !(getUserWalletIsLoading$ | async) &&
    ((userWallet$ | async)?.billing_status === 'overdue' ||
      ((userWallet$ | async)?.billing_status === 'trial' && !merchantPaidInAdvanced))
  "
  class="wrapper flex justify-between items-center transition-opacity duration-700 ease-in-out">
  <div class="font-medium text-xs leading-[16px] desc">{{ message$ | async }}</div>
  <div>
    <a
      getElementId
      getElementIdDescription="learn-more-billing"
      routerLink="/billing"
      class="text-xs leading-[16px] font-semibold"
      >Learn more</a
    >
  </div>
</div>
