<div class="py-2">
  <img width="46" height="46" src="/assets/images/warning.png" alt="warning" class="mx-auto" />
  <h3 class="font-bold text-center my-3">This collection exceeds your limit.</h3>
  <p class="mb-4 text-center text-gray-700">
    {{ data.first_name }} {{ data.last_name }} won't be able to pay until you reduce your balance, or until midnight
    when daily limits reset.
  </p>
  <mat-dialog-actions class="flex space-x-6 justify-center">
    <bey-button
      placeholder="Cancel"
      color="dark-blue-outlined"
      (onClick)="dialogRef.close(false)"
      data-test-id="cancel"></bey-button>
    <bey-button
      placeholder="Continue anyway"
      [small]="true"
      color="light-blue"
      (onClick)="dialogRef.close(true)"
      data-test-id="continue"></bey-button>
  </mat-dialog-actions>
</div>
