import { Component, EventEmitter, Output } from '@angular/core';
import { BeyChevronComponent } from '../../icons/bey-chevron/bey-chevron.component';
import { BeyButtonComponent } from '../../bey-button/bey-button.component';

@Component({
  selector: 'now-onafriq-highlight',
  standalone: true,
  imports: [BeyChevronComponent, BeyButtonComponent],
  templateUrl: './now-onafriq-highlight.component.html',
  styleUrls: ['./now-onafriq-highlight.component.scss'],
})
export class NowOnafriqHighlightComponent {
  @Output() onButtonClick: EventEmitter<void> = new EventEmitter();
}
