<bey-warning-banner [message]="'Collection failed'"></bey-warning-banner>
<div class="py-8 px-4 text-gray-600 lg:text-center max-w-sm mx-auto" data-test-id="failed-collection-blurb">
  This collection did not go through. Please ensure you have sufficient funds and your number is registered for Mobile
  Money and retry.

  <bey-button
    placeholder="Retry"
    color="yellow"
    classList="mx-auto mt-12 w-3/6 max-w-md"
    (click)="retryPrompt()"></bey-button>
</div>
