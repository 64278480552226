import { Component, Input, OnInit } from '@angular/core';
import { LazyLoadImageDirective } from '../../directives/lazy-load-image.directive';
import { NgIf } from '@angular/common';

@Component({
  selector: 'bey-country-flag-icon',
  templateUrl: './bey-country-flag-icon.component.html',
  styleUrls: ['./bey-country-flag-icon.component.scss'],
  standalone: true,
  imports: [NgIf, LazyLoadImageDirective],
})
export class BeyCountryFlagIconComponent implements OnInit {
  constructor() {}

  /***
   * 2 letter ISO country code in lowercase
   */
  @Input()
  isoCode: string;

  ngOnInit(): void {}
}
