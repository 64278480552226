import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RecentPaymentItem, RecentPaymentResponse } from '../payments/payments.interfaces';
import {
  BulkPayment,
  CreateBulkPaymentRequestBody,
  GetUserBulkPaymentsResponse,
  AddRecipientsToBulkPaymentRequestBody,
  BulkPaymentItem,
  GetRecipientsAssociatedToBulkPaymentResponseType,
  ValidateBulkPaymentRequestBody,
  ValidateBulkPaymentResponseBody,
  UpdateBulkPaymentRequestBody,
  UnvalidatedData,
} from './bulk-payments.interfaces';

@Injectable({
  providedIn: 'root',
})
export class BulkPaymentsService {
  constructor(private http: HttpClient) {}

  processBulkPayment(id: number) {
    return this.http.post(`${environment.API_URL}payments/bulk/${id}/process/`, {});
  }

  createBulkPayment(body: CreateBulkPaymentRequestBody) {
    return this.http.post<BulkPayment>(`${environment.API_URL}payments/bulk/`, body);
  }

  getBulkPayment(paymentId: number, businessId: number) {
    return this.http.get<BulkPayment>(
      `${environment.API_URL}payments/business/${businessId}/payments/bulk/${paymentId}/`
    );
  }

  getRecipientsAssociatedToBulkPayment(paymentId: number, businessId: number) {
    return this.http.get<GetRecipientsAssociatedToBulkPaymentResponseType>(
      `${environment.API_URL}payments/business/${businessId}/payments/bulk/${paymentId}/payments/`
    );
  }

  addRecipientsToBulkPayment(id: number, payments: AddRecipientsToBulkPaymentRequestBody['payments']) {
    return this.http.patch(`${environment.API_URL}payments/bulk/${id}/`, { payments });
  }

  updateBulkPayment(id: number, update: UpdateBulkPaymentRequestBody) {
    return this.http.patch<BulkPayment>(`${environment.API_URL}payments/bulk/${id}/`, { ...update });
  }

  saveForLater(bulkPayment: BulkPayment, body: UnvalidatedData) {
    // title and description are required by endpoint
    const { title, description } = bulkPayment;
    return this.http.patch(`${environment.API_URL}payments/bulk/${bulkPayment.id}/save-for-later/`, {
      unvalidated_data: body,
      title,
      description,
    });
  }

  validate(body: ValidateBulkPaymentRequestBody) {
    const { payments, business_id } = body;
    // Making sure that the phone is a string
    const _payments: Array<BulkPaymentItem> = payments.map((vals: BulkPaymentItem) => {
      let _phone: string | { code: string; phone_number: string } = vals.phone;

      if (typeof vals.phone === 'object') {
        _phone = _phone['code'] + _phone['phone_number'];
      }

      return {
        ...vals,
        phone: _phone as string,
      };
    });

    return this.http.post<ValidateBulkPaymentResponseBody>(`${environment.API_URL}payments/bulk/validate/`, {
      business_id,
      payments: _payments,
    });
  }

  updatePaymentForBulkPayment(
    paymentId: number,
    bulkPaymentId: number,
    payment: AddRecipientsToBulkPaymentRequestBody['payments'][number] & { description: string }
  ) {
    return this.http.patch<RecentPaymentItem>(
      `${environment.API_URL}payments/bulk/${bulkPaymentId}/payments/${paymentId}/update/`,
      {
        ...payment,
      }
    );
  }

  deletePaymentForBulkPayment(paymentId: number, bulkPaymentId: number) {
    return this.http.delete<void>(`${environment.API_URL}payments/bulk/${bulkPaymentId}/payments/${paymentId}/`);
  }

  getUserBulkPaymentsSummary(businessId: number) {
    return this.http.get<GetUserBulkPaymentsResponse['summary']>(
      `${environment.API_URL}payments/business/${businessId}/payments/bulk/summary/`
    );
  }

  getBulkPayments(businessId: number, filters: object = null) {
    let params = new HttpParams();

    if (filters) {
      Object.keys(filters).forEach((key) => {
        const val = filters[key];
        params = params.append(key, val);
      });
    }

    params = params.append('ordering', '-modified');

    return this.http.get<GetUserBulkPaymentsResponse>(
      `${environment.API_URL}payments/business/${businessId}/payments/bulk/`,
      { params }
    );
  }

  getMoreUserBulkPayments(url: string) {
    return this.http.get<GetUserBulkPaymentsResponse>(url);
  }
}
