import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  selectUserWallet,
  selectGetUserWalletIsLoading,
  selectUserWalletCurrency,
} from '../../../../ngrx/wallets/wallets.selectors';
import { Wallet } from 'src/app/ngrx/wallets/wallets.interfaces';
import { selectUserDetails } from 'src/app/ngrx/user/user.selectors';
import { getUserWalletStart } from 'src/app/ngrx/wallets/wallets.actions';
import { TransactionsUtilityService } from 'src/app/modules/shared/services/transactions-utility.service';
import { ApplyRoundingRulesPipe } from '../../../shared/pipes/apply-rounding-rules.pipe';
import { RemoveMinusSignPipe } from '../../../shared/pipes/remove-minus-sign.pipe';
import { NgIf, NgClass, AsyncPipe, CurrencyPipe } from '@angular/common';
import { retrieveOperatingCountry } from '../../../../../assets/utility/shared';

@Component({
  selector: 'home-balance-card',
  templateUrl: './balance-card.component.html',
  styleUrls: ['./balance-card.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, AsyncPipe, CurrencyPipe, RemoveMinusSignPipe, ApplyRoundingRulesPipe],
})
export class BalanceCardComponent implements OnInit, OnDestroy {
  userWallet$: Observable<Wallet>;
  userWalletCurrency$: Observable<string>;
  userWalletSubscription: Subscription;
  getUserWalletIsLoading$: Observable<boolean>;
  hidden: boolean = false;
  currencyPipeDigitalInfo: string;
  country: string;

  subs$: Subscription = new Subscription();

  constructor(private store$: Store, private transactionsUtility: TransactionsUtilityService) {
    // todo this needs to dispatch a new action to fetch user wallet here everytime to update the incoming and outgoing transactions
  }

  ngOnInit(): void {
    this.userWallet$ = this.store$.select(selectUserWallet);
    this.userWalletCurrency$ = this.store$.select(selectUserWalletCurrency);
    this.getUserWalletIsLoading$ = this.store$.select(selectGetUserWalletIsLoading);

    this.subs$.add(
      this.store$.select(selectUserDetails).subscribe((details) => {
        if (details) {
          const {
            businesses: [business],
          } = details;
          this.store$.dispatch(getUserWalletStart({ payload: { business_id: business.id } }));
        }
      })
    );

    this.subs$.add(
      this.userWalletCurrency$.subscribe((currency) => {
        if (currency) {
          this.country = retrieveOperatingCountry(currency, 'currency')?.name;
          this.currencyPipeDigitalInfo = this.transactionsUtility.retrieveCurrencyPipeDigitalInfo(this.country);
        }
      })
    );
  }

  onEyeClick(): void {
    this.hidden = !this.hidden;
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
