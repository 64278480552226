import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Data, Router } from '@angular/router';
import { AsyncPipe, Location, NgClass, NgIf } from '@angular/common';
import { Observable, Subject, takeUntil } from 'rxjs';
import { selectPhoneBookHeaderTitle } from '../../../../ngrx/contacts/contacts.selectors';
import { Store } from '@ngrx/store';
import { RouterService } from '../../../core/services/router.service';

@Component({
  selector: 'bey-page-header',
  templateUrl: './bey-page-header.component.html',
  styleUrls: ['./bey-page-header.component.scss'],
  standalone: true,
  imports: [NgIf, AsyncPipe, NgClass],
})
export class BeyPageHeaderComponent implements OnInit, OnDestroy {
  /****
   * Custom title to be displayed instead of the one that's fetched from route data
   */
  @Input() customTitle: string;
  /****
   * Custom action for the back button instead of the one that's fetched from route data
   */
  @Input() customBackButtonAction: Function;

  routeData$: Observable<Data>;
  headerTitle$: Observable<string>;
  ngDestroyed$: Subject<null> = new Subject();

  routeData: Data;
  hasHistory: boolean;

  constructor(
    private router: Router,
    private location: Location,
    private store$: Store,
    private routerService: RouterService
  ) {
    this.headerTitle$ = this.store$.select(selectPhoneBookHeaderTitle);
    this.hasHistory = this.router.navigated;
    this.routeData$ = this.routerService.routeData$;
  }

  ngOnInit(): void {
    this.routeData$.pipe(takeUntil(this.ngDestroyed$)).subscribe((value) => (this.routeData = value));
  }

  onBackClick(): void {
    if (this.customBackButtonAction) {
      this.customBackButtonAction();
    } else if (this.routeData['back']) {
      this.router.navigate([this.routeData['back']], this.routeData['state'] ? { state: this.routeData['state'] } : {});
    } else {
      this.location.back();
    }
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(null);
    this.ngDestroyed$.complete();
  }
}
