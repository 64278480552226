<div class="flex flex-col w-full px-7 pt-5 pb-8 lg:p-0">
  <span class="text-gray-800 text-2xl font-bold text-center">Get verified to enjoy the best of Onafriq.</span>
  <hr class="my-3" />
  <div class="flex mb-6">
    <bey-numbered-step-circle [stepNumber]="1" class="mr-3"></bey-numbered-step-circle>
    <span class="text-gray-800 text-sm">No daily limits</span>
  </div>
  <div class="flex">
    <bey-numbered-step-circle [stepNumber]="2" class="mr-3"></bey-numbered-step-circle>
    <span class="text-gray-800 text-sm">Onafriq link will feature your business name</span>
  </div>
  <div class="flex flex-col items-center w-full mt-7">
    <bey-button classList="w-full" class="w-full mb-4" (onClick)="proceedForNextStep()">
      <span placeholder>Proceed</span>
    </bey-button>
    <bey-button classList="w-full" color="gray" class="w-full" (onClick)="miscService.closeDrawer()">
      <span placeholder>Cancel</span>
    </bey-button>
  </div>
</div>
