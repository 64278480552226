import { Component, Inject } from '@angular/core';
import { MatLegacyDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgSwitch, NgSwitchCase } from '@angular/common';
import { BulkPaymentHighlightComponent } from './bulk-payment-highlight/bulk-payment-highlight.component';
import { NowOnafriqHighlightComponent } from './now-onafriq-highlight/now-onafriq-highlight.component';

@Component({
  selector: 'app-highlighted-feature-dialog-content',
  templateUrl: './highlighted-feature-dialog-content.component.html',
  styleUrls: ['./highlighted-feature-dialog-content.component.scss'],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, BulkPaymentHighlightComponent, NowOnafriqHighlightComponent],
})
export class HighlightedFeatureDialogContentComponent {
  constructor(private dialog: MatLegacyDialog, @Inject(MAT_DIALOG_DATA) public data: { key: string }) {}

  closeDialog(): void {
    this.dialog.closeAll();
    localStorage.setItem(this.data.key, 'seen');
  }
}
