<div [ngClass]="{ 'pb-4 lg:py-8': !isPreview }">
  <h3 class="font-bold text-center text-lg text-gray-800 pt-10 pb-9" [ngClass]="{ 'lg:hidden': !isPreview }">
    Review purchase
  </h3>
  <div class="flex flex-col" [ngClass]="{ 'lg:flex-row lg:justify-between lg:gap-x-8': linkPhoto && !isPreview }">
    <div class="flex px-6 justify-between gap-x-4" [ngClass]="{ 'lg:px-0  lg:w-5/12  md:justify-start': !isPreview }">
      <div class="w-5/12" *ngIf="linkPhoto" [ngClass]="{ 'lg:w-full': !isPreview }">
        <div class="hidden" [ngClass]="{ 'lg:flex': !isPreview }">
          <div
            class="relative left-2 -top-2.5 flex text-[#2F3235] bg-gray-100 w-[80px] h-[80px] border-gray-100 border-[3px] rounded-full justify-center items-center font-medium text-[38px]">
            {{ businessInfo.active_merchant_name | nameInitials }}
          </div>
          <div class="pt-3 pl-4 w-8/12">
            <div class="text-gray-800 font-bold">
              {{ businessInfo?.active_merchant_name }}
            </div>

            <a
              [href]="bl"
              class="break-words block underline underline-offset-8 font-bold text-blue-400 font-medium text-sm"
              [ngClass]="isPreview || forceMobile ? 'pointer-events-none !cursor-default' : ''"
              >{{ bl }}</a
            >
          </div>
        </div>

        <div
          [ngClass]="{ 'rounded-2xl lg:mx-0': !forceMobile, 'lg:mt-6': !isPreview }"
          class="mx-auto lg:w-full max-h-[50vh] sm:max-w-[50vw] xl:max-w-unset lg:max-h-[350px] relative z-2 overflow-hidden">
          <ng-container #lazyLoadContainer></ng-container>
        </div>

        <div
          class="bg-gray-200 animate-pulse h-[150px] rounded-2xl"
          [ngClass]="{ 'l lg:mt-6': !isPreview }"
          *ngIf="isLoadingViewer"></div>
      </div>
      <div class="flex flex-col justify-between text-gray-500 w-6/12" [ngClass]="{ 'lg:hidden': !isPreview }">
        <div>
          <h3 class="break-words font-bold">{{ linkInfo?.name }}</h3>
          <p class="max-w-[284px]">
            {{ linkInfo?.description | truncateText : 30 }}
          </p>
        </div>
        <bey-display-total-amount
          *ngIf="isDomesticCollection && !!linkInfo?.unit_amount && !!linkInfo?.unit_amount"
          [totalInfo]="totalInfo"></bey-display-total-amount>
      </div>
    </div>

    <form
      class="px-6 flex flex-col"
      [ngClass]="{ 'lg:w-6/12 lg:px-0': linkPhoto && !isPreview, 'w-full': !linkPhoto }"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <hr class="my-6" [ngClass]="{ 'lg:hidden': !isPreview }" />

      <div class="mb-4 mt-6" [ngClass]="{ 'lg:hidden': !isPreview }">Please provide your mobile money details.</div>

      <div class="hidden" *ngIf="!linkPhoto" [ngClass]="{ 'lg:flex': !isPreview }">
        <div
          class="relative left-2 -top-2.5 flex text-[#2F3235] bg-gray-100 w-[80px] h-[80px] border-gray-100 border-[3px] rounded-full justify-center items-center font-medium text-[38px]">
          {{ businessInfo.active_merchant_name | nameInitials }}
        </div>
        <div class="pt-3 pl-4 w-8/12">
          <div class="text-gray-800 font-bold">
            {{ businessInfo?.active_merchant_name }}
          </div>

          <a
            [href]="bl"
            class="break-words block underline underline-offset-8 font-bold text-blue-400 font-medium text-sm"
            [ngClass]="isPreview || forceMobile ? 'pointer-events-none !cursor-default' : ''"
            >{{ bl }}</a
          >
        </div>
      </div>

      <h3 class="text-gray-500 hidden pt-4 pb-1.5 break-words font-bold" [ngClass]="{ 'lg:block': !isPreview }">
        {{ linkInfo?.name }}
      </h3>
      <p class="text-gray-500 max-w-[284px] hidden pb-5" [ngClass]="{ 'lg:block': !isPreview }">
        {{ linkInfo?.description | truncateText : 30 }}
      </p>
      <bey-display-total-amount
        *ngIf="isDomesticCollection && !!linkInfo?.unit_amount"
        class="mb-7 hidden"
        [ngClass]="{ 'lg:block': !isPreview }"
        [totalInfo]="totalInfo"></bey-display-total-amount>

      <div class="w-full flex items-start justify-between space-x-3">
        <bey-input
          class="w-6/12"
          formControlName="first_name"
          label="First name"
          [readonlyNoFeedback]="!!linkInfo?.contact?.first_name"
          getElementId
          [control]="form.controls['first_name']"></bey-input>

        <bey-input
          class="w-6/12"
          formControlName="last_name"
          label="Last name"
          [readonlyNoFeedback]="!!linkInfo?.contact?.last_name"
          getElementId
          [control]="form.controls['last_name']"></bey-input>
      </div>

      <div class="w-full flex items-start justify-between space-x-3" formGroupName="phone">
        <bey-country-selector
          class="w-6/12"
          formControlName="code"
          bindLabel="label"
          label="Country"
          [readonlyNoFeedback]="!!parsedPhoneNumber?.dialingCode"
          getElementId
          [control]="form.get('phone.code')"
          [options]="countries"></bey-country-selector>
        <bey-input
          class="w-6/12"
          label="Phone number"
          type="phone"
          name="phone_number"
          formControlName="phone_number"
          [readonlyNoFeedback]="!!parsedPhoneNumber?.nationalNumber"
          getElementId
          [control]="form.controls['phone']"></bey-input>
      </div>

      <bey-cross-border-amount
        [ngClass]="{ hidden: isDomesticCollection }"
        formControlName="amount"
        [disabled]="!!linkInfo?.unit_amount || form.get('phone').invalid"
        [fromCountry$]="xbFromCountryInfo$"
        [toCountry]="xbToCountryInfo"
        getElementId
        [control]="form.controls['amount']"></bey-cross-border-amount>

      <bey-input
        [ngClass]="{ hidden: !isDomesticCollection || linkInfo.unit_amount }"
        class="w-full"
        formControlName="amount"
        type="amount"
        label="Amount"
        [disabled]="form.get('phone').invalid"
        getElementId
        [control]="form.controls['amount']"></bey-input>

      <bey-input
        class="w-full"
        type="textarea"
        label="Add note (Optional)"
        formControlName="note"
        placeholder="Write any extra notes to add for this payment. eg 'deliver to Kawuku Gardens in Musumba road'"
        getElementId
        [control]="form.controls['note']"></bey-input>
      <spinner *ngIf="form.pending" [diameter]="15"></spinner>

      <bey-button
        classList="px-[20px] mt-2.5 w-full"
        [small]="true"
        [submit]="true"
        [disabled]="form.invalid || form.pending"
        [isLoading]="isLoadingProcessing$ | async"
        getElementId
        getElementIdDescription="pay">
        <span placeholder class="font-medium">Pay</span>
      </bey-button>

      <bey-button
        color="green-outlined"
        classList="px-[20px] mt-7 w-full"
        [small]="true"
        (onClick)="onPrevious.emit()"
        [disabled]="(isLoadingProcessing$ | async) || isPreview"
        getElementId
        getElementIdDescription="back">
        <span placeholder class="font-medium">Back to previous page</span>
      </bey-button>
    </form>
  </div>
</div>
