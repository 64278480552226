export const contactsTypes = {
  GET_USER_CONTACTS_START: 'GET_USER_CONTACTS_START',
  GET_USER_CONTACTS_SUCCESS: 'GET_USER_CONTACTS_SUCCESS',
  GET_USER_CONTACTS_FAILURE: 'GET_USER_CONTACTS_FAILURE',
  GET_MORE_USER_CONTACTS_START: 'GET_MORE_USER_CONTACTS_START',
  GET_MORE_USER_CONTACTS_SUCCESS: 'GET_MORE_USER_CONTACTS_SUCCESS',
  // MISC
  SET_PHONE_BOOK_MISC_CONFIG: 'SET_PHONE_BOOK_MISC_CONFIG',
  SET_ACTIVE_CONTACT: 'SET_ACTIVE_CONTACT',
  RESET_ACTIVE_CONTACT: 'RESET_ACTIVE_CONTACT',
};
