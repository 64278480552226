import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'bey-close-icon',
  templateUrl: './bey-close-icon.component.html',
  styleUrls: ['./bey-close-icon.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class BeyCloseIconComponent implements OnInit {
  constructor() {}

  /****
   * CSS class list to override
   */
  @Input()
  classList: string;

  /**
   * Click event handler
   */
  @Output()
  onClick: EventEmitter<null> = new EventEmitter<null>();

  ngOnInit(): void {}
}
