<div
  (click)="onClick.emit()"
  class="inline-flex items-center cursor-pointer"
  [ngClass]="{
    'cursor-wait': isLoading,
    'cursor-not-allowed	': isBlocked
  }">
  <svg [attr.width]="width" [attr.height]="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.44023 10.0498L16.2402 10.0498"
      [attr.stroke]="strokeFillColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M9.45996 4L3.75996 10.05L9.45996 16"
      [attr.stroke]="strokeFillColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>

  <ng-content></ng-content>
</div>
