import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCurrentRoute } from '../../../../ngrx/auth/auth.selectors';
import { NgClass, AsyncPipe } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { GetElementIdDirective } from '../../../shared/directives/get-element-id.directive';

@Component({
  selector: 'footer[root-footer]',
  templateUrl: './root-footer.component.html',
  styleUrls: ['./root-footer.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, NgClass, AsyncPipe, GetElementIdDirective],
})
export class RootFooterComponent implements OnInit {
  currentRoute$: Observable<string>;
  routes: string[] = ['/', '/transactions', '/links'];

  constructor(private store$: Store) {}

  ngOnInit(): void {
    this.currentRoute$ = this.store$.select(selectCurrentRoute);
  }
}
