import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { buildCollectionLink } from '../../../../../assets/utility';
import { BeyCountrySelectorOptionInterface } from '../../../../../assets/interfaces/bey-country-selector.interface';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CollectionDialog,
  GetBusinessInfoByShortNameResponse,
} from '../../../../ngrx/collections/collections.interfaces';
import {
  GetUserBeyonicLinkResponse,
  ProcessBeyonicLinkRequestBody,
} from '../../../../ngrx/beyonic-link/beyonic-link.interfaces';
import { BusinessInfoExtended, UserBusinessDetailsResponse } from '../../../../ngrx/user/user.interfaces';
import { Store } from '@ngrx/store';
import { processBeyonicLinkStart } from '../../../../ngrx/beyonic-link/beyonic-link.actions';
import { Observable } from 'rxjs';
import { selectProcessBeyonicLinkIsLoading } from '../../../../ngrx/beyonic-link/beyonic-link.selectors';
import { selectCurrentHeadlessStepperStep } from '../../../../ngrx/misc/misc.selectors';
import { toggleHeadlessStepperStep } from '../../../../ngrx/misc/misc.actions';
import { FormUtilityService } from '../../../core/services/form-utility.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CreateCollectionDialogContentComponent } from '../../../collections/components/create-collection-dialog-content/create-collection-dialog-content.component';
import { NameInitialsPipe } from '../../pipes/name-initials.pipe';
import { BeyClientInfoComponent } from './components/bey-client-info/bey-client-info.component';
import { BeyPaymentInfoComponent } from './components/bey-payment-info/bey-payment-info.component';
import { BeyHeadlessStepperComponent } from '../../components/bey-headless-stepper/bey-headless-stepper.component';
import { BeyHeaderWithContentComponent } from '../../components/bey-header-with-content/bey-header-with-content.component';
import { NgIf, NgClass, NgStyle, AsyncPipe } from '@angular/common';
import { retrieveOperatingCountry } from 'src/assets/utility/shared';

@Component({
  selector: 'bey-link-interaction',
  templateUrl: './bey-link-interaction.component.html',
  styleUrls: ['./bey-link-interaction.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    BeyHeaderWithContentComponent,
    NgClass,
    BeyHeadlessStepperComponent,
    NgStyle,
    BeyPaymentInfoComponent,
    BeyClientInfoComponent,
    AsyncPipe,
    NameInitialsPipe,
  ],
})
export class BeyLinkInteractionComponent implements OnInit, OnDestroy {
  /***
   * Force render mobile view because we're dynamically
   * rendering this component on preview (Beyonic Link process)
   */
  @Input()
  forceMobile: boolean = false;

  /*****
   * This prop is used by the preview to pass the business info for logged-in users
   */
  @Input()
  userBusinessInfo: UserBusinessDetailsResponse | BusinessInfoExtended;

  /*****
   * Preview mode
   */
  @Input()
  isPreview: boolean = false;

  @ViewChild('lazyLoadContainer', { read: ViewContainerRef })
  lazyLoadContainer: ViewContainerRef;

  businessInfo: GetBusinessInfoByShortNameResponse;
  linkInfo: GetUserBeyonicLinkResponse;
  isLoadingProcessing$: Observable<boolean>;
  form: UntypedFormGroup;
  countries: Array<BeyCountrySelectorOptionInterface> = [];
  currentStep$: Observable<number>;
  forms: { step1: UntypedFormGroup; step0: UntypedFormGroup } = { step1: null, step0: null };
  totalInfo: { amount: number; currency: string } = {
    amount: 0,
    currency: '',
  };
  linkPhoto: string;
  isLoadingViewer: boolean = true;
  photoRejected: boolean;

  constructor(
    public formUtility: FormUtilityService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private dialog: MatDialog,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // get data from resolver or from prop
    this.businessInfo = this.route.snapshot.data?.['businessInfo'] || this.userBusinessInfo;
    this.linkInfo = this.route.snapshot.data?.['linkData'];
    this.currentStep$ = this.store.select(selectCurrentHeadlessStepperStep);

    // redirect to closed in case the link is closed or merchant hit their limit
    if (!this.linkInfo.is_active && !this.isPreview) {
      this.router.navigate([`closed/${this.businessInfo.short_name}`]);
      return;
    }

    this.photoRejected = this.linkInfo?.photo?.[0] && !this.linkInfo.photo[0].show_photo;

    this.linkPhoto = this.photoRejected
      ? 'assets/images/no-media-1.png'
      : this.linkInfo.public_photo_urls?.public_browse_file_name_url ||
        this.linkInfo.public_photo_url ||
        this.linkInfo?.photo?.[0]?.browse_file_name;

    this.isLoadingProcessing$ = this.store.select(selectProcessBeyonicLinkIsLoading);

    if (this.linkPhoto) {
      // lazy load image viewer component
      this.loadViewer();
    }
  }

  async loadViewer() {
    const { BeyImageViewerComponent } = await import('../../components/bey-image-viewer/bey-image-viewer.component');
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BeyImageViewerComponent);
    const componentRef = this.lazyLoadContainer.createComponent(componentFactory);
    const instance = componentRef.instance;

    instance.imageThumb = this.linkPhoto;
    instance.imageSrc = this.linkPhoto;
    instance.imageAlt = this.linkInfo.name;
    instance.linkInfo = this.linkInfo;
    instance.photoRejected = this.photoRejected;
    this.isLoadingViewer = false;

    this.cdr.detectChanges();
  }

  get bl(): string {
    return buildCollectionLink(this.businessInfo?.short_name, this.linkInfo?.link_id, true);
  }

  back(): void {
    this.store.dispatch(toggleHeadlessStepperStep({ currentStep: 0 }));
  }

  handleStepData({ formGroup, step }: { formGroup: UntypedFormGroup; step: number }) {
    this.forms[`step${step}`] = formGroup;

    switch (step) {
      case 0:
        {
          const { unit_amount, quantity } = this.forms['step0'].value;

          this.store.dispatch(toggleHeadlessStepperStep({ currentStep: 1 }));
          this.totalInfo = {
            amount: unit_amount,
            currency: this.linkInfo.unit_amount_currency, // the amount can be null if the amount is not specified on link creation
          };
        }
        break;

      case 1:
        const { quantity = 1, amount } = this.forms['step0'].value;
        const { first_name, last_name, phone, amount: amountAddedByUser, note } = this.forms['step1'].value;
        let unit_amount;

        // if the user can add an amount then we need to use unit_amount from link info
        if (this.linkInfo.unit_amount) {
          unit_amount = this.linkInfo.unit_amount;
        } else {
          unit_amount = amountAddedByUser?.calculatedAmount || amountAddedByUser || amount;
        }

        const currency = this.linkInfo.unit_amount_currency || amountAddedByUser?.calculatedAmountCurrency;

        const body: ProcessBeyonicLinkRequestBody = {
          phone: `${phone.code?.countryCode || phone.code}${phone.phone_number}`,
          first_name: first_name,
          last_name: last_name,
          unit_amount,
          quantity,
          currency,
        };

        note && (body.description = note);

        let merchantCountryCode = retrieveOperatingCountry(this.businessInfo.country, 'isoCode').countryCode;

        const dialogData: CollectionDialog = {
          business_id: null,
          description: null,
          first_name,
          last_name,
          hideFees: true,
          phone: `${phone.code?.countryCode || phone.code}${phone.phone_number}`,
          type: 'collection-link',
          ...this.businessInfo,
          // todo update the logic to support users with wallet from other countries
          amount: phone.code?.countryCode !== merchantCountryCode ? amountAddedByUser?.amount : unit_amount,
          displayAmount:
            phone.code?.countryCode !== merchantCountryCode ? amountAddedByUser?.amount : unit_amount * quantity,
          currency: phone.code?.countryCode !== merchantCountryCode ? amountAddedByUser?.currency : currency,
          displayCurrency: phone.code?.countryCode !== merchantCountryCode ? amountAddedByUser?.currency : currency,
          // todo update the logic to support users with wallet from other countries
          onSend: () =>
            this.store.dispatch(
              processBeyonicLinkStart({
                body,
                link_id: this.linkInfo.link_id,
                short_name: this.businessInfo.short_name,
              })
            ),
        };

        this.dialog.open(CreateCollectionDialogContentComponent, {
          data: dialogData,
        });
        break;
    }
  }

  previewQtyChange(total: number): void {
    this.totalInfo = {
      amount: total,
      currency: this.linkInfo.unit_amount_currency, // the amount can be null if the amount is not specified on link creation
    };
  }

  ngOnDestroy(): void {
    this.store.dispatch(toggleHeadlessStepperStep({ currentStep: 0 }));
  }
}
