import { createReducer, on } from '@ngrx/store';
import {
  getAccountPermissionsFailure,
  getAccountPermissionsStart,
  getAccountPermissionSuccess,
  lockAccount,
  loginFailure,
  loginStart,
  loginSuccess,
  logOut,
  resetUserPinFailure,
  resetUserPinStart,
  resetUserPinSuccess,
  sendVerificationCodeFailure,
  sendVerificationCodeStart,
  sendVerificationCodeSuccess,
  setCurrentRoute,
  setLoginIsLoadingToFalse,
  signupFailure,
  signupStart,
  signupSuccess,
  unlockAccount,
  updateToken,
  verifyVerificationCodeFailure,
  verifyVerificationCodeStart,
  verifyVerificationCodeSuccess,
} from './auth.actions';
import { AuthState } from './auth.interfaces';
import { produce } from 'immer';

const initialState: AuthState = {
  tokens: null,
  phone: null,
  currentRoute: '',
  lockedAccountTimeStamp: null,
  permissions: null,
  isLoading: {
    signup: false,
    login: false,
    sendVerificationCode: false,
    verifyVerificationCode: false,
    getUserSecurityQuestions: false,
    resetUserPin: false,
    permissions: false,
  },
  errorMessages: {
    signup: '',
    login: '',
    sendVerificationCode: '',
    verifyVerificationCode: '',
    getUserSecurityQuestions: '',
    resetUserPin: '',
  },
};

export const authReducer = createReducer(
  initialState,
  //----------START----------
  on(
    signupStart,
    produce((state) => {
      state.isLoading['signup'] = true;
    })
  ),
  on(
    loginStart,
    produce((state) => {
      state.isLoading['login'] = true;
      state.errorMessages['login'] = '';
    })
  ),
  on(
    sendVerificationCodeStart,
    produce((state) => {
      state.isLoading['sendVerificationCode'] = true;
      state.errorMessages['verifyVerificationCode'] = '';
      state.errorMessages['verifyVerificationCode'] = '';
    })
  ),
  on(
    verifyVerificationCodeStart,
    produce((state) => {
      state.isLoading['verifyVerificationCode'] = true;
    })
  ),
  on(
    resetUserPinStart,
    produce((state) => {
      state.isLoading['resetUserPin'] = true;
    })
  ),
  on(
    getAccountPermissionsStart,
    produce((state) => {
      state.isLoading['permissions'] = true;
    })
  ),
  //----------SUCCESS----------
  on(
    signupSuccess,
    produce((state) => {
      state.isLoading['signup'] = false;
      state.errorMessages['signup'] = '';
    })
  ),
  on(
    loginSuccess,
    produce((state, { payload: { tokens, phone } }) => {
      state.tokens = tokens;
      state.phone = phone;
      state.isLoading['login'] = false;
      state.errorMessages['login'] = '';
    })
  ),
  on(
    sendVerificationCodeSuccess,
    produce((state) => {
      state.isLoading['sendVerificationCode'] = false;
      state.errorMessages['sendVerificationCode'] = '';
    })
  ),
  on(
    verifyVerificationCodeSuccess,
    produce((state) => {
      state.isLoading['verifyVerificationCode'] = false;
      state.errorMessages['verifyVerificationCode'] = '';
    })
  ),
  on(
    resetUserPinSuccess,
    produce((state) => {
      state.isLoading['resetUserPin'] = false;
      state.errorMessages['resetUserPin'] = '';
    })
  ),
  on(
    getAccountPermissionSuccess,
    produce((state, { payload: { is_main, list } }) => {
      state.permissions = {
        is_main,
        list,
      };
      state.isLoading['permissions'] = false;
    })
  ),
  //----------FAILURE----------
  on(
    signupFailure,
    produce((state, { error }) => {
      state.isLoading['signup'] = false;
      state.errorMessages['signup'] = error;
    })
  ),
  on(
    loginFailure,
    produce((state, { error }) => {
      state.isLoading['login'] = false;
      state.errorMessages['login'] = error;
    })
  ),
  on(
    sendVerificationCodeFailure,
    produce((state, { error }) => {
      state.isLoading['sendVerificationCode'] = false;
      state.errorMessages['sendVerificationCode'] = error;
    })
  ),
  on(
    verifyVerificationCodeFailure,
    produce((state, { error }) => {
      state.isLoading['verifyVerificationCode'] = false;
      state.errorMessages['verifyVerificationCode'] = error;
    })
  ),
  on(
    resetUserPinFailure,
    produce((state, { error }) => {
      state.isLoading['resetUserPin'] = false;
      state.errorMessages['resetUserPin'] = error;
    })
  ),
  on(
    getAccountPermissionsFailure,
    produce((state) => {
      state.isLoading['permissions'] = false;
    })
  ),
  //----------OTHER----------
  on(
    logOut,
    produce((state) => {
      state.phone = null;
      state.tokens = null;
    })
  ),
  on(
    updateToken,
    produce((state, { payload }) => {
      state.tokens.access = payload;
    })
  ),
  on(
    lockAccount,
    produce((state) => {
      let lockedAccountTimeStamp = state['lockedAccountTimeStamp'];
      // if there's no timestamp already stored in the state then we create a new one
      let date = lockedAccountTimeStamp || new Date();

      state.lockedAccountTimeStamp = date;
      state.isLoading['login'] = false;
    })
  ),
  on(
    unlockAccount,
    produce((state) => {
      state.lockedAccountTimeStamp = null;
    })
  ),
  on(
    setCurrentRoute,
    produce((state, { payload }) => {
      state.currentRoute = payload;
    })
  ),
  on(
    setLoginIsLoadingToFalse,
    produce((state) => {
      state.isLoading['login'] = false;
    })
  )
);
