<svg
  [attr.height]="height + 'px'"
  [attr.width]="width + 'px'"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  (click)="onClick.emit()">
  <path
    d="M7.99997 13.832C11.2209 13.832 13.832 11.2209 13.832 7.99997C13.832 4.77904 11.2209 2.16797 7.99997 2.16797C4.77904 2.16797 2.16797 4.77904 2.16797 7.99997C2.16797 11.2209 4.77904 13.832 7.99997 13.832Z"
    [attr.stroke]="strokeFillColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M8.0002 11.528C8.44202 11.528 8.8002 11.1698 8.8002 10.728C8.8002 10.2862 8.44202 9.92798 8.0002 9.92798C7.55837 9.92798 7.2002 10.2862 7.2002 10.728C7.2002 11.1698 7.55837 11.528 8.0002 11.528Z"
    [attr.fill]="strokeFillColor" />
  <path d="M8 5.36792V8.38392" [attr.stroke]="strokeFillColor" stroke-width="1.5" stroke-linecap="round" />
</svg>
