import { Component, OnInit } from '@angular/core';
import { BeyButtonComponent } from '../bey-button/bey-button.component';

@Component({
  selector: 'app-bey-network-err-dialog-content',
  templateUrl: './bey-network-err-dialog-content.component.html',
  styleUrls: ['./bey-network-err-dialog-content.component.scss'],
  standalone: true,
  imports: [BeyButtonComponent],
})
export class BeyNetworkErrDialogContentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  refreshPage() {
    window.location.reload();
  }
}
