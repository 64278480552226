<td class="py-3 pl-4 w-[5%] lg:w-auto lg:rounded-l-lg">
  <div
    [class]="
      'min-w-[36px] w-9 h-9 flex items-center justify-center mr-4 rounded-full ' + transactionColorAndStatus?.color
    ">
    <img
      src="/assets/images/top-up.svg"
      alt="top-up"
      *ngIf="transaction?.top_up && transaction?.type === 'collections'" />
    <img
      src="/assets/images/withdraw.svg"
      alt="withdraw"
      *ngIf="transaction?.withdrawal && transaction?.type === 'payments'" />
    <img
      src="/assets/images/collect.svg"
      alt="collection"
      *ngIf="!transaction?.top_up && transaction?.type === 'collections'" />
    <img
      src="/assets/images/send-money.svg"
      alt="send money"
      *ngIf="!transaction?.withdrawal && transaction?.type === 'payments'" />
  </div>
</td>

<td class="py-3" *ngIf="!isAnimation">
  <div class="flex justify-between max-w-[160px] lg:max-w-[200px]">
    <div class="flex flex-col w-full">
      <div class="flex items-center mb-1 w-full">
        <span
          class="text-[#4D4D4B] text-base font-normal capitalize lg:text-lg lg:text-[#131313] lg:max-w-[200px] max-w-[160px] whitespace-nowrap text-ellipsis overflow-hidden">
          <ng-container *ngIf="transaction?.withdrawal && transaction?.type === 'payments'; else other">
            Withdrawal
          </ng-container>
          <ng-template #other>
            <ng-container *ngIf="transaction?.contact.first_name; else anonymous">
              {{ transaction.contact?.first_name | titlecase }} {{ transaction.contact?.last_name | titlecase }}
            </ng-container>
            <ng-template #anonymous>
              {{ transaction.contact?.phone | formatPhone }}
            </ng-template>
          </ng-template>
        </span>
      </div>
      <span class="text-black text-base whitespace-nowrap lg:max-w-[250px] max-w-[145px] font-medium lg:hidden block">{{
        transaction?.related_transaction?.xb_fx_details?.receive_amount || transaction?.amount
          | applyRoundingRules
            : roundingPipeParameters?.countryName
            : roundingPipeParameters?.transactionType
            : roundingPipeParameters?.isXB
          | currency : roundingPipeParameters?.countryCurrency + ' ' : 'symbol' : currencyPipeDigitalInfo
      }}</span>
    </div>
    <img
      *ngIf="transaction?.related_transaction?.xb_fx_details?.is_xb_collection"
      class="mt-1 h-4 w-4 lg:h-5 lg:w-5"
      src="/assets/images/green-globe.png" />
  </div>
</td>

<td class="py-4 pl-4 hidden lg:table-cell" *ngIf="!isAnimation">
  <span class="text-black text-base font-medium">{{
    transaction?.related_transaction?.xb_fx_details?.receive_amount || transaction?.amount
      | applyRoundingRules
        : roundingPipeParameters?.countryName
        : roundingPipeParameters?.transactionType
        : roundingPipeParameters?.isXB
      | currency : roundingPipeParameters?.countryCurrency + ' ' : 'symbol' : currencyPipeDigitalInfo
  }}</span>
</td>

<td class="py-3 hidden lg:table-cell" *ngIf="!isAnimation">
  <span class="text-[#454545]">
    <ng-container *ngIf="transaction?.top_up && transaction?.type === 'collections'"> Top Up </ng-container>
    <ng-container *ngIf="transaction?.withdrawal && transaction?.type === 'payments'"> Withdrawal </ng-container>
    <ng-container *ngIf="!transaction?.top_up && transaction?.type === 'collections'"> Collection </ng-container>
    <ng-container *ngIf="!transaction?.withdrawal && transaction?.type === 'payments'"> Send Money </ng-container>
  </span>
</td>

<!-- <td
  class="py-3 px-2 hidden lg:table-cell cursor-pointer"
  *ngIf="(!isAnimation && !(sidebarIsOpen$ | async)) || keepStatusVisible">
  <div class="flex items-center flex-nowrap">
    <span [class]="'text-base font-normal  text-bey-' + transactionColorAndStatus?.color">{{
      transactionColorAndStatus?.status
    }}</span>

    <div
      (click)="retryTransaction()"
      *ngIf="
        transactionColorAndStatus?.status === 'Failed' &&
        transaction?.request_type !== 'beyonic_link' &&
        transaction?.request_type !== 'static'
      "
      class="flex items-center flex-nowrap ml-3 z-10"
      HasPermission
      [expectedPermission]="expectedPermissions">
      <img class="w-3 h-3" src="assets/images/icons/blue-refresh.svg" alt="retry" />
      <span class="text-blue-400 text-sm mx-1"> Retry </span>
    </div>
  </div>
</td> -->

<td class="py-3 pr-4 text-right lg:rounded-r-lg" *ngIf="!isAnimation">
  <span class="text-base font-normal text-gray-800 whitespace-nowrap">{{
    transaction.created | relativeTime : true
  }}</span>
</td>
