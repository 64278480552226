import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { AuthState } from './auth.interfaces';

const selectAuth = (state: AppState) => {
  return state.auth;
};

//---------1ST LEVEL SELECTORS---------
export const selectTokens = createSelector(selectAuth, (state: AuthState) => state.tokens);

export const selectAuthPhone = createSelector(selectAuth, (state: AuthState) => state.phone);

export const selectCurrentRoute = createSelector(selectAuth, (state: AuthState) => state.currentRoute);

export const selectLockedAccountTimeStamp = createSelector(
  selectAuth,
  (state: AuthState) => state.lockedAccountTimeStamp
);

export const selectAuthIsLoading = createSelector(selectAuth, (state: AuthState) => state.isLoading);

export const selectAuthErrorMessages = createSelector(selectAuth, (state: AuthState) => state.errorMessages);
//---------2ND LEVEL SELECTORS---------
export const selectSignupIsLoading = createSelector(selectAuthIsLoading, (isLoading) => isLoading['signup']);
export const selectSignupErrorMessage = createSelector(
  selectAuthErrorMessages,
  (errorMessages) => errorMessages['signup']
);

export const selectLoginIsLoading = createSelector(selectAuthIsLoading, (isLoading) => isLoading['login']);
export const selectLoginErrorMessage = createSelector(
  selectAuthErrorMessages,
  (errorMessages) => errorMessages['login']
);

export const selectSendVerificationCodeIsLoading = createSelector(
  selectAuthIsLoading,
  (isLoading) => isLoading['sendVerificationCode']
);
export const selectSendVerificationCodeErrorMessage = createSelector(
  selectAuthErrorMessages,
  (errorMessages) => errorMessages['sendVerificationCode']
);

export const selectVerifyVerificationCodeIsLoading = createSelector(
  selectAuthIsLoading,
  (isLoading) => isLoading['verifyVerificationCode']
);
export const selectVerifyVerificationCodeErrorMessage = createSelector(
  selectAuthErrorMessages,
  (errorMessages) => errorMessages['verifyVerificationCode']
);

export const selectResetUserPinIsLoading = createSelector(
  selectAuthIsLoading,
  (isLoading) => isLoading['resetUserPin']
);
export const selectResetUserPinErrorMessage = createSelector(
  selectAuthErrorMessages,
  (errorMessages) => errorMessages['resetUserPin']
);

export const selectPermissionsIsLoading = createSelector(selectAuthIsLoading, (isLoading) => isLoading['permissions']);

export const selectAccountPermissions = createSelector(selectAuth, (state) => state.permissions);
