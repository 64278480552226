import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Operation } from '../../interfaces/operation.interface';
import { User } from '../../../../ngrx/user/user.interfaces';
import { selectUserDetails, selectGetUserBusinessDetailsIsLoading } from '../../../../ngrx/user/user.selectors';
import { selectGetUserKycIsLoading, selectPendingKyc } from '../../../../ngrx/KYC/kyc.selectors';
import dayjs from 'dayjs';
import { selectUserTransactionLimits, selectWalletLimitsShownAlert } from 'src/app/ngrx/wallets/wallets.selectors';
import { openSnackbar } from 'src/app/ngrx/misc/misc.actions';
import { MiscService } from 'src/app/ngrx/misc/misc.service';
import { setWalletLimitsShownAlert } from 'src/app/ngrx/wallets/wallets.actions';
import { selectAnimatedSidebarIsOpen } from '../../../../ngrx/misc/misc.selectors';
import { RecentTransactionsComponent } from '../../../shared/components/recent-transactions/recent-transactions.component';
import { OperationComponent } from '../../components/operation/operation.component';
import { BalanceCardComponent } from '../../components/balance-card/balance-card.component';
import { NgClass, AsyncPipe, NgFor } from '@angular/common';
import { BeyTrialBannerComponent } from '../../../core/components/bey-trial-banner/bey-trial-banner.component';
import { HasPermissionDirective } from '../../../auth/directives/has-permission.directive';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  standalone: true,
  imports: [
    BeyTrialBannerComponent,
    NgClass,
    BalanceCardComponent,
    OperationComponent,
    RecentTransactionsComponent,
    AsyncPipe,
    HasPermissionDirective,
    NgFor,
  ],
})
export class HomePage implements OnInit, OnDestroy, AfterViewInit {
  //---------Observables---------
  currentUser$: Observable<User>;
  pendingKyc$: Observable<boolean>;
  getPersonalProfileIsLoading$: Observable<boolean>;
  getBusinessProfileIsLoading$: Observable<boolean>;
  subs$: Subscription = new Subscription();
  sidebarIsOpen$: Observable<boolean>;

  activeTabIndex: number = 0;
  monthName: string;

  operations: Operation[] = [
    {
      title: 'Top Up',
      destination: '/top-up',
      state: 'top-up',
      icon: 'top-up',
      permissions: ['business_org_collections_create'],
    },
    {
      title: 'Send Money',
      destination: '/payments',
      icon: 'send-money',
      permissions: ['business_org_payments_create'],
    },
    {
      title: 'Collect',
      destination: '/collections',
      icon: 'collect',
      permissions: ['business_org_collections_create'],
    },
    {
      title: 'Withdraw',
      destination: '/withdraw',
      icon: 'withdraw',
      permissions: ['business_org_payments_create'],
    },
  ];
  isLoadingAnalytics: boolean = false;

  @ViewChildren('lazyLoadContainer', { read: ViewContainerRef })
  lazyLoadContainers: QueryList<ViewContainerRef>;

  constructor(
    private store$: Store,
    private miscService: MiscService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.store$.select(selectUserDetails);
    this.pendingKyc$ = this.store$.select(selectPendingKyc);
    this.sidebarIsOpen$ = this.store$.select(selectAnimatedSidebarIsOpen);

    this.getPersonalProfileIsLoading$ = this.store$.select(selectGetUserKycIsLoading);
    this.getBusinessProfileIsLoading$ = this.store$.select(selectGetUserBusinessDetailsIsLoading);

    this.monthName = dayjs().format('MMMM');

    this.subs$.add(
      this.store$.select(selectUserTransactionLimits).subscribe((limits) => {
        if (limits) {
          let walletLimitsShownAlert;
          this.store$
            .select(selectWalletLimitsShownAlert)
            .subscribe((shownAlert) => (walletLimitsShownAlert = shownAlert))
            .unsubscribe();

          // we don't show the alert again if it was already shown
          if (!walletLimitsShownAlert) {
            let snackbarDetails = this.miscService.checkUserWalletLimitsAlerts(limits);
            // we show the alert only if one of the alerts booleans is true
            if (snackbarDetails) {
              let walletLimitsShownAlertToBeSet = snackbarDetails['type'];
              const { color, content, title } = snackbarDetails;
              this.store$.dispatch(
                openSnackbar({
                  snackbarDetails: {
                    color,
                    content,
                    title,
                    bottomContentDetails: {
                      title: 'Learn more',
                      route: 'https://apphelp.onafriq.com/en/articles/7145726-why-do-i-need-to-verify-my-business',
                    },
                  },
                })
              );
              this.store$.dispatch(setWalletLimitsShownAlert({ payload: walletLimitsShownAlertToBeSet }));
            }
          }
        }
      })
    );
  }

  ngAfterViewInit() {
    this.loadAnalyticsComponent();
  }

  async loadAnalyticsComponent() {
    this.isLoadingAnalytics = true;
    this.cdr.detectChanges();

    try {
      const { BeySevenDayAnalyticsComponent } = await import(
        '../../../shared/components/bey-seven-day-analytics/bey-seven-day-analytics.component'
      );

      this.lazyLoadContainers.toArray().forEach((c) => {
        c.clear();
        c.createComponent(this.componentFactoryResolver.resolveComponentFactory(BeySevenDayAnalyticsComponent));
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingAnalytics = false;
    }
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
