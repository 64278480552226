import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, skipWhile, combineLatest, map, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectAccountPermissions, selectPermissionsIsLoading } from '../../../ngrx/auth/auth.selectors';
import { getAccountPermissionsStart } from '../../../ngrx/auth/auth.actions';
import { selectUserDetails } from '../../../ngrx/user/user.selectors';

/****
 * This guard prevents user with insufficient permissions to access a route or it's children
 * It checks if the app state has the user permissions, if not it'll fetch the user permissions
 * and use them to validate
 */

/****
 * IS Strict is used to control how strict is the guard
 * sometimes users have a single permission e.g. business_org_collections_create only
 * so we shouldn't block access to the page has other feature ,and we relay on
 * has permission directive to conditionally render the authorized parts
 */
@Injectable({
  providedIn: 'root',
})
export class HasPermissionsGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasPermissions(route);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasPermissions(childRoute);
  }

  private hasPermissions(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(selectUserDetails),
      switchMap((user) => {
        return combineLatest([
          this.store.select(selectAccountPermissions),
          this.store.select(selectPermissionsIsLoading),
        ]).pipe(
          skipWhile(([permissions, isLoading]) => {
            //   prevent multiple calls
            if (!permissions && !isLoading) {
              const business = user.businesses[0];

              this.store.dispatch(
                getAccountPermissionsStart({
                  payload: {
                    business_id: business.id,
                  },
                })
              );
            }

            return !permissions;
          })
        );
      }),
      map(([userPermissions]) => {
        const expectedPermissions: Array<string> = route.data?.['expectedPermissions'];
        let isStrict: boolean = true; // This controls how strict the guard should interact with users that don't have full permissions

        if (route.data?.['strictPermissions'] !== undefined) {
          isStrict = route.data?.['strictPermissions'];
        }

        if (expectedPermissions && userPermissions) {
          let hasPermission: boolean;

          // show everything for main business account
          if (userPermissions['is_main']) {
            hasPermission = true;
          } else {
            if (isStrict) {
              hasPermission = expectedPermissions.every((p) => userPermissions['list'].includes(p));
            } else {
              hasPermission = expectedPermissions.some((p) => userPermissions['list'].includes(p));
            }
          }

          if (!hasPermission) {
            this.router.navigate(['/']);
            return false;
          }

          return true;
        } else {
          console.error('ExpectedPermissions property is not set for route:', route);

          this.router.navigate(['/error']);
          return false;
        }
      })
    );
  }
}
