import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { selectContactsConfig } from './contacts.selectors';
import { EMPTY } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { ContactItem, CreateBulkContactsRequestBody, CreateContactBody } from './contacts.interface';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(private http: HttpClient, private store$: Store) {}

  /****
   *
   * @param paramsValues
   * This is an object that can contain any GET parameter supported by the endpoint
   *  e.g.
   *  ordering=first_name | ASC
   *  first_name=john | DESC
   *  ordering=-first_name
   *
   *  So technically we can do ordering and search using this method by just providing different params
   */
  getContacts(paramsValues = {}) {
    let params = new HttpParams();

    Object.keys(paramsValues).forEach((key) => {
      const val = paramsValues[key];
      val && (params = params.append(key, val));
    });

    params = params.append('ordering', '-created');

    return this.http.get(`${environment.API_URL}contacts/`, { params });
  }

  /******
   *  This method queries the store for saved config and retrieves more contacts based on that
   */
  getMoreContacts() {
    return this.store$.select(selectContactsConfig).pipe(
      take(1),
      mergeMap((v) => {
        const { next } = v;
        // Use the next URL to fetch the next batch of contacts from the API
        if (next) {
          return this.http.get(next);
        }

        return EMPTY;
      })
    );
  }

  createContact(params: CreateContactBody) {
    return this.http.post<ContactItem>(`${environment.API_URL}contacts/`, { ...params });
  }

  createBulkContact(body: CreateBulkContactsRequestBody) {
    return this.http.post<ContactItem>(`${environment.API_URL}contacts/bulk/`, body);
  }
}
