import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-payment-loading-dialog-content',
  templateUrl: './payment-loading-dialog-content.component.html',
  styleUrls: ['./payment-loading-dialog-content.component.scss'],
  standalone: true,
  imports: [NgIf, BeyButtonComponent, MatLegacyDialogModule],
})
export class PaymentLoadingDialogContentComponent implements OnInit {
  /***
   *        failureReason: 'This fail because .... test',
   *        type: 'fail',
   * @param data
   */
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: 'processing' | 'success' | 'fail';
    }
  ) {}

  ngOnInit(): void {}
}
