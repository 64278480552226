import { NgModule } from '@angular/core';
import { CollectionsLinkRoutingModule } from './collections-link-routing.module';
import { IndexPage } from './pages/index/index.component';
import { ActionsPage } from './pages/actions/actions.page';
import { BeyTransactionExceedsLimitComponentContent } from './components/bey-transaction-exceeds-limit-content/bey-transaction-exceeds-limit-component-content.component';
import { CollectionsModule } from '../collections/collections.module';

@NgModule({
  imports: [
    CollectionsLinkRoutingModule,
    CollectionsModule,
    IndexPage,
    ActionsPage,
    BeyTransactionExceedsLimitComponentContent,
  ],
})
export class CollectionsLinkModule {}
