import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of as observableOf, catchError, map, mergeMap, switchMap, tap } from 'rxjs';
import { CollectionsService } from './collections.service';
import { Router } from '@angular/router';
import { BeyToastService } from '../../modules/shared/services/bey-toast.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PostTopUpDialogContentComponent } from 'src/app/modules/top-up/components/post-top-up-dialog-content/post-top-up-dialog-content.component';
import { PostCreateCollectionDialogContentComponent } from 'src/app/modules/collections/components/post-create-collection-dialog-content/post-create-collection-dialog-content.component';
import { collectionsTypes } from './collections.types';
import { setWalletLimitsShownAlert } from '../wallets/wallets.actions';
import { Action } from '@ngrx/store';
import {
  createCollectionFailure,
  createCollectionLinkFailure,
  createCollectionLinkStart,
  createCollectionLinkSuccess,
  createCollectionStart,
  createCollectionSuccess,
  getCollectionInfoFailure,
  getCollectionInfoStart,
  getCollectionInfoSuccess,
  getCollectionLinkInfoFailure,
  getCollectionLinkInfoStart,
  getCollectionLinkInfoSuccess,
  getCollectionsFeesFailure,
  getCollectionsFeesStart,
  getCollectionsFeesSuccess,
  getMoreUserCollectionsFailure,
  getMoreUserCollectionsStart,
  getMoreUserCollectionsSuccess,
  getUserCollectionsFailure,
  getUserCollectionsStart,
  getUserCollectionsSuccess,
} from './collections.actions';

@Injectable()
export class CollectionsEffects {
  constructor(
    private collectionsService: CollectionsService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private dialog: MatDialog
  ) {}

  createCollectionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCollectionStart),
      switchMap(({ payload }) => {
        return this.collectionsService.createCollection(payload).pipe(
          mergeMap((resp) => {
            const { type, currency } = payload;
            const {
              contact: { first_name, last_name, phone },
              amount,
            } = resp;

            this.dialog.closeAll();

            if (type === 'top-up') {
              this.router.navigate(['/']);
              this.dialog.open(PostTopUpDialogContentComponent, {
                data: { amount, currency },
              });
            } else if (type === 'collection') {
              this.router.navigate(['/']);
              this.dialog.open(PostCreateCollectionDialogContentComponent, {
                data: { payload, success: true },
              });
            }

            const actions: Array<Action> = [
              createCollectionSuccess({
                payload: payload.description === 'Top Up' ? { phone, first_name, last_name } : null,
              }),
            ];

            if (type === 'collection') {
              actions.push(setWalletLimitsShownAlert({ payload: null }));
            } else if (type === 'collection-link') {
              this.router.navigate([`/pending/${payload?.['short_name']}`], {
                // todo in future think about how we manage data types and how to pass extra properties without compromising the data type
                queryParams: { id: payload.link_id, type: 'ul' },
              });
            } else if (type === 'collection-link-static') {
              this.router.navigate([`/pending/${payload?.['short_name']}`], {
                queryParams: {
                  id: resp?.['code'],
                  type: 'sl',
                },
              });
            }

            return actions;
          }),
          catchError((error) => {
            if (error.status !== 429) {
              this.dialog.closeAll();
              if (payload.type === 'top-up') {
                this.dialog.open(PostTopUpDialogContentComponent);
              } else if (payload.type === 'collection-link') {
                this.dialog.open(PostCreateCollectionDialogContentComponent, {
                  data: { payload, success: false, error },
                });
              } else {
                this.dialog.open(PostCreateCollectionDialogContentComponent, { data: { payload, success: false } });
              }
            }
            return observableOf(createCollectionFailure(error));
          })
        );
      })
    )
  );

  createPartialRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCollectionLinkStart),
      switchMap(({ payload }) =>
        this.collectionsService.createPartialRequest(payload).pipe(
          map((response) => ({
            type: collectionsTypes.CREATE_PARTIAL_REQUEST_SUCCESS,
            payload: response,
          })),
          catchError((e) => {
            return observableOf(createCollectionLinkFailure());
          })
        )
      )
    )
  );

  createPartialRequestSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createCollectionLinkSuccess),
        tap(({ payload }) => {
          const { id } = payload;

          this.toast.open('Your link has been created successfully', 'blue');
          this.router.navigate([`/collections-link/actions/${id}`]);
        })
      ),
    { dispatch: false }
  );

  createPartialRequestFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createCollectionLinkFailure),
        tap(() => {
          this.toast.open('Something went wrong, please try again', 'error');
        })
      ),
    { dispatch: false }
  );

  getUserCollectionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserCollectionsStart),
      switchMap(({ payload: { businessId } }) =>
        this.collectionsService.getUserCollections(businessId).pipe(
          map((data) => getUserCollectionsSuccess({ payload: data })),
          catchError((error) => {
            this.toast.open(
              'Something went wrong, please refresh the page',
              'error',
              'Refresh',
              () => location.reload(),
              false,
              true
            );
            return observableOf(getUserCollectionsFailure(error));
          })
        )
      )
    )
  );

  getMoreUserCollectionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreUserCollectionsStart),
      switchMap(({ payload: { url } }) =>
        this.collectionsService.getMoreUserCollections(url).pipe(
          map(({ results, next }) => getMoreUserCollectionsSuccess({ payload: { collections: results, next } })),
          catchError((error) => observableOf(getMoreUserCollectionsFailure(error)))
        )
      )
    )
  );

  getCollectionsFeesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCollectionsFeesStart),
      switchMap(({ payload }) =>
        this.collectionsService.getCollectionsFees(payload?.useOpenEndpoint).pipe(
          map((payload) => getCollectionsFeesSuccess({ payload })),
          catchError((error) => observableOf(getCollectionsFeesFailure(error)))
        )
      )
    )
  );

  getCollectionLinkInfoEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCollectionLinkInfoStart),
      switchMap(({ payload: { id, type, short_name } }) =>
        this.collectionsService.getCollectionLinkInfo(id).pipe(
          map((payload) => getCollectionLinkInfoSuccess({ payload })),
          catchError((e) => {
            if (e.status === 400 && e.error.error_code === 'Col400-5') {
              this.router.navigate([`/successful/${type}/${short_name}`], {
                queryParams: { code: id },
              });
            } else if (e.status === 422) {
              const { error } = e;
              const short_name = error?.short_name;

              //   redirect to link closed page
              this.router.navigate([`/closed/${short_name}`]);
            } else {
              this.router.navigate(['/error']);
            }

            return observableOf(getCollectionLinkInfoFailure(e));
          })
        )
      )
    )
  );

  getCollectionInfoEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCollectionInfoStart),
      switchMap(({ code, link_id }) => {
        const requiredRequest: Observable<object> = link_id
          ? this.collectionsService.getCollectionInfoByLinkId(link_id)
          : this.collectionsService.getCollectionInfoByCode(code);

        return requiredRequest.pipe(
          map((payload) => getCollectionInfoSuccess({ payload: link_id ? { ...payload, link_id } : payload })),
          catchError((error) => {
            if (error.status === 404) {
              this.router.navigate(['/error']);
            }
            return observableOf(getCollectionInfoFailure());
          })
        );
      })
    )
  );
}
