<div class="px-3 pt-4 lg:p-0">
  <bey-trial-banner></bey-trial-banner>
  <div class="lg:px-12 max-w-screen-lg mx-auto" [ngClass]="(pendingKyc$ | async) ? 'lg:mt-40' : 'lg:mt-20'">
    <div class="flex flex-col gap-1 w-full justify-between items-stretch lg:flex-row">
      <home-balance-card class="w-full"></home-balance-card>

      <div
        class="flex flex-col w-full bg-transparent md:rounded-lg lg:bg-gray-50 md:px-5 lg:max-w-[335px] lg:justify-center">
        <span class="text-black font-medium text-sm mb-4">Quick actions</span>
        <div class="flex items-center mb-8 justify-between md:m-0">
          <home-operation
            *ngFor="let operation of operations"
            [operation]="operation"
            HasPermission
            [expectedPermission]="operation.permissions"></home-operation>
        </div>
      </div>
    </div>

    <app-recent-transactions class="lg:mt-8 lg:max-w-[80vw] block"> </app-recent-transactions>
  </div>
</div>
