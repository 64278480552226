import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  CreateBeyonicLinkRequestBody,
  GetUserBeyonicLinkResponse,
  GetUserBeyonicLinksResponse,
  ProcessBeyonicLinkRequestBody,
  ProcessBeyonicLinkResponse,
  BeyonicLink,
  CheckBeyonicLinkNameRequestBody,
  UpdateBeyonicLinkRequestBody,
  UpdateBeyonicLinkPhotoResponse,
  LinkNameCheckResponse,
} from './beyonic-link.interfaces';
import { collectionsState } from '../collections/collections.interfaces';

@Injectable({
  providedIn: 'root',
})
export class BeyonicLinkService {
  constructor(private http: HttpClient) {}

  createBeyonicLink(payload: CreateBeyonicLinkRequestBody) {
    let newPayload = new FormData();
    Object.keys(payload).forEach((key) => {
      if (payload[key]) {
        newPayload.append(key, payload[key]);
      }
    });

    return this.http.post<BeyonicLink>(`${environment.API_URL}beyonic-links/`, newPayload);
  }

  updateBeyonicLink(body: UpdateBeyonicLinkRequestBody) {
    const data = { ...body };

    delete data['photo_browse_file_name'];
    delete data['beyonic_link_id'];

    return this.http.patch<BeyonicLink>(`${environment.API_URL}beyonic-links/${body['beyonic_link_id']}/`, data);
  }

  checkBeyonicLinkName(body: CheckBeyonicLinkNameRequestBody) {
    return this.http.post<LinkNameCheckResponse>(`${environment.API_URL}beyonic-links/name-check/`, body);
  }

  getUserBeyonicLinksSummary(businessId: number) {
    return this.http.get<GetUserBeyonicLinksResponse['summary']>(
      `${environment.API_URL}collections/business/${businessId}/beyonic-links/summary/`
    );
  }

  getUserBeyonicLinks(businessId: number, filters: object) {
    let params = new HttpParams();

    if (filters) {
      Object.keys(filters).forEach((key) => {
        const val = filters[key];
        params = params.append(key, val);
      });
    }

    params = params.append('ordering', '-created');

    return this.http.get<GetUserBeyonicLinksResponse>(
      `${environment.API_URL}collections/business/${businessId}/beyonic-links/`,
      { params }
    );
  }

  getMoreUserBeyonicLinks(url: string) {
    return this.http.get<GetUserBeyonicLinksResponse>(url);
  }

  getBeyonicLinkCollections(linkId: number, businessId: number) {
    let params = new HttpParams();

    params = params.append('ordering', '-created');

    return this.http.get<collectionsState['userCollections']>(
      `${environment.API_URL}collections/business/${businessId}/beyonic-links/${linkId}/payment-requests/`,
      { params }
    );
  }

  getMoreBeyonicLinkCollections(url: string) {
    return this.http.get<collectionsState['userCollections']>(url);
  }

  getBeyonicLinkInfo(link_id: string, businessId?: number) {
    // Public endpoint that doesn't require auth token
    if (!businessId) {
      return this.http.get<GetUserBeyonicLinkResponse>(`${environment.API_URL}beyonic-links/bl/${link_id}/`);
    }

    return this.http.get<GetUserBeyonicLinkResponse>(
      `${environment.API_URL}collections/business/${businessId}/beyonic-links/${link_id}/`
    );
  }

  processBeyonicLink(link_id: string, body: ProcessBeyonicLinkRequestBody) {
    return this.http.post<ProcessBeyonicLinkResponse>(
      `${environment.API_URL}beyonic-links/bl/${link_id}/process-link/`,
      body
    );
  }

  updateBeyonicLinkPhoto(link_id: number, photo: File) {
    const formData = new FormData();
    formData.append('browse_file_name', photo);

    return this.http.patch<UpdateBeyonicLinkPhotoResponse>(
      `${environment.API_URL}beyonic-links/${link_id}/photo/`,
      formData
    );
  }

  removeBeyonicLinkPhoto(link_id: number) {
    return this.http.delete(`${environment.API_URL}beyonic-links/${link_id}/remove-photo/`);
  }
}
