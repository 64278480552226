import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthService } from '../../../ngrx/auth/auth.service';
import { BehaviorSubject, Observable, throwError, Subscription } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectTokens } from '../../../ngrx/auth/auth.selectors';
import { Tokens } from 'src/app/ngrx/auth/auth.interfaces';
import { updateToken, logOut } from 'src/app/ngrx/auth/auth.actions';
import { Router } from '@angular/router';
import { BeyToastService } from '../../shared/services/bey-toast.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private tokens$: Observable<Tokens>;
  subs: Array<Subscription> = [];

  constructor(
    private store$: Store,
    private toast: BeyToastService,
    private authService: AuthService,
    private router: Router
  ) {
    this.tokens$ = this.store$.select(selectTokens);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    let accessToken: string;
    const skipInterceptor = req.headers.get('skip');

    if (skipInterceptor) {
      req = req.clone({
        headers: req.headers.delete('skip'),
      });
      return next.handle(req);
    }

    this.subs.push(this.tokens$.subscribe((tokens) => (accessToken = tokens?.access)));

    if (accessToken) {
      authReq = this.addTokenHeader(req, accessToken);
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 403 && error.error.code === 'token_not_valid') {
          return this.handle403Error(authReq, next);
        }
        return throwError(() => error);
      })
    );
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      let refreshToken: string;
      this.subs.push(this.tokens$.subscribe((tokens) => (refreshToken = tokens?.refresh)));

      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap(({ access }) => {
            this.isRefreshing = false;
            this.store$.dispatch(updateToken({ payload: access }));
            this.refreshTokenSubject.next(access);

            return next.handle(this.addTokenHeader(request, access));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            if (err.status === 401) {
              this.store$.dispatch(logOut());
              this.router.navigate(['/login']);
              this.toast.open('You have been automatically logged out for security reasons', 'blue', null, null);
            }
            return throwError(() => err);
          })
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((accessToken) => accessToken !== null),
      take(1),
      switchMap((accessToken) => next.handle(this.addTokenHeader(request, accessToken)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, accessToken: string) {
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + accessToken),
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

export const authInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
