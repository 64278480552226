import { createReducer, on } from '@ngrx/store';
import { PaymentState, RecentPaymentItem } from './payments.interfaces';
import { produce } from 'immer';
import {
  clearPaymentInfo,
  createPaymentFailure,
  createPaymentStart,
  createPaymentSuccess,
  getBankListStart,
  getBankListSuccess,
  getBankPaymentsFeesSuccess,
  getMoreUserPaymentsFailure,
  getMoreUserPaymentsStart,
  getMoreUserPaymentsSuccess,
  getPaymentsFeesSuccess,
  getPaymentsStart,
  getPaymentsSuccess,
  resetBankPaymentInfo,
  resetNewPayment,
  setLiquidateUser,
  updateBankPaymentInfo,
  updatePaymentInfo,
} from './payments.actions';

const initState: PaymentState = {
  liquidateUser: null,
  paymentsFees: null,
  bankPaymentsFees: null,
  paymentInfo: {
    amount: null,
    code: '',
    description: '',
    first_name: '',
    last_name: '',
    phone: '',
    phone_number: '',
    name_on_network: '',
    name_check_score: null,
  },
  bankList: [],
  bankPaymentInfo: null,
  isDirty: false,
  recentPayments: {
    data: {
      count: null,
      next: null,
      previous: null,
      results: [],
    },
    isLoading: false,
  },
  isLoading: {
    createPayment: false,
    createBankPayment: false,
    getMoreUserPayments: false,
    getPaymentsFees: false,
    getBankPaymentsFees: false,
    bankList: false,
  },
  errorMessages: {
    createPayment: '',
    createBankPayment: '',
    getMoreUserPayments: '',
    getPaymentsFees: '',
    getBankPaymentsFees: '',
  },
};

export const paymentsReducer = createReducer(
  initState,
  on(
    resetNewPayment,
    produce((state: PaymentState, { payload: { paymentId } }) => {
      let newPayments = state.recentPayments.data.results.map((payment) =>
        payment.id === paymentId ? (payment = { ...payment, newTransaction: false }) : payment
      );

      state.recentPayments.data.results = newPayments;
    })
  ),
  on(
    updatePaymentInfo,
    produce((state: PaymentState, { payload }) => {
      state.isDirty = true;
      state.paymentInfo = { ...payload };
    })
  ),
  on(
    updateBankPaymentInfo,
    produce((state: PaymentState, { payload }) => {
      state.bankPaymentInfo = {
        ...state.bankPaymentInfo,
        ...payload,
      };
    })
  ),
  on(
    resetBankPaymentInfo,
    produce((state: PaymentState) => {
      state.bankPaymentInfo = null;
    })
  ),
  on(
    clearPaymentInfo,
    produce((state: PaymentState) => {
      state.isDirty = false;
      state.paymentInfo = {
        amount: null,
        code: '',
        description: '',
        first_name: '',
        last_name: '',
        phone: '',
        phone_number: '',
        name_check_score: null,
        name_on_network: '',
      };
    })
  ),
  on(
    getPaymentsStart,
    produce((state: PaymentState) => {
      state.recentPayments.isLoading = true;
    })
  ),
  on(
    setLiquidateUser,
    produce((state: PaymentState, { payload }) => {
      state.liquidateUser = payload;
    })
  ),
  on(
    getPaymentsFeesSuccess,
    produce((state: PaymentState, { payload }) => {
      state.paymentsFees = payload;
    })
  ),
  on(
    getBankPaymentsFeesSuccess,
    produce((state: PaymentState, { payload }) => {
      state.bankPaymentsFees = payload;
    })
  ),
  on(
    getPaymentsSuccess,
    produce((state: PaymentState, { payload }) => {
      let currentStorePayments = state.recentPayments.data.results;
      let newPayments: RecentPaymentItem[];

      // check if there's already collection in the store before comparing them with new collections
      if (currentStorePayments.length) {
        newPayments = payload.results.map((payment) => {
          if (!!!currentStorePayments.find((currentStorePayment) => currentStorePayment.id === payment.id)) {
            return (payment = { ...payment, newTransaction: true });
          } else {
            return payment;
          }
        });
      } else {
        newPayments = payload.results;
      }

      state.recentPayments = {
        data: { ...payload, results: newPayments },
        isLoading: false,
      };
    })
  ),
  //----------CREATE PAYMENT----------
  on(
    createPaymentStart,
    produce((state) => {
      state.isLoading['createPayment'] = true;
    })
  ),
  on(
    createPaymentSuccess,
    produce((state) => {
      state.isLoading['createPayment'] = false;
      state.errorMessages['createPayment'] = '';
    })
  ),
  on(
    createPaymentFailure,
    produce((state, { error }) => {
      state.isLoading['createPayment'] = false;
      state.errorMessages['createPayment'] = error;
    })
  ),
  //----------CREATE MORE USER PAYMENTS----------
  on(
    getMoreUserPaymentsStart,
    produce((state) => {
      state.isLoading['getMoreUserPayments'] = true;
    })
  ),
  on(
    getMoreUserPaymentsSuccess,
    produce((state, { payload: { payments, next } }) => {
      state.recentPayments.data.next = next;
      state.recentPayments.data.results.push(...payments);
      state.isLoading['getMoreUserPayments'] = false;
      state.errorMessages['getMoreUserPayments'] = '';
    })
  ),
  on(
    getMoreUserPaymentsFailure,
    produce((state, { error }) => {
      state.isLoading['getMoreUserPayments'] = false;
      state.errorMessages['getMoreUserPayments'] = error;
    })
  ),
  //----------BANK LIST----------
  on(
    getBankListStart,
    produce((state) => {
      state.isLoading['bankList'] = true;
    })
  ),
  on(
    getBankListSuccess,
    produce((state, { payload }) => {
      state.isLoading['bankList'] = false;
      state.bankList = payload;
    })
  )
);
