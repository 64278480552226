import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bey-tab',
  templateUrl: './bey-tab.component.html',
  styleUrls: ['./bey-tab.component.scss'],
  standalone: true,
})
export class BeyTabComponent implements OnInit {
  constructor() {}
  @Input()
  name: string;

  @Input()
  active = false;

  ngOnInit(): void {}
}
