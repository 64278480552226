<div class="flex justify-center items-center h-full md:h-screen">
  <div class="text-center text-bey-dark-blue max-w-[290px]">
    <div class="font-medium text-3xl leading-[39px] mb-7 text-gray-800">Oops!</div>
    <div class="leading-5">We can’t seem to find the page you’re looking for</div>

    <bey-button
      (click)="navigate()"
      color="orange"
      [placeholder]="userLoggedIn ? 'Home' : 'Log in'"
      classList="mt-20 mx-auto px-7"
      id="error-page-home-btn"></bey-button>

    <div class="mt-8 leading-5 text-gray-800" *ngIf="!userLoggedIn">
      Don't have an account?
      <a
        getElementId
        getElementIdDescription="learn-more"
        class="ml-0.5 text-blue-500 font-semibold"
        href="https://www.onafriq.com/"
        target="_blank"
        >Learn More</a
      >
    </div>
  </div>
</div>
