import { createAction, props } from '@ngrx/store';
import { contactsTypes } from './contacts.types';
import { ContactItem, ContactsResponseInterface, ErrorType, GetContactsPayload } from './contacts.interface';

// GET contacts
export const getUserContactsStart = createAction(
  contactsTypes.GET_USER_CONTACTS_START,
  (defaultSetting: GetContactsPayload = {}) => ({ payload: defaultSetting })
);

export const getMoreUserContactsStart = createAction(contactsTypes.GET_MORE_USER_CONTACTS_START);

export const getUserContactsSuccess = createAction(
  contactsTypes.GET_USER_CONTACTS_SUCCESS,
  props<{ payload: ContactsResponseInterface }>()
);

export const getMoreUserContactsSuccess = createAction(
  contactsTypes.GET_MORE_USER_CONTACTS_SUCCESS,
  props<{ payload: ContactsResponseInterface }>()
);

export const getUserContactsFailure = createAction(
  contactsTypes.GET_USER_CONTACTS_FAILURE,
  props<{ payload: ErrorType }>()
);

// Misc
export const setPhoneBookMisc = createAction(
  contactsTypes.SET_PHONE_BOOK_MISC_CONFIG,
  props<{
    payload: {
      headerTitle: string;
      URL?: string;
    };
  }>()
);

export const setActiveContact = createAction(
  contactsTypes.SET_ACTIVE_CONTACT,
  props<{
    payload: ContactItem;
  }>()
);

export const resetActiveContact = createAction(contactsTypes.RESET_ACTIVE_CONTACT);
