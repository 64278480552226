import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf, tap } from 'rxjs';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { KycService } from './kyc.service';
import {
  createUserKycStart,
  createUserKycSuccess,
  createUserKycFailure,
  getUserKycStart,
  getUserKycSuccess,
  getUserKycFailure,
  getCountryUserIdDocsStart,
  getCountryUserIdDocsSuccess,
  getCountryUserIdDocsFailure,
} from './kyc.actions';
import { Store } from '@ngrx/store';
import { BeyToastService } from 'src/app/modules/shared/services/bey-toast.service';
import { toggleHeadlessStepperStep } from '../misc/misc.actions';
import { event } from '@fullstory/browser';

@Injectable()
export class KycEffects {
  constructor(
    private actions$: Actions,
    private personalProfileService: KycService,
    private store$: Store,
    private toast: BeyToastService
  ) {}

  loadUserDetails$$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUserKycStart),
      mergeMap(() =>
        this.personalProfileService.getPersonalDetails().pipe(
          map((data) => getUserKycSuccess({ payload: data })),
          catchError(() => observableOf(getUserKycFailure()))
        )
      )
    );
  });

  createPersonalProfileEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createUserKycStart),
      switchMap(({ payload, extras }) =>
        this.personalProfileService.savePersonalData(payload).pipe(
          mergeMap((profileInfo) => {
            let actions = [];
            if (extras.unverifiedBusiness) {
              // increase stepper to the last step
              actions.push(toggleHeadlessStepperStep({ currentStep: null }));
            }

            actions.push(
              createUserKycSuccess({
                payload: profileInfo,
                extras: {
                  isResubmit: extras.isResubmit,
                },
              })
            );

            return actions;
          }),
          catchError((error) => {
            this.toast.open();
            return observableOf(createUserKycFailure(error));
          })
        )
      )
    );
  });

  successfulKycCreationEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createUserKycSuccess),
        tap(({ payload, extras }) => {
          const {
            first_name,
            last_name,
            id,
            user: { email, phone },
            created,
          } = payload;
          const evtName = extras.isResubmit ? 'KYC_INFO_UPDATED' : 'KYC_CREATED';

          // send an FS sign up KYC submit event here
          event(evtName, {
            fullName: `${first_name} ${last_name}`,
            id,
            email,
            phone,
            created_at: created,
          });
        })
      ),
    { dispatch: false }
  );

  getCountryUserIdDocsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCountryUserIdDocsStart),
      switchMap(({ country }) =>
        this.personalProfileService.getIdentificationDocs(country).pipe(
          map(({ results }) => getCountryUserIdDocsSuccess({ payload: results })),
          catchError((e) => {
            console.error(e);
            return observableOf(getCountryUserIdDocsFailure());
          })
        )
      )
    )
  );
}
