import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, of, Subscription } from 'rxjs';
import { getRatesStart } from 'src/app/ngrx/misc/misc.actions';
import { selectFXRate } from 'src/app/ngrx/misc/misc.selectors';
import { selectUserDetails } from 'src/app/ngrx/user/user.selectors';
import { BeyCountrySelectorOptionInterface } from 'src/assets/interfaces/bey-country-selector.interface';

@Pipe({
  name: 'getExchangedAmount',
  standalone: true,
})
export class GetExchangedAmountPipe implements PipeTransform {
  isLoggedIn: boolean = false;

  subs$: Subscription = new Subscription();

  constructor(private store$: Store) {}

  transform(
    amount: string,
    fromCountry: { isoCode: string; currency: string },
    toCountryCurrency: string,
    ...args: unknown[]
  ): Observable<number> {
    this.subs$.add(
      this.store$.select(selectUserDetails).subscribe((user) => {
        if (user) {
          this.isLoggedIn = true;
        }
      })
    );

    if (fromCountry && toCountryCurrency) {
      const data = {
        currentUserCountry: fromCountry,
        toCountryCurrency,
      };

      this.store$.dispatch(
        getRatesStart({
          payload: {
            ...data,
            useOpenEndpoint: !this.isLoggedIn,
          },
        })
      );
    }

    return this.store$.select(selectFXRate).pipe(
      map((rate) => {
        if (rate) {
          return Math.ceil(Number(amount) / rate);
        }
        return 0;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
