import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { selectUserBusinessDetails } from '../../../../ngrx/user/user.selectors';
import { Store } from '@ngrx/store';
import {
  CreateCollectionLinkResponse,
  CreateCollectionRequestBody,
} from '../../../../ngrx/collections/collections.interfaces';
import { Subscription } from 'rxjs';
import { UserBusinessDetailsResponse } from '../../../../ngrx/user/user.interfaces';
import { beyLinkSharableTextContent, buildCollectionLink } from '../../../../../assets/utility';
import { toggleDrawer } from '../../../../ngrx/misc/misc.actions';
import { RENDER_DRAWER_CONTENT_DIRECTIVE_CONTENTS_NAMES_LIST } from 'src/assets/const';
import { TransactionsUtilityService } from 'src/app/modules/shared/services/transactions-utility.service';
import { ApplyRoundingRulesPipe } from '../../../shared/pipes/apply-rounding-rules.pipe';
import { FormatPhoneNumberPipe } from '../../../shared/pipes/phone-parse.pipe';
import { CalculateFeePipe } from '../../../shared/pipes/calculate-fee.pipe';
import { NameInitialsPipe } from '../../../shared/pipes/name-initials.pipe';
import { CurrencyPipe } from '@angular/common';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { BeyHeaderWithInitialsComponent } from '../../../shared/components/bey-header-with-initials/bey-header-with-initials.component';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  standalone: true,
  imports: [
    BeyHeaderWithInitialsComponent,
    BeyButtonComponent,
    CurrencyPipe,
    NameInitialsPipe,
    CalculateFeePipe,
    FormatPhoneNumberPipe,
    ApplyRoundingRulesPipe,
  ],
})
export class ActionsPage implements OnInit, OnDestroy {
  paymentInfo: CreateCollectionLinkResponse;
  businessInfo: UserBusinessDetailsResponse;
  infoSub: Subscription;
  currencyPipeDigitalInfo: string;
  uniqueLink: string;
  paymentInfoForCalculatingFee: CreateCollectionRequestBody = {
    amount: 0,
    business_id: 0,
    currency: '',
    description: '',
    first_name: '',
    last_name: '',
    phone: '',
    type: undefined,
  };
  beyLinkSharableTextContentProps: {
    first_name: string;
    link: string;
    amount: string;
    currency: string;
    desc: string;
    active_merchant_name: string;
  };

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private transactionsUtilityService: TransactionsUtilityService
  ) {
    this.infoSub = store.select(selectUserBusinessDetails).subscribe((info) => (this.businessInfo = info));
    this.currencyPipeDigitalInfo = transactionsUtilityService.retrieveCurrencyPipeDigitalInfo(
      this.businessInfo.country
    );
  }

  ngOnInit(): void {
    this.paymentInfo = this.route.snapshot.data?.['linkData'][0];
    const {
      contact: { phone, first_name, last_name, business_id },
      amount,
      description,
      amount_currency,
    } = this.paymentInfo;

    this.paymentInfoForCalculatingFee = {
      phone,
      first_name,
      last_name,
      business_id,
      amount: parseInt(amount),
      description,
      currency: amount_currency,
      type: 'collection-link',
    };

    this.uniqueLink = buildCollectionLink(this.businessInfo.short_name, this.paymentInfo.link_id);

    this.beyLinkSharableTextContentProps = {
      first_name,
      link: this.uniqueLink,
      amount,
      currency: amount_currency,
      desc: description,
      active_merchant_name: this.businessInfo.active_merchant_name,
    };
  }

  get messageContent() {
    return beyLinkSharableTextContent('text', this.beyLinkSharableTextContentProps);
  }

  get twitterContent() {
    return beyLinkSharableTextContent('twitter', this.beyLinkSharableTextContentProps);
  }

  toggleShare() {
    this.store.dispatch(
      toggleDrawer({
        component: RENDER_DRAWER_CONTENT_DIRECTIVE_CONTENTS_NAMES_LIST.SHARE,
        data: {
          userLink: this.uniqueLink,
          messageContent: this.messageContent,
          whatsappContent: this.messageContent,
          twitterContent: this.twitterContent,
          smsContent: this.messageContent,
          phone: this.paymentInfo.contact.phone,
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.infoSub?.unsubscribe();
  }
}
