<div
  class="text-center lg:text-left lg:text-2xl lg:leading-[41px] leading-[19px] font-medium mt-4 mb-5 lg:flex lg:justify-between lg:items-center">
  <span class="lg:ml-6">Send to</span>
  <bey-close-icon (click)="miscService.closeDrawer()" class="hidden lg:block"></bey-close-icon>
</div>

<div class="w-[350px] mx-auto space-y-7 lg:space-y-12 lg:w-auto">
  <div
    class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-fr"
    style="grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))">
    <div class="text-center" *ngIf="whatsAppLink">
      <a [href]="whatsAppLink" target="_blank">
        <img src="/assets/images/whatsApp.png" alt="WhatsApp" class="mx-auto w-10 lg:w-12" />
        <span class="text-sm leading-[16px] lg:mt-2 block mt-2">WhatsApp</span>
      </a>
    </div>

    <div class="text-center">
      <a (click)="handleFb()" preventDefaultEvent>
        <img src="/assets/images/fb.png" alt="FB" class="mx-auto w-10 lg:w-12" />
        <span class="text-sm leading-[16px] lg:mt-2 block mt-2">Facebook</span>
      </a>
    </div>

    <div class="text-center" *ngIf="mailLink">
      <a [href]="mailLink">
        <img src="/assets/images/g.png" alt="Gmail" class="mx-auto w-10 lg:w-12" />
        <span class="text-sm leading-[16px] lg:mt-2 block mt-2">Email</span>
      </a>
    </div>

    <div class="text-center" *ngIf="snippet">
      <a href="#" (click)="onEmbedButtonClick($event)">
        <img
          src="/assets/images/icons/html-tag-icon.png"
          class="mx-auto w-10 h-10 lg:h-12 lg:w-12 bg-blue-600 rounded-full p-2"
          alt="Embed" />
        <span class="text-sm leading-[16px] lg:mt-2 block mt-2">Embed</span>
      </a>
    </div>

    <div class="text-center" *ngIf="twitterContent">
      <a (click)="twitterHandler()" preventDefaultEvent>
        <img src="/assets/images/tw.png" alt="Twitter" class="mx-auto w-10 lg:w-12" />
        <span class="text-sm leading-[16px] lg:mt-2 block mt-2">Twitter</span>
      </a>
    </div>

    <div class="text-center">
      <a href="#" (click)="copyLink($event)">
        <img src="/assets/images/icons/link.png" class="mx-auto w-10 lg:w-12 bg-blue-600 rounded-full" alt="link" />
        <span class="text-sm leading-[16px] lg:mt-2 block mt-2">Copy link</span>
      </a>
    </div>

    <div class="text-center" *ngIf="ussd">
      <a href="#" (click)="copyUSSD($event)">
        <img
          src="/assets/images/icons/hashtag-icon.png"
          class="mx-auto w-10 lg:w-12 bg-blue-600 rounded-full p-2"
          alt="USSD" />
        <span class="text-sm leading-[16px] lg:mt-2 block mt-2">Copy USSD</span>
      </a>
    </div>

    <div class="text-center" *ngIf="smsContent">
      <a [href]="sms">
        <img
          src="/assets/images/icons/message-icon.png"
          alt="SMS"
          class="mx-auto w-10 lg:w-12 bg-blue-600 rounded-full p-2" />
        <span class="text-sm leading-[16px] lg:mt-2 block mt-2">SMS</span>
      </a>
    </div>
  </div>
</div>

<div
  class="hidden lg:flex mt-12 bg-gray-50 border rounded-md border-gray-200 py-2 px-4 text-blue-400 flex justify-between break-all">
  <a href="#" class="px-2" (click)="copyLink($event)">{{ userLink }}</a>
  <button (click)="copyLink($event)">
    <svg width="20" height="20" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.8346 9.91602V9.66602C19.8346 7.7804 19.8346 6.83759 19.2488 6.2518C18.6631 5.66602 17.7203 5.66602 15.8346 5.66602H9.66797C7.78235 5.66602 6.83954 5.66602 6.25376 6.2518C5.66797 6.83759 5.66797 7.7804 5.66797 9.66602V15.8327C5.66797 17.7183 5.66797 18.6611 6.25376 19.2469C6.83954 19.8327 7.78235 19.8327 9.66797 19.8327H9.91797"
        stroke="#006FAB"
        stroke-width="3" />
      <rect x="14.168" y="14.166" width="14.1667" height="14.1667" rx="2" stroke="#006FAB" stroke-width="3" />
    </svg>
  </button>
</div>

<div class="w-full px-7 my-7 lg:mb-0 lg:px-4 lg:pt-4">
  <bey-snippet-code *ngIf="!!snippet" class="w-full" [snippet]="snippet">
    <bey-button append (onClick)="onEmbedButtonClick($event)">
      <span placeholder class="whitespace-nowrap">Embed button</span>
    </bey-button>
  </bey-snippet-code>
</div>
