import { createAction, props } from '@ngrx/store';
import {
  CreateBeyonicLinkRequestBody,
  ErrorType,
  GetUserBeyonicLinksResponse,
  ProcessBeyonicLinkRequestBody,
  ProcessBeyonicLinkResponse,
  BeyonicLink,
  UpdateBeyonicLinkRequestBody,
} from './beyonic-link.interfaces';
import { collectionsState } from '../collections/collections.interfaces';
import { TYPES } from './beyonic-link.types';

//------------CREATE BEYONIC LINK------------
export const createBeyonicLinkStart = createAction(
  TYPES.CREATE_BEYONIC_LINK_START,
  props<{ payload: CreateBeyonicLinkRequestBody }>()
);
export const createBeyonicLinkSuccess = createAction(TYPES.CREATE_BEYONIC_LINK_SUCCESS, props<BeyonicLink>());
export const createBeyonicLinkFailure = createAction(TYPES.CREATE_BEYONIC_LINK_FAILURE, props<ErrorType>());
//------------UPDATE BEYONIC LINK------------
export const updateBeyonicLinkStart = createAction(
  TYPES.UPDATE_BEYONIC_LINK_START,
  props<{ payload: { data: UpdateBeyonicLinkRequestBody; type: 'full' | 'state' | 'photo' } }>()
);
export const updateBeyonicLinkSuccess = createAction(
  TYPES.UPDATE_BEYONIC_LINK_SUCCESS,
  props<{ payload: { data: BeyonicLink; type: 'full' | 'state' } }>()
);
export const updateBeyonicLinkFailure = createAction(TYPES.UPDATE_BEYONIC_LINK_FAILURE, props<ErrorType>());
//------------GET USER BEYONIC LINKS------------
export const getUserBeyonicLinksStart = createAction(
  TYPES.GET_USER_BEYONIC_LINKS_START,
  props<{ payload: { businessId: number; filters: object } }>()
);
export const getUserBeyonicLinksSuccess = createAction(
  TYPES.GET_USER_BEYONIC_LINKS_SUCCESS,
  props<{ payload: GetUserBeyonicLinksResponse }>()
);
export const getUserBeyonicLinksFailure = createAction(TYPES.GET_USER_BEYONIC_LINKS_FAILURE, props<ErrorType>());
//------------GET MORE USER BEYONIC LINKS------------
export const getMoreUserBeyonicLinksStart = createAction(
  TYPES.GET_MORE_USER_BEYONIC_LINKS_START,
  props<{ payload: { url: string } }>()
);
export const getMoreUserBeyonicLinksSuccess = createAction(
  TYPES.GET_MORE_USER_BEYONIC_LINKS_SUCCESS,
  props<{ payload: GetUserBeyonicLinksResponse }>()
);
export const getMoreUserBeyonicLinksFailure = createAction(
  TYPES.GET_MORE_USER_BEYONIC_LINKS_FAILURE,
  props<ErrorType>()
);
//------------GET BEYONIC LINK COLLECTIONS------------
export const getBeyonicLinkCollectionsStart = createAction(
  TYPES.GET_BEYONIC_LINK_COLLECTIONS_START,
  props<{ payload: { linkId: number; businessId: number } }>()
);
export const getBeyonicLinkCollectionsSuccess = createAction(
  TYPES.GET_BEYONIC_LINK_COLLECTIONS_SUCCESS,
  props<{ payload: collectionsState['userCollections'] }>()
);
export const getBeyonicLinkCollectionsFailure = createAction(
  TYPES.GET_BEYONIC_LINK_COLLECTIONS_FAILURE,
  props<ErrorType>()
);
//------------GET MORE BEYONIC LINK COLLECTIONS------------
export const getMoreBeyonicLinkCollectionsStart = createAction(
  TYPES.GET_MORE_BEYONIC_LINK_COLLECTIONS_START,
  props<{ payload: { url: string } }>()
);
export const getMoreBeyonicLinkCollectionsSuccess = createAction(
  TYPES.GET_MORE_BEYONIC_LINK_COLLECTIONS_SUCCESS,
  props<{ payload: collectionsState['userCollections'] }>()
);
export const getMoreBeyonicLinkCollectionsFailure = createAction(
  TYPES.GET_MORE_BEYONIC_LINK_COLLECTIONS_FAILURE,
  props<ErrorType>()
);
//------------PROCESS Beyonic Link------------
export const processBeyonicLinkStart = createAction(
  TYPES.PROCESS_BEYONIC_LINK_START,
  props<{ link_id: string; body: ProcessBeyonicLinkRequestBody; short_name: string }>()
);

export const processBeyonicLinkSuccessful = createAction(
  TYPES.PROCESS_BEYONIC_LINK_SUCCESS,
  props<{ body: ProcessBeyonicLinkResponse; short_name: string; link_id: string }>()
);
export const processBeyonicLinkFailure = createAction(TYPES.PROCESS_BEYONIC_LINK_FAILURE);
//------------OTHER------------
export const resetNewBeyonicLink = createAction(
  TYPES.RESET_NEW_BEYONIC_LINK,
  props<{ payload: { beyonicLinkId: number } }>()
);

export const setSelectedBeyonicLink = createAction(
  TYPES.SET_SELECTED_BEYONIC_LINK,
  props<{ payload: { beyonicLink: BeyonicLink } }>()
);
