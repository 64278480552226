import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DocGenRequestBody, getRatesRequestInterface, SnackbarDetailsInterface } from './misc.interface';
import { Store } from '@ngrx/store';
import { toggleDrawer } from './misc.actions';
import { from, lastValueFrom, Subscription } from 'rxjs';
import { selectDrawerIsOpen } from './misc.selectors';
import { TransactionLimits, TransactionReceiptResponse } from '../wallets/wallets.interfaces';
import { copyToClipboard, isMobile } from 'src/assets/utility';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { HIGHLIGHTED_FEATURES } from 'src/assets/const';
import dayjs from 'dayjs';
import { HighlightedFeatureDialogContentComponent } from 'src/app/modules/shared/components/highlighted-feature-dialog-content/highlighted-feature-dialog-content.component';
import { SavedUser, User } from '../user/user.interfaces';
import { selectUserDetails } from '../user/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class MiscService implements OnDestroy {
  currentUser: User;
  subs$: Subscription = new Subscription();

  constructor(private http: HttpClient, private store$: Store, private dialog: MatLegacyDialog) {
    this.subs$.add(store$?.select(selectUserDetails).subscribe((user) => (this.currentUser = user)));
  }

  generateMedia(body: DocGenRequestBody, type: 'png' | 'pdf') {
    return this.http.post(`${environment.API_URL}web-print/generate/${type}`, body, { responseType: 'blob' });
  }

  getRates(payload: getRatesRequestInterface) {
    const { fromCurrencyIso3, toCurrencyIso3, toCountryIso2, useOpenEndpoint = false } = payload;
    const url = `${environment.API_URL}${useOpenEndpoint ? `collection_fx_rate_open` : 'collection_fx_rate'}/`;
    let params = new HttpParams();
    const _p = {
      from_currency_iso3: fromCurrencyIso3,
      to_currency_iso3: toCurrencyIso3,
      to_country_iso2: toCountryIso2,
    };

    Object.keys(_p).forEach((key) => {
      const val = _p[key];
      val && (params = params.append(key, val));
    });

    return this.http.get(url, { params });
  }

  closeDrawer() {
    /// only close the drawer in case it's open
    this.store$
      .select(selectDrawerIsOpen)
      .subscribe((isOpen) => {
        if (isOpen) {
          this.store$.dispatch(toggleDrawer({ data: null, component: null }));
        }
      })
      .unsubscribe();
  }

  checkUserWalletLimitsAlerts(limits: TransactionLimits): SnackbarDetailsInterface {
    const {
      exceeded_daily_transaction_limit,
      exceeded_wallet_limit,
      exceeded_warning_daily_transaction_limit_reached,
      exceeded_warning_wallet_limit_reached,
    } = limits;

    let walletLimitSnackbarContent = { content: 'Withdraw funds to continue receiving payments' };

    let dailyTransactionLimitSnackbarContent = {
      content: 'Daily limits reset at midnight. Get verified to continue receiving collections.',
    };

    if (exceeded_wallet_limit) {
      return { ...walletLimitSnackbarContent, title: 'Limit exceeded.', color: 'error', type: 'exceeded_wallet_limit' };
    } else if (exceeded_daily_transaction_limit) {
      return {
        ...dailyTransactionLimitSnackbarContent,
        title: 'Limit exceeded.',
        color: 'error',
        type: 'exceeded_daily_transaction_limit',
      };
    } else if (exceeded_warning_wallet_limit_reached) {
      return {
        ...walletLimitSnackbarContent,
        title: 'Approaching limit.',
        color: 'warning',
        type: 'exceeded_warning_wallet_limit_reached',
      };
    } else if (exceeded_warning_daily_transaction_limit_reached) {
      return {
        ...dailyTransactionLimitSnackbarContent,
        title: 'Approaching limit.',
        color: 'warning',
        type: 'exceeded_warning_daily_transaction_limit_reached',
      };
    } else {
      return null;
    }
  }

  copyText(text: string) {
    return from(copyToClipboard(text));
  }

  fetchReceiptInfo(
    transId: string | number,
    businessId: number,
    receiptType: 'merchant' | 'client',
    transactionType: 'collections' | 'payments'
  ) {
    const paths = {
      client: {
        collections: `collections/public/payment-requests/${transId}/customer-receipt/`,
        payments: `payments/public/payments/${transId}/customer-receipt/`,
      },
      merchant: {
        collections: `collections/business/${businessId}/payment-requests/${transId}/merchant-receipt/`,
        payments: `payments/business/${businessId}/payments/${transId}/merchant-receipt/`,
      },
    };

    const url = `${environment.API_URL}${paths[receiptType][transactionType]}`;
    return this.http.get<TransactionReceiptResponse>(url);
  }

  generateReceipt(payload) {
    return this.http.post(`/api/receipt/`, payload, { responseType: 'blob' });
  }

  /**
   * Checks if user has seen recent highlighted feature then display if necessary
   */
  async displayHighlightedFeatures(route: string, savedUser: SavedUser): Promise<void> {
    const features = HIGHLIGHTED_FEATURES.filter((feature) => {
      // check if feature should be shown on login for new users, otherwise check if route matches
      if (!(feature.showOnLoginForNewUsers && !savedUser.samePreviousSavedUser) && !route.includes(feature.route)) {
        return false;
      }
      // check if user has already seen this feature
      if (localStorage.getItem(feature.key) === 'seen') {
        return false;
      }
      // check if feature has expired
      if (dayjs(new Date()).isAfter(feature.expiry_date)) {
        return false;
      }
      // check if feature should be shown on mobile
      if (!feature.showOnMobile && isMobile()) {
        return false;
      }
      return true;
    });

    for (const feature of features) {
      const dialogRef = this.dialog.open(HighlightedFeatureDialogContentComponent, {
        width: '80vw',
        // height: '95vh',
        maxWidth: '780px',
        maxHeight: '675px',
        panelClass: 'remove-inner-padding',
        disableClose: true,
        data: {
          key: feature.key,
        },
      });
      await lastValueFrom(dialogRef.afterClosed());
    }
  }

  /******
   * Detects if the user is a member of a business or not
   * if no user was provided then it checks for current user
   */
  isMember(passedUser?: User): boolean {
    let user = passedUser || this.currentUser;
    if (user) {
      let { business_id, businesses } = user;
      return user && !business_id && !!businesses.length;
    }

    return false;
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
