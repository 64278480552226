import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { TransactionsUtilityService } from 'src/app/modules/shared/services/transactions-utility.service';
import { CollectionDialog } from 'src/app/ngrx/collections/collections.interfaces';
import { CROSS_BORDER_OPERATING_COUNTRIES } from 'src/assets/const';
import { ApplyRoundingRulesPipe } from '../../../shared/pipes/apply-rounding-rules.pipe';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { NgIf, TitleCasePipe, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-post-create-collection-dialog-content',
  templateUrl: './post-create-collection-dialog-content.component.html',
  styleUrls: ['./post-create-collection-dialog-content.component.scss'],
  standalone: true,
  imports: [NgIf, BeyButtonComponent, MatLegacyDialogModule, TitleCasePipe, CurrencyPipe, ApplyRoundingRulesPipe],
})
export class PostCreateCollectionDialogContentComponent implements OnInit {
  currency: string;
  country: string;
  currencyPipeDigitalInfo: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { payload: CollectionDialog; success: boolean; error?: Error },
    private transactionsUtilityService: TransactionsUtilityService
  ) {
    this.currency = data.payload?.displayCurrency || data.payload?.currency;
    this.country = CROSS_BORDER_OPERATING_COUNTRIES.find((country) => country.currency === this.currency).label;

    this.currencyPipeDigitalInfo = this.transactionsUtilityService.retrieveCurrencyPipeDigitalInfo(this.country);
  }

  ngOnInit(): void {}
}
