<bey-header-with-content [noBackground]="true">
  <div class="flex flex-col lg:flex-row p-8">
    <img
      src="assets/images/icons/circle-checkmark.svg"
      class="mb-[15px] w-[37px] h-[37px] lg:w-[74px] lg:h-[74px] lg:mr-[42px]"
      alt="check mark" />
    <div class="flex flex-col">
      <span class="text-white text-lg mb-1.5 lg:text-[20px] lg:mb-3"
        >Hi {{ transactionInfo?.contact?.first_name }},</span
      >
      <span class="text-white text-lg">
        <span *ngIf="['sl', 'bl', 'ul', 'collections', 'c'].includes(this.transactionType); else payments">
          Payment received, <br class="lg:hidden" />thank you.
        </span>
      </span>
      <ng-template #payments>
        <span>You have received a payment from {{ transactionInfo?.business_name }}</span>
      </ng-template>
    </div>
  </div>
</bey-header-with-content>

<div class="w-full lg:max-w-2xl lg:mx-auto">
  <div class="p-6 mb-3 lg:mb-8 bg-white rounded-lg">
    <div class="mx-auto max-w-lg">
      <span class="text-gray-800 font-medium text-base py-4 block lg:text-lg">{{
        transactionInfo?.business_name
      }}</span>
      <span class="text-gray-400 font-bold text-sm pb-4 block lg:text-base">Order details</span>
      <div class="grid grid-cols-2 gap-y-2">
        <div class="font-semibold">Date of purchase</div>
        <div *ngIf="transactionInfo?.formatted_payment_date; else creationDate">
          {{ transactionInfo?.formatted_payment_date }}
        </div>
        <ng-template #creationDate>
          <div>{{ transactionInfo?.created | dateFormat : 'DD/MM/YYYY, HH:hh:mm' }}</div>
        </ng-template>
        <div class="font-semibold">Amount paid</div>
        <div>
          {{
            transactionInfo?.amount
              | applyRoundingRules : country : 'collection' : false
              | currency : transactionInfo?.amount_currency + ' ' : 'symbol' : currencyPipeDigitalInfo
          }}
        </div>
        <div class="font-semibold">Description</div>
        <div>{{ transactionInfo?.beyonic_link?.description || transactionInfo?.description }}</div>

        <div *ngIf="beyonicLinkNotes" class="col-span-2 mt-1">
          <div class="font-semibold">Additional notes</div>
          <div>{{ beyonicLinkNotes }}</div>
        </div>
      </div>

      <bey-button
        class="mt-20 block"
        classList="mx-auto"
        [isLoading]="receiptIsLoading$ | async"
        (onClick)="download()">
        <span placeholder class="font-medium">Download receipt</span>
      </bey-button>
    </div>
  </div>

  <div *ngIf="!(userDetails$ | async)">
    <bey-sign-up-callout
      header="Is sending and receiving payments a hassle?"
      body="Beyonic makes it so much easier."
      linkText="Start your free trial"
      (trialStart)="handleSignUp()"></bey-sign-up-callout>
  </div>
</div>
