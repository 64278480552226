import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bey-numbered-step-circle',
  templateUrl: './bey-numbered-step-circle.component.html',
  styleUrls: ['./bey-numbered-step-circle.component.scss'],
  standalone: true,
})
export class BeyNumberedStepCircleComponent implements OnInit {
  /***
   * The step's number
   */
  @Input()
  stepNumber: number;
  constructor() {}

  ngOnInit(): void {}
}
