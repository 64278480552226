<div class="flex justify-between max-w-5xl px-3 pt-4 lg:pl-10">
  <div class="flex flex-col w-full max-w-lg mx-auto lg:mx-0">
    <bey-page-header class="block mb-6 lg:mt-20"></bey-page-header>

    <div class="w-full mb-6 rounded-[10px] bg-white">
      <bey-create-collection-form
        collectionType="unique"
        [isCollectionLink]="true"
        (limitConfirm)="showConfirmDialog($event)"></bey-create-collection-form>

      <div class="xl:hidden px-3.5 pb-6">
        <div class="mb-2 mt-12x text-gray-800 font-medium text-lg">How do Single use links work?</div>
        <div class="text-gray-600 text-justify">
          After creating the link, share it with the receiver. When they click the link, a request will be sent to their
          mobile number to authorize and complete this transaction. The receiver of the request must have sufficient
          funds in their mobile money to successfully complete this transaction.
        </div>
      </div>
    </div>
  </div>

  <bey-feature-info-card
    class="lg:mt-20 block"
    header="How do Single use links work?"
    body="After creating the link, share it with the receiver. When they click the link, a request will be sent to their mobile number to authorize and complete this transaction. The receiver of the request must have sufficient funds in their mobile money to successfully complete this transaction.">
  </bey-feature-info-card>
</div>
