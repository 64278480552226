import { Injectable } from '@angular/core';
import {
  Router,
  CanActivateChild,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Tokens } from '../../../ngrx/auth/auth.interfaces';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectTokens } from '../../../ngrx/auth/auth.selectors';
import { selectIsSuspended } from 'src/app/ngrx/user/user.selectors';
import { suspendUser } from 'src/app/ngrx/user/user.actions';

@Injectable({
  providedIn: 'any',
})
export class UserAccountStateGuard implements CanActivateChild, CanActivate {
  tokens$: Observable<Tokens>;
  isSuspended$: Observable<boolean>;

  subs$: Subscription = new Subscription();

  constructor(private router: Router, private store: Store) {
    this.tokens$ = store.select(selectTokens);
    this.isSuspended$ = store.select(selectIsSuspended);
  }

  verifyLoginStatus(): boolean {
    let hasToken: boolean, isSuspended: boolean;

    this.subs$.add(this.tokens$.subscribe((tokens) => (hasToken = !!tokens?.access)));
    this.subs$.add(this.isSuspended$.subscribe((suspended: boolean) => (isSuspended = suspended)));

    if (isSuspended) {
      this.store.dispatch(suspendUser());

      return false;
    } else if (!hasToken) {
      this.router.navigate(['login']);

      return false;
    }

    return true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.verifyLoginStatus();
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.verifyLoginStatus();
  }
}
