import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, Router } from '@angular/router';
import { Subject, takeUntil, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterService implements OnDestroy {
  private _routeData$: Subject<Data> = new Subject<Data>();
  readonly routeData$ = this._routeData$.asObservable();
  private destroy$ = new Subject<void>();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        map(() => {
          let route = this.activatedRoute.snapshot;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this._routeData$.next(route.data);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
