/*****
 * UTIL functions shared between server and client apps
 * Don't include functions with external dependencies
 */
import { AMOUNT_ROUNDING_RULES, BEYONIC_COUNTRIES } from '../const';
import { BeyonicCountry } from '../interfaces';

/****
 * Apply rounding rules on values based on the central bank specifications of the country
 * @param value
 * @param country
 * @param type
 * @param isXB
 */
export const roundAmountByCountry = (
  value: number | string,
  country: string,
  type: 'collection' | 'payment' | 'fee',
  isXB: boolean = false
) => {
  const roundingRule = AMOUNT_ROUNDING_RULES[country]?.[type]?.[isXB];
  value = Number(value);

  switch (roundingRule) {
    case 'up':
      if (value % 1 !== 0) {
        // this means the number has decimals and needs to be rounded up
        value = Math.trunc(value) + 1;
      }
      break;

    case 'down':
      value = Math.floor(value);
      break;

    default:
      value = 0;
      break;
  }

  return value;
};

/*****
 *  A function to retrieve a country from the operating countries either using its title, iso code, country code, and currency
 */
export const retrieveOperatingCountry = (
  value: string,
  fetchType: 'currency' | 'isoCode' | 'name' | 'countryCode'
): BeyonicCountry => {
  return BEYONIC_COUNTRIES.find((country) => value === country[fetchType]);
};
