<div class="flex flex-col lg:flex-row lg:h-screen h-full">
  <header
    root-header
    class="relative h-[55px] z-50"
    [ngClass]="{
      '!h-[165px]':
        (pendingKyc$ | async) && (targetedRoutesToShowPendingKycBanner$ | async).includes(currentRoute$ | async)
    }"></header>
  <aside root-sidebar [ngClass]="{ hidden: footerHidden }"></aside>
  <section
    class="overflow-x-hidden lg:grow content h-full relative"
    id="content"
    [ngClass]="{ 'footer-hidden': footerHidden }">
    <pending-kyc-banner
      class="hidden absolute top-[30px] left-[50%] translate-x-[-50%] z-50 lg:block"></pending-kyc-banner>
    <div [@triggerName]="animation$ | async" class="h-full md:h-auto">
      <div class="bey-transfer-info-sidebar-main flex w-full">
        <div class="bey-transfer-info-sidebar-content z-20">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>

        <div
          bey-animated-sidebar-drawer
          class="bey-transfer-info-sidebar hidden lg:flex"
          [ngClass]="{
            open: (sidebarIsOpen$ | async),
            closed: !(sidebarIsOpen$ | async),
            over: (sidebarIsOver$ | async)
          }">
          <ng-template [cdkPortalOutlet]="selectedPortal$ | async"></ng-template>
        </div>
      </div>
    </div>
  </section>

  <footer root-footer [ngClass]="{ hidden: footerHidden }" style="height: 90px"></footer>

  <bey-snackbar
    [ngClass]="(snackbarIsShown$ | async) ? 'opened' : 'closed'"
    class="snackbar fixed translate-x-[-50%] left-[50%]"></bey-snackbar>

  <bey-drawer>
    <ng-container #content>
      <div renderDrawerContent></div>
    </ng-container>
  </bey-drawer>
</div>
