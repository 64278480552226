import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { BeyToastService } from '../services/bey-toast.service';
import { catchError, tap } from 'rxjs/operators';
import { BeyonicLinkService } from '../../../ngrx/beyonic-link/beyonic-link.service';
import { select, Store } from '@ngrx/store';
import { BeyonicLink } from '../../../ngrx/beyonic-link/beyonic-link.interfaces';
import { selectUserBeyonicLinks } from '../../../ngrx/beyonic-link/beyonic-link.selectors';
import { setSelectedBeyonicLink } from 'src/app/ngrx/beyonic-link/beyonic-link.actions';
import { selectUserDetails } from '../../../ngrx/user/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class BeyonicLinkResolver implements Resolve<any> {
  userBeyonicLinks$: Observable<Array<BeyonicLink>>;

  constructor(
    private beyonicLinkService: BeyonicLinkService,
    private router: Router,
    private toast: BeyToastService,
    private store: Store
  ) {
    this.userBeyonicLinks$ = store.select(selectUserBeyonicLinks);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const linkId = route.params?.['id'] || route.params?.['link_id'];

    return this.store.pipe(
      select(selectUserDetails),
      switchMap((user) => {
        const {
          businesses: [business],
        } = user;

        return this.beyonicLinkService.getBeyonicLinkInfo(linkId, business.id).pipe(
          tap((link) => {
            this.store.dispatch(setSelectedBeyonicLink({ payload: { beyonicLink: link } }));
          }),
          catchError((err) => {
            console.error(err);
            this.toast.open(err.error.detail, 'error');
            this.router.navigate(['/error']);

            return EMPTY;
          })
        );
      })
    );
  }
}
