import { Component, Input, OnInit } from '@angular/core';
import { NgStyle } from '@angular/common';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [MatLegacyProgressSpinnerModule, NgStyle],
})
export class SpinnerComponent implements OnInit {
  /**
   * Center align the Spinner
   */
  @Input() center: boolean = false;

  /**
   * Diameter of the Spinner circle
   */
  @Input() diameter: number = 35;
  constructor() {}

  ngOnInit(): void {}
}
