export const MISC_TYPES = {
  TOGGLE_ANIMATED_SIDEBAR: 'TOGGLE_ANIMATED_SIDEBAR',
  TOGGLE_SLIDING_SIDEBAR: 'TOGGLE_SLIDING_SIDEBAR', // this is positioned on top of the content
  TOGGLE_DRAWER: 'TOGGLE_DRAWER',

  // MISC
  PRINT_DOC_START: 'PRINT_DOC_START',
  PRINT_DOC_SUCCESS: 'PRINT_DOC_SUCCESS',
  PRINT_DOC_FAIL: 'PRINT_DOC_FAIL',

  GET_RATES_START: 'GET_RATES_START',
  GET_RATES_SUCCESS: 'GET_RATES_SUCCESS',
  GET_RATES_FAILURE: 'GET_RATES_FAILURE',

  // Registration Stepper
  SET_REGISTRATION_STEPPER_STEP: 'SET_REGISTRATION_STEPPER_STEP',

  // snackbar
  OPEN_SNACKBAR: 'OPEN_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR',

  COPY_TEXT: 'COPY_TEXT',

  // Fetch receipt data
  FETCH_RECEIPT_DATA_START: 'FETCH_RECEIPT_DATA_START',
  FETCH_RECEIPT_DATA_SUCCESS: 'FETCH_RECEIPT_DATA_SUCCESS',
  FETCH_RECEIPT_DATA_FAILURE: 'FETCH_RECEIPT_DATA_FAILURE',

  // Generate receipt
  GENERATE_RECEIPT_START: 'GENERATE_RECEIPT_START',
  GENERATE_RECEIPT_SUCCESS: 'GENERATE_RECEIPT_SUCCESS',
  GENERATE_RECEIPT_FAIL: 'GENERATE_RECEIPT_FAIL',

  // Flexible dialog
  CHANGE_OPENING_STATUS: 'CHANGE_OPENING_STATUS',
  CHANGE_MINIMIZATION_STATUS: 'CHANGE_MINIMIZATION_STATUS',
  CHANGE_EXPANSION_STATUS: 'CHANGE_EXPANSION_STATUS',
};
