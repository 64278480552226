export const collectionsTypes = {
  GET_USER_COLLECTIONS_START: 'GET_USER_COLLECTIONS_START',
  GET_USER_COLLECTIONS_SUCCESS: 'GET_USER_COLLECTIONS_SUCCESS',
  GET_USER_COLLECTIONS_FAILURE: 'GET_USER_COLLECTIONS_FAILURE',
  GET_MORE_USER_COLLECTIONS_START: 'GET_MORE_USER_COLLECTIONS_START',
  GET_MORE_USER_COLLECTIONS_SUCCESS: 'GET_MORE_USER_COLLECTIONS_SUCCESS',
  GET_MORE_USER_COLLECTIONS_FAILURE: 'GET_MORE_USER_COLLECTIONS_FAILURE',
  GET_COLLECTIONS_FEES_START: 'GET_COLLECTIONS_FEES_START',
  GET_COLLECTIONS_FEES_SUCCESS: 'GET_COLLECTIONS_FEES_SUCCESS',
  GET_COLLECTIONS_FEES_FAILURE: 'GET_COLLECTIONS_FEES_FAILURE',
  // Normal Collection
  CREATE_COLLECTION_START: 'CREATE_COLLECTION_START',
  CREATE_COLLECTION_SUCCESS: 'CREATE_COLLECTION_SUCCESS',
  CREATE_COLLECTION_FAILURE: 'CREATE_COLLECTION_FAILURE',
  // Collection Link
  CREATE_PARTIAL_REQUEST_START: 'CREATE_PARTIAL_REQUEST_START',
  CREATE_PARTIAL_REQUEST_SUCCESS: 'CREATE_PARTIAL_REQUEST_SUCCESS',
  CREATE_PARTIAL_REQUEST_FAILURE: 'CREATE_PARTIAL_REQUEST_FAILURE',
  GET_COLLECTION_LINK_INFO_START: 'GET_COLLECTION_LINK_INFO_START',
  GET_COLLECTION_LINK_INFO_SUCCESS: 'GET_COLLECTION_LINK_INFO_SUCCESS',
  GET_COLLECTION_LINK_INFO_FAILURE: 'GET_COLLECTION_LINK_INFO_FAILURE',
  // Update collection info
  UPDATE_COLLECTION_INFO: 'UPDATE_COLLECTION_INFO',
  RESET_COLLECTION_INFO: 'RESET_COLLECTION_INFO',
  RESET_NEW_COLLECTION: 'RESET_NEW_COLLECTION',
  // Get collection info
  GET_COLLECTION_INFO_START: 'GET_COLLECTION_INFO_START',
  GET_COLLECTION_INFO_SUCCESS: 'GET_COLLECTION_INFO_SUCCESS',
  GET_COLLECTION_INFO_FAILURE: 'GET_COLLECTION_INFO_FAILURE',
};
