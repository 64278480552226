import { createReducer, on } from '@ngrx/store';
import { WalletsState } from './wallets.interfaces';
import {
  getBillingAttemptDetailsFailure,
  getBillingAttemptDetailsStart,
  getBillingAttemptDetailsSuccess,
  getSubscriptionPriceFailure,
  getSubscriptionPriceStart,
  getSubscriptionPriceSuccess,
  getUserBalanceFailure,
  getUserBalanceStart,
  getUserBalanceSuccess,
  getUserBillingsFailure,
  getUserBillingsStart,
  getUserBillingsSuccess,
  getUserWalletFailure,
  getUserWalletStart,
  getUserWalletSuccess,
  paySubNowFailure,
  paySubNowReset,
  paySubNowStart,
  paySubNowSuccess,
  setWalletLimitsShownAlert,
} from './wallets.actions';
import { logOut } from '../auth/auth.actions';
import { clearSavedUser } from '../user/user.actions';
import { produce } from 'immer';

const initialState: WalletsState = {
  userWallet: null,
  userTransactions: null,
  userBalance: null,
  userBillings: {
    count: null,
    next: null,
    previous: null,
    results: [],
    payNow: null,
  },
  walletLimitsShownAlert: null,
  subscriptionInfo: {
    price: null,
    currency: null,
  },
  isLoading: {
    getUserWallet: false,
    getUserTransactions: false,
    getUserBalance: false,
    getUserBillings: false,
    getSubscriptionPrice: false,
  },
  errorMessages: {
    getUserWallet: '',
    getUserTransactions: '',
    getUserBalance: '',
    getUserBillings: '',
    getSubscriptionPrice: '',
  },
};

export const walletsReducer = createReducer(
  initialState,
  //----------START----------
  on(
    getUserWalletStart,
    produce((state) => {
      state.isLoading['getUserWallet'] = true;
    })
  ),
  on(
    getUserBalanceStart,
    produce((state) => {
      state.isLoading['getUserBalance'] = true;
    })
  ),
  on(
    getUserBillingsStart,
    produce((state) => {
      state.isLoading['getUserBillings'] = true;
    })
  ),
  on(
    getSubscriptionPriceStart,
    produce((state) => {
      state.isLoading['getSubscriptionPrice'] = true;
    })
  ),
  on(
    paySubNowStart,
    produce((state) => {
      state.isLoading['payNow'] = true;
    })
  ),
  on(
    getBillingAttemptDetailsStart,
    produce((state, payload) => {
      const loadingState = {
        ...state.isLoading,
      };

      // if delay is NOT present this means that the action WASN'T invoked by PAY NOW,
      // and we can add the loading state
      if (!payload.delay) {
        loadingState['billingAttemptDetails'] = true;
      }

      state.isLoading = loadingState;
    })
  ),
  //----------SUCCESS----------
  on(
    getUserWalletSuccess,
    produce((state, { payload }) => {
      let walletLimitsShownAlert = state['walletLimitsShownAlert'];

      if (walletLimitsShownAlert) {
        // if we showed an alert for a user and then the boolean value for that alert became false, that means something changed in the wallet and we need to reset the value for walletLimitsShownAlert
        if (!payload['transaction_limits'][walletLimitsShownAlert]) {
          walletLimitsShownAlert = null;
        }
      }

      state.userWallet = {
        ...payload,
        balance: payload?.['balance'] || 0,
      };
      state.walletLimitsShownAlert = walletLimitsShownAlert;
      state.isLoading['getUserWallet'] = false;
      state.errorMessages['getUserWallet'] = '';
    })
  ),
  on(
    getUserBalanceSuccess,
    produce((state, { payload }) => {
      state.userBalance = payload;
      state.isLoading['getUserBalance'] = false;
      state.errorMessages['getUserBalance'] = '';
    })
  ),
  on(
    getUserBillingsSuccess,
    produce((state, { payload: { result, isAppend } }) => {
      if (isAppend) {
        const currentResults = state.userBillings?.results || [];

        state.userBillings = {
          ...result,
          results: currentResults.concat(result.results || []),
        };
      } else {
        state.userBillings = result;
      }

      state.isLoading['getUserBillings'] = false;
      state.errorMessages['getUserBillings'] = '';
    })
  ),
  on(
    getSubscriptionPriceSuccess,
    produce((state, { payload: { subscription_price, subscription_price_currency } }) => {
      state.subscriptionInfo = {
        price: subscription_price,
        currency: subscription_price_currency,
      };
      state.isLoading['getSubscriptionPrice'] = false;
      state.errorMessages['getSubscriptionPrice'] = '';
    })
  ),
  on(
    paySubNowSuccess,
    produce((state, { result }) => {
      state.userBillings.results.unshift(result);
    })
  ),
  on(
    getBillingAttemptDetailsSuccess,
    produce((state, { result }) => {
      const updatedBillings = [...state.userBillings.results];
      const _attemptIndex = updatedBillings.findIndex((i) => i.id === result.id);

      if (_attemptIndex !== -1) {
        updatedBillings[_attemptIndex] = result;
      }

      state.userBillings.results = updatedBillings;
      state.userBillings.payNow = result;
      state.isLoading['payNow'] = false;
      state.isLoading['billingAttemptDetails'] = false;
    })
  ),
  //----------FAILURE----------
  on(
    getUserWalletFailure,
    produce((state, { error }) => {
      state.isLoading['getUserWallet'] = false;
      state.errorMessages['getUserWallet'] = error;
    })
  ),
  on(
    getUserBalanceFailure,
    produce((state, { error }) => {
      state.isLoading['getUserBalance'] = false;
      state.errorMessages['getUserBalance'] = error;
    })
  ),
  on(
    getUserBillingsFailure,
    produce((state, { error }) => {
      state.isLoading['getUserBillings'] = false;
      state.errorMessages['getUserBillings'] = error;
    })
  ),
  on(
    getSubscriptionPriceFailure,
    produce((state, { error }) => {
      state.isLoading['getSubscriptionPrice'] = false;
      state.errorMessages['getSubscriptionPrice'] = error;
    })
  ),
  on(
    paySubNowFailure,
    produce((state, { error }) => {
      state.isLoading['payNow'] = false;
      state.errorMessages = {
        payNow: error,
      }; // storing the whole error object
    })
  ),
  on(
    getBillingAttemptDetailsFailure,
    produce((state) => {
      state.isLoading['payNow'] = false;
      state.isLoading['billingAttemptDetails'] = false;
    })
  ),
  //----------OTHER----------
  on(
    setWalletLimitsShownAlert,
    produce((state, { payload }) => {
      state.walletLimitsShownAlert = payload;
    })
  ),
  on(
    logOut,
    produce((state) => {
      state.userWallet = null;
      state.userTransactions = null;
      state.userBalance = null;
      state.userBillings = null;
      state.subscriptionInfo = null;
    })
  ),
  on(
    clearSavedUser,
    produce((state) => {
      state.walletLimitsShownAlert = null;
    })
  ),
  on(
    paySubNowReset,
    produce((state) => {
      state.userBillings.payNow = null;
      state.errorMessages['payNow'] = null;
    })
  )
);
