<div>
  <label *ngIf="labelPresent || label" class="block font-medium text-sm mb-1">{{ label }}</label>
  <div
    class="w-full flex transition ease-in-out duration-500 relative"
    [ngClass]="{
      'bg-white border-solid focus-within:border-yellow-800 disabled:border-gray-300': !readonly,
      'border rounded-md focus-within:border-[1.5px] focus-within:-m-[0.5px] disabled:border': !readonly,
      'hover:border-gray-800 hover:border-[1.5px] hover:-m-[0.5px]': !readonly && !focused,
      'bg-transparent': readonly,
      'border-gray-800': !displayError,
      'border-bey-red': displayError
    }">
    <div class="fixed h-full w-full top-0 inset-0 z-50" (click)="toggleFocus()" *ngIf="focused"></div>
    <div class="flex px-2 w-full">
      <div class="w-10/12">
        <input
          type="text"
          autocomplete="new-password"
          [placeholder]="placeholder"
          class="w-full focus:outline-none text-gray-800 placeholder:text-gray-300 text-sm p-[8px] bg-transparent"
          [readOnly]="readonly"
          [ngClass]="readonly ? 'pointer-events-none' : ''"
          [value]="inputFieldValue"
          (input)="updateInputVal($event)"
          (focusin)="toggleFocus()"
          (blur)="onBlur()"
          (focusout)="toggleFocus(true)" />
      </div>
      <div
        class="w-2/12 transition ease-in-out duration-300 flex items-center justify-center"
        [ngClass]="isLoading ? 'opacity-1' : 'opacity-0'">
        <spinner [diameter]="23"></spinner>
      </div>
    </div>

    <div
      *ngIf="options?.length"
      class="absolute left-0 bg-white top-[34px] transition-all ease-in-out duration-300 rounded-md menu overflow-auto"
      [ngClass]="focused && !readonly ? 'opacity-1 z-100' : '-z-1 opacity-0 '"
      getElementId
      getElementIdDescription="country-selector">
      <ul class="unstyled space-y-1.5">
        <li class="pb-[11px]"></li>
        <li
          class="flex focus: py-1.5 rounded items-center transition ease-in-out duration-500 cursor-pointer hover:bg-gray-100"
          *ngFor="let contact of options"
          (click)="selectContact(contact)"
          [ngClass]="value?.value === contact.value ? 'bg-[#CCE4F1]' : ''"
          getElementId
          [getElementIdDescription]="'contact-option ' + contact.label">
          <span
            class="rounded-full bg-blue-500 text-white text-xs font-normal ml-2 mr-[18px] max-w-[24px] max-h-[24px] min-w-[24px] min-h-[24px] flex items-center justify-center"
            >{{ contact.label[0] === '+' ? '-' : (contact.label | nameInitials) }}</span
          >
          <span
            class="font-normal text-gray-600 text-sm leading-[14px] text-base font-medium text-gray-600 w-full text-ellipsis overflow-hidden"
            >{{ contact.label[0] === '+' ? (contact.label | formatPhone) : (contact.label | titlecase) }}</span
          >
        </li>
      </ul>
    </div>
  </div>
  <bey-field-error-message *ngIf="!hideValidationFeedback" [displayError]="displayError" [errorMessage]="errorMessage">
  </bey-field-error-message>
</div>
