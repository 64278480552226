import { createReducer, on } from '@ngrx/store';
import { MiscState } from './misc.interface';
import {
  changeExpansionStatus,
  changeMinimizationStatus,
  changeOpeningStatus,
  closeSnackbar,
  fetchReceiptDataFailure,
  fetchReceiptDataStart,
  fetchReceiptDataSuccess,
  generateReceiptFail,
  generateReceiptSuccess,
  getRatesFailure,
  getRatesStart,
  getRatesSuccess,
  openSnackbar,
  printDocFail,
  printDocStart,
  printDocSuccess,
  toggleAnimatedSidebar,
  toggleDrawer,
  toggleHeadlessStepperStep,
  toggleSlidingSidebar,
} from './misc.actions';
import { produce } from 'immer';

const initState: MiscState = {
  fxRate: null,
  animatedSidebar: {
    payload: null,
    isOpen: false,
    contentProjection: false,
    header: null,
    isOver: false,
  },
  flexibleDialog: {
    isOpened: false,
    isExpanded: false,
    isMinimized: false,
  },
  snackbar: {
    show: false,
    title: '',
    content: '',
    color: null,
    bottomContentDetails: null,
  },
  slidingSidebarOpen: false,
  targetedRoutesToShowPendingKycBanner: ['/', '/profile', '/billing', '/transactions'],
  drawer: {
    isOpen: false,
    component: '',
    data: null,
  },
  isLoading: {
    documentPrint: false,
    getRates: false,
  },
  errorMessages: {
    documentPrint: '',
    getRates: '',
  },

  /***
   * Registration stepper KYC and KYB
   */
  headlessStepper: {
    currentStep: 0,
  },

  receiptInfo: null,
};

export const MiscReducer = createReducer(
  initState,
  on(
    toggleAnimatedSidebar,
    produce((state, { isOpen, payload, contentProjection, header, isOver }) => {
      state.animatedSidebar = {
        payload,
        contentProjection,
        isOpen,
        header,
        isOver,
      };
    })
  ),
  on(
    toggleSlidingSidebar,
    produce((state) => {
      state.slidingSidebarOpen = !state.slidingSidebarOpen;
    })
  ),
  on(
    toggleDrawer,
    produce((state, { data, component }) => {
      state.drawer = {
        isOpen: !state.drawer.isOpen,
        data,
        component,
      };
    })
  ),
  //----------START----------
  on(
    printDocStart,
    produce((state) => {
      state.isLoading['documentPrint'] = true;
    })
  ),
  on(
    getRatesStart,
    produce((state) => {
      state.isLoading['getRates'] = true;
    })
  ),
  on(
    fetchReceiptDataStart,
    produce((state) => {
      state.isLoading['receiptPrint'] = true;
    })
  ),
  //----------SUCCESS----------
  on(
    printDocSuccess,
    produce((state) => {
      state.isLoading['documentPrint'] = false;
      state.errorMessages['documentPrint'] = '';
    })
  ),
  on(
    getRatesSuccess,
    produce((state, { payload }) => {
      state.fxRate = payload['fx_rate'];
      state.isLoading['getRates'] = false;
      state.errorMessages['getRates'] = '';
    })
  ),
  on(
    fetchReceiptDataSuccess,
    produce((state, { payload }) => {
      state.receiptInfo = payload;
    })
  ),
  on(
    generateReceiptSuccess,
    produce((state) => {
      state.isLoading['receiptPrint'] = false;
    })
  ),
  //----------FAILURE----------
  on(
    printDocFail,
    produce((state, { error }) => {
      state.isLoading['documentPrint'] = false;
      state.errorMessages['documentPrint'] = error;
    })
  ),
  on(
    getRatesFailure,
    produce((state, { error }) => {
      state.isLoading['getRates'] = false;
      state.errorMessages['getRates'] = error;
    })
  ),
  on(
    generateReceiptFail,
    produce((state) => {
      state.isLoading['receiptPrint'] = false;
    })
  ),
  on(
    fetchReceiptDataFailure,
    produce((state) => {
      state.isLoading['receiptPrint'] = false;
    })
  ),
  // --- Registration stepper
  on(
    toggleHeadlessStepperStep,
    produce((state, { currentStep }) => {
      // !== null because steps start from 0
      state.headlessStepper.currentStep = currentStep !== null ? currentStep : state.headlessStepper.currentStep + 1; // increase if currentStep is null because it's easier this way from effect side
    })
  ),
  // --- Flexible Dialog
  on(
    changeOpeningStatus,
    produce((state, { payload: { isOpened } }) => {
      let object: object = { isOpened };
      if (!isOpened) {
        object = { ...object, isExpanded: false, isMinimized: false };
      }
      state.flexibleDialog = {
        ...state.flexibleDialog,
        ...object,
      };
    })
  ),
  on(
    changeMinimizationStatus,
    produce((state, { payload }) => {
      let booleanValue: boolean;

      if (payload?.['isMinimized']) {
        booleanValue = payload['isMinimized'];
      } else {
        booleanValue = !state['flexibleDialog']['isMinimized'];
      }
      state.flexibleDialog.isMinimized = booleanValue;
      state.flexibleDialog.isExpanded = booleanValue ? false : state['flexibleDialog']['isExpanded'];
    })
  ),
  on(
    changeExpansionStatus,
    produce((state, { payload }) => {
      let booleanValue: boolean;

      if (payload?.['isExpanded']) {
        booleanValue = payload['isExpanded'];
      } else {
        booleanValue = !state['flexibleDialog']['isExpanded'];
      }

      state.flexibleDialog.isExpanded = booleanValue;
      state.flexibleDialog.isMinimized = booleanValue ? false : state['flexibleDialog']['isMinimized'];
    })
  ),
  // --- snackbar
  on(
    openSnackbar,
    produce((state, { snackbarDetails }) => {
      state.snackbar = { ...snackbarDetails, show: true };
    })
  ),
  on(
    closeSnackbar,
    produce((state) => {
      state.snackbar.show = false;
    })
  )
);
