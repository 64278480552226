import { animate, state, style, transition, trigger } from '@angular/animations';

/***********
 *  Fade In ---- Fade Out
 */
const fadeInEnterTransition = transition(':enter', [
  style({
    opacity: 0,
  }),
  animate(
    '700ms ease-in',
    style({
      opacity: 1,
    })
  ),
]);

const fadeOutLeaveTrans = transition(':leave', [
  style({
    opacity: 1,
  }),
  animate(
    '700ms ease-out',
    style({
      opacity: 0,
    })
  ),
]);

const fadeOutAbsoluteLeaveTrans = transition(':leave', [
  style({
    opacity: 1,
    position: 'absolute',
  }),
  animate(
    '700ms ease-out',
    style({
      opacity: 0,
    })
  ),
]);

/*****
 *  Slide in and slide out
 */
const slideInEnterTrans = transition(':enter', [
  style({
    opacity: 0,
    transform: 'translateX(100%)',
    position: 'absolute',
    width: '100%',
  }),
  animate(
    '1s ease-in-out',
    style({
      transform: 'translateX(0)',
      opacity: 1,
    })
  ),
]);

const slideOutEnterTrans = transition(':leave', [
  style({
    opacity: 1,
    transform: 'translateX(0%)',
    position: 'absolute',
    width: '100%',
  }),
  animate(
    '1s ease-in-out',
    style({
      transform: 'translateX(-100%)',
      opacity: 0,
    })
  ),
]);

/****
 *  Transitions
 */
export const fadeIn = trigger('fadeIn', [fadeInEnterTransition]);
export const fadeOut = trigger('fadeOut', [fadeOutLeaveTrans]);
export const fadeOutAbsolute = trigger('fadeOutAbsolute', [fadeOutAbsoluteLeaveTrans]);

export const slideIn = trigger('slideIn', [slideInEnterTrans]);
export const slideOut = trigger('slideOut', [slideOutEnterTrans]);

export const Fading = trigger('Fading', [
  state('void', style({ opacity: 0 })),
  state('*', style({ opacity: 1 })),
  transition(':enter', animate('800ms ease-out')),
  transition(':leave', animate('800ms ease-in')),
]);

export const slideOpenAccordion = trigger('slideOpenAccordion', [
  state(
    'close',
    style({
      maxHeight: 0,
      overflow: 'hidden',
    })
  ),
  state(
    'open',
    style({
      maxHeight: 'auto',
      overflow: 'unset',
    })
  ),
  transition('close => open', [
    style({
      maxHeight: 0,
    }),
    animate(
      '500ms ease-in-out',
      style({
        maxHeight: '800px',
      })
    ),
  ]),
  transition('open => close', [
    style({
      maxHeight: '800px',
      overflow: 'unset',
    }),
    animate(
      '500ms ease-in-out',
      style({
        maxHeight: '0px',
        overflow: 'hidden',
      })
    ),
  ]),
]);

const ANIMATION_DURATION = '700ms ease-in-out';

export const stepperFadeInOut = trigger('stepperFadeInOut', [
  state(
    'inactive',
    style({
      opacity: 0,
      zIndex: -1,
      maxHeight: 0,
      position: 'absolute',
      top: 0,
      overflow: 'hidden',
    })
  ),
  state(
    'active',
    style({
      opacity: 1,
      zIndex: 10,
      maxHeight: 'unset',
    })
  ),
  transition('inactive => active', [
    style({
      height: '*',
    }),
    animate(ANIMATION_DURATION),
  ]),
  transition('active => inactive', [
    style({
      height: '0',
    }),
    animate(ANIMATION_DURATION),
  ]),
]);
