import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bey-recent-transactions-item-skeleton',
  templateUrl: './bey-recent-transactions-item-skeleton.component.html',
  styleUrls: ['./bey-recent-transactions-item-skeleton.component.scss'],
  standalone: true,
})
export class BeyRecentTransactionsItemSkeletonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
