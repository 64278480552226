import { createReducer, on } from '@ngrx/store';
import {
  createBeyonicLinkStart,
  getUserBeyonicLinksStart,
  getMoreUserBeyonicLinksStart,
  createBeyonicLinkSuccess,
  getUserBeyonicLinksSuccess,
  getMoreUserBeyonicLinksSuccess,
  createBeyonicLinkFailure,
  getUserBeyonicLinksFailure,
  getMoreUserBeyonicLinksFailure,
  updateBeyonicLinkStart,
  updateBeyonicLinkSuccess,
  updateBeyonicLinkFailure,
  resetNewBeyonicLink,
  processBeyonicLinkStart,
  processBeyonicLinkSuccessful,
  processBeyonicLinkFailure,
  setSelectedBeyonicLink,
  getBeyonicLinkCollectionsStart,
  getBeyonicLinkCollectionsSuccess,
  getBeyonicLinkCollectionsFailure,
  getMoreBeyonicLinkCollectionsStart,
  getMoreBeyonicLinkCollectionsSuccess,
  getMoreBeyonicLinkCollectionsFailure,
} from './beyonic-link.actions';
import { logOut } from '../auth/auth.actions';
import { BeyonicLink, BeyonicLinkState } from './beyonic-link.interfaces';
import { produce } from 'immer';

const initialState: BeyonicLinkState = {
  userBeyonicLinks: null,
  selectedBeyonicLink: null,
  selectedBeyonicLinkCollections: null,
  isLoading: {
    createBeyonicLink: false,
    updateBeyonicLink: false,
    getUserBeyonicLinks: false,
    getMoreUserBeyonicLinks: false,
    getBeyonicLinkCollections: false,
    getMoreBeyonicLinkCollections: false,
  },
  errorMessages: {
    createBeyonicLink: '',
    updateBeyonicLink: '',
    getUserBeyonicLinks: '',
    getMoreUserBeyonicLinks: '',
    getBeyonicLinkCollections: '',
    getMoreBeyonicLinkCollections: '',
  },
};

export const beyonicLinkReducer = createReducer(
  initialState,
  //----------START----------
  on(
    createBeyonicLinkStart,
    produce((state) => {
      state.isLoading['createBeyonicLink'] = true;
    })
  ),
  on(
    updateBeyonicLinkStart,
    produce((state) => {
      state.isLoading['updateBeyonicLink'] = true;
    })
  ),
  on(
    getUserBeyonicLinksStart,
    produce((state) => {
      state.userBeyonicLinks = null;
      state.isLoading['getUserBeyonicLinks'] = true;
    })
  ),
  on(
    getMoreUserBeyonicLinksStart,
    produce((state) => {
      state.isLoading['getMoreUserBeyonicLinks'] = true;
    })
  ),
  on(
    getBeyonicLinkCollectionsStart,
    produce((state) => {
      state.isLoading['getBeyonicLinkCollections'] = true;
    })
  ),
  on(
    getMoreBeyonicLinkCollectionsStart,
    produce((state) => {
      state.isLoading['getMoreBeyonicLinkCollections'] = true;
    })
  ),
  on(
    processBeyonicLinkStart,
    produce((state) => {
      state.isLoading['processBeyonicLink'] = true;
    })
  ),
  //----------SUCCESS----------
  on(
    createBeyonicLinkSuccess,
    produce((state) => {
      state.isLoading['createBeyonicLink'] = false;
      state.errorMessages['createBeyonicLink'] = '';
    })
  ),
  on(
    updateBeyonicLinkSuccess,
    produce((state, { payload: { data } }) => {
      let beyonicLinks: Array<BeyonicLink>;
      beyonicLinks = state?.['userBeyonicLinks']?.['results']?.map((link) => {
        if (link?.id === data?.id) {
          return data;
        } else {
          return link;
        }
      });

      state.selectedBeyonicLink = state?.selectedBeyonicLink?.id === data?.id ? data : state?.selectedBeyonicLink;
      state.userBeyonicLinks.results = beyonicLinks;
      state.isLoading['updateBeyonicLink'] = false;
      state.errorMessages['updateBeyonicLink'] = '';
    })
  ),
  on(
    getUserBeyonicLinksSuccess,
    produce((state, { payload }) => {
      let currentStoreUserBeyonicLinks = state?.userBeyonicLinks?.results;
      let newUserBeyonicLinks: Array<BeyonicLink>;

      // check if there's already beyonic link in the store before comparing them with new beyonic links
      if (currentStoreUserBeyonicLinks?.length) {
        newUserBeyonicLinks = payload.results.map((beyonicLink) => {
          if (
            !!!currentStoreUserBeyonicLinks.find(
              (currentStoreCollection) => currentStoreCollection.id === beyonicLink.id
            )
          ) {
            return (beyonicLink = { ...beyonicLink, newTransaction: true });
          } else {
            return beyonicLink;
          }
        });
      } else {
        newUserBeyonicLinks = payload.results;
      }

      state.userBeyonicLinks = {
        ...payload,
        results: newUserBeyonicLinks,
      };
      state.isLoading['getUserBeyonicLinks'] = false;
      state.errorMessages['getUserBeyonicLinks'] = '';
    })
  ),
  on(
    getMoreUserBeyonicLinksSuccess,
    produce((state, { payload }) => {
      state.userBeyonicLinks = {
        ...payload,
        next: payload['next'],
        results: [...state?.['userBeyonicLinks']?.['results'], ...payload?.['results']],
      };
      state.isLoading['getMoreUserBeyonicLinks'] = false;
      state.errorMessages['getMoreUserBeyonicLinks'] = '';
    })
  ),
  on(
    getBeyonicLinkCollectionsSuccess,
    produce((state, { payload }) => {
      state.selectedBeyonicLinkCollections = {
        ...payload,
        next: payload['next'],
        results: payload?.['results'],
      };
      state.isLoading['getBeyonicLinkCollections'] = false;
      state.errorMessages['getBeyonicLinkCollections'] = '';
    })
  ),
  on(
    getMoreBeyonicLinkCollectionsSuccess,
    produce((state, { payload }) => {
      state.selectedBeyonicLinkCollections = {
        ...payload,
        next: payload['next'],
        results: state?.['selectedBeyonicLinkCollections']?.['results'].concat(payload?.['results']),
      };
      state.isLoading['getMoreBeyonicLinkCollections'] = false;
      state.errorMessages['getMoreBeyonicLinkCollections'] = '';
    })
  ),
  on(
    processBeyonicLinkSuccessful,
    produce((state) => {
      state.isLoading['processBeyonicLink'] = false;
    })
  ),
  //----------FAILURE----------
  on(
    createBeyonicLinkFailure,
    produce((state, { error }) => {
      state.isLoading['createBeyonicLink'] = false;
      state.errorMessages['createBeyonicLink'] = error;
    })
  ),
  on(
    updateBeyonicLinkFailure,
    produce((state, { error }) => {
      state.isLoading['updateBeyonicLink'] = false;
      state.errorMessages['updateBeyonicLink'] = error;
    })
  ),
  on(
    getUserBeyonicLinksFailure,
    produce((state, { error }) => {
      state.isLoading['getUserBeyonicLinks'] = false;
      state.errorMessages['getUserBeyonicLinks'] = error;
    })
  ),
  on(
    getMoreUserBeyonicLinksFailure,
    produce((state, { error }) => {
      state.isLoading['getMoreUserBeyonicLinks'] = false;
      state.errorMessages['getMoreUserBeyonicLinks'] = error;
    })
  ),
  on(
    getBeyonicLinkCollectionsFailure,
    produce((state, { error }) => {
      state.isLoading['getBeyonicLinkCollections'] = false;
      state.errorMessages['getBeyonicLinkCollections'] = error;
    })
  ),
  on(
    getMoreBeyonicLinkCollectionsFailure,
    produce((state, { error }) => {
      state.isLoading['getMoreBeyonicLinkCollections'] = false;
      state.errorMessages['getMoreBeyonicLinkCollections'] = error;
    })
  ),
  on(
    processBeyonicLinkFailure,
    produce((state) => {
      state.isLoading = {
        processBeyonicLink: false,
      };
    })
  ),
  //----------OTHER----------
  on(
    setSelectedBeyonicLink,
    produce((state, { payload: { beyonicLink } }) => {
      state.selectedBeyonicLink = beyonicLink;
    })
  ),
  on(
    logOut,
    produce((state) => {
      state.selectedBeyonicLink = null;
      state.userBeyonicLinks = {
        count: 0,
        next: null,
        previous: null,
        summary: null,
        results: [],
      };
    })
  ),
  on(
    resetNewBeyonicLink,
    produce((state, { payload: { beyonicLinkId } }) => {
      let newUserBeyonicLinks = state.userBeyonicLinks.results.map((beyonicLink) =>
        beyonicLink.id === beyonicLinkId ? (beyonicLink = { ...beyonicLink, newTransaction: false }) : beyonicLink
      );

      state.userBeyonicLinks.results = newUserBeyonicLinks;
    })
  )
);
