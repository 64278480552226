<bey-header class="hidden lg:block"></bey-header>
<div class="relative h-[82px] bg-gray-200 lg:h-[115px]" [ngClass]="{ '!mx-0 !-mt-1': isPreview }">
  <div class="text-white pl-8 pt-4" *ngIf="backButton" @fadeIn>
    <bey-back-arrow strokeFillColor="#ffff" (onClick)="onBack.emit()">
      <span class="pl-3">Back</span>
    </bey-back-arrow>
  </div>

  <div
    class="left-2/4 top-[40px] bg-gray-50 absolute -translate-x-1/2 w-20 h-20 z-50 border-gray-100 border-[5px] rounded-full flex justify-center items-center lg:hidden">
    <span class="font-bold text-4xl mt-2">{{ name | nameInitials }}</span>
  </div>
</div>
