import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[lazyLoad]',
  standalone: true,
})
export class LazyLoadImageDirective implements OnInit {
  /***
   * URL src for the placeholder image
   */
  @Input()
  placeholderSrc: string;

  /**
   * Real image src to be replaced after image has done loading
   */
  realSrc: string;

  private elementRef: ElementRef<HTMLImageElement>;

  constructor(ref: ElementRef<HTMLImageElement>) {
    this.elementRef = ref;
  }

  // Image has loaded, update src to the real image
  @HostListener('load')
  onLoad() {
    this.elementRef.nativeElement.src = this.realSrc;
  }

  ngOnInit(): void {
    const support: boolean = 'loading' in HTMLImageElement.prototype;

    if (support) {
      this.elementRef.nativeElement.setAttribute('loading', 'lazy');
      this.realSrc = this.elementRef.nativeElement.src;

      // check if we have a placeholder because we don't want to implement the same loading image to all images
      if (this.placeholderSrc) {
        this.elementRef.nativeElement.src = this.placeholderSrc;
      }
    }
  }
}
