import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  BillingItem,
  BusinessLimitsCheckRequestBody,
  BusinessLimitsCheckResponse,
  getWalletInfoResponse,
  SubscriptionPrice,
  WalletsState,
} from './wallets.interfaces';

@Injectable({
  providedIn: 'root',
})
export class WalletsService {
  constructor(private http: HttpClient) {}

  getUserWallet(business_id: number) {
    return this.http.get<getWalletInfoResponse>(`${environment.API_URL}wallets/business/${business_id}/wallets/`);
  }

  getUserBillings(business_id: number, url: string = null) {
    if (url) {
      return this.http.get<WalletsState['userBillings']>(url);
    }

    let params = new HttpParams();
    params = params.append('ordering', '-id');
    params = params.append('limit', '5');

    return this.http.get<WalletsState['userBillings']>(
      `${environment.API_URL}billing-attempts/business/${business_id}/billing-attempts/`,
      { params }
    );
  }

  getUserBalance(balanceId: number) {
    return this.http.get<any>(environment.API_URL + `wallets/${balanceId}/`);
  }

  getSubscriptionPrice() {
    return this.http.get<SubscriptionPrice>(environment.API_URL + 'subscription/price/1/');
  }

  businessLimitsCheck(payload: BusinessLimitsCheckRequestBody) {
    return this.http.post<BusinessLimitsCheckResponse>(environment.API_URL + 'wallet-limits/check/', {
      ...payload,
    });
  }

  paySubscriptionNow(business_id: number) {
    return this.http.post<BillingItem>(`${environment.API_URL}billing-attempts/pay-now/`, {
      business_id,
    });
  }

  getBillingAttemptInfo(id: number) {
    return this.http.get<BillingItem>(`${environment.API_URL}billing-attempts/${id}/`);
  }
}
