import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BeyButtonComponent } from '../bey-button/bey-button.component';

@Component({
  selector: 'bey-snippet-code',
  templateUrl: './bey-snippet-code.component.html',
  styleUrls: ['./bey-snippet-code.component.scss'],
  standalone: true,
  imports: [BeyButtonComponent],
})
export class BeySnippetCodeComponent {
  @Input() snippet: string;
}
