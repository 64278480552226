import { Component, OnInit } from '@angular/core';
import { BeyLinkInteractionComponent } from '../../../shared/pages/bey-link-interaction/bey-link-interaction.component';

@Component({
  selector: 'app-bey-link-interaction',
  templateUrl: './bey-link-interaction.page.html',
  styleUrls: ['./bey-link-interaction.page.scss'],
  standalone: true,
  imports: [BeyLinkInteractionComponent],
})
export class BeyLinkInteractionPage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
