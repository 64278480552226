import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BeyNetworkStateComponent } from '../components/bey-network-state/bey-network-state.component';
import { BeyNewAppVersionContentComponent } from '../../shared/components/bey-new-app-version-content/bey-new-app-version-content.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerUtilService {
  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private snackMe: MatSnackBar,
    private dialog: MatDialog
  ) {}

  handleVersion(): void {
    this.updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.info(`Downloading new app version: ${evt.version.hash}`);
          break;

        case 'VERSION_READY':
          console.info(`New app version ready for use: ${evt.latestVersion.hash}`);

          this.dialog.open(BeyNewAppVersionContentComponent, {
            maxWidth: '80%',
            data: {
              actionType: 'prompt',
            },
            disableClose: true,
          });
          break;

        case 'VERSION_INSTALLATION_FAILED':
          console.error(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });

    this.updates.unrecoverable.subscribe((evt) => {
      console.error(evt);
      this.dialog.open(BeyNewAppVersionContentComponent, {
        maxWidth: '80%',
        data: {
          actionType: 'unrecoverable',
        },
        disableClose: true,
      });
    });
  }

  handleNetworkChange(): void {
    const snackBar = (state: 'offline' | 'online') => {
      this.snackMe.openFromComponent(BeyNetworkStateComponent, {
        panelClass: 'network-toast',
        data: {
          variant: state,
        },
        duration: state === 'online' ? 4000 : 10000,
      });
    };

    window.addEventListener('online', () => snackBar('online'));
    window.addEventListener('offline', () => snackBar('offline'));
  }
}
