import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  AbstractControl,
  ReactiveFormsModule,
  FormsModule,
  FormControl,
} from '@angular/forms';
import { FormUtilityService } from 'src/app/modules/core/services/form-utility.service';
import { Subscription } from 'rxjs';
import { BeyFieldErrorMessageComponent } from '../bey-field-error-message/bey-field-error-message.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { CurrencyMaskModule } from 'ng2-currency-mask-beyonic';
import { AutofocusDirective } from '../../directives/auto-focus.directive';
import { NgIf, NgClass, NgSwitch, CommonModule } from '@angular/common';
import { PhoneReformatDirective } from '../../directives/phone-reformat.directive';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BeyInputComponent),
  multi: true,
};

const noop = () => {};

@Component({
  selector: 'bey-input',
  templateUrl: './bey-input.component.html',
  styleUrls: ['./bey-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    ReactiveFormsModule,
    FormsModule,
    AutofocusDirective,
    CurrencyMaskModule,
    SpinnerComponent,
    BeyFieldErrorMessageComponent,
    NgSwitch,
    CommonModule,
    PhoneReformatDirective,
  ],
})

/***
 *  Beyonic Input field used across the application
 */
export class BeyInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  /***
   *  Text for input field placeholder attribute
   */
  @Input()
  placeholder: string = '';

  /***
   *  Text for input field label
   */
  @Input()
  label: string = '';

  /***
   *  Input field name attribute
   */
  @Input()
  name: string = '';

  /***
   *  Input field required attribute
   */
  @Input()
  required: boolean = false;

  /****
   *  This includes all HTML default types like text | tel | email | password | etc.
   *  ALSO CUSTOM:
   *  - Textarea
   *  - amount (Uses input masking and outputs formatted number for the user)
   */
  @Input()
  type: string = 'text';

  /***
   *  Make the field readonly and un editable
   */
  @Input()
  readonly: boolean = false;

  /***
   *  Make the field readonly and un editable also don't show background feedback
   */
  @Input()
  readonlyNoFeedback: boolean = false;

  /***
   *  disables the field
   */
  @Input()
  disabled: boolean = false;

  /***
   *  Make browser focus on the field by default
   */
  @Input()
  autofocus?: boolean = false;

  /***
   *  Change the design to input with only bottom border
   */
  @Input()
  underlined?: boolean = false;

  /***
   *  Hide the validation feedback message showing under the field
   */
  @Input()
  hideValidationFeedback: boolean = false;

  /***
   *  Transparent background
   */
  @Input()
  transparent: boolean = false;

  /***
   *  Enter key event handler
   */
  @Input()
  onClickEnter: () => void;

  /***
   * Add margin on top of the input field to make it match with neighboring component
   */
  @Input()
  labelMargin: boolean = false;

  /***
   *  Currency symbol to show in the text field (UGX | $ | LYD)
   */
  @Input()
  currencySymbol: string;

  /****
   * Class list to override in form of object
   * for the following elements: label
   */
  @Input()
  classList: { [label: string]: string };

  /****
   * A spinner to be shown if there's a loading status
   */
  @Input()
  isLoading: boolean;

  /****
   * Custom name for error message string
   */
  @Input()
  errorMessageCustomName: string;

  /****
   * formControl instance
   */
  @Input()
  control: AbstractControl | FormControl;

  displayError: boolean = false;
  warning: boolean = false;
  errorMessage: string = '';
  subs$: Subscription = new Subscription();

  /****
   *  This component supports model
   *  FORM CONTROL
   *  formControlName="idNum"
   */
  constructor(private formUtility: FormUtilityService) {}
  ngOnInit(): void {
    if (!!this.control) {
      this.subs$.add(
        this.control.valueChanges.subscribe(() => {
          this.updateErrorStatus();
        })
      );
    }
  }

  updateErrorStatus(): void {
    if (this.control) {
      this.control.markAsTouched();
      this.warning = this.formUtility.checkFieldWarnings(this.control);
      this.errorMessage = this.formUtility.getFieldErrorMessage(this.control, this.errorMessageCustomName);
      this.displayError = this.formUtility.getFieldValidation(this.control);
    }
  }

  //The internal data model
  private innerValue: any = '';
  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  get value(): any {
    return this.innerValue;
  }

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
    this.updateErrorStatus();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  enterClickHandler(): void {
    if (this.onClickEnter) this.onClickEnter();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
