<div class="flex flex-col p-4 items-center text-center">
  <svg
    *ngIf="(createCollectionIsLoading$ | async) || (processBeyonicLinkIsLoading$ | async); else notLoadingIcon"
    class="animate-spin"
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.8126 2.31255C24.8126 3.58978 23.7772 4.62516 22.5001 4.62516C21.2229 4.62516 20.1875 3.58978 20.1875 2.31255C20.1875 1.03532 21.2229 6.10352e-05 22.5001 6.10352e-05C23.7772 6.10352e-05 24.8126 1.03532 24.8126 2.31255Z"
      fill="#00121C" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.6251 42.3125C24.6251 43.5898 23.5897 44.6252 22.3126 44.6252C21.0354 44.6252 20 43.5898 20 42.3125C20 41.0353 21.0354 40.0001 22.3126 40.0001C23.5897 40.0001 24.6251 41.0353 24.6251 42.3125Z"
      fill="#005989" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.6876 24.8125C41.4103 24.8125 40.375 23.7772 40.375 22.5001C40.375 21.2227 41.4103 20.1873 42.6876 20.1873C43.9648 20.1873 45.0001 21.2227 45.0001 22.5001C45.0001 23.7772 43.9648 24.8125 42.6876 24.8125Z"
      fill="#002D45" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.31261 24.8125C1.03539 24.8125 0 23.7772 0 22.5001C0 21.2227 1.03539 20.1873 2.31261 20.1873C3.58984 20.1873 4.62523 21.2227 4.62523 22.5001C4.62523 23.7772 3.58984 24.8125 2.31261 24.8125Z"
      fill="#3175FC" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.4108 9.8606C37.5077 10.7637 36.0434 10.7637 35.1403 9.8606C34.2371 8.95752 34.2371 7.49322 35.1403 6.59013C36.0434 5.68692 37.5077 5.68692 38.4108 6.59013C39.3137 7.49322 39.3137 8.95752 38.4108 9.8606Z"
      fill="#001F30" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.85999 38.4098C8.95691 39.3129 7.49261 39.3129 6.58952 38.4098C5.68631 37.5067 5.68631 36.0424 6.58952 35.1394C7.49261 34.2363 8.95691 34.2363 9.85999 35.1394C10.7631 36.0424 10.7631 37.5067 9.85999 38.4098Z"
      fill="#006FAB" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.1402 38.4098C34.2371 37.5067 34.2371 36.0424 35.1402 35.1394C36.0433 34.2363 37.5076 34.2363 38.4107 35.1394C39.3138 36.0424 39.3138 37.5067 38.4107 38.4098C37.5076 39.3129 36.0433 39.3129 35.1402 38.4098Z"
      fill="#00456B" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.58942 9.8606C5.68634 8.95752 5.68634 7.49322 6.58942 6.59013C7.49251 5.68692 8.95681 5.68692 9.8599 6.59013C10.763 7.49322 10.763 8.95752 9.8599 9.8606C8.95681 10.7637 7.49251 10.7637 6.58942 9.8606Z"
      fill="#31B1F7" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.3135 4.70705C31.8281 5.88837 30.4768 6.45241 29.2954 5.96692C28.1142 5.48144 27.55 4.13028 28.0355 2.94884C28.521 1.76752 29.8723 1.20348 31.0536 1.68884C32.235 2.17445 32.799 3.52574 32.3135 4.70705Z"
      fill="#00121C" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9658 42.051C16.4803 43.2323 15.1291 43.7965 13.9477 43.311C12.7664 42.8254 12.2024 41.4743 12.6879 40.2929C13.1734 39.1116 14.5245 38.5475 15.706 39.0329C16.8873 39.5186 17.4513 40.8697 16.9658 42.051Z"
      fill="#005989" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.2935 32.3128C39.1122 31.8273 38.5481 30.4761 39.0335 29.2947C39.5191 28.1134 40.8703 27.5493 42.0517 28.0348C43.2329 28.5203 43.7971 29.8716 43.3116 31.0529C42.826 32.2342 41.4749 32.7982 40.2935 32.3128Z"
      fill="#002D45" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.94975 16.9652C1.76844 16.4797 1.20427 15.1284 1.68988 13.9471C2.17537 12.7658 3.52652 12.2016 4.70784 12.6872C5.88928 13.1727 6.45332 14.524 5.96784 15.7052C5.48235 16.8866 4.13107 17.4506 2.94975 16.9652Z"
      fill="#3175FC" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.0205 16.8572C40.8418 17.3491 39.4875 16.7925 38.9955 15.6139C38.5034 14.4353 39.0602 13.0811 40.2388 12.589C41.4174 12.097 42.7716 12.6536 43.2637 13.8322C43.7557 15.0109 43.1991 16.3652 42.0205 16.8572Z"
      fill="#001F30" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.76075 32.4109C3.58201 32.903 2.22777 32.3464 1.73573 31.1678C1.24368 29.9891 1.8004 28.6348 2.97901 28.1429C4.15763 27.6508 5.51187 28.2074 6.00391 29.386C6.49595 30.5646 5.93937 31.919 4.76075 32.4109Z"
      fill="#006FAB" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.142 42.0202C27.65 40.8416 28.2065 39.4873 29.3852 38.9953C30.5638 38.5032 31.9181 39.0598 32.4102 40.2384C32.9021 41.417 32.3455 42.7714 31.1669 43.2635C29.9883 43.7554 28.6339 43.1988 28.142 42.0202Z"
      fill="#00456B" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5893 4.76155C12.0972 3.58294 12.6538 2.22857 13.8324 1.73665C15.0112 1.24448 16.3655 1.8012 16.8574 2.97981C17.3495 4.15843 16.7929 5.5128 15.6143 6.00471C14.4357 6.49675 13.0813 5.94017 12.5893 4.76155Z"
      fill="#BFD5FF" />
  </svg>
  <ng-template #notLoadingIcon>
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="23" r="23" fill="#BFD5FF" />
      <circle cx="22.9997" cy="23.0002" r="18.8806" fill="#E9F1FF" />
      <path
        d="M28.1091 27.5104L18.084 17.4854"
        stroke="#3175FC"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M18.7041 28.3853L28.6867 28.0879L28.8991 18.1903"
        stroke="#3175FC"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </ng-template>

  <span
    *ngIf="(createCollectionIsLoading$ | async) || (processBeyonicLinkIsLoading$ | async)"
    class="text-black text-lg font-medium my-4"
    >Processing collection prompt
  </span>

  <span
    *ngIf="(createCollectionIsLoading$ | async) || (processBeyonicLinkIsLoading$ | async); else notLoadingContent"
    class="text-gray-500 text-lg font-normal my-4">
    Sending a collection prompt for
    <span class="font-semibold">{{
      data?.displayAmount
        | applyRoundingRules : country : 'collection' : false
        | currency : currency + ' ' : 'symbol' : currencyPipeDigitalInfo
    }}</span>
    to
    <span class="font-semibold"> {{ data?.first_name }} {{ data?.last_name }} {{ data?.phone }} </span>
  </span>

  <ng-template #notLoadingContent>
    <span class="text-gray-700 text-lg font-medium my-4">
      Send a collection prompt of
      <span class="font-semibold">{{
        data?.displayAmount
          | applyRoundingRules : country : 'collection' : false
          | currency : currency + ' ' : 'symbol' : currencyPipeDigitalInfo
      }}</span>
      to
      <span class="font-semibold"> {{ data?.first_name }} {{ data?.last_name }} {{ data?.phone }} </span>
      ?</span
    >
    <bey-name-check [data]="beyNameCheckProps"></bey-name-check>
    <span *ngIf="!data?.hideFees" class="my-2 text-gray-700 font-normal text-base"
      >Transaction fee:
      {{
        transaction
          | calculateFee
          | applyRoundingRules : country : 'fee' : false
          | currency : currency + ' ' : 'symbol' : currencyPipeDigitalInfo
      }}
    </span>
  </ng-template>

  <mat-dialog-actions *ngIf="!(createCollectionIsLoading$ | async) && !(processBeyonicLinkIsLoading$ | async)">
    <bey-button class="mr-3" [small]="true" (onClick)="onClick()"
      ><span placeholder class="font-medium">Send</span></bey-button
    >
    <bey-button color="gray" [mat-dialog-close]="true" [small]="true"
      ><span placeholder class="font-medium">Cancel</span></bey-button
    >
  </mat-dialog-actions>
</div>
