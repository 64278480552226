import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-multiple-incorrect-attempts-dialog-content',
  templateUrl: './login-multiple-incorrect-attempts-dialog-content.component.html',
  styleUrls: ['./login-multiple-incorrect-attempts-dialog-content.component.scss'],
  standalone: true,
})
export class LoginMultipleIncorrectAttemptsDialogContentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
