import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserDetails } from '../../../../ngrx/user/user.selectors';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { GetElementIdDirective } from '../../../shared/directives/get-element-id.directive';

@Component({
  selector: 'app-error-page',
  templateUrl: './error.page.html',
  standalone: true,
  imports: [BeyButtonComponent, NgIf, GetElementIdDirective],
})
export class ErrorPage implements OnInit, OnDestroy {
  userLoggedIn: boolean = false;
  userDetailsSub: Subscription;

  constructor(store: Store, private router: Router) {
    this.userDetailsSub = store.select(selectUserDetails).subscribe((v) => (this.userLoggedIn = v !== null));
  }

  ngOnInit(): void {}

  navigate(): void {
    if (environment.is_pay) {
      window.open(`${environment.URL}/login`, '_blank');
    } else {
      this.router.navigate([this.userLoggedIn ? '/' : '/login']);
    }
  }

  ngOnDestroy(): void {
    this.userDetailsSub.unsubscribe();
  }
}
