import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { updateBeyonicLinkStart } from 'src/app/ngrx/beyonic-link/beyonic-link.actions';
import { BeyonicLink } from 'src/app/ngrx/beyonic-link/beyonic-link.interfaces';
import { selectUpdateBeyonicLinkIsLoading } from 'src/app/ngrx/beyonic-link/beyonic-link.selectors';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'update-beyonic-link-dialog-content',
  templateUrl: './update-beyonic-link-dialog-content.component.html',
  styleUrls: ['./update-beyonic-link-dialog-content.component.scss'],
  standalone: true,
  imports: [NgIf, MatLegacyDialogModule, BeyButtonComponent, AsyncPipe],
})
export class UpdateBeyonicLinkDialogContentComponent implements OnInit {
  title: string;
  subTitle: string;
  actionButton: string;
  action: boolean;

  updateBeyonicLinkIsLoading$: Observable<boolean>;

  constructor(
    private store$: Store,
    @Inject(MAT_DIALOG_DATA)
    public beyonicLink: BeyonicLink
  ) {
    this.updateBeyonicLinkIsLoading$ = store$.select(selectUpdateBeyonicLinkIsLoading);
  }

  ngOnInit(): void {
    if (this.beyonicLink['is_active']) {
      this.title = 'Close ';
      this.subTitle = ' re-open ';
      this.actionButton = 'close ';
      this.action = false;
    } else {
      this.title = 'Restore ';
      this.subTitle = ' close ';
      this.actionButton = 'restore ';
      this.action = true;
    }
  }

  onClick(): void {
    this.store$.dispatch(
      updateBeyonicLinkStart({
        payload: { data: { is_active: this.action, beyonic_link_id: this.beyonicLink?.id }, type: 'state' },
      })
    );
  }
}
