<div
  *ngIf="!!!(currentUser$ | async)?.first_name"
  class="flex flex-col lg:flex-row lg:justify-between lg:items-center bg w-full rounded-lg mx-auto py-8 px-7 min-h-[116px]"
  [ngClass]="classList">
  <div class="text-gray-800 leading-5 text-xl font-semibold max-w-[270px]">
    {{ header }}
  </div>

  <a
    href="#"
    (click)="trialStart.emit()"
    class="block text-blue-500 font-bold mt-8 lg:mt-0 text-xl leading-[16px]"
    preventDefaultEvent
    >{{ linkText }}</a
  >
</div>
