export const TYPES = {
  GET_USER_BEYONIC_LINKS_START: 'GET_USER_BEYONIC_LINKS_START',
  GET_USER_BEYONIC_LINKS_SUCCESS: 'GET_USER_BEYONIC_LINKS_SUCCESS',
  GET_USER_BEYONIC_LINKS_FAILURE: 'GET_USER_BEYONIC_LINKS_FAILURE',
  GET_MORE_USER_BEYONIC_LINKS_START: 'GET_MORE_USER_BEYONIC_LINKS_START',
  GET_MORE_USER_BEYONIC_LINKS_SUCCESS: 'GET_MORE_USER_BEYONIC_LINKS_SUCCESS',
  GET_MORE_USER_BEYONIC_LINKS_FAILURE: 'GET_MORE_USER_BEYONIC_LINKS_FAILURE',
  GET_BEYONIC_LINK_COLLECTIONS_START: 'GET_BEYONIC_LINK_COLLECTIONS_START',
  GET_BEYONIC_LINK_COLLECTIONS_SUCCESS: 'GET_BEYONIC_LINK_COLLECTIONS_SUCCESS',
  GET_BEYONIC_LINK_COLLECTIONS_FAILURE: 'GET_BEYONIC_LINK_COLLECTIONS_FAILURE',
  GET_MORE_BEYONIC_LINK_COLLECTIONS_START: 'GET_MORE_BEYONIC_LINK_COLLECTIONS_START',
  GET_MORE_BEYONIC_LINK_COLLECTIONS_SUCCESS: 'GET_MORE_BEYONIC_LINK_COLLECTIONS_SUCCESS',
  GET_MORE_BEYONIC_LINK_COLLECTIONS_FAILURE: 'GET_MORE_BEYONIC_LINK_COLLECTIONS_FAILURE',
  CREATE_BEYONIC_LINK_START: 'CREATE_BEYONIC_LINK_START',
  CREATE_BEYONIC_LINK_SUCCESS: 'CREATE_BEYONIC_LINK_SUCCESS',
  CREATE_BEYONIC_LINK_FAILURE: 'CREATE_BEYONIC_LINK_FAILURE',
  UPDATE_BEYONIC_LINK_START: 'UPDATE_BEYONIC_LINK_START',
  UPDATE_BEYONIC_LINK_SUCCESS: 'UPDATE_BEYONIC_LINK_SUCCESS',
  UPDATE_BEYONIC_LINK_FAILURE: 'UPDATE_BEYONIC_LINK_FAILURE',
  RESET_NEW_BEYONIC_LINK: 'RESET_NEW_BEYONIC_LINK',
  PROCESS_BEYONIC_LINK_START: 'PROCESS_BEYONIC_LINK_START',
  PROCESS_BEYONIC_LINK_SUCCESS: 'PROCESS_BEYONIC_LINK_SUCCESS',
  PROCESS_BEYONIC_LINK_FAILURE: 'PROCESS_BEYONIC_LINK_FAILURE',
  SET_SELECTED_BEYONIC_LINK: 'SET_SELECTED_BEYONIC_LINK',
};
