<div [ngClass]="{ 'min-h-[600px]': forceMobileView }">
  <bey-warning-banner [message]="message" [forceMobile]="isMobile" [backButton]="backButton"></bey-warning-banner>
  <div class="py-8 px-4 text-gray-600 lg:text-center" data-test-id="closed-link-blurb">
    This link is not accepting payments at the moment.

    <ng-container *ngIf="businessInfo?.active_merchant_name">
      Please reach out to {{ businessInfo?.active_merchant_name }} if you need to pay.
    </ng-container>
  </div>

  <bey-sign-up-callout
    header="Is sending and receiving payments a hassle?"
    body="Beyonic makes it so much easier."
    linkText="Start your free trial"
    classList="my-14"
    (trialStart)="handleSignUp()"></bey-sign-up-callout>
</div>
