import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { toggleHeadlessStepperStep } from '../../../../ngrx/misc/misc.actions';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';

@Component({
  selector: 'app-nin-multiple-incorrect-attempts-dialog-content',
  templateUrl: './nin-multiple-incorrect-attempts-dialog-content.component.html',
  styleUrls: ['./nin-multiple-incorrect-attempts-dialog-content.component.scss'],
  standalone: true,
  imports: [BeyButtonComponent],
})
export class NINMultipleIncorrectAttemptsDialogContentComponent implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    public dialogReference: MatDialogRef<NINMultipleIncorrectAttemptsDialogContentComponent>,
    private store: Store
  ) {}

  ngOnInit(): void {}

  onButtonClick(): void {
    this.router.navigate(['/login']);
    this.dialog.closeAll();
    this.store.dispatch(toggleHeadlessStepperStep({ currentStep: 0 }));
  }
}
