<div class="w-full ease-in-out duration-500 text-gray-400 focus-within:text-bey-black">
  <label
    *ngIf="label && !labelMargin"
    class="block text-[#242729] text-sm font-medium mb-1"
    [ngClass]="classList?.['label']"
    >{{ label }}</label
  >
  <label *ngIf="labelMargin" class="h-[20px] block"></label>
  <ng-container [ngSwitch]="type">
    <textarea
      *ngSwitchCase="'textarea'"
      rows="4"
      [placeholder]="placeholder"
      [name]="name"
      [disabled]="disabled"
      class="w-full block outline-none p-2 text-gray-800 disabled:text-gray-300 placeholder:text-gray-300 text-sm transition ease-in-out duration-500 resize-none"
      [(ngModel)]="value"
      [required]="required"
      (blur)="onBlur()"
      [ngClass]="{
        'bg-white border-solid focus:border-yellow-800 hover:border-gray-800 disabled:border-gray-300': !readonly,
        'border-0 border-b focus:border-b-[1.5px] hover:border-b-[1.5px] focus:-mb-[0.5px] hover:-mb-[0.5px] disabled:border-b':
          underlined && !readonly,
        'border rounded-md focus:border-[1.5px] hover:border-[1.5px] focus:-m-[0.5px] hover:-m-[0.5px] disabled:border':
          !underlined && !readonly,
        'bg-transparent': readonly || transparent,
        'border-gray-800': !displayError && !!value,
        'border-gray-300': !displayError && !value,
        'border-bey-red': displayError && !warning,
        'border-orange-500': displayError && warning
      }"
      [readOnly]="readonly || readonlyNoFeedback"
      [autofocus]="autofocus"
      (keyup.enter)="enterClickHandler()">
    </textarea>

    <div
      *ngSwitchCase="'amount'"
      class="relative flex w-full border-solid focus-within:border-gray-500 transition ease-in-out duration-500 overflow-hidden"
      [ngClass]="{
        'bg-white border-solid [&:has(:focus)]:border-yellow-800 hover:border-gray-800 [&:has(:disabled)]:border-gray-300':
          !readonly,
        'border-0 border-b [&:has(:focus)]:border-b-[1.5px] hover:border-b-[1.5px] [&:has(:focus)]:-mb-[0.5px] hover:-mb-[0.5px] [&:has(:disabled)]:border-b':
          underlined && !readonly,
        'border rounded-md [&:has(:focus)]:border-[1.5px] hover:border-[1.5px] [&:has(:focus)]:-m-[0.5px] hover:-m-[0.5px] [&:has(:disabled)]:border':
          !underlined && !readonly,
        'bg-transparent': readonly || transparent,
        'border-gray-800': !displayError,
        'border-bey-red': displayError && !warning,
        'border-orange-500': displayError && warning
      }">
      <ng-content select="[prepend]"></ng-content>
      <input
        type="text"
        pattern="[0-9]*"
        inputmode="numeric"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [name]="name"
        class="outline-none text-gray-800 disabled:text-gray-300 placeholder:text-gray-300 text-sm w-full p-2"
        [(ngModel)]="value"
        [required]="required"
        (blur)="onBlur()"
        [readOnly]="readonly || readonlyNoFeedback"
        [ngClass]="{
          'bg-transparent': readonly || transparent,
        }"
        [autofocus]="autofocus"
        (keyup.enter)="enterClickHandler()"
        currencyMask
        [options]="{
          prefix: currencySymbol || '',
          align: 'left',
          thousands: ',',
          decimal: '.',
          precision: 0,
          allowNegative: false,
        }" />
      <div class="absolute right-2 top-[50%] translate-y-[-50%]">
        <ng-content select="[append]"></ng-content>
      </div>
    </div>

    <div
      *ngSwitchCase="'phone'"
      class="relative flex w-full border-solid focus-within:border-gray-500 transition ease-in-out duration-500 overflow-hidden"
      [ngClass]="{
        'bg-white border-solid [&:has(:focus)]:border-yellow-800 hover:border-gray-800 [&:has(:disabled)]:border-gray-300':
          !readonly,
        'border-0 border-b [&:has(:focus)]:border-b-[1.5px] hover:border-b-[1.5px] [&:has(:focus)]:-mb-[0.5px] hover:-mb-[0.5px] [&:has(:disabled)]:border-b':
          underlined && !readonly,
        'border rounded-md [&:has(:focus)]:border-[1.5px] hover:border-[1.5px] [&:has(:focus)]:-m-[0.5px] hover:-m-[0.5px] [&:has(:disabled)]:border':
          !underlined && !readonly,
        'bg-transparent': readonly || transparent,
        'border-gray-800': !displayError,
        'border-bey-red': displayError && !warning,
        'border-orange-500': displayError && warning
      }">
      <ng-content select="[prepend]"></ng-content>
      <input
        type="text"
        pattern="[0-9]*"
        inputmode="numeric"
        PhoneReformat
        [disabled]="disabled"
        [placeholder]="placeholder"
        [name]="name"
        class="outline-none text-gray-800 disabled:text-gray-300 placeholder:text-gray-300 text-sm w-full p-2"
        [(ngModel)]="value"
        [required]="required"
        (blur)="onBlur()"
        [readOnly]="readonly || readonlyNoFeedback"
        [ngClass]="{
          'bg-transparent': readonly || transparent,
        }"
        [autofocus]="autofocus"
        [control]="control"
        (keyup.enter)="enterClickHandler()" />
      <div class="absolute right-2 top-[50%] translate-y-[-50%]">
        <ng-content select="[append]"></ng-content>
      </div>
    </div>

    <div
      *ngSwitchDefault
      class="relative flex w-full transition ease-in-out duration-500 overflow-hidden"
      [ngClass]="{
        'bg-white border-solid [&:has(:focus)]:border-yellow-800 hover:border-gray-800 [&:has(:disabled)]:border-gray-300':
          !readonly,
        'border-0 border-b [&:has(:focus)]:border-b-[1.5px] hover:border-b-[1.5px] [&:has(:focus)]:-mb-[0.5px] hover:-mb-[0.5px] [&:has(:disabled)]:border-b':
          underlined && !readonly,
        'border rounded-md [&:has(:focus)]:border-[1.5px] hover:border-[1.5px] [&:has(:focus)]:-m-[0.5px] hover:-m-[0.5px] [&:has(:disabled)]:border':
          !underlined && !readonly,
        'bg-transparent': readonly || transparent,
        'border-gray-800': !displayError,
        'border-bey-red': displayError && !warning,
        'border-orange-500': displayError && warning
      }">
      <ng-content select="[prepend]"></ng-content>
      <input
        (blur)="onBlur()"
        [placeholder]="placeholder"
        [type]="type"
        [name]="name"
        class="outline-none text-gray-800 placeholder:text-gray-300 text-sm w-full p-2"
        [(ngModel)]="value"
        [disabled]="disabled"
        [required]="required"
        [readOnly]="readonly || readonlyNoFeedback"
        [ngClass]="{
          'bg-transparent': readonly || transparent,
        }"
        [autofocus]="autofocus"
        (keyup.enter)="enterClickHandler()"
        (keydown.arrowup)="(false)"
        (keydown.arrowdown)="(false)" />
      <div class="absolute right-2 top-[50%] translate-y-[-50%]">
        <ng-content select="[append]"></ng-content>
      </div>
    </div>
  </ng-container>

  <div class="min-h-[21px] mt-1" *ngIf="isLoading">
    <spinner [diameter]="15"></spinner>
  </div>
  <bey-field-error-message
    *ngIf="!(hideValidationFeedback || isLoading)"
    [displayError]="displayError"
    [errorMessage]="errorMessage"
    [warning]="warning">
  </bey-field-error-message>
</div>
