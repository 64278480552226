export default {
  // CREATE KYC
  CREATE_USER_KYC_START: 'CREATE_USER_KYC_START',
  CREATE_USER_KYC_SUCCESS: 'CREATE_USER_KYC_SUCCESS',
  CREATE_USER_KYC_FAILURE: 'CREATE_USER_KYC_FAILURE',

  // GET KYC
  GET_USER_KYC_START: 'GET_USER_KYC_START',
  GET_USER_KYC_SUCCESS: 'GET_USER_KYC_SUCCESS',
  GET_USER_KYC_FAILURE: 'GET_USER_KYC_FAILURE',

  // GET ACCEPTED ID PER COUNTRY
  GET_COUNTRY_ID_DOCS_START: 'GET_COUNTRY_ID_DOCS_START',
  GET_COUNTRY_ID_DOCS_SUCCESS: 'GET_COUNTRY_ID_DOCS_SUCCESS',
  GET_COUNTRY_ID_DOCS_FAILURE: 'GET_COUNTRY_ID_DOCS_FAILURE',
};
