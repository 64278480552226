import { Component, OnDestroy, OnInit } from '@angular/core';
import { MiscService } from 'src/app/ngrx/misc/misc.service';
import { createBusinessProfileStart } from '../../../../../../ngrx/user/user.actions';
import { Store } from '@ngrx/store';
import {
  selectCreateBusinessProfileData,
  selectCreateBusinessProfileIsLoading,
} from '../../../../../../ngrx/user/user.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CreateBusinessProfileRequestBody } from '../../../../../../ngrx/user/user.interfaces';
import { AsyncPipe, NgIf } from '@angular/common';
import { BeyButtonComponent } from '../../../bey-button/bey-button.component';
import { BeyNumberedStepCircleComponent } from '../../../bey-numbered-step-circle/bey-numbered-step-circle.component';
import { selectUserKycDetails } from '../../../../../../ngrx/KYC/kyc.selectors';

@Component({
  selector: 'bey-continue-unverified-drawer-contents',
  templateUrl: './continue-unverified-drawer-content.component.html',
  styleUrls: ['./continue-unverified-drawer-content.component.scss'],
  standalone: true,
  imports: [BeyNumberedStepCircleComponent, BeyButtonComponent, AsyncPipe, NgIf],
})
export class ContinueUnverifiedDrawerContentComponent implements OnInit, OnDestroy {
  stop$: Subject<void> = new Subject<void>();
  businessProfileIsLoading$: Observable<boolean>;

  businessProfileData: CreateBusinessProfileRequestBody;
  isUganda: boolean = false; //todo remove this temp once we update the copy of the page

  constructor(public miscService: MiscService, private store: Store) {
    this.businessProfileIsLoading$ = store.select(selectCreateBusinessProfileIsLoading);
  }

  ngOnInit(): void {
    this.store
      .select(selectCreateBusinessProfileData)
      .pipe(takeUntil(this.stop$))
      .subscribe((v) => {
        if (v) {
          this.businessProfileData = v;
        }
      });

    this.store
      .select(selectUserKycDetails)
      .pipe(takeUntil(this.stop$))
      .subscribe((v) => {
        if (v) {
          this.isUganda = v.country === 'Uganda';
        }
      });
  }

  continueUnverified() {
    if (this.businessProfileData) {
      this.store.dispatch(
        createBusinessProfileStart({
          payload: {
            ...this.businessProfileData,
            unverified: true, // This will ensure that we will proceed the last step and show operation successful
          },
        })
      );
    } else {
      console.error('Business profile undefined.');
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
