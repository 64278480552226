import { Component, OnInit } from '@angular/core';
import { toggleHeadlessStepperStep } from '../../../../ngrx/misc/misc.actions';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { BeyNavigationFooterComponent } from '../../../shared/pages/bey-link-interaction/components/bey-navigation-footer/bey-navigation-footer.component';
import { BeyLinkInteractionComponent } from '../../../shared/pages/bey-link-interaction/bey-link-interaction.component';
import { BeyPreviewHeaderComponent } from '../../../shared/pages/bey-link-interaction/components/bey-preview-header/bey-preview-header.component';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  standalone: true,
  imports: [BeyPreviewHeaderComponent, BeyLinkInteractionComponent, BeyNavigationFooterComponent],
})
export class PreviewComponent implements OnInit {
  constructor(private store: Store, public location: Location) {}

  ngOnInit(): void {}

  togglePreviewStep(currentStep: number): void {
    this.store.dispatch(toggleHeadlessStepperStep({ currentStep }));
  }
}
