export const membersTypes = {
  SET_INVITATION: 'SET_INVITATION',
  GET_TEAM_MEMBERS_START: 'GET_TEAM_MEMBERS_START',
  GET_TEAM_MEMBERS_SUCCESS: 'GET_TEAM_MEMBERS_SUCCESS',
  GET_TEAM_MEMBERS_FAILURE: 'GET_TEAM_MEMBERS_FAILURE',
  GET_INVITED_MEMBERS_START: 'GET_INVITED_MEMBERS_START',
  GET_INVITED_MEMBERS_SUCCESS: 'GET_INVITED_MEMBERS_SUCCESS',
  GET_INVITED_MEMBERS_FAILURE: 'GET_INVITED_MEMBERS_FAILURE',

  ON_BOARD_MEMBER_START: 'ON_BOARD_MEMBER_START',
  ON_BOARD_MEMBER_SUCCESS: 'ON_BOARD_MEMBER_SUCCESS',
  ON_BOARD_MEMBER_FAILURE: 'ON_BOARD_MEMBER_FAILURE',

  INVITE_MEMBER_START: 'INVITE_MEMBER_START',
  INVITE_MEMBER_SUCCESS: 'INVITE_MEMBER_SUCCESS',
  INVITE_MEMBER_FAILURE: 'INVITE_MEMBER_FAILURE',
  RESEND_INVITE_START: 'RESEND_INVITE_START',
  RESEND_INVITE_SUCCESS: 'RESEND_INVITE_SUCCESS',
  RESEND_INVITE_FAILURE: 'RESEND_INVITE_FAILURE',
  CANCEL_INVITE_START: 'CANCEL_INVITE_START',
  CANCEL_INVITE_SUCCESS: 'CANCEL_INVITE_SUCCESS',
  CANCEL_INVITE_FAILURE: 'CANCEL_INVITE_FAILURE',
};
