import { createReducer, on } from '@ngrx/store';
import { KycStateInterface } from './kycStateInterface';
import { getUserWalletSuccess } from '../wallets/wallets.actions';
import { loginSuccess } from '../auth/auth.actions';
import {
  createUserKycFailure,
  createUserKycStart,
  createUserKycSuccess,
  getCountryUserIdDocsFailure,
  getCountryUserIdDocsStart,
  getCountryUserIdDocsSuccess,
  getUserKycFailure,
  getUserKycStart,
  getUserKycSuccess,
} from './kyc.actions';
import { produce } from 'immer';

const initialState: KycStateInterface = {
  kycInfo: null,
  pendingKyc: false,
  acceptedKycDocs: null,
  isLoading: {
    kycCreate: false,
    kycFetch: false,
    kycComments: false,
    acceptedKycDocs: false,
  },
};

export const kycReducer = createReducer(
  initialState,
  // create kyc profile
  on(
    createUserKycStart,
    produce((state) => {
      state.isLoading.kycCreate = true;
    })
  ),
  on(
    createUserKycSuccess,
    produce((state, { payload }) => {
      state.kycInfo = payload;
      state.isLoading.kycCreate = false;
    })
  ),
  on(
    createUserKycFailure,
    produce((state) => {
      state.isLoading.kycCreate = false;
    })
  ),

  //AUTH ACTIONS
  on(
    loginSuccess,
    produce((state, { payload }) => {
      state.kycInfo = payload.currentUser.profile;
    })
  ),
  // GET KYC
  on(
    getUserKycStart,
    produce((state) => {
      state.isLoading.kycFetch = true;
    })
  ),
  on(
    getUserKycSuccess,
    produce((state, { payload }) => {
      let pendingKyc: boolean = payload.status === 'received' || false;

      state.isLoading.kycFetch = false;
      state.kycInfo = payload;
      state.pendingKyc = pendingKyc;
    })
  ),
  on(
    getUserKycFailure,
    produce((state) => {
      state.isLoading.kycFetch = false;
    })
  ),

  //WALLET ACTIONS
  on(
    getUserWalletSuccess,
    produce((state, { payload }) => {
      state.pendingKyc = !!!payload?.['id'];
    })
  ),

  // Get country user ID docs
  on(
    getCountryUserIdDocsStart,
    produce((state) => {
      state.isLoading.acceptedKycDocs = true;
    })
  ),
  on(
    getCountryUserIdDocsSuccess,
    produce((state, { payload }) => {
      state.acceptedKycDocs = payload;
      state.isLoading.acceptedKycDocs = false;
    })
  ),
  on(
    getCountryUserIdDocsFailure,
    produce((state) => {
      state.isLoading.acceptedKycDocs = false;
    })
  )
);
