<div class="px-6 text-gray-500 pb-6" [ngClass]="{ 'lg:px-0': !isPreview }">
  <h3 class="pt-4 pb-1.5 break-words font-bold">{{ linkInfo?.name }}</h3>
  <p>
    {{ linkInfo?.description }}
  </p>

  <form (ngSubmit)="handleSubmit()" [formGroup]="formData">
    <div class="mt-5" *ngIf="linkInfo?.can_specify_quantity; else amtOnly">
      <bey-total-amount
        [unitAmount]="linkInfo?.unit_amount"
        [quantity]="qty"
        formControlName="unit_amount"
        [isLoading]="formData.pending"
        getElementId
        [control]="formData.controls['unit_amount']">
        <bey-quantity-selector
          label="Quantity"
          formControlName="quantity"
          getElementId
          [control]="formData.controls['quantity']"></bey-quantity-selector>
      </bey-total-amount>
    </div>
    <ng-template #amtOnly>
      <div class="py-5" *ngIf="linkInfo?.unit_amount">
        <bey-total-amount
          [unitAmount]="linkInfo?.unit_amount"
          [currency]="linkInfo?.unit_amount_currency"
          [quantity]="qty"
          formControlName="unit_amount"
          [isLoading]="formData.pending">
        </bey-total-amount>
      </div>
    </ng-template>

    <bey-button
      [disabled]="formData.pending && (linkInfo.can_specify_quantity || !!linkInfo.unit_amount)"
      classList="mt-6 w-full"
      [submit]="true"
      getElementId
      getElementIdDescription="proceed">
      <span placeholder class="font-medium">Proceed to pay</span>
    </bey-button>
  </form>
</div>
