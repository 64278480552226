import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TransactionsUtilityService } from '../services/transactions-utility.service';

@Pipe({
  name: 'calculateFx',
  standalone: true,
})
export class CalculateFxPipe implements PipeTransform, OnDestroy {
  subs$: Subscription = new Subscription();

  constructor(private store$: Store, private transactionsUtility: TransactionsUtilityService) {}
  transform(value: number, renderForMerchant: boolean): number {
    return this.transactionsUtility.calculateFx(value, renderForMerchant);
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
