import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { combineLatestWith, Observable, skipWhile } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectUserKycDetails } from '../../../ngrx/KYC/kyc.selectors';
import { getUserKycStart } from '../../../ngrx/KYC/kyc.actions';
import { map } from 'rxjs/operators';
import { BeyProfileUnderReviewDialogComponent } from '../../shared/components/bey-profile-under-review-dialog/bey-profile-under-review-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { selectUserDetails } from '../../../ngrx/user/user.selectors';
//todo test creating an account
@Injectable({
  providedIn: 'root',
})
export class CanTransactGuard implements CanLoad {
  constructor(private store: Store, private router: Router, private dialog: MatDialog) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // check here if the user have their KYC approved and provided partial KYB
    // ASYNC GUARD

    return this.store.pipe(
      select(selectUserKycDetails),
      skipWhile((kycInfo) => {
        if (!kycInfo) {
          this.store.dispatch(getUserKycStart());
        }
        return !kycInfo;
      }),
      combineLatestWith(this.store.select(selectUserDetails)),
      map(([kycInfo, user]) => {
        // First business of businesses FOR NOW
        const { businesses } = user;
        // IF the user is not approved or doesn't have partial business info
        // if (kycInfo.status !== 'approved' || !kycInfo.business) {
        if (kycInfo.status !== 'approved' || !businesses[0]?.name) {
          if (segments.length === 1) {
            // Only show the dialog for user who finished their profiles
            if (kycInfo.status === 'received' && businesses[0]?.name) {
              // if (kycInfo.status === 'received' && kycInfo.business) {
              this.dialog.open(BeyProfileUnderReviewDialogComponent);
            }
            // This only fires if the user entered the page into the URL bar
            if (this.router?.['navigationId'] === 1) {
              this.router.navigate(['/']);
            }
          } else {
            // This fires only if the user was trying to access a child page of the route
            this.router.navigate(['/']);
          }
          return false;
        }
        return true;
      })
    );
  }
}
