import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  private activePortal: Subject<TemplatePortal> = new Subject<TemplatePortal>();
  readonly portal$: Observable<TemplatePortal> = this.activePortal.asObservable();

  constructor() {}

  setPortal(portal: TemplatePortal) {
    this.activePortal.next(portal);
  }
}
