import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { selectUserDetails } from '../user/user.selectors';
import { Subscription } from 'rxjs';
import {
  CreatePersonalProfileBody,
  CreatePersonalProfileResponse,
  GetCountryUserIdDocsResponse,
} from './kycStateInterface';

@Injectable({
  providedIn: 'root',
})
export class KycService implements OnDestroy {
  profileId: number;
  sub$: Subscription;

  // todo remove store usage here
  constructor(private http: HttpClient, private store: Store) {
    this.sub$ = this.store.select(selectUserDetails).subscribe((v) => (this.profileId = v?.profile_id));
  }

  getPersonalDetails() {
    return this.http.get<CreatePersonalProfileResponse>(`${environment.API_URL}users/profile/${this.profileId}/`);
  }

  savePersonalData(requestBody: CreatePersonalProfileBody) {
    const _payload = {
      ...requestBody,
    };

    const newRequestBody = new FormData();
    Object.keys(_payload).forEach((key) => newRequestBody.append(key, requestBody[key]));

    return this.http.patch<CreatePersonalProfileResponse>(
      `${environment.API_URL}users/profile/${this.profileId}/`,
      newRequestBody
    );
  }

  getIdentificationDocs(countryIsoCode?: string) {
    let params = new HttpParams();
    // country is optional
    if (countryIsoCode) {
      params = params.append('country', countryIsoCode);
    }

    return this.http.get<GetCountryUserIdDocsResponse>(
      `${environment.API_URL}users/country-user-identification-documents/`,
      { params }
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
