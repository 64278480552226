import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';

const selectKyc = (state: AppState) => state.kyc;

// First level
const selectIsLoading = createSelector(selectKyc, (state) => state.isLoading);
export const selectUserKycDetails = createSelector(selectKyc, (state) => state?.kycInfo);
export const selectPendingKyc = createSelector(selectKyc, (state) => state?.pendingKyc);

// Second Level
export const selectCreateKycIsLoading = createSelector(selectIsLoading, (state) => state.kycCreate);
export const selectGetUserKycIsLoading = createSelector(selectIsLoading, (state) => state.kycFetch);
export const selectAcceptedKycDocs = createSelector(selectKyc, (state) => state.acceptedKycDocs);
export const selectIsLoadingAcceptedKycDocs = createSelector(selectIsLoading, (state) => state.acceptedKycDocs);
