<div class="lg:pb-10">
  <bey-with-initials-header [name]="businessInfo?.active_merchant_name"></bey-with-initials-header>
  <div
    class="hidden mt-[-55px] relative left-0 lg:flex text-gray-800 bg-gray-50 w-[110px] h-[110px] border-gray-100 border-[7px] rounded-full justify-center items-center mx-auto">
    <span class="font-bold text-5xl mt-3">{{ businessInfo?.active_merchant_name | nameInitials }}</span>
  </div>

  <div class="lg:max-w-xl mx-auto">
    <div
      class="calc-height w-full bg-white pt-4 lg:!h-auto lg:shadow-lg lg:p-3 lg:rounded-bl-2xl lg:rounded-br-2xl lg:pt-24 lg:mt-[-90px]">
      <div class="text-gray-800 text-base pt-4 font-medium lg:mb-6 lg:text-lg lg:text-center px-3">
        Send to {{ businessInfo?.active_merchant_name }}
      </div>

      <bey-create-collection-form
        [businessInfoStaticLink]="businessInfo"
        [retriedCollectionInfo]="retriedCollectionInfo"
        collectionType="stable"
        [showFormTitle]="false"></bey-create-collection-form>

      <div class="text-center text-blue-400 text-lg font-semibold pb-4 lg:mb-8" *ngIf="merchantUssdCode">
        <div class="pb-4">or</div>
        <a [href]="'tel:' + merchantUssdCode">Dial {{ merchantUssdCode }} to pay</a>
      </div>
    </div>
  </div>
</div>
