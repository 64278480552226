import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectTokens } from '../../../ngrx/auth/auth.selectors';
import { map } from 'rxjs/operators';

/*********
 *  This guard used to prevent chunk loading if the user is not authorized
 */
@Injectable({
  providedIn: 'root',
})
export class UserAuthGuard implements CanLoad {
  constructor(private store: Store, private router: Router) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // This is empty in case the URL entered doesn't match whatever we have on the routing module
    if (!route.path) {
      this.router.navigate(['/error']);
      return false;
    }

    return this.store.pipe(
      select(selectTokens),
      map((tokens) => {
        if (!tokens) {
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      })
    );
  }
}
