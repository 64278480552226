import { Component, Input, OnInit } from '@angular/core';
import { fadeOut } from '../../../../../assets/utility/transitions';
import { NgIf } from '@angular/common';

@Component({
  selector: 'bey-feature-info-card',
  templateUrl: './bey-feature-info-card.component.html',
  styleUrls: ['./bey-feature-info-card.component.scss'],
  animations: [fadeOut],
  standalone: true,
  imports: [NgIf],
})
export class BeyFeatureInfoCardComponent implements OnInit {
  constructor() {}

  /****
   * Card header text
   */
  @Input()
  header: string;

  /****
   * Card body text
   */
  @Input()
  body: string;

  hidden: boolean = false;

  ngOnInit(): void {}

  dismiss() {
    // todo in future we should hide that forever using cookies or redux persisted state
    this.hidden = true;
  }
}
