import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js/min';

@Pipe({
  name: 'formatPhoneNumber',
  standalone: true,
})
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(value: string, internationalFormat: boolean = true): string {
    if (!value) {
      return '';
    }

    const parsedPhone = parsePhoneNumber(value);

    return internationalFormat ? parsedPhone.formatInternational() : parsedPhone.formatNational();
  }
}
