import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UpdateBeyonicLinkDialogContentComponent } from 'src/app/modules/beyonic-link/components/update-beyonic-link-dialog-content/update-beyonic-link-dialog-content.component';
import { updateBeyonicLinkStart } from 'src/app/ngrx/beyonic-link/beyonic-link.actions';
import { BeyonicLink } from 'src/app/ngrx/beyonic-link/beyonic-link.interfaces';
import { selectDrawerData } from 'src/app/ngrx/misc/misc.selectors';
import { MiscService } from 'src/app/ngrx/misc/misc.service';
import { Router } from '@angular/router';
import { NgClass } from '@angular/common';

@Component({
  selector: 'update-beyonic-links-drawer-content',
  templateUrl: './update-beyonic-links-drawer-content.component.html',
  styleUrls: ['./update-beyonic-links-drawer-content.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class UpdateBeyonicLinksDrawerContentComponent implements OnInit, OnDestroy {
  link: BeyonicLink;
  content: string;
  action: boolean;

  subs$: Subscription = new Subscription();

  constructor(
    private store$: Store,
    private miscService: MiscService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subs$.add(
      this.store$.select(selectDrawerData).subscribe((data) => {
        if (data) {
          this.link = data?.['link'];

          if (data?.['link']?.is_active) {
            this.content = 'Close link';
            this.action = false;
          } else {
            this.content = 'Restore link';
            this.action = true;
          }
        }
      })
    );
  }

  onClick(): void {
    this.dialog.open(UpdateBeyonicLinkDialogContentComponent, { data: this.link });
    this.miscService.closeDrawer();
  }

  edit(): void {
    this.router.navigate(['/links/edit/' + this.link.id]);
    this.miscService.closeDrawer();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
