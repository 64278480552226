import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/ngrx/user/user.interfaces';
import { selectUserDetails } from 'src/app/ngrx/user/user.selectors';
import { PreventDefaultEventDirective } from '../../directives/prevent-default-event.directive';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'bey-sign-up-callout',
  templateUrl: './bey-signup-callout.component.html',
  styleUrls: ['./bey-signup-callout.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, PreventDefaultEventDirective, AsyncPipe],
})
export class BeySignUpCalloutComponent implements OnInit {
  currentUser$: Observable<User>;

  constructor(private store$: Store) {}

  /****
   *  Text for the Header - Has default value
   */
  @Input()
  header: string = 'Is sending and receiving payments a hassle?';

  /****
   *  Text for the body - Has default value
   */
  @Input()
  body: string = 'Onafriq makes it so much easier.';

  /****
   *  Text for the link - Has default value
   */
  @Input()
  linkText: string = 'Start your free trial';

  /****
   *  CSS classes to override or extend for parent (wrapper div)
   */
  @Input()
  classList: string = '';

  /****
   *  Event emitted when user clicks on the start trial
   */
  @Output()
  trialStart: EventEmitter<null> = new EventEmitter<null>();

  ngOnInit(): void {
    this.currentUser$ = this.store$.select(selectUserDetails);
  }
}
