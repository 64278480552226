export const errorMessages = {
  required: '{field} is required',
  minlength: "{field} doesn't meet the required length ({actualLength}/{requiredLength})",
  maxlength: '{field} exceeds the allowed length ({requiredLength})',
  leadingSpace: "Leading space isn't allowed",
  numericValueOnly: 'Only numbers allowed',
  mustMatch: 'PINs must match',
  questionSelected: 'This security question was already selected',
  phoneNumberError: 'Invalid phone number',
  leadingZero: 'Leading zero is not allowed',
  email: 'Invalid email format',
  insufficientFunds: 'You have insufficient funds. Please top up.',
  transactionsLimitExceeded: 'Exceeds your limit. Send up to {currency} {value}',
  noAvailableTransactionValue: 'Daily limit reached. Limits reset at midnight.',
  walletLimitReached: 'Your wallet is full. Please withdraw to proceed.',
  topUpLimitExceeded: 'Exceeds your limit. Top up to {currency} {value}',
  transactionsLimitExceededCollection: 'Exceeds your limit. Collect up to {currency} {value}',
  exceedsOpenWalletLimit: 'Cannot accept this amount. Contact {activeMerchantName} for further assistance.',
  recaptcha: 'Please refresh and try again, contact support if the issue persists',
  beyonicLinkName: 'Give your link a unique name',
  amountExceedWalletLimit: 'Exceeds the limit. Max amount: {currency} {value}',
  '4DigitsError': 'Field must only contain 4 digits',
  '/^[0-9]*$/': 'Only numbers are valid in this field',
  '^((?![^A-Za-z0-9_\\s-]).)*$': 'Special characters are not allowed',
  "^((?![^A-Za-z0-9 '-.]).)*$": "Only these special characters are allowed in this field (. ' -)",
  '^((?![^A-Za-z0-9 _@.+-.,:;]).)*$': 'Only these special characters are allowed in this field (_ : , @ . + -)',
  min: 'Minimum value is {currency} {value}',
  max: 'Maximum value is {currency} {value}',
  'xb.required': '{field} is required',
  'xb.min': '{field} value is below minimum {currency} {value} limit',
  'xb.max': '{field} value exceeds transactions {currency} {value} limit',
  unsupportedTelco: 'Only {supportedTelcos} {verb} supported for {country}',
  sameFile: 'The files cannot be the same file.',
  kycDocNumInvalid: 'Invalid document number',
  duplicatePhone: 'Duplicate phone number',
  duplicatePhoneUpdate: 'Duplicate entry. This contact already exists.',
  networkError: 'Network error occurred. Please verify your internet connection.',
};
