import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectUserDetails } from 'src/app/ngrx/user/user.selectors';
import { User } from 'src/stories/User';
import { environment } from '../../../../../environments/environment';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'bey-header-with-content',
  templateUrl: './bey-header-with-content.component.html',
  styleUrls: ['./bey-header-with-content.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, AsyncPipe],
})
export class BeyHeaderWithContentComponent implements OnInit {
  userDetails$: Observable<User>;

  @Input()
  noBackground: boolean = false;

  constructor(public router: Router, private store: Store) {
    this.userDetails$ = store.select(selectUserDetails);
  }

  ngOnInit(): void {}

  onLoginClick(): void {
    if (environment.is_pay) {
      window.open(`${environment.URL}/login`, '_blank');
    } else {
      this.router.navigate(['/login']);
    }
  }
}
