import { createReducer, on } from '@ngrx/store';
import {
  cancelInviteFailure,
  cancelInviteStart,
  cancelInviteSuccess,
  getInvitedMembersFailure,
  getInvitedMembersStart,
  getInvitedMembersSuccess,
  getTeamMembersFailure,
  getTeamMembersStart,
  getTeamMembersSuccess,
  onboardMemberStart,
  onboardMemberSuccess,
  onboardMemberFailure,
  setInvitation,
  inviteMemberFailure,
  inviteMemberStart,
  inviteMemberSuccess,
  resendInviteFailure,
  resendInviteStart,
  resendInviteSuccess,
} from './members.actions';
import { MembersState } from './members.interfaces';
import { produce } from 'immer';

const initialState: MembersState = {
  members: null,
  invitations: null,
  invitation: null,
  isLoading: {
    getTeamMembers: false,
    getInvitedMembers: false,
    onboardMember: false,
    inviteMember: false,
    resendInvite: false,
    cancelInvite: false,
  },
  errorMessages: {
    getTeamMembers: '',
    getInvitedMembers: '',
    onboardMember: '',
    inviteMember: '',
    resendInvite: '',
    cancelInvite: '',
  },
};

export const membersReducer = createReducer(
  initialState,
  //----------START----------
  on(
    getTeamMembersStart,
    produce((state) => {
      state.isLoading['getTeamMembers'] = true;
    })
  ),
  on(
    getInvitedMembersStart,
    produce((state) => {
      state.isLoading['getInvitedMembers'] = true;
    })
  ),
  on(
    onboardMemberStart,
    produce((state) => {
      state.isLoading['onboardMember'] = true;
    })
  ),
  on(
    inviteMemberStart,
    produce((state) => {
      state.isLoading['inviteMember'] = true;
    })
  ),
  on(
    resendInviteStart,
    produce((state) => {
      state.isLoading['resendInvite'] = true;
    })
  ),
  on(
    cancelInviteStart,
    produce((state) => {
      state.isLoading['cancelInvite'] = true;
    })
  ),
  //----------SUCCESS----------
  on(
    getTeamMembersSuccess,
    produce((state, { payload }) => {
      state.members = payload;
      state.isLoading['getTeamMembers'] = false;
      state.errorMessages['getTeamMembers'] = '';
    })
  ),
  on(
    getInvitedMembersSuccess,
    produce((state, { payload }) => {
      state.invitations = payload;
      state.isLoading['getInvitedMembers'] = false;
      state.errorMessages['getInvitedMembers'] = '';
    })
  ),
  on(
    onboardMemberSuccess,
    produce((state) => {
      state.isLoading['onboardMember'] = false;
      state.errorMessages['onboardMember'] = '';
    })
  ),
  on(
    inviteMemberSuccess,
    produce((state) => {
      state.isLoading['inviteMember'] = false;
      state.errorMessages['inviteMember'] = '';
    })
  ),
  on(
    resendInviteSuccess,
    produce((state) => {
      state.isLoading['resendInvite'] = false;
      state.errorMessages['resendInvite'] = '';
    })
  ),
  on(
    cancelInviteSuccess,
    produce((state) => {
      state.isLoading['cancelInvite'] = false;
      state.errorMessages['cancelInvite'] = '';
    })
  ),
  //----------FAILURE----------
  on(
    getTeamMembersFailure,
    produce((state, { error }) => {
      state.isLoading['getTeamMembers'] = false;
      state.errorMessages['getTeamMembers'] = error;
    })
  ),
  on(
    getInvitedMembersFailure,
    produce((state, { error }) => {
      state.isLoading['getInvitedMembers'] = false;
      state.errorMessages['getInvitedMembers'] = error;
    })
  ),
  on(
    onboardMemberFailure,
    produce((state, { error }) => {
      state.isLoading['onboardMember'] = false;
      state.errorMessages['onboardMember'] = error;
    })
  ),
  on(
    inviteMemberFailure,
    produce((state, { error }) => {
      state.isLoading['inviteMember'] = false;
      state.errorMessages['inviteMember'] = error;
    })
  ),
  on(
    resendInviteFailure,
    produce((state, { error }) => {
      state.isLoading['resendInvite'] = false;
      state.errorMessages['resendInvite'] = error;
    })
  ),
  on(
    cancelInviteFailure,
    produce((state, { error }) => {
      state.isLoading['cancelInvite'] = false;
      state.errorMessages['cancelInvite'] = error;
    })
  ),
  //----------OTHER----------
  on(
    setInvitation,
    produce((state, { payload }) => {
      state.invitation = payload;
    })
  )
);
