import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeMinusSign',
  standalone: true,
})
export class RemoveMinusSignPipe implements PipeTransform {
  transform(value: string | number, ...args: unknown[]): string | number {
    return Math.abs(Number(value));
  }
}
