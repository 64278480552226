import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TransactionsUtilityService } from '../../services/transactions-utility.service';
import { ApplyRoundingRulesPipe } from '../../pipes/apply-rounding-rules.pipe';
import { CurrencyPipe } from '@angular/common';
import { retrieveOperatingCountry } from '../../../../../assets/utility/shared';

@Component({
  selector: 'bey-display-total-amount',
  templateUrl: './bey-display-total-amount.component.html',
  styleUrls: ['./bey-display-total-amount.component.scss'],
  standalone: true,
  imports: [CurrencyPipe, ApplyRoundingRulesPipe],
})
export class BeyDisplayTotalAmountComponent implements OnInit, OnChanges {
  /****
   * Total info
   */
  @Input()
  set totalInfo(v) {
    this._totalInfo = v;
  }

  country: string;
  currencyPipeDigitalInfo: string;
  _totalInfo: { amount: number; currency: string } = {
    amount: 0,
    currency: '',
  };

  constructor(private transactionsUtility: TransactionsUtilityService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.buildPipeInfo();
  }

  ngOnInit(): void {
    this.buildPipeInfo();
  }

  buildPipeInfo(): void {
    this.country = retrieveOperatingCountry(this._totalInfo?.currency, 'currency')?.name;
    this.currencyPipeDigitalInfo = this.transactionsUtility.retrieveCurrencyPipeDigitalInfo(this.country);
  }
}
