import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bey-exclamation-icon',
  templateUrl: './bey-exclamation-icon.component.html',
  styleUrls: ['./bey-exclamation-icon.component.scss'],
  standalone: true,
})
export class BeyExclamationIconComponent implements OnInit {
  constructor() {}
  strokeFillColor: string = '';

  ngOnInit(): void {
    switch (this.variant) {
      case 'danger':
        this.strokeFillColor = '#D90C34';
        break;
      case 'primary':
        this.strokeFillColor = '#000';
        break;
      case 'warning':
        this.strokeFillColor = '#F06800';
        break;
      case 'currentColor':
        this.strokeFillColor = 'currentColor';
        break;
    }
  }

  /*****
   * This determines the color of the icon
   * Primary ---- Black
   * Danger ---- Red
   */
  @Input()
  variant: 'primary' | 'danger' | 'warning' | 'currentColor' = 'primary';

  /*****
   *  Height of the icon default to 16px
   */
  @Input()
  height: number = 16;

  /*****
   * Width of the icon default to 16px
   */
  @Input()
  width: number = 16;

  /***
   * Icon click event emitter to parent
   */
  @Output()
  onClick: EventEmitter<null> = new EventEmitter<null>();
}
