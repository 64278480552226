export const userTypes = {
  GET_USER_DETAILS_START: 'GET_USER_DETAILS_START',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',
  GET_USER_BUSINESS_DETAILS_START: 'GET_USER_BUSINESS_DETAILS_START',
  GET_USER_BUSINESS_DETAILS_SUCCESS: 'GET_USER_BUSINESS_DETAILS_SUCCESS',
  GET_USER_BUSINESS_DETAILS_FAILURE: 'GET_USER_BUSINESS_DETAILS_FAILURE',

  CREATE_BUSINESS_PROFILE_START: 'CREATE_BUSINESS_PROFILE_START',
  CREATE_BUSINESS_PROFILE_SUCCESS: 'CREATE_BUSINESS_PROFILE_SUCCESS',
  CREATE_BUSINESS_PROFILE_FAILURE: 'CREATE_BUSINESS_PROFILE_FAILURE',
  SUBMIT_CREATE_BUSINESS_PROFILE_FORM_DATA: 'SUBMIT_CREATE_BUSINESS_PROFILE_FORM_DATA',

  GET_INDUSTRIES_START: 'GET_INDUSTRIES_START',
  GET_INDUSTRIES_SUCCESS: 'GET_INDUSTRIES_SUCCESS',
  GET_INDUSTRIES_FAILURE: 'GET_INDUSTRIES_FAILURE',

  GET_USER_BANK_ACCOUNT_START: 'GET_USER_BANK_ACCOUNT_START',
  GET_USER_BANK_ACCOUNT_SUCCESS: 'GET_USER_BANK_ACCOUNT_SUCCESS',
  GET_USER_BANK_ACCOUNT_FAILURE: 'GET_USER_BANK_ACCOUNT_FAILURE',
  UPLOAD_BUSINESS_DOCUMENTS_START: 'UPLOAD_BUSINESS_DOCUMENTS_START',
  UPLOAD_BUSINESS_DOCUMENTS_SUCCESS: 'UPLOAD_BUSINESS_DOCUMENTS_SUCCESS',
  UPLOAD_BUSINESS_DOCUMENTS_FAILURE: 'UPLOAD_BUSINESS_DOCUMENTS_FAILURE',
  CLEAR_SAVED_USER: 'CLEAR_SAVED_USER',
  CLEAR_USER_KYB_FILE_REJECTED: 'CLEAR_USER_KYB_FILE_REJECTED',
  GET_COUNTRY_DOCUMENTS: 'GET_COUNTRY_DOCUMENTS',
  SET_COUNTRY_DOCUMENTS: 'SET_COUNTRY_DOCUMENTS',
  GET_USER_KYB_START: 'GET_USER_KYB_START',
  GET_USER_KYB_SUCCESS: 'GET_USER_KYB_SUCCESS',
  GET_USER_KYB_FAILURE: 'GET_USER_KYB_FAILURE',
  SET_BUSINESS_ID: 'SET_BUSINESS_ID',
  SET_BUSINESS_PROFILE_DATA: 'SET_BUSINESS_PROFILE_DATA',
  SET_BUSINESS_PROFILE_FIRST_PART_DATA: 'SET_BUSINESS_PROFILE_FIRST_PART_DATA',
  SET_USER_BANK_ACCOUNT: 'SET_USER_BANK_ACCOUNT',
  CLEAR_USER_BANK_ACCOUNT: 'CLEAR_USER_BANK_ACCOUNT',
  CLEAR_BUSINESS_PROFILE_FIRST_PART_DATA: 'CLEAR_BUSINESS_PROFILE_FIRST_PART_DATA',
  SET_BUSINESS_PROFILE_SECOND_PART_DATA: 'SET_BUSINESS_PROFILE_SECOND_PART_DATA',
  CLEAR_BUSINESS_PROFILE_SECOND_PART_DATA: 'CLEAR_BUSINESS_PROFILE_SECOND_PART_DATA',
  SUSPEND_USER: 'SUSPEND_USER',
};
