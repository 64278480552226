<div class="bg-gray-50 flex justify-between px-4 py-5 w-full">
  <button
    (click)="stepChange.emit(0)"
    class="px-4 py-2 rounded-lg"
    data-test-id="back-btn"
    matRipple
    [ngClass]="{
      'cursor-not-allowed !text-gray-400': (currentStep$ | async) === 0,
      'text-blue-400': (currentStep$ | async) !== 0
    }">
    <span class="text-inherit inline-flex items-center font-medium"
      ><bey-chevron direction="left" strokeFillColor="currentColor"></bey-chevron> Back</span
    >
  </button>

  <button
    (click)="stepChange.emit(1)"
    class="px-4 py-2 rounded-lg"
    data-test-id="next-btn"
    matRipple
    [ngClass]="{
      'cursor-not-allowed !text-gray-400': (currentStep$ | async) === 1,
      'text-blue-400': (currentStep$ | async) !== 1
    }">
    <span class="text-inherit inline-flex items-center font-medium"
      >Next<bey-chevron strokeFillColor="currentColor" direction="right"></bey-chevron
    ></span>
  </button>
</div>
