import { ComponentFactoryResolver, Directive, ElementRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectDrawerComponent, selectDrawerIsOpen } from 'src/app/ngrx/misc/misc.selectors';
import {
  ContinueUnverifiedDrawerContentComponent,
  ShareDrawerContentComponent,
  UploadBusinessDocumentsDrawerContentComponent,
} from '../components/bey-drawer/drawer-contents';
import { UpdateBeyonicLinksDrawerContentComponent } from '../components/bey-drawer/drawer-contents/update-beyonic-links-drawer-content/update-beyonic-links-drawer-content.component';

@Directive({
  selector: '[renderDrawerContent]',
  standalone: true,
})
export class RenderDrawerContentDirective implements OnInit, OnDestroy {
  componentsList = {
    SHARE: ShareDrawerContentComponent,
    CONTINUE_UNVERIFIED: ContinueUnverifiedDrawerContentComponent,
    UPLOAD_BUSINESS_DOCUMENTS: UploadBusinessDocumentsDrawerContentComponent,
    UPDATE_BEYONIC_LINK: UpdateBeyonicLinksDrawerContentComponent,
  };

  subs$: Subscription = new Subscription();

  constructor(
    private store$: Store,
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.subs$.add(
      this.store$.select(selectDrawerIsOpen).subscribe((booleanValue) => {
        if (booleanValue) {
          let component;
          this.subs$.add(
            this.store$
              .select(selectDrawerComponent)
              .subscribe((componentName) => (component = this.componentsList[componentName]))
          );

          if (component) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            const componentRef = this.viewContainerRef.createComponent(componentFactory);

            const host = this.element.nativeElement;
            if (!host.hasChildNodes()) {
              host.insertBefore(componentRef.location.nativeElement, host.firstChild);
            } else {
              host.replaceChild(componentRef.location.nativeElement, host.firstChild);
            }
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
