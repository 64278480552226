import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { BeyButtonComponent } from '../bey-button/bey-button.component';

export interface DialogContentProps {
  title: string;
  body: string;
}

@Component({
  selector: 'bey-new-app-version-content',
  templateUrl: './bey-new-app-version-content.component.html',
  styleUrls: ['./bey-new-app-version-content.component.scss'],
  standalone: true,
  imports: [BeyButtonComponent, MatLegacyDialogModule],
})
export class BeyNewAppVersionContentComponent implements OnInit {
  contentProps: DialogContentProps;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { actionType: 'prompt' | 'unrecoverable' }
  ) {}

  ngOnInit(): void {
    if (this.data.actionType === 'prompt') {
      this.contentProps = {
        title: 'New Version Available',
        body: "An updated version of our app is ready for you! 🚀 <br />To experience the latest features and improvements, please click 'Reload'.",
      };
    } else {
      this.contentProps = {
        title: 'Unrecoverable State',
        body: 'An unrecoverable error occurred. Please refresh the page or try again later.',
      };
    }
  }

  process(): void {
    window.location.reload();
  }
}
