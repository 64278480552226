<div data-test-id="snackbar" [class]="snackbarClass">
  <img
    (click)="onCrossClick()"
    src="assets/logos/cross-white.svg"
    class="absolute right-2.5 top-2.5 cursor-pointer w-5 h-5" />
  <span data-test-id="title" class="text-white font-bold mb-3 text-sm">{{ (snackbarDetails$ | async)?.title }}</span>
  <span
    data-test-id="content"
    [ngClass]="(snackbarDetails$ | async)?.bottomContentDetails ? 'mb-3' : ''"
    class="text-white font-normal text-sm"
    >{{ (snackbarDetails$ | async)?.content }}</span
  >

  <div
    data-test-id="bottom-content"
    *ngIf="(snackbarDetails$ | async)?.bottomContentDetails"
    class="flex flex-col w-full">
    <hr class="w-full my-2" size="1px" />
    <a class="text-center" target="_blank" [href]="(snackbarDetails$ | async)?.bottomContentDetails?.route">
      <span data-test-id="bottom-content-title" class="text-base text-white mt-2 font-bold">{{
        (snackbarDetails$ | async)?.bottomContentDetails?.title
      }}</span>
    </a>
  </div>
</div>
