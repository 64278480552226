import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { EMPTY, of as observableOf, skipWhile } from 'rxjs';
import { catchError, map, switchMap, mergeMap, tap } from 'rxjs/operators';
import { BeyToastService } from '../../modules/shared/services/bey-toast.service';
import { UserService } from './user.service';
import * as userActions from './user.actions';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MiscService } from '../misc/misc.service';
import { toggleHeadlessStepperStep } from '../misc/misc.actions';
import { getUserBusinessDocumentsResponse } from './user.interfaces';
import { setBusinessProfileData } from './user.actions';
import { empty } from '../auth/auth.actions';
import { event, setUserVars } from '@fullstory/browser';
import { Store } from '@ngrx/store';
import { selectUserKycDetails } from '../KYC/kyc.selectors';

@Injectable()
export class UserEffects {
  constructor(
    private userService: UserService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private dialog: MatDialog,
    private miscService: MiscService,
    private store: Store
  ) {}

  getUserDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getUserDetailsStart),
      switchMap(({ payload }) =>
        this.userService.getUserDetails(payload).pipe(
          map((data: any) => userActions.getUserDetailsSuccess({ payload: data })),
          catchError((error) => {
            if (error.status != 401)
              this.toast.open(
                'Something went wrong, please refresh the page',
                'error',
                'Refresh',
                () => location.reload(),
                false,
                true
              );
            return observableOf(userActions.getUserDetailsFailure(error));
          })
        )
      )
    )
  );

  getUserBusinessDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getUserBusinessDetailsStart),
      switchMap(({ payload }) =>
        this.userService.getUserBusinessDetails(payload).pipe(
          map((data: any) => userActions.getUserBusinessDetailsSuccess({ payload: data })),
          catchError((error) => observableOf(userActions.getUserBusinessDetailsFailure(error)))
        )
      )
    )
  );

  createBusinessProfileEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.createBusinessProfileStart),
      switchMap(({ payload }) =>
        this.userService.createBusinessProfile(payload).pipe(
          mergeMap((businessProfile) => {
            let actions = [];
            const { id } = businessProfile;
            // todo remove this if no required because we're getting business ID on KYC
            actions.push(userActions.setBusinessId({ payload: { id } }));
            // stop the loading states
            actions.push(userActions.createBusinessProfileSuccess({ payload: businessProfile }));

            if (payload.unverified) {
              //  we jump to the step that contains the successful operation for unverified businesses
              actions.push(toggleHeadlessStepperStep({ currentStep: 4 }));

              // reset the saved profile from the sign-up process
              actions.push(userActions.setBusinessProfileData({ payload: null }));
              // close the drawer if it's open
              this.miscService.closeDrawer();
            } else {
              // Submit files
              actions.push(userActions.submitBusinessProfileFormData());
            }

            return actions;
          }),
          catchError((error) => {
            this.toast.open(error.error?.detail, 'error');
            return observableOf(userActions.createBusinessProfileFailure(error));
          })
        )
      )
    )
  );

  onSubmitBusinessProfileFormData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.submitBusinessProfileFormData),
      switchMap(() =>
        this.userService.createBusinessDocs().pipe(
          mergeMap((data: any) => {
            const actions = [];
            //  increase the counter to go to the last page of the stepper
            actions.push(toggleHeadlessStepperStep({ currentStep: null }));
            // reset the saved profile from the sign-up process
            actions.push(userActions.setBusinessProfileData({ payload: null }));

            return actions;
          }),
          catchError((error) => {
            console.error(error);
            return EMPTY;
          })
        )
      )
    )
  );

  onUploadBusinessDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.uploadBusinessDocumentsStart),
      switchMap(() =>
        this.userService.createBusinessDocs().pipe(
          mergeMap(() => {
            this.router.navigate(['/business-documents/success'], { state: { access: true } });
            return [userActions.getUserKybStart(), userActions.uploadBusinessDocumentsSuccess()];
          }),
          catchError((error) => observableOf(userActions.uploadBusinessDocumentsFailure(error)))
        )
      )
    )
  );

  getIndustriesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getIndustriesStart),
      switchMap(() =>
        this.userService.getIndustries().pipe(
          map((data: any) => userActions.getIndustriesSuccess({ payload: data })),
          catchError((error) => observableOf(userActions.getIndustriesFailure(error)))
        )
      )
    )
  );

  getUserCountryDocumentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getCountryDocuments),
      mergeMap(
        () => this.userService.getCountryDocuments().pipe(map((data: any) => userActions.setCountryDocuments(data)))
        // todo catch error here
      )
    )
  );

  getUserBankAccountEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getUserBankAccountStart),
      switchMap(() =>
        this.userService.getUserBankAccount().pipe(
          map((data) => userActions.getUserBankAccountSuccess({ payload: data.results[0]?.contact })),
          catchError((error) => observableOf(userActions.getUserBankAccountFailure(error)))
        )
      )
    )
  );

  suspendUserEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.suspendUser),
        tap(() => this.router.navigate(['/suspended'], { state: { access: true } }))
      ),
    { dispatch: false }
  );

  getUserKybDocsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getUserKybStart),
      mergeMap(() =>
        this.userService
          .getUserBusinessDocuments()
          .pipe(map((data: getUserBusinessDocumentsResponse) => userActions.getUserKybSuccess({ payload: data })))
      )
    )
  );

  onUserRegistrationKybStoreEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setBusinessProfileData),
      map(({ payload }) => {
        if (payload) {
          return toggleHeadlessStepperStep({ currentStep: 1 });
        }

        return empty();
      })
    )
  );

  kybSubmitSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.createBusinessProfileSuccess),
        tap(({ payload: businessInfo }) => {
          const {
            id,
            name,
            created: created_at,
            user: { id: user_id, first_name, last_name },
          } = businessInfo;

          // send an FS sign up KYB submit event here
          event('KYB_CREATED', {
            businessName: name,
            created_at,
            id,
            userInfo: {
              id: user_id,
              fullName: `${first_name} ${last_name}`,
            },
          });
        })
      ),
    { dispatch: false }
  );

  businessDetailsEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.getUserBusinessDetailsSuccess),
        concatLatestFrom(() => this.store.select(selectUserKycDetails)),
        tap(([{ payload: businessDetails }, kycInfo]) => {
          let businessStatus = 'other';
          /***
           * Verified: KYC + KYB verified.
           * Unverified: KYC verified.
           * Other: KYC submitted, under review, or rejected.
           */

          if (kycInfo && businessDetails) {
            if (businessDetails.status === 'verified' && kycInfo.status === 'approved') {
              businessStatus = 'verified';
            } else if (kycInfo?.status === 'approved') {
              businessStatus = 'unverified';
            }

            setUserVars({
              businessStatus,
            });
          }
        })
      ),
    { dispatch: false }
  );
}
