import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { MembersService } from './members.service';
import {
  cancelInviteFailure,
  cancelInviteStart,
  cancelInviteSuccess,
  getInvitedMembersFailure,
  getInvitedMembersStart,
  getInvitedMembersSuccess,
  getTeamMembersFailure,
  getTeamMembersStart,
  getTeamMembersSuccess,
  onboardMemberStart,
  onboardMemberSuccess,
  onboardMemberFailure,
  inviteMemberFailure,
  inviteMemberStart,
  inviteMemberSuccess,
  resendInviteFailure,
  resendInviteStart,
  resendInviteSuccess,
} from './members.actions';
import { sendVerificationCodeStart } from '../auth/auth.actions';
import { Router } from '@angular/router';
import { BeyToastService } from 'src/app/modules/shared/services/bey-toast.service';

@Injectable()
export class MembersEffects {
  constructor(
    private multiUserService: MembersService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService
  ) {}

  onboardMemberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onboardMemberStart),
      switchMap(({ payload }) =>
        this.multiUserService.onboardMember(payload).pipe(
          mergeMap(() => {
            let { phone, password } = payload;
            this.router.navigate(['/accept-invite/phone-verification'], {
              state: { phone, pin: password },
            });
            return [onboardMemberSuccess(), sendVerificationCodeStart({ payload: { phone } })];
          }),
          catchError((error) => {
            console.error(error);
            this.toast.open(
              error.error.phone ? 'Phone Number already exists.' : 'Something went wrong, please try again'
            );

            return observableOf(onboardMemberFailure(error));
          })
        )
      )
    )
  );

  getTeamMembersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTeamMembersStart),
      switchMap(({ payload: { id } }) =>
        this.multiUserService.getTeamMembers(id).pipe(
          map((payload) => getTeamMembersSuccess({ payload })),
          catchError((error) => {
            console.error(error);
            return observableOf(getTeamMembersFailure(error));
          })
        )
      )
    )
  );

  getInvitedMembersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInvitedMembersStart),
      switchMap(() =>
        this.multiUserService.getInvitedMembers().pipe(
          map(({ results }) => getInvitedMembersSuccess({ payload: results })),
          catchError((error) => {
            console.error(error);
            return observableOf(getInvitedMembersFailure(error));
          })
        )
      )
    )
  );

  inviteMemberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(inviteMemberStart),
      switchMap(({ payload }) =>
        this.multiUserService.inviteMember(payload).pipe(
          switchMap(() => [inviteMemberSuccess(), getInvitedMembersStart()]),
          catchError((error) => {
            console.error(error);
            return observableOf(inviteMemberFailure(error));
          })
        )
      )
    )
  );

  resendInviteEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendInviteStart),
      switchMap(({ payload: { id } }) =>
        this.multiUserService.resendInvite(id).pipe(
          map(() => resendInviteSuccess()),
          catchError((error) => {
            console.error(error);
            return observableOf(resendInviteFailure(error));
          })
        )
      )
    )
  );

  cancelInviterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelInviteStart),
      switchMap(({ payload: { id } }) =>
        this.multiUserService.cancelInvite(id).pipe(
          switchMap(() => [cancelInviteSuccess(), getInvitedMembersStart()]),
          catchError((error) => {
            console.error(error);
            return observableOf(cancelInviteFailure(error));
          })
        )
      )
    )
  );
}
