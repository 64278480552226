<div class="flex flex-col items-center w-full py-3">
  <span class="text-gray-800 font-medium text-2xl mb-4">Do you want to log off?</span>
  <span class="font-normal text-base text-gray-800 mb-3 text-center"
    >If you do nothing, your account will be <br />
    logged off automatically in
  </span>
  <span class="font-medium text-base text-gray-800 mb-3">{{ data.timeLeft }} seconds</span>
  <div mat-dialog-actions>
    <bey-button
      classList="mr-6"
      placeholder="Cancel"
      color="dark-blue-outlined"
      (onClick)="_stay()"
      [small]="true"
      getElementId
      getElementIdDescription="Cancel"></bey-button>
    <bey-button
      placeholder="Log off"
      color="yellow"
      [small]="true"
      (onClick)="_logout()"
      getElementId
      getElementIdDescription="Log_off"></bey-button>
  </div>
</div>
