import { Component, OnInit, EventEmitter, Input, Output, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogModule,
} from '@angular/material/legacy-dialog';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { GetElementIdDirective } from '../../../shared/directives/get-element-id.directive';

@Component({
  selector: 'bey-session-idle-modal',
  templateUrl: './bey-session-idle-modal.component.html',
  styleUrls: ['./bey-session-idle-modal.component.scss'],
  standalone: true,
  imports: [MatLegacyDialogModule, BeyButtonComponent, GetElementIdDirective],
})
export class BeySessionIdleModalComponent implements OnInit {
  @Input() timeLeft: number;
  @Input() showModal: boolean;
  @Output() logout: EventEmitter<null> = new EventEmitter<null>();
  @Output() stay: EventEmitter<null> = new EventEmitter<null>();

  constructor(public dialogRef: MatDialogRef<BeySessionIdleModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  _logout() {
    this.dialogRef.close(true);
    this.logout.emit();
  }

  _stay() {
    this.dialogRef.close();
    this.stay.emit();
  }
}
