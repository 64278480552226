import { Pipe, PipeTransform } from '@angular/core';
import parsePhoneNumber from 'libphonenumber-js/min';

@Pipe({
  name: 'formatPhone',
  standalone: true,
})
export class FormatPhonePipe implements PipeTransform {
  transform(value: string, formatType: 'INTERNATIONAL' | 'NATIONAL' = 'INTERNATIONAL'): string {
    if (!value) return '';

    try {
      const parsed = parsePhoneNumber(value);
      if (parsed) {
        return parsed.format(formatType);
      }
      throw new Error('Invalid phone number');
    } catch (e) {
      console.error(e);
      return '';
    }
  }
}
