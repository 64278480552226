import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { createCollectionStart } from 'src/app/ngrx/collections/collections.actions';
import { selectCreateCollectionIsLoading } from 'src/app/ngrx/collections/collections.selectors';
import { CollectionDialog, CreateCollectionRequestBody } from 'src/app/ngrx/collections/collections.interfaces';
import { Observable } from 'rxjs';
import { TransactionsUtilityService } from 'src/app/modules/shared/services/transactions-utility.service';
import { CROSS_BORDER_OPERATING_COUNTRIES } from 'src/assets/const';
import { selectProcessBeyonicLinkIsLoading } from '../../../../ngrx/beyonic-link/beyonic-link.selectors';
import { ApplyRoundingRulesPipe } from '../../../shared/pipes/apply-rounding-rules.pipe';
import { CalculateFeePipe } from '../../../shared/pipes/calculate-fee.pipe';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { NgIf, AsyncPipe, CurrencyPipe } from '@angular/common';
import { BeyNameCheckComponent } from '../../../shared/components/bey-name-check/bey-name-check.component';

@Component({
  selector: 'app-create-collection-dialog-content',
  templateUrl: './create-collection-dialog-content.component.html',
  styleUrls: ['./create-collection-dialog-content.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatLegacyDialogModule,
    BeyButtonComponent,
    AsyncPipe,
    CurrencyPipe,
    CalculateFeePipe,
    ApplyRoundingRulesPipe,
    BeyNameCheckComponent,
  ],
})
export class CreateCollectionDialogContentComponent implements OnInit {
  createCollectionIsLoading$: Observable<boolean>;
  processBeyonicLinkIsLoading$: Observable<boolean>;
  transaction: CreateCollectionRequestBody;
  currency: string;
  country: string;
  currencyPipeDigitalInfo: string;
  beyNameCheckProps: { first_name: string; last_name: string; name_check_score: number; hasNameCheck: boolean };

  constructor(
    private store$: Store,
    public transactionsUtility: TransactionsUtilityService,
    @Inject(MAT_DIALOG_DATA) public data: CollectionDialog & { name_check_score: number }
  ) {
    this.transaction = { ...this.data, type: 'collection' };
    this.currency = data?.displayCurrency || data?.currency;

    this.country = CROSS_BORDER_OPERATING_COUNTRIES.find((country) => country.currency === this.currency).label;
    this.currencyPipeDigitalInfo = this.transactionsUtility.retrieveCurrencyPipeDigitalInfo(this.country);

    const { first_name, last_name, name_check_score } = data;
    this.beyNameCheckProps = { first_name, last_name, name_check_score, hasNameCheck: name_check_score !== undefined };
  }

  ngOnInit(): void {
    this.createCollectionIsLoading$ = this.store$.select(selectCreateCollectionIsLoading);
    this.processBeyonicLinkIsLoading$ = this.store$.select(selectProcessBeyonicLinkIsLoading);
  }

  onClick(): void {
    if (this.data.onSend) {
      this.data.onSend();
    } else {
      this.store$.dispatch(createCollectionStart({ payload: this.data }));
    }
  }
}
