import { Component } from '@angular/core';
import { BeyHeaderComponent } from 'src/app/modules/shared/components/bey-header/bey-header.component';
import { PendingKycBannerComponent } from '../pending-kyc-banner/pending-kyc-banner.component';

@Component({
  selector: 'header[root-header]',
  templateUrl: './root-header.component.html',
  styleUrls: ['./root-header.component.scss'],
  standalone: true,
  imports: [PendingKycBannerComponent, BeyHeaderComponent],
})
export class RootHeaderComponent {}
