import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProfileInterface } from 'src/app/ngrx/user/user.interfaces';
import { selectUserKycDetails } from '../../../../ngrx/KYC/kyc.selectors';
import { NameInitialsPipe } from '../../../shared/pipes/name-initials.pipe';
import { AsyncPipe, NgClass } from '@angular/common';
import { RouterLinkActive, RouterLink, Router } from '@angular/router';
import { GetElementIdDirective } from '../../../shared/directives/get-element-id.directive';
import { HasPermissionDirective } from '../../../auth/directives/has-permission.directive';
import { BeyChevronComponent } from 'src/app/modules/shared/components/icons/bey-chevron/bey-chevron.component';

@Component({
  selector: 'aside[root-sidebar]',
  templateUrl: './root-sidebar.component.html',
  styleUrls: ['./root-sidebar.component.scss'],
  standalone: true,
  imports: [
    RouterLinkActive,
    RouterLink,
    AsyncPipe,
    NameInitialsPipe,
    GetElementIdDirective,
    HasPermissionDirective,
    BeyChevronComponent,
    NgClass,
  ],
})
export class RootSidebarComponent implements OnInit {
  transferMenuIsOpen: boolean = false;

  currentUser$: Observable<ProfileInterface>;

  constructor(private store$: Store, private router: Router) {}

  ngOnInit(): void {
    this.currentUser$ = this.store$.select(selectUserKycDetails);

    if (this.router.url.includes('payments')) {
      this.transferMenuIsOpen = true;
    }
  }

  onTransferClick(): void {
    this.transferMenuIsOpen = !this.transferMenuIsOpen;
  }
}
