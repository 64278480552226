import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'bey-chevron',
  templateUrl: './bey-chevron.component.html',
  styleUrls: ['./bey-chevron.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class BeyChevronComponent implements OnInit {
  constructor() {}

  /*****
   * Height of the chevron default value is 20
   */
  @Input()
  height: number = 20;

  /*****
   * Width of the chevron default value is 20
   */
  @Input()
  width: number = 20;

  @Input()
  direction: 'up' | 'down' | 'left' | 'right' = 'right';

  @Input()
  strokeFillColor: string = '#4D4D4B';

  ngOnInit(): void {}
}
