import { createAction, props } from '@ngrx/store';
import { membersTypes } from './members.types';
import {
  ErrorType,
  InvitationItem,
  InviteMemberRequestBody,
  TeamMemberItem,
  OnboardMemberRequestBody,
} from './members.interfaces';

//------------GET TEAM MEMBERS------------
export const getTeamMembersStart = createAction(
  membersTypes.GET_TEAM_MEMBERS_START,
  props<{ payload: { id: number } }>()
);
export const getTeamMembersSuccess = createAction(
  membersTypes.GET_TEAM_MEMBERS_SUCCESS,
  props<{ payload: Array<TeamMemberItem> }>()
);
export const getTeamMembersFailure = createAction(membersTypes.GET_TEAM_MEMBERS_FAILURE, props<ErrorType>());

//------------GET INVITED MEMBERS------------
export const getInvitedMembersStart = createAction(membersTypes.GET_INVITED_MEMBERS_START);
export const getInvitedMembersSuccess = createAction(
  membersTypes.GET_INVITED_MEMBERS_SUCCESS,
  props<{ payload: Array<InvitationItem> }>()
);
export const getInvitedMembersFailure = createAction(membersTypes.GET_INVITED_MEMBERS_FAILURE, props<ErrorType>());

//------------ON BOARD MEMBER------------
export const onboardMemberStart = createAction(
  membersTypes.ON_BOARD_MEMBER_START,
  props<{ payload: OnboardMemberRequestBody }>()
);
export const onboardMemberSuccess = createAction(membersTypes.ON_BOARD_MEMBER_SUCCESS);
export const onboardMemberFailure = createAction(membersTypes.ON_BOARD_MEMBER_FAILURE, props<ErrorType>());

//------------INVITE MEMBER------------
export const inviteMemberStart = createAction(
  membersTypes.INVITE_MEMBER_START,
  props<{ payload: InviteMemberRequestBody }>()
);
export const inviteMemberSuccess = createAction(membersTypes.INVITE_MEMBER_SUCCESS);
export const inviteMemberFailure = createAction(membersTypes.INVITE_MEMBER_FAILURE, props<ErrorType>());

//------------RESEND INVITE------------
export const resendInviteStart = createAction(membersTypes.RESEND_INVITE_START, props<{ payload: { id: number } }>());
export const resendInviteSuccess = createAction(membersTypes.RESEND_INVITE_SUCCESS);
export const resendInviteFailure = createAction(membersTypes.RESEND_INVITE_FAILURE, props<ErrorType>());
//------------CANCEL INVITE------------
export const cancelInviteStart = createAction(membersTypes.CANCEL_INVITE_START, props<{ payload: { id: number } }>());
export const cancelInviteSuccess = createAction(membersTypes.CANCEL_INVITE_SUCCESS);
export const cancelInviteFailure = createAction(membersTypes.CANCEL_INVITE_FAILURE, props<ErrorType>());

//------------OTHER------------
export const setInvitation = createAction(membersTypes.SET_INVITATION, props<{ payload: InvitationItem }>());
