import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { CreateBusinessProfileRequestBody, UserState } from './user.interfaces';

const selectUser = (state: AppState) => {
  return state.user;
};

//---------1ST LEVEL SELECTORS---------
export const selectUserDetails = createSelector(selectUser, (state: UserState) => state.userDetails);

export const selectUserBusinessDetails = createSelector(selectUser, (state: UserState) => state.userBusinessDetails);

export const selectUserBankAccount = createSelector(selectUser, (state: UserState) => state.userBankAccount);

export const selectSavedUser = createSelector(selectUser, (state: UserState) => state.savedUser);

export const selectIndustries = createSelector(selectUser, (state: UserState) =>
  state.industries ? state.industries.results : []
);

export const selectUserKybState = createSelector(selectUser, (state: UserState) => state.userKybState);

export const selectBusinessProfileFirstPartData = createSelector(
  selectUser,
  (state: UserState) => state.businessProfileFirstPartData
);

export const selectBusinessProfileSecondPartData = createSelector(
  selectUser,
  (state: UserState) => state.businessProfileSecondPartData
);

// this selector is for registration purpose only
const selectCreateBusinessProfile = createSelector(selectUser, (user: UserState) => user.createBusinessProfileData);
export const selectCreateBusinessProfileData = createSelector(
  selectCreateBusinessProfile,
  ({ data }: { data: CreateBusinessProfileRequestBody }) => data
);

export const selectIsSuspended = createSelector(selectUser, (state: UserState) => state.isSuspended);

export const selectUserIsLoading = createSelector(selectUser, (state: UserState) => state.isLoading);

export const selectUserErrorMessages = createSelector(selectUser, (state: UserState) => state.errorMessages);

//---------2ND LEVEL SELECTORS---------
export const selectUploadBusinessDocumentsIsLoading = createSelector(
  selectUserIsLoading,
  (isLoading) => isLoading['uploadBusinessDocuments']
);

export const selectGetUserBusinessDetailsIsLoading = createSelector(
  selectUserIsLoading,
  (isLoading) => isLoading['getUserBusinessDetails']
);

export const selectCreateBusinessProfileIsLoading = createSelector(
  selectUserIsLoading,
  (isLoading) => isLoading['createBusinessProfile']
);

export const selectGetCountryDocumentsIsLoading = createSelector(
  selectUserIsLoading,
  ({ getCountryDocs }) => getCountryDocs
);

export const selectUserBusinessDocuments = createSelector(selectUserKybState, ({ businessDocs }) => businessDocs);

export const selectCountryDocuments = createSelector(selectUserKybState, ({ countryDocs }) => countryDocs);

export const selectGetIndustriesIsLoading = createSelector(
  selectUserIsLoading,
  (isLoading) => isLoading['getIndustries']
);

export const selectGetUserBankAccountIsLoading = createSelector(
  selectUserIsLoading,
  (isLoading) => isLoading['getUserBankAccount']
);

export const selectGetUserKybIsLoading = createSelector(selectUserIsLoading, (isLoading) => isLoading['kybDocs']);
