<div class="flex flex-col items-center">
  <a
    class="w-11 h-11 mb-3 rounded-full bg-white flex items-center justify-center cursor-pointer"
    (click)="clickHandler()"
    getElementId
    [getElementIdDescription]="operation.icon"
    ><img [src]="'/assets/images/' + operation.icon + '.svg'"
  /></a>
  <span class="text-black text-sm font-light cursor-default">{{ operation.title }}</span>
</div>
