import { Component, Inject } from '@angular/core';
import { MatLegacyDialog, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BeyButtonComponent } from 'src/app/modules/shared/components/bey-button/bey-button.component';
import { BeySnippetCodeComponent } from 'src/app/modules/shared/components/bey-snippet-code/bey-snippet-code.component';
import { SafeHtml } from 'src/app/modules/shared/pipes/safe-html.pipe';
import { BeyToastService } from 'src/app/modules/shared/services/bey-toast.service';
import { copyToClipboard } from 'src/assets/utility';

@Component({
  selector: 'copy-beyonic-link-embed-button-dialog-content',
  templateUrl: './copy-beyonic-link-embed-button-dialog-content.component.html',
  styleUrls: ['./copy-beyonic-link-embed-button-dialog-content.component.scss'],
  standalone: true,
  imports: [BeySnippetCodeComponent, SafeHtml, BeyButtonComponent],
})
export class CopyBeyonicLinkEmbedButtonDialogContentComponent {
  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { title: string; snippet: string },
    private dialog: MatLegacyDialog,
    private toast: BeyToastService
  ) {}

  onCrossClick(): void {
    this.dialog.closeAll();
  }

  onButtonClick(): void {
    copyToClipboard(this.data.snippet);
    this.toast.open('Code copied 🎉');
    this.dialog.closeAll();
  }
}
