import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { suspendUser } from 'src/app/ngrx/user/user.actions';

@Injectable()
export class SuspendedUserInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error?.status === 403 && error?.error?.code === 'user_inactive') {
          this.store.dispatch(suspendUser());
        }
        return throwError(() => error);
      })
    );
  }
}
