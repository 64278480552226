import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { Subscription } from 'rxjs';
import { selectUserWalletCurrency } from 'src/app/ngrx/wallets/wallets.selectors';
import {
  ACCEPTED_DOC_FILE_TYPES,
  ACCEPTED_IMAGE_FILE_TYPES,
  CROSS_BORDER_OPERATING_COUNTRIES,
  SPECIAL_CHARACTERS_REGEX_VALIDATION,
} from 'src/assets/const';
import { getFormattedNumber } from 'src/assets/utility';
import { differentFileValidator, noLeadingSpaces } from 'src/assets/utility/form.validators';
import { errorMessages } from '../../../../assets/utility/fieldValidationErrorMessages';
import { warningMessages } from '../../../../assets/utility/fieldValidationWarningMessages';
import { BeyToastService } from '../../shared/services/bey-toast.service';
import { ContactItem } from 'src/app/ngrx/contacts/contacts.interface';

@Injectable({
  providedIn: 'root',
})
export class FormUtilityService {
  merchantWalletCurrency: string;
  subs$: Subscription = new Subscription();

  constructor(private toast: BeyToastService, private store$: Store) {
    this.subs$.add(
      store$.select(selectUserWalletCurrency).subscribe((currency) => (this.merchantWalletCurrency = currency))
    );
  }

  onSelectContact(form: UntypedFormGroup, setCountryValObj = false, callback?: (contact?: ContactItem) => void) {
    this.subs$.add(
      form.get('first_name').valueChanges.subscribe((contact) => {
        let isContactObject = false;
        // reset amount value
        form.get('amount').setValue(null);
        form.get('amount').markAsUntouched();

        // clear
        form.get('first_name').clearAsyncValidators();
        form.get('first_name').clearValidators();

        if (typeof contact === 'object' && contact !== null) {
          isContactObject = true;
          const { first_name, last_name, phone } = contact;
          const parsedPhone = parsePhoneNumber(phone || '');

          if (setCountryValObj) {
            //get country by code and patch the value here
            if (parsedPhone) {
              const country = CROSS_BORDER_OPERATING_COUNTRIES.find(
                (i) => i.countryCode === `+${parsedPhone.countryCallingCode}`
              );
              form.get('phone.code').patchValue(country);
            }
          } else {
            parsedPhone && form.get('phone.code').setValue(`+${parsedPhone.countryCallingCode}`);
          }
          parsedPhone && form.get('phone.phone_number').setValue(parsedPhone.nationalNumber);

          form.get('first_name').setValue(first_name);
          form.get('last_name').setValue(last_name);
        }
        // setting the validators back to the field whether the contact type is object or not
        form
          .get('first_name')
          .setValidators([
            Validators.required,
            noLeadingSpaces,
            Validators.pattern(SPECIAL_CHARACTERS_REGEX_VALIDATION),
          ]);

        callback?.(isContactObject ? contact : undefined);
      })
    );
  }

  getFieldErrorMessage(control: AbstractControl, customName: string = '', customMessage: string = '') {
    // @ts-ignore
    const hasWarning = control.warnings;
    const warningType = hasWarning ? Object.keys(hasWarning)[0] : '';

    const hasError = control.errors;
    let errorType = '';

    if (hasError) {
      let prioritizedError = Object.keys(hasError).filter((error) => error.startsWith('p-'))[0];

      if (prioritizedError) {
        errorType = prioritizedError.split('p-')[1];
      } else {
        errorType = Object.keys(hasError)[0];
      }
    }

    let formattedFieldName;
    if (customName) {
      formattedFieldName = customName;
    } else {
      let fieldName = this.getControlName(control);
      formattedFieldName = `${fieldName[0].toUpperCase()}${fieldName.replace(/_|\./, ' ').slice(1)}`;
    }

    if (hasError) {
      // return custom message | THIS SHOULD BE USED ONLY WITH SELECT COMPONENT BECAUSE IT ONLY REQUIRES ONE VALIDATION RULE (REQUIRED)
      if (customMessage) {
        return customMessage;
      }

      switch (errorType) {
        case 'required':
        case 'xb.required':
          return errorMessages['required'].replace('{field}', formattedFieldName);
        case 'minlength': {
          const {
            minlength: { requiredLength, actualLength },
          } = hasError;
          const toReplace = {
            '{field}': formattedFieldName,
            '{requiredLength}': requiredLength,
            '{actualLength}': actualLength,
          };

          return errorMessages['minlength'].replace(/{field}|{requiredLength}|{actualLength}/g, (m) => {
            return toReplace[m];
          });
        }

        case 'maxlength': {
          const {
            maxlength: { requiredLength },
          } = hasError;
          const toReplace = {
            '{field}': formattedFieldName,
            '{requiredLength}': requiredLength,
          };

          return errorMessages['maxlength'].replace(/{field}|{requiredLength}/g, (m) => {
            return toReplace[m];
          });
        }
        case 'min':
          const { min: minErr } = hasError;
          return errorMessages['min']
            .replace('{value}', getFormattedNumber(minErr?.['min']))
            .replace('{currency}', this.merchantWalletCurrency);
        case 'max':
          const { max: maxErr } = hasError;
          return errorMessages['max']
            .replace('{value}', getFormattedNumber(maxErr?.['max']))
            .replace('{currency}', this.merchantWalletCurrency);
        case 'pattern':
          return errorMessages[control.errors['pattern']['requiredPattern']];

        case 'xb.max':
        case 'xb.min':
          const toReplace = {
            '{field}': formattedFieldName,
            '{value}': getFormattedNumber(hasError?.['value']),
            '{currency}': hasError?.['currency'],
          };

          return errorMessages[errorType].replace(/{field}|{value}|{currency}/g, (m) => {
            return toReplace[m];
          });

        case 'amountExceedWalletLimit': {
          // todo in future we need to use this way to send data instead of hasError?.[errorType] and use one replace method but we change to replace object
          const { currency, value } = hasError;

          const toReplace = {
            '{value}': getFormattedNumber(value, true),
            '{currency}': currency,
          };

          return errorMessages[errorType].replace(/{value}|{currency}/g, (m) => toReplace[m]);
        }

        case 'transactionsLimitExceededCollection':
        case 'transactionsLimitExceeded':
        case 'topUpLimitExceeded': {
          const toReplace = {
            '{value}': getFormattedNumber(hasError?.[errorType]),
            '{currency}': this.merchantWalletCurrency,
          };

          return errorMessages[errorType].replace(/{value}|{currency}/g, (m) => {
            return toReplace[m];
          });
        }

        case 'unsupportedTelco': {
          const toReplace = {
            '{supportedTelcos}': hasError?.['supportedTelcos'],
            '{country}': hasError?.['country'],
            '{verb}': hasError?.['verb'],
          };

          return errorMessages[errorType].replace(/{supportedTelcos}|{verb}|{country}/g, (m) => {
            return toReplace[m];
          });
        }

        case 'exceedsOpenWalletLimit': {
          const toReplace = {
            '{activeMerchantName}': hasError?.['activeMerchantName'],
          };

          return errorMessages[errorType].replace(/{activeMerchantName}/g, (m) => toReplace[m]);
        }

        // returns whatever was passed to it || Could be used with API async validations
        case 'custom':
          const { custom = '' } = hasError;
          return custom || '';

        default:
          return errorMessages[errorType];
      }
    } else if (hasWarning) {
      switch (warningType) {
        case 'transactionsLimitExceededCollection':
        case 'transactionsLimitExceeded':
        case 'transactionsLimitWarning':
          const toReplace = {
            '{value}': getFormattedNumber(hasWarning?.[warningType]),
            '{currency}': this.merchantWalletCurrency,
          };

          return warningMessages[warningType].replace(/{value}|{currency}/g, (m) => {
            return toReplace[m];
          });

        default:
          return warningMessages[warningType];
      }
    }
  }

  getFieldValidation(control: AbstractControl): boolean {
    //@ts-ignore
    // In this case we don't want to show the text as a warning message but as an error instead
    if (control.warnings?.renderedAsError) {
      return true;
    }

    //@ts-ignore
    return (control.invalid || control.warnings) && control.touched;
  }

  checkFieldWarnings(control: AbstractControl): boolean {
    //@ts-ignore
    // In this case we don't want to show the text as a warning message but as an error instead
    if (control.warnings?.renderedAsError) return false;
    //@ts-ignore
    // we check for error here because we need to hide warnings when errors exist, errors have higher priority
    return control.warnings && !control.errors;
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  preUploadFileCheck(e: Event, imagesOnly: boolean = false): File {
    const target = e.target as HTMLInputElement;
    let files;

    if (target) {
      files = target.files;
    } else {
      files = e;
    }

    if (!!files?.length) {
      const file = files[0];

      const fileSize: number = file.size / 1024 / 1024; // size in MiB
      const fileType: string = file.type;

      if (fileSize > 10) {
        // Max file size is 10mb
        this.toast.open('The file exceeds the maximum file size of 10 MB, please resize and try again.');
        return null;
      } else if (!imagesOnly && !ACCEPTED_DOC_FILE_TYPES.includes(fileType)) {
        // accepted types are PDF, JPG, PNG
        this.toast.open('Invalid file type. PDF or PNG & JPEG only.');
        return null;
      } else if (imagesOnly && !ACCEPTED_IMAGE_FILE_TYPES.includes(fileType)) {
        // accepted types are images
        this.toast.open('Invalid file type. PNG & JPEG only.');
        return null;
      } else {
        return file;
      }
    }

    return null;
  }

  checkFileUploadEquality(formData: UntypedFormGroup, file: File, document_id: string, document_id2: string) {
    const currentControl = formData.get(document_id);
    const otherControl = formData.get(document_id2);
    const otherFile = otherControl.value;

    currentControl.setValue(file);
    currentControl.setValidators(differentFileValidator(otherFile));
    currentControl.markAsTouched();
    currentControl.updateValueAndValidity();

    if (otherFile) {
      otherControl.setValidators(differentFileValidator(file));
      otherControl.markAsTouched();
      otherControl.updateValueAndValidity();
    }

    // Check the validity of both controls
    const filesValid = !currentControl.hasError('sameFile') && !otherControl.hasError('sameFile');

    if (!filesValid) {
      currentControl.setErrors({ sameFile: true });
      otherControl.setErrors({ sameFile: true });
    }
  }

  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
  }
}
