<div class="h-full relative lg:min-h-screen">
  <bey-with-initials-header [name]="businessInfo?.active_merchant_name" [showLogo]="false"></bey-with-initials-header>

  <div
    class="flex flex-col mt-[42px] lg:mt-[-100px] lg:relative lg:top-0 lg:mb-20 text-center lg:bg-white lg:rounded-xl lg:shadow-xl lg:w-[611px] lg:inset-x-0 top-52 mx-auto lg:pt-[54px] lg:pb-[58px]">
    <div
      class="hidden lg:flex text-[#2F3235] bg-gray-100 w-[140px] h-[140px] mb-9 rounded-full justify-center items-center font-medium mx-auto text-[50px] leading-[59px]">
      {{ businessInfo?.active_merchant_name | nameInitials }}
    </div>

    <div class="text-gray-500 leading-[19px] font-medium lg:text-2xl lg:leading-[25px]">
      {{ businessInfo?.active_merchant_name }}
    </div>
    <div class="text-blue-400 mt-[5px] leading-[19px] font-medium">
      <a [href]="uniqueLink" class="max-w-[95%] mx-auto break-words block">{{ uniqueLink }}</a>
    </div>

    <div class="text-gray-500 text-center leading-[19px] max-w-[272px] mx-auto mt-7 lg:mt-10">
      You have created a collections link to
      <strong>{{ paymentInfo?.contact?.phone | formatPhoneNumber : true }}</strong> for
      <strong>{{
        paymentInfo?.amount
          | applyRoundingRules : businessInfo?.country : 'collection' : false
          | currency : paymentInfo?.amount_currency + ' ' : 'symbol' : currencyPipeDigitalInfo
      }}</strong>
      for
      {{ paymentInfo.description }}
    </div>

    <span class="text-gray-500 text-center text-sm font-normal my-4"
      >Transaction fee:
      {{
        paymentInfoForCalculatingFee
          | calculateFee
          | applyRoundingRules : businessInfo?.country : 'fee' : false
          | currency : paymentInfoForCalculatingFee?.currency + ' ' : 'symbol' : currencyPipeDigitalInfo
      }}</span
    >

    <bey-button placeholder="Share link" color="yellow" classList="mx-auto px-4" (onClick)="toggleShare()"></bey-button>
  </div>
</div>
