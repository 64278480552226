import { Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { stepperFadeInOut } from '../../../../../assets/utility/transitions';
import { NgFor, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'div[bey-headless-stepper]',
  templateUrl: './bey-headless-stepper.component.html',
  animations: [stepperFadeInOut],
  standalone: true,
  imports: [NgFor, NgTemplateOutlet],
})
export class BeyHeadlessStepperComponent implements OnInit {
  constructor() {}

  /****
   *  Steps passed down from the parent as content children
   */
  @ContentChildren('step')
  steps: QueryList<any>;

  /****
   * Current step in the stepper
   */
  @Input()
  currentStep: number = 0;

  ngOnInit(): void {}
}
