<div
  class="w-full max-w-[160px] ease-in-out duration-500 focus-within:text-bey-black relative"
  data-test-id="qty-selector">
  <label class="block text-gray-600 font-bold mb-1" *ngIf="label">{{ label }}</label>
  <div
    class="flex transition ease-in-out duration-500"
    [ngClass]="{
      'border-bey-red': displayError,
      'border-[#C4C4C4] focus-within:border-gray-500': !displayError
    }"
    data-test-id="wrapper">
    <button
      (click)="updateQuantity(-step)"
      type="button"
      [disabled]="disabled"
      class="flex items-center justify-center border border-r-0 rounded-l px-3 border-inherit w-4/12 touch-manipulation"
      [ngClass]="{ 'text-bey-red': displayError || !canReduce, 'cursor-not-allowed': notAllowed }"
      data-test-id="decrement"
      matRipple>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
      </svg>
    </button>
    <input
      type="text"
      [(ngModel)]="quantity"
      pattern="[0-9]*"
      inputmode="numeric"
      class="p-2 border-y text-center text-sm border-solid outline-none text-gray-800 border-inherit w-4/12 touch-manipulation"
      [name]="name"
      [disabled]="disabled"
      [readonly]="readonly"
      currencyMask
      [options]="{
        prefix: '',
        align: 'center',
        thousands: ',',
        decimal: '.',
        precision: 0,
        allowNegative: false
      }"
      (keyup)="changeHandler()"
      data-test-id="qty" />
    <button
      (click)="updateQuantity(step)"
      type="button"
      [disabled]="disabled"
      class="flex items-center justify-center border border-l-0 rounded-r px-3 border-inherit w-4/12 touch-manipulation"
      data-test-id="increment"
      matRipple>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
      </svg>
    </button>
  </div>
  <bey-field-error-message [displayError]="!!errorMessage" [errorMessage]="errorMessage"> </bey-field-error-message>
</div>
