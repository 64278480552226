import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Wallet } from 'src/app/ngrx/wallets/wallets.interfaces';
import { selectGetUserWalletIsLoading, selectUserWallet } from 'src/app/ngrx/wallets/wallets.selectors';
import dayjs from 'dayjs';
import { RouterLink } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';
import { GetElementIdDirective } from '../../../shared/directives/get-element-id.directive';

@Component({
  selector: 'bey-trial-banner',
  templateUrl: './bey-trial-banner.component.html',
  styleUrls: ['./bey-trial-banner.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, AsyncPipe, GetElementIdDirective],
})
export class BeyTrialBannerComponent implements OnDestroy {
  merchantPaidInAdvanced: boolean;
  userWallet$: Observable<Wallet>;
  getUserWalletIsLoading$: Observable<boolean>;
  message$: BehaviorSubject<string> = new BehaviorSubject<string>('empty val');
  subscriptions$: Subscription = new Subscription();

  constructor(private store$: Store) {
    this.userWallet$ = this.store$.select(selectUserWallet);
    this.getUserWalletIsLoading$ = this.store$.select(selectGetUserWalletIsLoading);
    this.subscriptions$.add(
      this.userWallet$.subscribe((wallet) => {
        if (wallet?.billing_status === 'trial') {
          // we retrieve the difference between the merchant's wallet creation date and next billing date, if it's more than a month then it means he paid at least one month in advanced
          this.merchantPaidInAdvanced = dayjs(wallet.next_billing_date).diff(wallet.created, 'month') > 1;
          this.message$.next(
            `You have ${Math.floor(
              30 - dayjs().diff(dayjs(wallet.created)) / (60 * 60 * 24 * 1000)
            )} days left on your free trial`
          );
        } else {
          if (!wallet?.last_paid_date) {
            this.message$.next('Your trial period ended');
          } else {
            this.message$.next('Your subscription period ended');
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
