import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { closeSnackbar } from 'src/app/ngrx/misc/misc.actions';
import { SnackbarDetailsInterface } from 'src/app/ngrx/misc/misc.interface';
import { selectSnackbarDetails } from 'src/app/ngrx/misc/misc.selectors';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'bey-snackbar',
  templateUrl: './bey-snackbar.component.html',
  styleUrls: ['./bey-snackbar.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, AsyncPipe],
})
export class BeySnackbarComponent implements OnInit, OnDestroy {
  snackbarDetails$: Observable<SnackbarDetailsInterface>;

  snackbarClass: string;
  snackabarTimeout;
  snackbarColors: { [key: string]: string } = {
    warning: 'bg-orange-400',
    error: 'bg-[#A93538]',
  };

  subs$: Subscription = new Subscription();

  constructor(private store$: Store) {
    this.snackbarDetails$ = this.store$.select(selectSnackbarDetails);
  }

  ngOnInit(): void {
    this.subs$.add(
      this.snackbarDetails$.subscribe((details) => {
        this.snackbarClass = `w-[345px] p-6 pb-4 flex flex-col relative ${this.snackbarColors[details?.color]}`;

        if (details?.timeout && details?.show) {
          this.snackabarTimeout = setTimeout(() => {
            this.store$.dispatch(closeSnackbar());
          }, details['timeout']);
        }
      })
    );
  }

  onCrossClick() {
    this.store$.dispatch(closeSnackbar());
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
    clearTimeout(this.snackabarTimeout);
  }
}
