import { Component, forwardRef, Input, OnInit, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { FormUtilityService } from 'src/app/modules/core/services/form-utility.service';
import { Subscription } from 'rxjs';
import { BeyFieldErrorMessageComponent } from '../bey-field-error-message/bey-field-error-message.component';
import { CurrencyMaskModule } from 'ng2-currency-mask-beyonic';
import { MatRippleModule } from '@angular/material/core';
import { NgIf, NgClass } from '@angular/common';

const noop = () => {};

@Component({
  selector: 'bey-quantity-selector',
  templateUrl: './bey-quantity-selector.component.html',
  styleUrls: ['./bey-quantity-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BeyQuantitySelectorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    MatRippleModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    FormsModule,
    BeyFieldErrorMessageComponent,
  ],
})
export class BeyQuantitySelectorComponent implements OnInit, ControlValueAccessor, OnDestroy {
  /***
   *  Text for input field label
   */
  @Input()
  label: string;

  /***
   *  Input field name attribute
   */
  @Input()
  name: string = '';

  /***
   *  Minimum value
   */
  @Input()
  min: number = 1;

  /***
   *  Maximum value
   */
  @Input()
  max: number;

  /***
   *  Number of steps per button click
   */
  @Input()
  step: number = 1;

  /***
   *  Make the field readonly and un editable
   */
  @Input()
  readonly: boolean = false;

  /***
   *  disables the field
   */
  @Input()
  disabled: boolean = false;

  /****
   * formControl instance
   */
  @Input()
  control: AbstractControl;

  public quantity = 1;
  public canReduce: boolean = true;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  displayError: boolean = false;
  warning: boolean = false;
  errorMessage: string = '';
  subs$: Subscription = new Subscription();
  constructor(private formUtility: FormUtilityService) {}

  ngOnInit(): void {
    if (!!this.control) {
      this.subs$.add(
        this.control.valueChanges.subscribe(() => {
          this.updateErrorStatus();
        })
      );
    }
  }

  updateErrorStatus(): void {
    if (this.control) {
      this.control.markAsTouched();
      this.errorMessage = this.formUtility.getFieldErrorMessage(this.control);
      this.displayError = this.formUtility.getFieldValidation(this.control);
    }
  }

  writeValue(value: any): void {
    this.quantity = value;
    this.onChangeCallback(value);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  updateQuantity(increment: number) {
    const newValue = this.quantity + increment;

    if (newValue >= this.min && (this.max ? newValue <= this.max : true)) {
      this.quantity = newValue;
      this.canReduce = true;
      this.onChangeCallback(this.quantity);
    } else {
      this.canReduce = false;
    }
  }

  changeHandler(): void {
    const val = this.quantity;

    if (isNaN(val) || val < 1) {
      this.quantity = null;
      this.onChangeCallback(null);
    } else if (val >= this.min && (this.max ? val <= this.max : true)) {
      this.onChangeCallback(val);
    }
  }

  get notAllowed(): boolean {
    return this.quantity === 1;
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
