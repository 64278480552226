import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BeyPageHeaderComponent } from '../../../shared/components/bey-page-header/bey-page-header.component';
import { HasPermissionDirective } from '../../../auth/directives/has-permission.directive';

@Component({
  selector: 'collections-recent-collections',
  templateUrl: './recent-collections.component.html',
  styleUrls: ['./recent-collections.component.scss'],
  standalone: true,
  imports: [BeyPageHeaderComponent, RouterLink, HasPermissionDirective],
})
export class RecentCollectionsPage implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}
}
