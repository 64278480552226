import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { BeyWarningBannerComponent } from '../../../shared/components/bey-warning-banner/bey-warning-banner.component';

@Component({
  selector: 'app-failed-collection',
  templateUrl: './failed-collection.page.html',
  styleUrls: ['./failed-collection.page.scss'],
  standalone: true,
  imports: [BeyWarningBannerComponent, BeyButtonComponent],
})
export class FailedCollectionPage implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  retryPrompt(): void {
    const type = this.route.snapshot.queryParamMap.get('type');
    const id = this.route.snapshot.queryParamMap.get('id');
    const shortName = this.route.snapshot.paramMap.get('short_name');
    const isPay = environment.is_pay;

    if (!id) {
      this.router.navigate(['/error']);
      return;
    }

    if (type === 'ul') {
      const route = `/sl/${shortName}/${id}`;
      this.router.navigate([route]);
    } else if (type === 'sl') {
      const queryParams = { code: id };
      const route = isPay ? `/${shortName}` : `/sl/${shortName}`;
      this.router.navigate([route], { queryParams });
    }
    // this is temporary since we will use a new endpoint to retry
    else if (type === 'bl') {
      const link_id = this.route.snapshot.queryParamMap.get('link_id');
      const route = isPay ? `/${shortName}/${link_id}` : `/bl/${shortName}/${link_id}`;
      this.router.navigate([route]);
    } else {
      this.router.navigate(['/error']);
    }
  }
}
