<div class="inline-flex items-center">
  <div
    [ngClass]="{
      'bg-red-500': data?.variant === 'offline',
      'bg-green-500': data?.variant === 'online'
    }"
    class="w-5 h-5 rounded-full"
    data-test-id="circle"></div>
  <span class="ml-2 text-sm md:text-base font-light" data-testid="body">
    {{
      data?.variant === 'online'
        ? 'Your device is connected to the internet.'
        : 'Your device lost its internet connection.'
    }}</span
  >
</div>
