import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BeyNameCheckDialogComponent } from '../bey-name-check-dialog/bey-name-check-dialog.component';
import { BeyExclamationIconComponent } from '../icons/bey-exclamation-icon/bey-exclamation-icon.component';
import { NgIf, NgClass, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'bey-name-check',
  templateUrl: './bey-name-check.component.html',
  styleUrls: ['./bey-name-check.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, BeyExclamationIconComponent, TitleCasePipe],
})
export class BeyNameCheckComponent implements OnInit {
  @Input() data: { first_name: string; last_name: string; name_check_score: number; hasNameCheck: boolean };

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openNameCheckInfo(): void {
    this.dialog.open(BeyNameCheckDialogComponent);
  }
}
