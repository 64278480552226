import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCurrentHeadlessStepperStep } from '../../../../../../ngrx/misc/misc.selectors';
import { BeyChevronComponent } from '../../../../components/icons/bey-chevron/bey-chevron.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'bey-navigation-footer',
  templateUrl: './bey-navigation-footer.component.html',
  styleUrls: ['./bey-navigation-footer.component.scss'],
  standalone: true,
  imports: [MatRippleModule, NgClass, BeyChevronComponent, AsyncPipe],
})
export class BeyNavigationFooterComponent implements OnInit {
  @Output()
  stepChange: EventEmitter<number> = new EventEmitter<number>();

  currentStep$: Observable<number>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.currentStep$ = this.store.select(selectCurrentHeadlessStepperStep);
  }
}
