import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeIn } from '../../../../../assets/utility/transitions';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'bey-rounded-btn',
  templateUrl: './bey-rounded-btn.component.html',
  styleUrls: ['./bey-rounded-btn.component.scss'],
  animations: [fadeIn],
  standalone: true,
  imports: [NgClass, NgIf, SpinnerComponent],
})
export class BeyRoundedBtnComponent implements OnInit {
  constructor() {}

  /****
   * Source location of the button icon used on image tag
   */
  @Input()
  iconSrc: string;

  /****
   * Alt text for button icon
   */
  @Input()
  iconAlt: string;

  /***
   * Loading state
   */
  @Input()
  isLoading: boolean;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<Event>();

  ngOnInit(): void {}
}
