import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectUserDetails } from '../../../../ngrx/user/user.selectors';
import { Observable } from 'rxjs';
import { fadeIn } from '../../../../../assets/utility/transitions';
import { NameInitialsPipe } from '../../pipes/name-initials.pipe';
import { BeyBackArrowComponent } from '../icons/bey-back-arrow/bey-back-arrow.component';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { BeyHeaderComponent } from '../bey-header/bey-header.component';
// todo to be replaced with BeyHeaderWithContentComponent for the sake of consistency
@Component({
  selector: 'bey-with-initials-header',
  templateUrl: './bey-header-with-initials.component.html',
  styleUrls: ['./bey-header-with-initials.component.scss'],
  animations: [fadeIn],
  standalone: true,
  imports: [NgClass, NgIf, BeyBackArrowComponent, AsyncPipe, NameInitialsPipe, BeyHeaderComponent],
})
export class BeyHeaderWithInitialsComponent implements OnInit {
  userDetails$: Observable<any>;

  constructor(public router: Router, private store: Store) {
    this.userDetails$ = store.select(selectUserDetails);
  }
  /****
   *  Full name if available e.g. in case of businesses
   */
  @Input()
  name: string;

  /*****
   *  Forces render the page in mobile view instead desktop
   *  Used in Beyonic link preview
   */
  @Input()
  forceMobileView: boolean = false;

  /***
   * Show a back button
   */
  @Input()
  backButton: boolean = false;

  /***
   * Show a back button
   */
  @Input()
  isPreview: boolean = false;

  /***
   * Show/hide beyonic logo on desktop view
   */
  @Input()
  showLogo: boolean = true;

  @Output()
  onBack: EventEmitter<null> = new EventEmitter();

  ngOnInit(): void {}

  navigate() {
    if (environment.is_pay) {
      window.open(`${environment.URL}/login`, '_blank');
    } else {
      this.router.navigate(['/login']);
    }
  }
}
