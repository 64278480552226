import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormUtilityService } from 'src/app/modules/core/services/form-utility.service';
import { TransactionsUtilityService } from '../../services/transactions-utility.service';
import { ApplyRoundingRulesPipe } from '../../pipes/apply-rounding-rules.pipe';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NgIf, CurrencyPipe } from '@angular/common';
import { BeyDisplayTotalAmountComponent } from '../bey-display-total-amount/bey-display-total-amount.component';
import { BeyFieldErrorMessageComponent } from '../bey-field-error-message/bey-field-error-message.component';
import { retrieveOperatingCountry } from '../../../../../assets/utility/shared';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BeyTotalAmountComponent),
  multi: true,
};

@Component({
  selector: 'bey-total-amount',
  templateUrl: './bey-total-amount.component.html',
  styleUrls: ['./bey-total-amount.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [
    BeyDisplayTotalAmountComponent,
    NgIf,
    SpinnerComponent,
    CurrencyPipe,
    ApplyRoundingRulesPipe,
    BeyFieldErrorMessageComponent,
  ],
})
export class BeyTotalAmountComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor, OnDestroy {
  /****
   *  Unit amount to be displayed and used to calculate the total amount
   */
  @Input()
  unitAmount: string;

  /****
   *  Qty of the items to be procured
   */
  @Input()
  quantity: number;

  /***
   *  Amount currency
   */
  @Input()
  currency: string = 'UGX';

  /***
   *  Show loading indicator
   */
  @Input()
  isLoading: boolean = false;

  /****
   * formControl instance
   */
  @Input()
  control: AbstractControl;

  public totalAmount: number;
  public country: string;
  public currencyPipeDigitalInfo: string;
  totalInfo: { amount: number; currency: string } = {
    amount: 0,
    currency: '',
  };

  displayError: boolean = false;
  warning: boolean = false;
  errorMessage: string = '';
  subs$: Subscription = new Subscription();

  updateErrorStatus(): void {
    if (this.control) {
      this.control.markAsTouched();
      this.errorMessage = this.formUtility.getFieldErrorMessage(this.control);
      this.displayError = this.formUtility.getFieldValidation(this.control);
    }
  }

  ngAfterViewInit(): void {
    this.calculateAmount(this.quantity);
  }

  constructor(
    private transactionsUtility: TransactionsUtilityService,
    private cdr: ChangeDetectorRef,
    private formUtility: FormUtilityService
  ) {}

  ngOnInit(): void {
    this.country = retrieveOperatingCountry(this.currency, 'currency')?.name;
    this.currencyPipeDigitalInfo = this.transactionsUtility.retrieveCurrencyPipeDigitalInfo(this.country);

    if (!!this.control) {
      this.subs$.add(
        this.control.valueChanges.subscribe(() => {
          this.updateErrorStatus();
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['quantity']) {
      this.calculateAmount(changes['quantity']?.currentValue);
    }
  }

  calculateAmount(qty: number): void {
    this.totalAmount = Number(this.unitAmount) * qty;
    this.totalInfo.amount = this.totalAmount;
    this.totalInfo.currency = this.currency;
    this.value = this.totalAmount;
  }

  //-----From ControlValueAccessor interface-----
  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  get value(): any {
    return this.totalAmount;
  }

  set value(v: any) {
    this.onChangeCallback(v);
    this.cdr.detectChanges();
  }

  writeValue(value: any) {
    this.totalAmount = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  //---------------------------------------------

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
