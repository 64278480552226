<div
  [ngClass]="{
    'text-blue-500': data?.name_check_score === 0,
    'text-bey-red': data?.name_check_score < 40,
    'text-bey-orange': data?.name_check_score < 80,
    'text-bey-green': data?.name_check_score >= 80
  }"
  data-test-id="wrapper"
  class="flex space-x-2"
  *ngIf="data?.hasNameCheck && data?.name_check_score !== null">
  <div class="mt-[3px]">
    <bey-exclamation-icon variant="currentColor" (onClick)="openNameCheckInfo()"></bey-exclamation-icon>
  </div>
  <div *ngIf="data?.name_check_score != 0; else scoreEqualsZero" class="text-sm text-left" data-test-id="score-text">
    {{ data?.name_check_score }}% match for the contact {{ data?.first_name | titlecase }}
    {{ data?.last_name | titlecase }}
  </div>
  <ng-template #scoreEqualsZero>
    <div data-test-id="zero-score-text" class="text-sm text-left">
      We are unable to find a contact to match this registered number to. You may still continue the transaction.
    </div>
  </ng-template>
</div>
