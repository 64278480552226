import { enableProdMode, Injectable, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgIdleModule } from '@ng-idle/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RootStoreModule } from './app/ngrx/root-store.module';
import { CoreModule } from './app/modules/core/core.module';
import { HomeModule } from './app/modules/home/home.module';
import { AppRoutingModule } from './app/app-routing.module';
import {
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  BrowserModule,
  HammerModule,
  bootstrapApplication,
} from '@angular/platform-browser';
import { FailRetryAgainInterceptor } from './app/modules/shared/interceptors/fail-retry-again.interceptor';
import { AuthInterceptor } from './app/modules/auth/interceptors/auth.interceptor';
import { SuspendedUserInterceptor } from './app/modules/shared/interceptors/suspended-user.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import * as Hammer from 'hammerjs';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';

if (environment.production) {
  enableProdMode();
}

@Injectable()
class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
    pan: { enable: false },
  };
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      HomeModule,
      CoreModule,
      RootStoreModule,
      NgIdleModule.forRoot(),
      HammerModule,
      ServiceWorkerModule.register('sw-master.js', {
        enabled: false,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      MatLegacySnackBarModule
    ),
    { provide: HTTP_INTERCEPTORS, useClass: SuspendedUserInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FailRetryAgainInterceptor,
      multi: true,
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig, deps: [] },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
