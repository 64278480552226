import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  CreateBusinessProfileRequestBody,
  getIndustriesResponse,
  getUserBusinessDocumentsResponse,
  User,
  UserBusinessDetailsResponse,
} from './user.interfaces';
import { RecentPaymentItem } from '../payments/payments.interfaces';
import { EMPTY, forkJoin, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserDetails } from './user.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  currentUser$: Observable<User>;
  currentUser: User;
  subs$: Subscription = new Subscription();

  constructor(private http: HttpClient, private store$: Store) {
    this.currentUser$ = this.store$.select(selectUserDetails);
    this.subs$.add(this.currentUser$.subscribe((data) => (this.currentUser = data)));
  }

  getUserDetails({ phone }: { phone: string }) {
    return this.http.get<User>(environment.API_URL + `users/${phone}/`);
  }

  getUserBusinessDetails({ id }: { id: number }) {
    return this.http.get<UserBusinessDetailsResponse>(environment.API_URL + `businesses/${id}/`);
  }

  createBusinessProfile(requestBody: CreateBusinessProfileRequestBody) {
    // remove unwanted payload data
    const payload = {
      ...requestBody,
    };

    delete payload.unverified;
    return this.http.post<UserBusinessDetailsResponse>(environment.API_URL + 'businesses/', payload);
  }

  getIndustries() {
    return this.http.get<getIndustriesResponse>(environment.API_URL + 'industries/?limit=11');
  }

  getUserBankAccount() {
    return this.http.get<{
      count: number;
      next: string;
      previous: string;
      results: Array<RecentPaymentItem>;
    }>(environment.API_URL + 'payments/?payment_type=bank&withdrawal=true');
  }

  // todo make this dynamic based on the user's registered country AKA phone number
  getCountryDocuments() {
    let country_iso_2 = 'UG';
    return this.http.get(`${environment.API_URL}country-documents/?country=${country_iso_2}`);
  }

  createBusinessDocument({ document_id, file, identifier_number = null, business_id = null }) {
    const businessID = business_id || this.currentUser.business_id;
    const formData = new FormData();

    formData.append('business', String(businessID));
    formData.append('document', document_id);
    formData.append('browse_filename', file);
    formData.append('identifier_number', identifier_number);

    return this.http.post(`${environment.API_URL}business-documents/`, formData);
  }

  createBusinessDocs() {
    const buildForm = (key: 'doc1' | 'doc2'): FormData | null => {
      const formData = new FormData();
      const data = window[key];

      if (!data) return null;

      Object.keys(data).forEach((k) => {
        formData.append(k, data[k]);
      });

      delete window[key];
      return formData;
    };

    const forms = ['doc1', 'doc2'].map((v: 'doc1' | 'doc2') => buildForm(v));
    // check that we have all data
    if (forms.reduce((acc, v) => acc && !!v, true)) {
      return forkJoin(
        forms.map((f) => {
          f.append('business', String(this.currentUser.business_id));
          return this.http.post(`${environment.API_URL}business-documents/`, f);
        })
      );
    }

    // This means no KYB files attached to the window, and we can't do anything at this point
    console.error('Missing business docs info');
    return EMPTY;
  }

  getUserBusinessDocuments() {
    return this.http.get<getUserBusinessDocumentsResponse>(`${environment.API_URL}business-documents/`);
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
