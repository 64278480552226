import { Directive, HostListener, Input } from '@angular/core';
import { parsePhoneNumber, CountryCode, isValidPhoneNumber, AsYouType } from 'libphonenumber-js/min';
import { AbstractControl } from '@angular/forms';

/*****
 *  Reformat the phone number to a valid format based on the inputs
 */
@Directive({
  selector: '[PhoneReformat]',
  standalone: true,
})
export class PhoneReformatDirective {
  /****
   *  Phone number control whether it was a group (phone  + code) or single
   */
  @Input()
  control: AbstractControl;

  /*****
   * Control is composed of a Form Group (phone and code)
   */
  @Input()
  isGroup: boolean = true;

  @Input()
  countryIsoCode: string;

  constructor() {}

  /// On blur format the phone number to local format
  @HostListener('blur')
  onBlur() {
    if (!this.control) {
      return;
    }

    const val: { code: string; phone_number: string } | string = this.control.value;
    // check if the code is country object or just country code string (Based on bound value from country selector)
    const code = typeof val?.['code'] === 'object' ? val['code']['value'] : val?.['code'];
    // check if the control is composed of code and phone number or just a phone
    const num = typeof val === 'object' ? code + val?.['phone_number'] : val;

    if (!this.isValidNumber(num)) {
      return;
    }

    try {
      const phoneNumber = parsePhoneNumber(num, this.countryIsoCode as CountryCode);
      if (phoneNumber && phoneNumber.isValid()) {
        this.updateControl(phoneNumber.nationalNumber);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private updateControl(nationalNumber: string): void {
    if (this.isGroup) {
      this.control.get('phone_number').setValue(nationalNumber);
    } else {
      this.control.setValue(nationalNumber);
    }
  }

  /****
   * Validates the phone number whether country code was provided or combined
   * within the number itself
   * @param num: string
   * @private
   */
  private isValidNumber(num: string): boolean {
    try {
      if (this.countryIsoCode) {
        // Validates the phone based on the provided country code
        return isValidPhoneNumber(num, this.countryIsoCode as CountryCode);
      } else if (num) {
        // Use AsYouType to validate phone number without a country code
        const asYouType = new AsYouType();
        asYouType.input(num);

        return asYouType.getNumber() ? asYouType.getNumber().isValid() : false;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
