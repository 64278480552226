import { createAction, props } from '@ngrx/store';
import { MISC_TYPES } from './misc.types';
import { TransactionReceiptResponse } from '../wallets/wallets.interfaces';
import {
  CopyTextPayload,
  DocGenRequestBody,
  ErrorType,
  getRatesResponseInterface,
  SnackbarDetailsInterface,
} from './misc.interface';

export const toggleAnimatedSidebar = createAction(
  MISC_TYPES.TOGGLE_ANIMATED_SIDEBAR,
  props<{ isOpen: boolean; payload?: object; contentProjection: boolean; header?: string; isOver: boolean }>()
);

export const toggleSlidingSidebar = createAction(MISC_TYPES.TOGGLE_SLIDING_SIDEBAR);

export const toggleDrawer = createAction(
  MISC_TYPES.TOGGLE_DRAWER,
  props<{ component?: string; data: { [key: string]: any } }>()
);

// ------- Print --------
export const printDocStart = createAction(
  MISC_TYPES.PRINT_DOC_START,
  props<{
    extras: { userFriendlyError: string; fileName: string };
    docType: 'pdf' | 'png';
    body: DocGenRequestBody;
    callBack?: Function;
  }>()
);
export const printDocSuccess = createAction(MISC_TYPES.PRINT_DOC_SUCCESS);
export const printDocFail = createAction(MISC_TYPES.PRINT_DOC_FAIL, props<{ error: string; displayMessage: string }>());

// ------- get rates -------
export const getRatesStart = createAction(
  MISC_TYPES.GET_RATES_START,
  props<{
    payload: {
      currentUserCountry: { isoCode: string; currency: string };
      toCountryCurrency: string;
      useOpenEndpoint?: boolean;
    };
  }>()
);
export const getRatesSuccess = createAction(
  MISC_TYPES.GET_RATES_SUCCESS,
  props<{ payload: getRatesResponseInterface }>()
);
export const getRatesFailure = createAction(MISC_TYPES.GET_RATES_FAILURE, props<ErrorType>());

// ---- Registration Stepper
export const toggleHeadlessStepperStep = createAction(
  MISC_TYPES.SET_REGISTRATION_STEPPER_STEP,
  props<{ currentStep: number }>()
);

// ------- get rates -------
export const openSnackbar = createAction(
  MISC_TYPES.OPEN_SNACKBAR,
  props<{ snackbarDetails: SnackbarDetailsInterface }>()
);

export const closeSnackbar = createAction(MISC_TYPES.CLOSE_SNACKBAR);

export const copyText = createAction(MISC_TYPES.COPY_TEXT, props<{ payload: CopyTextPayload }>());

// ------- Fetch receipts data -------
export const fetchReceiptDataStart = createAction(
  MISC_TYPES.FETCH_RECEIPT_DATA_START,
  props<{
    payload: {
      transId: string | number;
      businessId: number;
      receiptType: 'merchant' | 'client';
      transactionType: 'collections' | 'payments';
      extras: { shortName: string; is_xb_request: boolean };
    };
  }>()
);

export const fetchReceiptDataSuccess = createAction(
  MISC_TYPES.FETCH_RECEIPT_DATA_SUCCESS,
  props<{ payload: TransactionReceiptResponse }>()
);

export const fetchReceiptDataFailure = createAction(MISC_TYPES.GENERATE_RECEIPT_FAIL);
// ------- Generate receipts -------
export const generateReceiptStart = createAction(
  MISC_TYPES.GENERATE_RECEIPT_START,
  props<{
    payload: {
      receiptType: 'client' | 'merchant';
      transactionType: 'collections' | 'payments';
      data: TransactionReceiptResponse;
      extras: { shortName: string; fileName: string; is_xb_request: boolean };
    };
  }>()
);
export const generateReceiptSuccess = createAction(MISC_TYPES.GENERATE_RECEIPT_SUCCESS);
export const generateReceiptFail = createAction(MISC_TYPES.GENERATE_RECEIPT_FAIL, props<ErrorType>());

// ------- flexible dialog -------
export const changeOpeningStatus = createAction(
  MISC_TYPES.CHANGE_OPENING_STATUS,
  props<{
    payload: {
      isOpened: boolean;
    };
  }>()
);
export const changeMinimizationStatus = createAction(
  MISC_TYPES.CHANGE_MINIMIZATION_STATUS,
  props<{
    payload?: {
      isMinimized: boolean;
    };
  }>()
);
export const changeExpansionStatus = createAction(
  MISC_TYPES.CHANGE_EXPANSION_STATUS,
  props<{
    payload?: {
      isExpanded: boolean;
    };
  }>()
);
