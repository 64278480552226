import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormUtilityService } from '../../services/form-utility.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { BeyContactSelectorInterface } from '../../../../../assets/interfaces/bey-contact-selector.interface';
import { BeyCountrySelectorOptionInterface } from '../../../../../assets/interfaces/bey-country-selector.interface';
import {
  CROSS_BORDER_OPERATING_COUNTRIES,
  SPECIAL_CHARACTERS_REGEX_VALIDATION,
  SPECIAL_CHARACTERS_REGEX_VALIDATION_FOR_DESCRIPTION_FIELD,
} from '../../../../../assets/const';
import { PaymentLoadingDialogContentComponent } from '../../../collections/components/payment-loading-dialog-content/payment-loading-dialog-content.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { delay } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { noLeadingSpaces, phoneNumberValidator } from '../../../../../assets/utility/form.validators';
import { NameInitialsPipe } from '../../../shared/pipes/name-initials.pipe';
import { BeySignUpCalloutComponent } from '../../../shared/components/bey-signup-callout/bey-signup-callout.component';
import { NgIf } from '@angular/common';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { BeyContactSelectorComponent } from '../../../shared/components/bey-contact-selector/bey-contact-selector.component';
import { BeyCountrySelectorComponent } from '../../../shared/components/bey-country-selector/bey-country-selector.component';
import { BeyInputComponent } from '../../../shared/components/bey-input/bey-input.component';
import { BeyTabComponent } from '../../../shared/components/bey-tabs/bey-tab/bey-tab.component';
import { BeyTabGroupComponent } from '../../../shared/components/bey-tabs/bey-tab-group.component';
import { BeyHeaderWithInitialsComponent } from '../../../shared/components/bey-header-with-initials/bey-header-with-initials.component';
import { GetElementIdDirective } from '../../../shared/directives/get-element-id.directive';
import { Store } from '@ngrx/store';
import { selectUserWallet, selectUserWalletCurrency } from 'src/app/ngrx/wallets/wallets.selectors';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
  standalone: true,
  imports: [
    BeyHeaderWithInitialsComponent,
    BeyTabGroupComponent,
    BeyTabComponent,
    ReactiveFormsModule,
    BeyInputComponent,
    BeyCountrySelectorComponent,
    BeyContactSelectorComponent,
    BeyButtonComponent,
    NgIf,
    BeySignUpCalloutComponent,
    NameInitialsPipe,
    GetElementIdDirective,
  ],
})
export class DemoComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  contacts: Array<BeyContactSelectorInterface> = [];
  countries: Array<BeyCountrySelectorOptionInterface> = [...CROSS_BORDER_OPERATING_COUNTRIES];
  merchantWalletCurrency: string;
  subscription: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    public router$: Router,
    public formUtility: FormUtilityService,
    private dialog: MatDialog,
    private store$: Store
  ) {
    this.form = new UntypedFormGroup({
      phone: fb.group(
        {
          code: new UntypedFormControl('+256', [Validators.required]),
          phone_number: new UntypedFormControl(null, [Validators.required]),
        },
        { validators: [phoneNumberValidator(false)] }
      ),
      first_name: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(SPECIAL_CHARACTERS_REGEX_VALIDATION),
      ]),
      last_name: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(SPECIAL_CHARACTERS_REGEX_VALIDATION),
      ]),
      amount: new UntypedFormControl(null, [Validators.required, Validators.min(500), Validators.max(5000000)]),
      description: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern(SPECIAL_CHARACTERS_REGEX_VALIDATION_FOR_DESCRIPTION_FIELD),
        noLeadingSpaces,
      ]),
    });
  }
  ngOnInit(): void {
    this.subscription.add(
      this.store$.select(selectUserWalletCurrency).subscribe((currency) => (this.merchantWalletCurrency = currency))
    );
  }

  handleSignUp() {
    this.router$.navigate(['/signup']);
  }

  formSubmit(form) {
    if (form.valid) {
      const ref = this.dialog.open(PaymentLoadingDialogContentComponent, {
        data: {
          type: 'processing',
        },
      });

      this.subscription.add(
        of([])
          .pipe(delay(500))
          .subscribe(() => {
            ref.componentInstance.data = { type: 'success' };
          })
      );
    } else {
      this.formUtility.validateAllFormFields(this.form);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
