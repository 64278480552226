import { Injectable } from '@angular/core';
import { ContactsService } from './contacts.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { BeyToastService } from '../../modules/shared/services/bey-toast.service';
import { catchError, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { contactsTypes } from './contacts.types';
import { EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectPhoneBookRedirectURL } from './contacts.selectors';

@Injectable()
export class ContactsEffects {
  constructor(
    private contactService: ContactsService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private store$: Store
  ) {}

  getUserContactsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactsTypes.GET_USER_CONTACTS_START),
      mergeMap((actionPayload) =>
        this.contactService.getContacts(actionPayload?.['payload']).pipe(
          map((payload) => ({ type: contactsTypes.GET_USER_CONTACTS_SUCCESS, payload })),
          catchError(() => {
            this.toast.open(
              'Something went wrong, please refresh the page',
              'error',
              'Refresh',
              () => location.reload(),
              false,
              true
            );
            return EMPTY;
          })
        )
      )
    );
  });

  getMoreUserContactsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactsTypes.GET_MORE_USER_CONTACTS_START),
      mergeMap(() =>
        this.contactService.getMoreContacts().pipe(
          map((payload) => ({ type: contactsTypes.GET_MORE_USER_CONTACTS_SUCCESS, payload })),
          catchError(() => {
            this.toast.open(
              'Something went wrong, please refresh the page',
              'error',
              'Refresh',
              () => location.reload(),
              false,
              true
            );
            return EMPTY;
          })
        )
      )
    )
  );

  setPhoneBookHeaderTitleEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(contactsTypes.SET_PHONE_BOOK_MISC_CONFIG),
        tap(() => {
          this.router.navigate(['phone-book']);
        })
      ),
    { dispatch: false }
  );

  /**
   *  Select the redirect URL from the store and send the user back after choosing a contact
   *  only if available
   */
  setActiveContactEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(contactsTypes.SET_ACTIVE_CONTACT),
        withLatestFrom(this.store$.pipe(select(selectPhoneBookRedirectURL))),
        tap(([, URL]) => URL && this.router.navigate([URL]))
      ),
    { dispatch: false }
  );
}
