import { createAction, props } from '@ngrx/store';
import { walletsTypes } from './wallets.types';
import { BillingItem, ErrorType, SubscriptionPrice, walletLimitsShownAlert, WalletsState } from './wallets.interfaces';

//------------GET USER WALLET------------
export const getUserWalletStart = createAction(
  walletsTypes.GET_USER_WALLET_START,
  props<{ payload: { business_id: number } }>()
);
export const getUserWalletSuccess = createAction(walletsTypes.GET_USER_WALLET_SUCCESS, props<{ payload: any }>());
export const getUserWalletFailure = createAction(walletsTypes.GET_USER_WALLET_FAILURE, props<ErrorType>());
//------------GET USER BALANCE------------
export const getUserBalanceStart = createAction(
  walletsTypes.GET_USER_BALANCE_START,
  props<{ payload: { balanceId: number } }>()
);
export const getUserBalanceSuccess = createAction(walletsTypes.GET_USER_BALANCE_SUCCESS, props<{ payload: any }>());
export const getUserBalanceFailure = createAction(walletsTypes.GET_USER_BALANCE_FAILURE, props<ErrorType>());
//------------GET USER BILLINGS------------
export const getUserBillingsStart = createAction(
  walletsTypes.GET_USER_BILLINGS_START,
  props<{ payload: { business_id?: number; nextUrl?: string } }>()
);
export const getUserBillingsSuccess = createAction(
  walletsTypes.GET_USER_BILLINGS_SUCCESS,
  props<{ payload: { result: WalletsState['userBillings']; isAppend: boolean } }>()
);
export const getUserBillingsFailure = createAction(walletsTypes.GET_USER_BILLINGS_FAILURE, props<ErrorType>());
//------------GET SUBSCRIPTION PRICE------------
export const getSubscriptionPriceStart = createAction(walletsTypes.GET_SUBSCRIPTION_PRICE_START);
export const getSubscriptionPriceSuccess = createAction(
  walletsTypes.GET_SUBSCRIPTION_PRICE_SUCCESS,
  props<{ payload: SubscriptionPrice }>()
);
export const getSubscriptionPriceFailure = createAction(
  walletsTypes.GET_SUBSCRIPTION_PRICE_FAILURE,
  props<ErrorType>()
);

//------------PAY SUBSCRIPTION NOW------------
export const paySubNowStart = createAction(walletsTypes.PAY_SUB_NOW_START, props<{ business_id: number }>());
export const paySubNowSuccess = createAction(walletsTypes.PAY_SUB_NOW_SUCCESS, props<{ result: BillingItem }>());
export const paySubNowFailure = createAction(walletsTypes.PAY_SUB_NOW_FAILURE, props<{ error: Error }>());
export const paySubNowReset = createAction(walletsTypes.PAY_SUB_NOW_RESET);

//------------PAY SUBSCRIPTION NOW------------
// Delay is optional and in milliseconds
export const getBillingAttemptDetailsStart = createAction(
  walletsTypes.GET_BILLING_ATTEMPT_DETAILS_START,
  props<{ id: number; delay?: number }>()
);

export const getBillingAttemptDetailsSuccess = createAction(
  walletsTypes.GET_BILLING_ATTEMPT_DETAILS_SUCCESS,
  props<{ result: BillingItem }>()
);

export const getBillingAttemptDetailsFailure = createAction(
  walletsTypes.GET_BILLING_ATTEMPT_DETAILS_FAILURE,
  props<ErrorType>()
);

//------------OTHERS------------
export const setWalletLimitsShownAlert = createAction(
  walletsTypes.SET_WALLET_LIMITS_SHOWN_ALERT,
  props<{ payload: walletLimitsShownAlert['alertType'] }>()
);
