import { Component, EventEmitter, Output } from '@angular/core';
import { BeyChevronComponent } from '../../icons/bey-chevron/bey-chevron.component';
import { BeyButtonComponent } from '../../bey-button/bey-button.component';

@Component({
  selector: 'bulk-payment-highlight',
  standalone: true,
  imports: [BeyChevronComponent, BeyButtonComponent],
  templateUrl: './bulk-payment-highlight.component.html',
  styleUrls: ['./bulk-payment-highlight.component.scss'],
})
export class BulkPaymentHighlightComponent {
  @Output() onButtonClick: EventEmitter<void> = new EventEmitter();
}
