<div class="w-full px-3.5 py-[22px]" (swipe)="onSwipe($event)">
  <div class="flex relative space-x-2x" #wrapper>
    <ng-container *ngFor="let tab of tabs; let i = index">
      <div class="text-lg cursor-pointer grow" (click)="selectTab(i)">
        <button
          class="text-lg text-center leading-[22px] tab relative w-full"
          [ngClass]="tab.active ? 'tab--active' : ''">
          <span>{{ tab.name }}</span>
        </button>
      </div>
    </ng-container>

    <div class="bar" [style.--left]="left" [style.--width]="barWidth + 'px'"></div>
  </div>

  <div class="mt-12">
    <ng-content></ng-content>
  </div>
</div>
