import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCurrentRoute } from 'src/app/ngrx/auth/auth.selectors';
import { selectUserKycDetails } from 'src/app/ngrx/KYC/kyc.selectors';
import { ProfileInterface } from 'src/app/ngrx/user/user.interfaces';
import { selectUserDetails } from 'src/app/ngrx/user/user.selectors';
import { environment } from 'src/environments/environment';
import { User } from 'src/stories/User';
import { NameInitialsPipe } from '../../pipes/name-initials.pipe';

@Component({
  selector: 'bey-header',
  templateUrl: './bey-header.component.html',
  styleUrls: ['./bey-header.component.scss'],
  standalone: true,
  imports: [RouterLink, NgIf, AsyncPipe, NameInitialsPipe],
})
export class BeyHeaderComponent {
  currentUser$: Observable<User>;
  currentRoute$: Observable<string>;
  userKyc$: Observable<ProfileInterface>;
  environment = environment;

  constructor(private store$: Store, private router: Router) {}

  ngOnInit(): void {
    this.currentUser$ = this.store$.select(selectUserDetails);
    this.currentRoute$ = this.store$.select(selectCurrentRoute);
    this.userKyc$ = this.store$.select(selectUserKycDetails);
  }

  login(): void {
    if (environment.is_pay) {
      window.open(`${environment.URL}/login`, '_blank');
    } else {
      this.router.navigate(['/login']);
    }
  }
}
