import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { RouterService } from '../../../core/services/router.service';
import { AsyncPipe } from '@angular/common';
import { GetElementIdDirective } from '../../directives/get-element-id.directive';

@Component({
  selector: 'app-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.scss'],
  standalone: true,
  imports: [AsyncPipe, GetElementIdDirective],
})
export class PageInfoComponent implements OnInit, OnDestroy {
  /***
   * The data associated with the ActivatedRoute.
   * This component makes use of `data.title` and `data.description`
   */
  routeData: Data;
  routeData$: Observable<Data>;
  ngDestroyed$: Subject<null> = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {
    this.routeData$ = this.routerService.routeData$;
    this.routeData$.pipe(takeUntil(this.ngDestroyed$)).subscribe((value) => (this.routeData = value));
  }

  onBackClick(): void {
    this.routeData['back']
      ? this.router.navigate(
          [this.routeData['back']],
          this.routeData['state'] ? { state: this.routeData['state'] } : {}
        )
      : this.location.back();
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(null);
    this.ngDestroyed$.complete();
  }
}
