import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  CancelInviteResponseBody,
  InviteMemberRequestBody,
  InviteMemberResponseBody,
  ListInvitationsResponseBody,
  ListTeamMembersResponseBody,
  ResendInviteResponseBody,
  InvitationItem,
  OnboardMemberRequestBody,
} from './members.interfaces';

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  constructor(private http: HttpClient) {}

  getInvitationByCode(code: string) {
    return this.http.get<InvitationItem>(`${environment.API_URL}businesses/user-invitations/by-code/${code}/`);
  }

  getTeamMembers(id: number) {
    return this.http.get<ListTeamMembersResponseBody>(environment.API_URL + `businesses/${id}/members/`);
  }

  getInvitedMembers() {
    let params = new HttpParams();

    params = params.append('ordering', '-id');
    params = params.append('status', 'pending,expired');

    return this.http.get<ListInvitationsResponseBody>(environment.API_URL + 'businesses/user-invitations/', { params });
  }

  onboardMember(payload: OnboardMemberRequestBody) {
    return this.http.post<any>(environment.API_URL + 'businesses/signup/', payload);
  }

  inviteMember(payload: InviteMemberRequestBody) {
    return this.http.post<InviteMemberResponseBody>(environment.API_URL + 'businesses/user-invitations/', payload);
  }

  resendInvite(id: number) {
    return this.http.post<ResendInviteResponseBody>(
      environment.API_URL + `businesses/user-invitations/${id}/resend/`,
      null
    );
  }

  cancelInvite(id: number) {
    return this.http.post<CancelInviteResponseBody>(
      environment.API_URL + `businesses/user-invitations/${id}/cancel/`,
      null
    );
  }
}
