import { createAction, props } from '@ngrx/store';
import { bulkPaymentsTypes } from './bulk-payments.types';
import {
  BulkPayment,
  CreateBulkPaymentRequestBody,
  GetUserBulkPaymentsResponse,
  AddRecipientsToBulkPaymentRequestBody,
  UpdateBulkPaymentRequestBody,
  UnvalidatedData,
} from './bulk-payments.interfaces';
import { ErrorType } from '../misc/misc.interface';
import { RecentPaymentItem, RecentPaymentResponse } from '../payments/payments.interfaces';

//------------CREATE BULK PAYMENT------------
export const createBulkPaymentStart = createAction(
  bulkPaymentsTypes.CREATE_BULK_PAYMENT_START,
  props<{ payload: CreateBulkPaymentRequestBody }>()
);
export const createBulkPaymentSuccess = createAction(bulkPaymentsTypes.CREATE_BULK_PAYMENT_SUCCESS);
export const createBulkPaymentFailure = createAction(bulkPaymentsTypes.CREATE_BULK_PAYMENT_FAILURE, props<ErrorType>());

//------------UPDATE BULK PAYMENT------------
export const updateBulkPaymentStart = createAction(
  bulkPaymentsTypes.UPDATE_BULK_PAYMENT_START,
  props<{ payload: { id: number; update: UpdateBulkPaymentRequestBody } }>()
);
export const updateBulkPaymentSuccess = createAction(bulkPaymentsTypes.UPDATE_BULK_PAYMENT_SUCCESS);
export const updateBulkPaymentFailure = createAction(bulkPaymentsTypes.UPDATE_BULK_PAYMENT_FAILURE, props<ErrorType>());

//------------SAVE FOR LATER------------
export const saveForLaterStart = createAction(
  bulkPaymentsTypes.SAVE_FOR_LATER_START,
  props<{ payload: { bulkPayment: BulkPayment; data: UnvalidatedData } }>()
);
export const saveForLaterSuccess = createAction(bulkPaymentsTypes.SAVE_FOR_LATER_SUCCESS);
export const saveForLaterFailure = createAction(bulkPaymentsTypes.SAVE_FOR_LATER_FAILURE, props<ErrorType>());

//------------FETCH TO BE PROCESSED BULK PAYMENT------------
export const fetchToBeProcessedBulkPaymentStart = createAction(
  bulkPaymentsTypes.FETCH_TO_BE_PROCESSED_BULK_PAYMENT_START,
  props<{ payload: { id: number } }>()
);
export const fetchToBeProcessedBulkPaymentSuccess = createAction(
  bulkPaymentsTypes.FETCH_TO_BE_PROCESSED_BULK_PAYMENT_SUCCESS,
  props<{ payload: BulkPayment }>()
);
export const fetchToBeProcessedBulkPaymentFailure = createAction(
  bulkPaymentsTypes.FETCH_TO_BE_PROCESSED_BULK_PAYMENT_FAILURE,
  props<ErrorType>()
);

//------------FETCH TO BE PROCESSED BULK PAYMENT RECIPIENTS------------
export const fetchToBeProcessedBulkPaymentRecipientsStart = createAction(
  bulkPaymentsTypes.FETCH_TO_BE_PROCESSED_BULK_PAYMENT_RECIPIENTS_START,
  props<{ payload: { id: number } }>()
);
export const fetchToBeProcessedBulkPaymentRecipientsSuccess = createAction(
  bulkPaymentsTypes.FETCH_TO_BE_PROCESSED_BULK_PAYMENT_RECIPIENTS_SUCCESS,
  props<{ payload: BulkPayment['payments'] }>()
);
export const fetchToBeProcessedBulkPaymentRecipientsFailure = createAction(
  bulkPaymentsTypes.FETCH_TO_BE_PROCESSED_BULK_PAYMENT_RECIPIENTS_FAILURE,
  props<ErrorType>()
);

//------------ADD RECIPIENTS TO BULK PAYMENT------------
export const addRecipientsToBulkPaymentStart = createAction(
  bulkPaymentsTypes.ADD_RECIPIENTS_TO_BULK_PAYMENT_START,
  props<{ payload: { recipient: AddRecipientsToBulkPaymentRequestBody; businessId: number } }>()
);
export const addRecipientsToBulkPaymentSuccess = createAction(bulkPaymentsTypes.ADD_RECIPIENTS_TO_BULK_PAYMENT_SUCCESS);
export const addRecipientsToBulkPaymentFailure = createAction(
  bulkPaymentsTypes.ADD_RECIPIENTS_TO_BULK_PAYMENT_FAILURE,
  props<ErrorType>()
);

//------------PROCESS BULK PAYMENT------------
export const processBulkPaymentStart = createAction(
  bulkPaymentsTypes.PROCESS_BULK_PAYMENT_START,
  props<{ payload: { id: number } }>()
);
export const processBulkPaymentSuccess = createAction(bulkPaymentsTypes.PROCESS_BULK_PAYMENT_SUCCESS);
export const processBulkPaymentFailure = createAction(
  bulkPaymentsTypes.PROCESS_BULK_PAYMENT_FAILURE,
  props<ErrorType>()
);

//------------OTHERS------------
export const setToBeProcessedBulkPayment = createAction(
  bulkPaymentsTypes.SET_TO_BE_PROCESSED_BULK_PAYMENT,
  props<{ payload: BulkPayment }>()
);

//------------GET USER BULK PAYMENTS------------
export const getUserBulkPaymentsStart = createAction(
  bulkPaymentsTypes.GET_USER_BULK_PAYMENTS_START,
  props<{ payload: { businessId: number; filters?: object } }>()
);
export const getUserBulkPaymentsSuccess = createAction(
  bulkPaymentsTypes.GET_USER_BULK_PAYMENTS_SUCCESS,
  props<{ payload: GetUserBulkPaymentsResponse }>()
);
export const getUserBulkPaymentsFailure = createAction(
  bulkPaymentsTypes.GET_USER_BULK_PAYMENTS_FAILURE,
  props<ErrorType>()
);

//------------GET MORE USER BULK PAYMENTS------------
export const getMoreUserBulkPaymentsStart = createAction(
  bulkPaymentsTypes.GET_MORE_USER_BULK_PAYMENTS_START,
  props<{ payload: { url: string } }>()
);
export const getMoreUserBulkPaymentsSuccess = createAction(
  bulkPaymentsTypes.GET_MORE_USER_BULK_PAYMENTS_SUCCESS,
  props<{ payload: GetUserBulkPaymentsResponse }>()
);
export const getMoreUserBulkPaymentsFailure = createAction(
  bulkPaymentsTypes.GET_MORE_USER_BULK_PAYMENTS_FAILURE,
  props<ErrorType>()
);
//------------GET PAYMENTS FOR BULK PAYMENT------------
export const getPaymentsForBulkPaymentStart = createAction(
  bulkPaymentsTypes.GET_PAYMENTS_FOR_BULK_PAYMENT_START,
  props<{ payload: { id: number; businessId: number } }>()
);
export const getPaymentsForBulkPaymentSuccess = createAction(
  bulkPaymentsTypes.GET_PAYMENTS_FOR_BULK_PAYMENT_SUCCESS,
  props<{ payload: RecentPaymentResponse }>()
);
export const getPaymentsForBulkPaymentFailure = createAction(
  bulkPaymentsTypes.GET_PAYMENTS_FOR_BULK_PAYMENT_FAILURE,
  props<ErrorType>()
);
//------------GET MORE PAYMENTS FOR BULK PAYMENT------------
export const getMorePaymentsForBulkPaymentStart = createAction(
  bulkPaymentsTypes.GET_MORE_PAYMENTS_FOR_BULK_PAYMENT_START,
  props<{ payload: { id: number; params: object } }>()
);
export const getMorePaymentsForBulkPaymentSuccess = createAction(
  bulkPaymentsTypes.GET_MORE_PAYMENTS_FOR_BULK_PAYMENT_SUCCESS,
  props<{ payload: RecentPaymentResponse }>()
);
export const getMorePaymentsForBulkPaymentFailure = createAction(
  bulkPaymentsTypes.GET_MORE_PAYMENTS_FOR_BULK_PAYMENT_FAILURE,
  props<ErrorType>()
);
export const setSelectedBulkPayment = createAction(
  bulkPaymentsTypes.SET_SELECTED_BULK_PAYMENT,
  props<{ payload: { bulkPayment: BulkPayment } }>()
);
//------------UPDATE PAYMENT FOR BULK PAYMENT------------
export const updatePaymentForBulkPaymentStart = createAction(
  bulkPaymentsTypes.UPDATE_PAYMENT_FOR_BULK_PAYMENT_START,
  props<{
    payload: {
      paymentId: number;
      bulkPaymentId: number;
      payment: AddRecipientsToBulkPaymentRequestBody['payments'][number] & { description: string };
      businessId: number;
    };
  }>()
);
export const updatePaymentForBulkPaymentSuccess = createAction(
  bulkPaymentsTypes.UPDATE_PAYMENT_FOR_BULK_PAYMENT_SUCCESS,
  props<{ payload: RecentPaymentItem }>()
);
export const updatePaymentForBulkPaymentFailure = createAction(
  bulkPaymentsTypes.UPDATE_PAYMENT_FOR_BULK_PAYMENT_FAILURE,
  props<ErrorType>()
);
//------------DELETE PAYMENT FOR BULK PAYMENT------------
export const deletePaymentForBulkPaymentStart = createAction(
  bulkPaymentsTypes.DELETE_PAYMENT_FOR_BULK_PAYMENT_START,
  props<{ payload: { paymentId: number; bulkPaymentId: number; businessId: number } }>()
);
export const deletePaymentForBulkPaymentSuccess = createAction(
  bulkPaymentsTypes.DELETE_PAYMENT_FOR_BULK_PAYMENT_SUCCESS
);
export const deletePaymentForBulkPaymentFailure = createAction(
  bulkPaymentsTypes.DELETE_PAYMENT_FOR_BULK_PAYMENT_FAILURE,
  props<ErrorType>()
);
