import { createAction, props } from '@ngrx/store';
import { userTypes } from './user.types';
import * as userInterfaces from './user.interfaces';
import { ContactItem } from '../contacts/contacts.interface';
import { getUserBusinessDocumentsResponse } from './user.interfaces';

//------------GET USER DETAILS------------
export const getUserDetailsStart = createAction(
  userTypes.GET_USER_DETAILS_START,
  props<{ payload: { phone: string } }>()
);
export const getUserDetailsSuccess = createAction(
  userTypes.GET_USER_DETAILS_SUCCESS,
  props<{ payload: userInterfaces.User }>()
);
export const getUserDetailsFailure = createAction(
  userTypes.GET_USER_DETAILS_FAILURE,
  props<userInterfaces.ErrorType>()
);
//------------GET USER BUSINESS DETAILS------------
export const getUserBusinessDetailsStart = createAction(
  userTypes.GET_USER_BUSINESS_DETAILS_START,
  props<{ payload: { id: number } }>()
);
export const getUserBusinessDetailsSuccess = createAction(
  userTypes.GET_USER_BUSINESS_DETAILS_SUCCESS,
  props<{ payload: userInterfaces.UserBusinessDetailsResponse }>()
);
export const getUserBusinessDetailsFailure = createAction(
  userTypes.GET_USER_BUSINESS_DETAILS_FAILURE,
  props<userInterfaces.ErrorType>()
);

//------------BUSINESS DOCS + KYB------------
export const getCountryDocuments = createAction(userTypes.GET_COUNTRY_DOCUMENTS);
export const setCountryDocuments = createAction(userTypes.SET_COUNTRY_DOCUMENTS, props<{ payload: 'object' }>());

export const getUserKybStart = createAction(userTypes.GET_USER_KYB_START);
export const getUserKybSuccess = createAction(
  userTypes.GET_USER_KYB_SUCCESS,
  props<{ payload: getUserBusinessDocumentsResponse }>()
);
export const getUserKybFailure = createAction(userTypes.GET_USER_KYB_FAILURE);

//------------CREATE BUSINESS PROFILE------------
export const createBusinessProfileStart = createAction(
  userTypes.CREATE_BUSINESS_PROFILE_START,
  props<{ payload: userInterfaces.CreateBusinessProfileRequestBody }>()
);
export const createBusinessProfileSuccess = createAction(
  userTypes.CREATE_BUSINESS_PROFILE_SUCCESS,
  props<{ payload: userInterfaces.UserBusinessDetailsResponse }>()
);
export const createBusinessProfileFailure = createAction(
  userTypes.CREATE_BUSINESS_PROFILE_FAILURE,
  props<userInterfaces.ErrorType>()
);

export const submitBusinessProfileFormData = createAction(userTypes.SUBMIT_CREATE_BUSINESS_PROFILE_FORM_DATA);

//------------GET INDUSTRIES------------
export const getIndustriesStart = createAction(userTypes.GET_INDUSTRIES_START);
export const getIndustriesSuccess = createAction(
  userTypes.GET_INDUSTRIES_SUCCESS,
  props<{ payload: userInterfaces.getIndustriesResponse }>()
);
export const getIndustriesFailure = createAction(userTypes.GET_INDUSTRIES_FAILURE, props<userInterfaces.ErrorType>());
//------------GET USER BANK ACCOUNT------------
export const getUserBankAccountStart = createAction(userTypes.GET_USER_BANK_ACCOUNT_START);
export const getUserBankAccountSuccess = createAction(
  userTypes.GET_USER_BANK_ACCOUNT_SUCCESS,
  props<{ payload: ContactItem }>()
);
export const getUserBankAccountFailure = createAction(
  userTypes.GET_USER_BANK_ACCOUNT_FAILURE,
  props<userInterfaces.ErrorType>()
);

//------------UPLOAD BUSINESS DOCUMENTS------------
export const uploadBusinessDocumentsStart = createAction(userTypes.UPLOAD_BUSINESS_DOCUMENTS_START);
export const uploadBusinessDocumentsSuccess = createAction(userTypes.UPLOAD_BUSINESS_DOCUMENTS_SUCCESS);
export const uploadBusinessDocumentsFailure = createAction(
  userTypes.UPLOAD_BUSINESS_DOCUMENTS_FAILURE,
  props<userInterfaces.ErrorType>()
);
//------------OTHERS------------
export const clearSavedUser = createAction(userTypes.CLEAR_SAVED_USER);

export const setBusinessId = createAction(userTypes.SET_BUSINESS_ID, props<{ payload: { id: number } }>());

export const setBusinessProfileData = createAction(
  userTypes.SET_BUSINESS_PROFILE_DATA,
  props<{ payload: userInterfaces.CreateBusinessProfileRequestBody }>()
);

export const setBusinessProfileFirstPartData = createAction(
  userTypes.SET_BUSINESS_PROFILE_FIRST_PART_DATA,
  props<{ payload: userInterfaces.BusinessProfileFirstPartData }>()
);

export const clearBusinessProfileFirstPartData = createAction(userTypes.CLEAR_BUSINESS_PROFILE_FIRST_PART_DATA);

export const setBusinessProfileSecondPartData = createAction(
  userTypes.SET_BUSINESS_PROFILE_SECOND_PART_DATA,
  props<{ payload: userInterfaces.BusinessProfileSecondPartData }>()
);

export const clearBusinessProfileSecondPartData = createAction(userTypes.CLEAR_BUSINESS_PROFILE_SECOND_PART_DATA);

export const setUserBankAccount = createAction(userTypes.SET_USER_BANK_ACCOUNT, props<{ payload: ContactItem }>());

export const clearUserBankAccount = createAction(userTypes.CLEAR_USER_BANK_ACCOUNT);

export const clearUserKybFileRejected = createAction(userTypes.CLEAR_USER_KYB_FILE_REJECTED);

export const suspendUser = createAction(userTypes.SUSPEND_USER);
