import { Component, OnInit, Input } from '@angular/core';
import { fadeIn } from '../../../../../assets/utility/transitions';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'bey-field-error-message',
  templateUrl: './bey-field-error-message.component.html',
  styleUrls: ['./bey-field-error-message.component.scss'],
  animations: [fadeIn],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class BeyFieldErrorMessageComponent implements OnInit {
  constructor() {}

  /***
   *  Error message string
   */
  @Input()
  errorMessage: string = '';

  /***
   *  boolean value to determine if the error message is a warning
   */
  @Input()
  warning: boolean = false;

  /***
   *  Boolean used to control rendering the error message
   *  WE NEED THAT BECAUSE SOMETIMES WE'RE GROUPING TWO INPUT FIELDS AND ONLY SHOW THE ERROR ON ONE OF THEM
   */
  @Input()
  displayError: boolean;

  ngOnInit(): void {}
}
