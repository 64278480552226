import { createAction, props } from '@ngrx/store';
import PaymentTypes from './payments.types';
import {
  BankPaymentInfo,
  BankPaymentsFees,
  CreatePaymentRequestBody,
  ErrorType,
  LiquidateUser,
  PaymentInfo,
  PaymentsFees,
  RecentPaymentItem,
  RecentPaymentResponse,
} from './payments.interfaces';

export const updatePaymentInfo = createAction(PaymentTypes.UPDATE_PAYMENT_INFO, props<{ payload: PaymentInfo }>());
export const updateBankPaymentInfo = createAction(
  PaymentTypes.UPDATE_BANK_PAYMENTS_INFO,
  props<{ payload: BankPaymentInfo }>()
);
export const resetBankPaymentInfo = createAction(PaymentTypes.RESET_BANK_PAYMENTS_INFO);
export const setLiquidateUser = createAction(PaymentTypes.SET_LIQUIDATE_USER, props<{ payload: LiquidateUser }>());
export const clearPaymentInfo = createAction(PaymentTypes.CLEAR_PAYMENT_INFO);

export const getPaymentsStart = createAction(
  PaymentTypes.GET_PAYMENTS_START,
  props<{ payload: { business_id: number } }>()
);
export const getPaymentsSuccess = createAction(
  PaymentTypes.GET_PAYMENTS_SUCCESS,
  props<{ payload: RecentPaymentResponse }>()
);

export const resetNewPayment = createAction(
  PaymentTypes.RESET_NEW_PAYMENT,
  props<{ payload: { paymentId: number } }>()
);

//------------CREATE PAYMENT------------
export const createPaymentStart = createAction(
  PaymentTypes.CREATE_PAYMENT_START,
  props<{ payload: CreatePaymentRequestBody }>()
);
export const createPaymentSuccess = createAction(PaymentTypes.CREATE_PAYMENT_SUCCESS, props<any>());
export const createPaymentFailure = createAction(PaymentTypes.CREATE_PAYMENT_FAILURE, props<ErrorType>());
//------------GET MORE USER PAYMENTS------------
export const getMoreUserPaymentsStart = createAction(
  PaymentTypes.GET_MORE_USER_PAYMENTS_START,
  props<{ payload: { url: string } }>()
);
export const getMoreUserPaymentsSuccess = createAction(
  PaymentTypes.GET_MORE_USER_PAYMENTS_SUCCESS,
  props<{ payload: { payments: RecentPaymentItem[]; next: string } }>()
);
export const getMoreUserPaymentsFailure = createAction(PaymentTypes.GET_MORE_USER_PAYMENTS_FAILURE, props<ErrorType>());
//------------GET PAYMENTS FEES------------
export const getPaymentsFeesStart = createAction(PaymentTypes.GET_PAYMENTS_FEES_START);
export const getPaymentsFeesSuccess = createAction(
  PaymentTypes.GET_PAYMENTS_FEES_SUCCESS,
  props<{ payload: PaymentsFees }>()
);
export const getPaymentsFeesFailure = createAction(PaymentTypes.GET_PAYMENTS_FEES_FAILURE, props<ErrorType>());
//------------GET BANK PAYMENTS FEES------------
export const getBankPaymentsFeesStart = createAction(PaymentTypes.GET_BANK_PAYMENTS_FEES_START);
export const getBankPaymentsFeesSuccess = createAction(
  PaymentTypes.GET_BANK_PAYMENTS_FEES_SUCCESS,
  props<{ payload: BankPaymentsFees }>()
);
export const getBankPaymentsFeesFailure = createAction(PaymentTypes.GET_BANK_PAYMENTS_FEES_FAILURE, props<ErrorType>());
//------------MISC------------
export const getBankListStart = createAction(PaymentTypes.GET_BANK_LIST_START);
export const getBankListSuccess = createAction(PaymentTypes.GET_BANK_LIST_SUCCESS, props<{ payload: any }>());
export const getBankListFail = createAction(PaymentTypes.GET_BANK_LIST_FAIL, props<ErrorType>());
