import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, catchError, map } from 'rxjs';
import { CollectionsService } from '../../../ngrx/collections/collections.service';

@Injectable({
  providedIn: 'root',
})
export class PromptCollectionInfoResolver implements Resolve<boolean> {
  constructor(private collectionsService: CollectionsService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const code = route.queryParamMap.get('code');

    if (!code) {
      return of(null);
    }

    return this.collectionsService.getCollectionInfoByCode(code).pipe(
      map((payload) => payload),
      catchError((e) => {
        console.error(e);
        this.router.navigate(['/error']);
        return of(null);
      })
    );
  }
}
