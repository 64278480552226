import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bey-preview-header',
  templateUrl: './bey-preview-header.component.html',
  styleUrls: ['./bey-preview-header.component.scss'],
  standalone: true,
})
export class BeyPreviewHeaderComponent implements OnInit {
  @Output()
  close: EventEmitter<null> = new EventEmitter<null>();

  constructor() {}

  ngOnInit(): void {}
}
