export default {
  CREATE_PAYMENT_START: 'CREATE_PAYMENT_START',
  CREATE_PAYMENT_SUCCESS: 'CREATE_PAYMENT_SUCCESS',
  CREATE_PAYMENT_FAILURE: 'CREATE_PAYMENT_FAILURE',
  GET_MORE_USER_PAYMENTS_START: 'GET_MORE_USER_PAYMENTS_START',
  GET_MORE_USER_PAYMENTS_SUCCESS: 'GET_MORE_USER_PAYMENTS_SUCCESS',
  GET_MORE_USER_PAYMENTS_FAILURE: 'GET_MORE_USER_PAYMENTS_FAILURE',
  GET_PAYMENTS_FEES_START: 'GET_PAYMENTS_FEES_START',
  GET_PAYMENTS_FEES_SUCCESS: 'GET_PAYMENTS_FEES_SUCCESS',
  GET_PAYMENTS_FEES_FAILURE: 'GET_PAYMENTS_FEES_FAILURE',
  GET_BANK_PAYMENTS_FEES_START: 'GET_BANK_PAYMENTS_FEES_START',
  GET_BANK_PAYMENTS_FEES_SUCCESS: 'GET_BANK_PAYMENTS_FEES_SUCCESS',
  GET_BANK_PAYMENTS_FEES_FAILURE: 'GET_BANK_PAYMENTS_FEES_FAILURE',
  GET_BANK_LIST_START: 'GET_BANK_LIST_START',
  GET_BANK_LIST_SUCCESS: 'GET_BANK_LIST_SUCCESS',
  GET_BANK_LIST_FAIL: 'GET_BANK_LIST_FAIL',
  RESET_NEW_PAYMENT: 'RESET_NEW_PAYMENT',
  UPDATE_PAYMENT_INFO: 'UPDATE_PAYMENT_INFO',
  CLEAR_PAYMENT_INFO: 'CLEAR_PAYMENT_INFO',
  TOGGLE_DIRTY: 'TOGGLE_DIRTY',
  SET_LIQUIDATE_USER: 'SET_LIQUIDATE_USER',

  GET_PAYMENTS_START: 'GET_PAYMENTS_START',
  GET_PAYMENTS_SUCCESS: 'GET_PAYMENTS_SUCCESS',

  UPDATE_BANK_PAYMENTS_INFO: 'UPDATE_BANK_PAYMENTS_INFO',
  RESET_BANK_PAYMENTS_INFO: 'RESET_BANK_PAYMENTS_INFO',
};
