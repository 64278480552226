import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BeyCountrySelectorOptionInterface } from '../../../../../assets/interfaces/bey-country-selector.interface';
import { fadeIn, fadeOut } from '../../../../../assets/utility/transitions';
import { FormUtilityService } from 'src/app/modules/core/services/form-utility.service';
import { Subscription } from 'rxjs';
import { CountryFilterPipe } from '../../pipes/array-filter.pipe';
import { BeyFieldErrorMessageComponent } from '../bey-field-error-message/bey-field-error-message.component';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { NgIf, NgClass } from '@angular/common';
import { GetElementIdDirective } from '../../directives/get-element-id.directive';

/****
 *  Optionally we can use [(ngModel)]="var" on this component
 */
const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BeyCountrySelectorComponent),
  multi: true,
};

@Component({
  selector: 'bey-country-selector',
  templateUrl: './bey-country-selector.component.html',
  styleUrls: ['./bey-country-selector.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  animations: [fadeIn, fadeOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    BeyFieldErrorMessageComponent,
    CountryFilterPipe,
    GetElementIdDirective,
  ],
})
export class BeyCountrySelectorComponent implements OnInit, OnDestroy {
  /**
   * Country options following BeyCountrySelectorOptionInterface interface
   */
  @Input()
  options: Array<BeyCountrySelectorOptionInterface>;

  /***
   * The name of the property that we're using to find the option's name from the options array
   */
  @Input()
  bindLabel: string = 'label';

  /***
   * The name of the property that we're using to find the option's value from the options array
   *  IN CASE YOU WANT THE FULL OBJECT THEN DON'T PROVIDE A BINDING VALUE
   */
  @Input()
  bindValue: string;

  /***
   * Make the selector a readonly state
   */
  @Input()
  readonly: boolean = false;

  /***
   * Hide the validation feedback if some other method is used to show that
   */
  @Input()
  hideValidationFeedback: boolean = false;

  /***
   * Text for the label displayed above the component
   */
  @Input()
  label: string;

  /****
   * Class list to override in form of object
   * for the following elements: label
   */
  @Input()
  classList: { [label: string]: string };

  /***
   *  Make the field readonly and un editable also don't show background feedback
   */
  @Input()
  readonlyNoFeedback: boolean = false;

  /****
   * formControl instance
   */
  @Input()
  control: AbstractControl;

  displayError: boolean = false;
  errorMessage: string = '';
  subs$: Subscription = new Subscription();

  //The internal data model
  private innerValue: any = null;

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  public inputFieldValue = null;
  public focused: boolean = false;
  private navigationByKeyIndex: number = 0;

  @ViewChildren('countryOption')
  private countryOptions: QueryList<ElementRef>;

  @ViewChild('codeInput')
  private codeInputElement: ElementRef;

  // keeping this reference for later if it caused issue with how the error message component is displayed
  @ContentChild('message')
  message: ElementRef;

  constructor(private ref: ChangeDetectorRef, private formUtility: FormUtilityService) {}

  ngOnInit(): void {
    if (!!this.control) {
      this.subs$.add(
        this.control.valueChanges.subscribe(() => {
          this.updateErrorStatus();
        })
      );
    }
  }

  updateErrorStatus(): void {
    if (this.control) {
      this.control.markAsTouched();
      this.errorMessage = this.formUtility.getFieldErrorMessage(this.control);
      this.displayError = this.formUtility.getFieldValidation(this.control);
    }
  }

  // Accessor of the component value ------NOT THE INNER INPUT FIELD THE ACTUAL VALUE SELECTED BY THE USER-------
  get value(): any {
    return this.innerValue;
  }

  // //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.inputFieldValue = v?.value || '';
      this.onChangeCallback(v);
    }
  }

  // Update the inner input field value to be able to search for a specific value
  updateInputVal(e) {
    const { value = '' } = e.target;
    this.inputFieldValue = value;

    // Rest the selected value
    if (!value) {
      this.value = null;
    }
  }

  // Opens the options menu
  toggleFocus(isOut: boolean = false) {
    this.focused = true;
    // Rest the input field value to the selected country if available
    if (isOut) {
      this.onBlur();
      this.focused = false;
      // reset the navigation index since we're filtering and the indexes are changing
      this.navigationByKeyIndex = 0;
    }
  }

  selectCountry(country: BeyCountrySelectorOptionInterface) {
    this.innerValue = country;
    const val = this.bindValue ? country[this.bindValue] : country;

    this.inputFieldValue = this.bindValue ? country[this.bindValue] : val?.value;
    this.onChangeCallback(val);
    this.toggleFocus(true);
  }

  // remove the selected value
  clear() {
    this.value = null;
    this.inputFieldValue = null;
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback(); // it was removed so we show the 'invalid phone number' only when user enters phone number after selecting the country code
    this.updateErrorStatus();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    // Check if the value we have from external change is within our list of options and then update the component value
    if (value) {
      if (typeof value === 'object') {
        this.selectCountry(value);
        this.ref.detectChanges();
        return;
      } else {
        const country = this.getCountryFromOptions(value);
        // This will populate the component on external changes of value
        if (country) {
          this.innerValue = country;
          const val = this.bindValue ? country[this.bindValue] : country;
          this.inputFieldValue = this.bindValue ? country[this.bindValue] : val?.value || null;
          this.onChangeCallback(val);
          return;
        }
      }
    }

    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
    // Set the inner input field value provided from the upper level component
    if (this.value) {
      const country = this.getCountryFromOptions(this.value);

      if (country) {
        this.innerValue = country;
        this.inputFieldValue = this.bindValue ? country[this.bindValue] : country?.value || null;
      }
    }
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  /*****
   * This function returns country object from the set of options provided
   * @param value
   */
  getCountryFromOptions(value) {
    return this.options.find((c) => c.value === value);
  }

  /**
   * This method is used to navigate between the input field and the list of the countries
   * @param event
   */
  jumpToList(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      // Navigate to the dropdown list
      event.preventDefault();
      const firstItm = this.countryOptions.first;

      if (firstItm) {
        firstItm.nativeElement.focus();
      }
    } else if (event.key === 'Enter') {
      // close the dropdown and choose the value but only
      // if we have a country selected before and then close
      if (this.innerValue.country) {
        this.toggleFocus(true);
      }
    } else if (event.key === 'Escape') {
      // close the dropdown
      this.toggleFocus(true);
    }
  }

  /****
   *  Handle the key press event on the dropdown
   * @param event
   * @param value
   */
  handleKey(event: KeyboardEvent, value: BeyCountrySelectorOptionInterface) {
    // First find the element from the QueryList using the tabindex attribute saved inside the navigationByKeyIndex
    const element = this.countryOptions.find((el, index) => index === this.navigationByKeyIndex);
    if (!element) {
      return null;
    }

    const index = Number(element.nativeElement.getAttribute('tabindex'));

    switch (event.key) {
      case 'ArrowDown':
        // If the current index is less than the menu items we navigate to the next element
        if (this.navigationByKeyIndex < this.countryOptions.length - 1) {
          this.navigationByKeyIndex = index + 1;
        }
        break;

      case 'ArrowUp':
        // If the current index is zero, and we press up this means the user wants to use the input field
        if (this.navigationByKeyIndex === 0) {
          // close the list and move focus back to the input field
          const id = setTimeout(() => {
            this.toggleFocus(true);
            this.codeInputElement.nativeElement.focus();
            clearTimeout(id);
          }, 0);
        } else {
          // else navigate to the previous item on the list
          this.navigationByKeyIndex = index - 1;
          element.nativeElement.focus();
        }
        break;

      case 'Enter':
        value && this.selectCountry(value);
        this.toggleFocus(true);

        break;

      case 'Escape':
        this.toggleFocus(true);
        break;

      case 'Backspace':
        this.clear();
        const id = setTimeout(() => {
          this.countryOptions.first.nativeElement.focus();
          this.codeInputElement.nativeElement.focus();
          this.navigationByKeyIndex = 0;
          clearTimeout(id);
        });
        break;

      default:
        if (/^[a-zA-Z0-9]$/.test(event.key)) {
          // set focus and use the key in the input field
          const id = setTimeout(() => {
            this.codeInputElement.nativeElement.focus();
            this.inputFieldValue = event.key;
            this.navigationByKeyIndex = 0;
            clearTimeout(id);
          }, 0);
        }

        break;
    }

    const newElement = this.countryOptions.find((el, index) => index === this.navigationByKeyIndex);

    if (newElement) {
      newElement.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
