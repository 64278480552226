import { Component, Input, OnInit } from '@angular/core';
import { Location, NgClass, NgIf } from '@angular/common';
import { BeyWarningIconComponent } from '../icons/bey-warning-icon/bey-warning-icon.component';
import { BeyBackArrowComponent } from '../icons/bey-back-arrow/bey-back-arrow.component';

@Component({
  selector: 'bey-warning-banner',
  templateUrl: './bey-warning-banner.component.html',
  styleUrls: ['./bey-warning-banner.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, BeyBackArrowComponent, BeyWarningIconComponent],
})
export class BeyWarningBannerComponent implements OnInit {
  constructor(private location: Location) {}
  // todo update unit tests
  ngOnInit(): void {}

  /*****
   *
   *  Body of the warning message that you'd like to display to the customer
   */
  @Input()
  message: string;

  @Input()
  forceMobile: boolean = false;

  /***
   * Show a back button
   */
  @Input()
  backButton: boolean = false;

  onBack(): void {
    this.location.back();
  }
}
