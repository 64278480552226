import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectDrawerPayload,
  selectAnimatedSidebarIsOpen,
  selectDrawerHasContentProjection,
  selectDrawerHeader,
  selectAnimatedSidebarIsOver,
} from '../../../../ngrx/misc/misc.selectors';
import { toggleAnimatedSidebar } from '../../../../ngrx/misc/misc.actions';
import { fadeOut } from '../../../../../assets/utility/transitions';
import { BeyTransferInfoComponent } from '../bey-transfer-info/bey-transfer-info.component';
import { BeyCloseIconComponent } from '../icons/bey-close-icon/bey-close-icon.component';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'div[bey-animated-sidebar-drawer]',
  templateUrl: './bey-animated-sidebar-drawer.component.html',
  styleUrls: ['./bey-animated-sidebar-drawer.component.scss'],
  animations: [fadeOut],
  standalone: true,
  imports: [NgIf, NgClass, BeyCloseIconComponent, BeyTransferInfoComponent, AsyncPipe],
})
export class BeyAnimatedSidebarDrawerComponent implements OnInit {
  payload$: Observable<any>;
  hasContentProjection$: Observable<any>;
  sidebarIsOpen$: Observable<boolean>;
  sidebarIsOver$: Observable<boolean>;
  header$: Observable<string> = of(null);

  constructor(private store$: Store) {
    this.payload$ = store$.select(selectDrawerPayload);
    this.hasContentProjection$ = store$.select(selectDrawerHasContentProjection);
    this.sidebarIsOpen$ = store$.select(selectAnimatedSidebarIsOpen);
    this.sidebarIsOver$ = store$.select(selectAnimatedSidebarIsOver);
    this.header$ = store$.select(selectDrawerHeader);
  }

  toggle() {
    this.sidebarIsOver$
      .subscribe((isOver) => {
        this.store$.dispatch(toggleAnimatedSidebar({ isOpen: false, payload: null, contentProjection: false, isOver }));
      })
      .unsubscribe();
  }

  ngOnDestroy(): void {
    this.toggle();
  }

  ngOnInit(): void {}
}
