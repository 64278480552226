import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription, map, skipWhile, combineLatest, switchMap } from 'rxjs';
import { selectAccountPermissions, selectPermissionsIsLoading } from '../../../ngrx/auth/auth.selectors';
import { getAccountPermissionsStart } from '../../../ngrx/auth/auth.actions';
import { selectUserDetails } from '../../../ngrx/user/user.selectors';

@Directive({
  selector: '[HasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  /****
   * List of expected permissions for a DOM element to view be rendered
   */
  @Input()
  expectedPermission: Array<string>;

  /***
   * Check for all expected permissions using
   * TRUE => every;
   * FALSE => some;
   */
  @Input()
  strictCheck: boolean = true;

  /****
   *  Element hide mode
   *  display => Uses CSS display
   *  remove => Removes node from DOM
   */
  @Input()
  hideMode: 'display' | 'remove' = 'remove';

  private subscription: Subscription = new Subscription();

  constructor(private store: Store, private element: ElementRef) {}

  ngOnInit(): void {
    this.element.nativeElement.style.display = 'none';

    this.subscription = this.store
      .pipe(
        select(selectUserDetails),
        switchMap((user) => {
          return combineLatest([
            this.store.select(selectAccountPermissions),
            this.store.select(selectPermissionsIsLoading),
          ]).pipe(
            skipWhile(([permissions, isLoading]) => {
              //   prevent multiple calls
              if (!permissions && !isLoading && user) {
                const business = user.businesses[0];

                this.store.dispatch(
                  getAccountPermissionsStart({
                    payload: {
                      business_id: business.id,
                    },
                  })
                );
              }

              return !permissions;
            })
          );
        }),
        map(([userPermissions]) => {
          const expectedPermissions = this.expectedPermission;
          if (expectedPermissions && userPermissions) {
            // show everything for main business account
            if (userPermissions['is_main']) {
              return true;
            }

            if (this.strictCheck) {
              return expectedPermissions.every((r) => userPermissions['list'].includes(r));
            } else {
              return expectedPermissions.some((r) => userPermissions['list'].includes(r));
            }
          } else {
            console.error('ExpectedPermissions property is not set or user permissions are not loaded.');
            return false;
          }
        })
      )
      .subscribe((hasPermission) => {
        if (hasPermission) {
          this.element.nativeElement.style.display = '';
        } else {
          if (this.hideMode === 'display') {
            this.element.nativeElement.style.display = 'none';
          } else {
            this.element.nativeElement.remove();
          }
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
