import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials',
  standalone: true,
})
export class NameInitialsPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (!value) return '';

    const splitStr = value.trim().split(' ');

    return splitStr?.length > 1
      ? splitStr[0]?.[0]?.toUpperCase() + splitStr[splitStr?.length - 1]?.[0]?.toUpperCase()
      : splitStr[0]?.[0]?.toUpperCase();
  }
}
