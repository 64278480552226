import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { getUserContactsStart } from '../../../../ngrx/contacts/contacts.actions';
import { selectContactsList, selectGetContactsLoading } from '../../../../ngrx/contacts/contacts.selectors';
import {
  selectCollectionInfo,
  selectCollectionsFees,
  selectCreateCollectionIsLoading,
} from '../../../../ngrx/collections/collections.selectors';
import {
  CollectionInfo,
  CreateCollectionRequestBody,
  GetBusinessInfoByShortNameResponse,
} from '../../../../ngrx/collections/collections.interfaces';
import { selectUserBusinessDetails, selectUserDetails } from '../../../../ngrx/user/user.selectors';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  checkTransactionLimits,
  transactionAmountValidation,
  noLeadingSpaces,
  phoneNumberValidator,
  validateAmountAgainstLimits,
} from '../../../../../assets/utility/form.validators';
import {
  CROSS_BORDER_OPERATING_COUNTRIES,
  SPECIAL_CHARACTERS_REGEX_VALIDATION,
  SPECIAL_CHARACTERS_REGEX_VALIDATION_FOR_DESCRIPTION_FIELD,
} from '../../../../../assets/const';
import { Store } from '@ngrx/store';
import { FormUtilityService } from '../../../core/services/form-utility.service';
import { BehaviorSubject, Observable, Subscription, of, catchError } from 'rxjs';
import { BeyContactSelectorInterface } from '../../../../../assets/interfaces/bey-contact-selector.interface';
import { UserBusinessDetailsResponse } from '../../../../ngrx/user/user.interfaces';
import { createCollectionLinkStart, resetCollectionInfo } from '../../../../ngrx/collections/collections.actions';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { selectUserTransactionLimits } from '../../../../ngrx/wallets/wallets.selectors';
import { TransactionLimits } from '../../../../ngrx/wallets/wallets.interfaces';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TransactionsUtilityService } from '../../services/transactions-utility.service';
import { getUserWalletStart } from '../../../../ngrx/wallets/wallets.actions';
import { CreateCollectionDialogContentComponent } from '../../../collections/components/create-collection-dialog-content/create-collection-dialog-content.component';
import { WalletsService } from '../../../../ngrx/wallets/wallets.service';
import { getUserLocation } from '../../../../../assets/utility';
import { BeyButtonComponent } from '../bey-button/bey-button.component';
import { BeyCrossBorderAmountComponent } from '../bey-cross-border-amount/bey-cross-border-amount.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { BeyCountrySelectorComponent } from '../bey-country-selector/bey-country-selector.component';
import { BeyInputComponent } from '../bey-input/bey-input.component';
import { BeyContactSelectorComponent } from '../bey-contact-selector/bey-contact-selector.component';
import { GetElementIdDirective } from '../../directives/get-element-id.directive';
import { BeyonicCountry } from 'src/assets/interfaces';
import { retrieveOperatingCountry } from 'src/assets/utility/shared';
import { BeyCountrySelectorOptionInterface } from 'src/assets/interfaces/bey-country-selector.interface';
import { ContactsService } from 'src/app/ngrx/contacts/contacts.service';
import { ContactItem } from 'src/app/ngrx/contacts/contacts.interface';
import { BeyToastService } from '../../services/bey-toast.service';

@Component({
  selector: 'bey-create-collection-form',
  templateUrl: './bey-create-collection-form.component.html',
  styleUrls: ['./bey-create-collection-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    BeyContactSelectorComponent,
    BeyInputComponent,
    BeyCountrySelectorComponent,
    NgIf,
    BeyCrossBorderAmountComponent,
    BeyButtonComponent,
    AsyncPipe,
    GetElementIdDirective,
  ],
})
export class BeyCreateCollectionFormComponent implements OnInit, OnDestroy {
  /*****
   * This prop makes the component render and run as a collection link
   */
  @Input()
  isCollectionLink: boolean = false;

  /****
   * The type of the collection to make sure we implement the appropriate checks
   */
  @Input()
  collectionType: 'prompt' | 'stable' | 'unique' = 'prompt';

  /****
   * Business info passed as a prop in case it's a static link
   * We're using a resolver there
   */
  @Input()
  businessInfoStaticLink: GetBusinessInfoByShortNameResponse;

  /*******
   * Retried (Failed) collection information passed as prop
   * from the resolver
   */
  @Input()
  retriedCollectionInfo: CollectionInfo;

  /*******
   * Makes the form title visible
   */
  @Input()
  showFormTitle: boolean = true;

  /****
   * This event is related to the limits
   * Users should see a dialog to confirm that the collection might not happen
   * Because of the limits amount > available_transaction_value
   */
  @Output()
  limitConfirm: EventEmitter<any> = new EventEmitter<UntypedFormGroup>();

  collectionInfo: CollectionInfo;
  contacts: Array<BeyContactSelectorInterface> = [];
  businessInfo: UserBusinessDetailsResponse | GetBusinessInfoByShortNameResponse;
  countries: Array<BeyCountrySelectorOptionInterface> = [];
  form: UntypedFormGroup;
  userWalletLimits: TransactionLimits;
  xbToCountryInfo: BeyonicCountry;
  isLoading: boolean = false;

  subs$: Subscription = new Subscription();
  getUserContactsIsLoading$: Observable<boolean>;
  isXbCollectionRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  createCollectionLoading$: Observable<boolean>;
  xbFromCountryInfo$: BehaviorSubject<BeyCountrySelectorOptionInterface> = new BehaviorSubject(null);
  xbApproved: boolean; // Business approved to do XB transactions on the platform
  existingContact?: ContactItem;

  constructor(
    private store$: Store,
    public formUtility: FormUtilityService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private transactionsUtility: TransactionsUtilityService,
    private cdr: ChangeDetectorRef,
    private walletsService: WalletsService,
    private contactService: ContactsService,
    private toast: BeyToastService
  ) {
    this.form = new UntypedFormGroup({
      phone: this.fb.group(
        {
          code: new UntypedFormControl(null, [Validators.required]),
          phone_number: new UntypedFormControl(null, [Validators.required]),
        },
        {
          validators: [phoneNumberValidator(false)],
        }
      ),
      first_name: new UntypedFormControl(null, [
        Validators.required,
        noLeadingSpaces,
        Validators.pattern(SPECIAL_CHARACTERS_REGEX_VALIDATION),
      ]),
      last_name: new UntypedFormControl(null, [
        Validators.required,
        noLeadingSpaces,
        Validators.pattern(SPECIAL_CHARACTERS_REGEX_VALIDATION),
      ]),
      amount: new UntypedFormControl(null, [Validators.required]),
      description: new UntypedFormControl(null, [
        Validators.required,
        noLeadingSpaces,
        Validators.minLength(3),
        Validators.maxLength(200),
        Validators.pattern(SPECIAL_CHARACTERS_REGEX_VALIDATION_FOR_DESCRIPTION_FIELD),
      ]),
    });
  }

  ngOnInit(): void {
    this.getUserContactsIsLoading$ = this.store$.select(selectGetContactsLoading);
    this.createCollectionLoading$ = this.store$.select(selectCreateCollectionIsLoading);

    this.subs$.add(
      this.store$.select(selectUserBusinessDetails).subscribe((business) => {
        if (this.businessInfoStaticLink) {
          this.businessInfo = this.businessInfoStaticLink;
        } else {
          this.businessInfo = business;
          this.store$.dispatch(getUserWalletStart({ payload: { business_id: business.id } }));
        }
      })
    );

    if (this.businessInfo) {
      // check if business info object type is GetBusinessInfoByShortNameResponse
      if ('approved_for_cxb_transactions' in this.businessInfo) {
        this.xbToCountryInfo = retrieveOperatingCountry(this.businessInfo.country, 'isoCode');
      } else {
        this.xbToCountryInfo = retrieveOperatingCountry(this.businessInfo.country, 'name');
      }
    }

    // only fetch contacts for logged-in users
    this.subs$.add(
      this.store$.select(selectUserDetails).subscribe((user) => {
        if (user) {
          this.store$.dispatch(getUserContactsStart());

          // pull and populate the contacts
          this.subs$.add(
            this.store$.select(selectContactsList).subscribe((contacts) => {
              this.contacts = contacts.map((contact) => ({
                ...contact,
                label: contact.first_name ? `${contact.first_name} ${contact.last_name}` : contact.phone,
                value: contact.first_name,
              }));
            })
          );
        }
      })
    );

    // populate collection info if exist (in case of retry [FAILED collection])
    if (this.retriedCollectionInfo) {
      this.collectionInfo = {
        ...this.retriedCollectionInfo,
      };
    }

    // select collection info if exist in the store (in case of repeat)
    this.subs$.add(
      this.store$.select(selectCollectionInfo).subscribe((collectionInfo: CollectionInfo) => {
        if (collectionInfo) {
          this.collectionInfo = collectionInfo;
        }
      })
    );

    const {
      code = '',
      phone_number = '',
      first_name = '',
      last_name = '',
      description = '',
      amount = '',
    } = this.collectionInfo || {};
    let country: BeyCountrySelectorOptionInterface;

    if (code) {
      country = CROSS_BORDER_OPERATING_COUNTRIES.find((c) => c.countryCode === code);
    }
    this.form.get('phone.code').setValue(country);
    this.form.get('phone.phone_number').setValue(phone_number);
    this.form.get('first_name').setValue(first_name);
    this.form.get('last_name').setValue(last_name);
    this.form.get('description').setValue(description);

    // render the appropriate amount field based on the country selected
    this.subs$.add(
      this.form.get('phone.code').valueChanges.subscribe((countryInfo: BeyCountrySelectorOptionInterface) => {
        this.xbFromCountryInfo$.next(countryInfo);

        // detect if it's a domestic collection or not
        if (!countryInfo) {
          this.form.get('amount').patchValue(null);
          this.isXbCollectionRequest$.next(false);
        } else {
          this.isXbCollectionRequest$.next(countryInfo.countryCode !== this.xbToCountryInfo.countryCode);
        }
      })
    );

    // in case of retry XB collection we need to update amount field to hold XB info in form of object
    if (country) {
      if (country?.label !== this.xbToCountryInfo.name) {
        this.isXbCollectionRequest$.next(true);
        this.form.get('amount').patchValue({
          currency: country.currency,
          amount: amount,
          calculatedAmount: amount,
          calculatedAmountCurrency: country.currency,
        });
      } else {
        this.form.get('amount').patchValue(amount);
      }

      // trigger change detection
      this.xbFromCountryInfo$.next(country);
      this.cdr.detectChanges();
    }

    // Add the collection validation amount against the fees
    this.subs$.add(
      this.store$.select(selectCollectionsFees).subscribe((fees) => {
        if (fees) {
          this.form
            .get('amount')
            .addValidators(
              transactionAmountValidation(
                this.form,
                fees,
                this.transactionsUtility,
                this.xbToCountryInfo.isoCode,
                this.collectionType !== 'stable'
              )
            );
        }
      })
    );

    // adding transactions limits validator to the form
    this.subs$.add(
      this.store$.select(selectUserTransactionLimits).subscribe((limits) => {
        if (limits) {
          this.userWalletLimits = limits;
          this.form.get('amount').addValidators([checkTransactionLimits(limits, this.isCollectionLink, true)]);
        }
      })
    );

    // re-run the amount validation on phone value change
    this.subs$.add(
      this.form.get('phone').valueChanges.subscribe(() => {
        this.form.controls['amount'].updateValueAndValidity();
      })
    );

    // in case business info from short name we use approved_for_cxb_transactions
    if (this.businessInfo && this.xbToCountryInfo) {
      if (this.businessInfo?.['status'] === 'verified' || this.businessInfo?.['approved_for_cxb_transactions']) {
        this.countries = CROSS_BORDER_OPERATING_COUNTRIES;
      } else {
        this.countries = CROSS_BORDER_OPERATING_COUNTRIES.filter(
          (country) => country.isoCode === this.xbToCountryInfo.isoCode
        );
      }
    }

    // if static link then we need to check against the limits using async validator
    if (this.collectionType === 'stable') {
      this.subs$.add(
        this.isXbCollectionRequest$.subscribe((isXb) => {
          const field = this.form.get('amount');

          field.clearAsyncValidators();
          field.addAsyncValidators(
            validateAmountAgainstLimits(
              this.walletsService,
              {
                business_id: this.businessInfo.id,
                activeMerchantName: this.businessInfo.active_merchant_name,
                currency: this.xbToCountryInfo.currency,
              },
              isXb
            )
          );
        })
      );
    }

    // Auto select the country based on the user location
    getUserLocation()
      .then((result) => {
        if (result?.['country']) {
          const userCountry = CROSS_BORDER_OPERATING_COUNTRIES.find((c) => c.label === result?.['country']);
          const field = this.form.get('phone.code');
          // Don't update the user input in case the GEO IP request takes more than usual
          if (userCountry && !field.value) {
            field.setValue(userCountry);
            this.xbFromCountryInfo$.next(userCountry);
          }
        }
      })
      .catch((e) => {
        console.error(e);
      });

    this.formUtility.onSelectContact(this.form, true, (contact: ContactItem) => {
      contact && (this.existingContact = contact);
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const {
        phone: { code: country, phone_number },
        amount,
        first_name,
        last_name,
        description,
      } = this.form.value;

      const parsedPhone = parsePhoneNumber(country.countryCode + phone_number);
      const _amount = (this.collectionType === 'stable' ? amount?.calculatedAmount : amount?.amount) || amount; // amount in the wallet currency
      const payload: CreateCollectionRequestBody = {
        first_name,
        last_name,
        amount: _amount,
        phone: parsedPhone.number,
        description,
        business_id: this.businessInfo.id,
        currency:
          (this.collectionType === 'stable' ? amount?.calculatedAmountCurrency : amount?.currency) ||
          this.xbToCountryInfo.currency,
        type: 'collection-link',
      };

      // render over limit collection for a unique link collection
      if (this.isCollectionLink) {
        // show the dialog to confirm only if the amount exceeds the daily transaction value
        if (_amount > this.userWalletLimits.available_collections_transaction_value) {
          this.limitConfirm.emit(payload);
        } else {
          this.store$.dispatch(
            createCollectionLinkStart({
              payload,
            })
          );
        }
      } else if (this.collectionType === 'prompt') {
        let contact$: Observable<ContactItem>;

        if (
          this.existingContact?.first_name === first_name &&
          this.existingContact?.last_name === last_name &&
          this.existingContact?.phone === parsedPhone.number
        ) {
          contact$ = of(this.existingContact);
        } else {
          this.isLoading = true;
          contact$ = this.contactService
            .createContact({
              first_name,
              last_name,
              business_id: this.businessInfo.id,
              phone: parsedPhone.number,
            })
            .pipe(
              catchError((e) => {
                this.isLoading = false;
                this.toast.open(e?.error?.['phone']?.[0] || 'Something went wrong, please try again');

                return of(null);
              })
            );
        }
        this.subs$.add(
          contact$.subscribe((v) => {
            if (v) {
              this.isLoading = false;

              const { name_check_score } = v;

              this.dialog.open(CreateCollectionDialogContentComponent, {
                data: {
                  ...this.form.value,
                  amount: _amount,
                  phone: parsedPhone.number,
                  displayAmount: amount?.amount || amount,
                  business_id: this.businessInfo?.id,
                  type: 'collection',
                  currency: amount?.calculatedAmountCurrency || this.xbToCountryInfo.currency,
                  displayCurrency: amount?.currency || this.xbToCountryInfo.currency,
                  short_name: this.businessInfo?.short_name,
                  hideFees: false,
                  name_check_score,
                },
              });
            }
          })
        );
      } else {
        // normal collection
        this.dialog.open(CreateCollectionDialogContentComponent, {
          data: {
            ...this.form.value,
            amount: _amount,
            phone: parsedPhone.number,
            displayAmount: amount?.amount || amount,
            business_id: this.businessInfo?.id,
            type: this.collectionType === 'stable' ? 'collection-link-static' : 'collection',
            currency: amount?.calculatedAmountCurrency || this.xbToCountryInfo.currency,
            displayCurrency: amount?.currency || this.xbToCountryInfo.currency,
            short_name: this.businessInfo?.short_name,
            hideFees: this.collectionType === 'stable',
          },
        });
      }
    } else {
      this.formUtility.validateAllFormFields(this.form);
    }
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
    this.store$.dispatch(resetCollectionInfo());
  }
}
