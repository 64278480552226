<div class="mx-3 lg:px-[105px] lg:pt-8">
  <div class="font-extrabold text-gray-900 text-[26px] leading-8 lg:text-center">Name check</div>
  <div class="text-gray-700 mt-4 max-w-[439px] lg:text-lg">
    <img src="/assets/images/name-check.png" alt="name check" class="mx-auto mb-8 hidden lg:block" lazyLoad />
    <p class="mb-1 lg:mb-4">
      This shows how well the name provided matches registered mobile users. You can make the transaction even when
      there is no match.
    </p>
    <p>Name check is only available on some mobile networks.</p>
  </div>
  <hr class="mt-3 lg:mt-0 border-t-[#D9D9D8] lg:hidden" />
  <mat-dialog-actions class="-mb-4 -mt-2 lg:mb-8 lg:mt-0">
    <div
      class="justify-center flex text-blue-500 font-bold w-full text-lg leading-5 cursor-pointer"
      [mat-dialog-close]="true">
      Close
    </div>
  </mat-dialog-actions>
</div>
