<div
  class="flex opacity-0 mt-1 ease-in-out duration-500 min-h-[21px] pb-px"
  [ngClass]="{ 'opacity-100': displayError }"
  data-test-id="err-container">
  <div *ngIf="displayError" @fadeIn class="flex">
    <div class="w-5 h-5 flex items-center">
      <svg *ngIf="!warning" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.4 15.6161C13.6209 15.6161 16.232 13.0051 16.232 9.78415C16.232 6.56322 13.6209 3.95215 10.4 3.95215C7.17907 3.95215 4.56799 6.56322 4.56799 9.78415C4.56799 13.0051 7.17907 15.6161 10.4 15.6161Z"
          fill="#D90C34" />
        <path
          d="M10.4 13.5122C10.9523 13.5122 11.4 13.0645 11.4 12.5122C11.4 11.9599 10.9523 11.5122 10.4 11.5122C9.84774 11.5122 9.40002 11.9599 9.40002 12.5122C9.40002 13.0645 9.84774 13.5122 10.4 13.5122Z"
          fill="white" />
        <path d="M10.4 7.1521V10.1681" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      </svg>
      <svg *ngIf="warning" width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.49857 12.1235H2.73007C2.22973 12.1258 1.76627 11.9085 1.51565 11.5542C1.26502 11.1999 1.26566 10.7629 1.51732 10.409L3.90157 7.03403L6.28582 3.65903C6.53683 3.30546 6.99883 3.08789 7.49857 3.08789C7.99832 3.08789 8.46031 3.30546 8.71132 3.65903L11.0956 7.03403L13.4798 10.409C13.7315 10.7629 13.7321 11.1999 13.4815 11.5542C13.2309 11.9085 12.7674 12.1258 12.2671 12.1235H7.49857Z"
          fill="#FF8514" />
        <path
          d="M7.49947 10.9033C7.94144 10.9033 8.29972 10.5675 8.29972 10.1533C8.29972 9.73911 7.94144 9.40332 7.49947 9.40332C7.0575 9.40332 6.69922 9.73911 6.69922 10.1533C6.69922 10.5675 7.0575 10.9033 7.49947 10.9033Z"
          fill="white" />
        <path d="M7.44141 6.12305V8.31005" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      </svg>
    </div>

    <span
      [ngClass]="warning ? 'text-orange-500' : 'text-bey-red'"
      class="block ml-1 text-sm"
      data-test-id="error-message">
      {{ errorMessage }}
    </span>
  </div>
</div>
