<div class="relative bg-gray-800 text-white flex items-center justify-between px-4 py-2.5">
  <img
    src="assets/images/logo/onafriq.svg"
    routerLink="/"
    class="cursor-pointer"
    width="125"
    height="38"
    alt="onafriq" />
  <span
    *ngIf="!(currentUser$ | async) && (currentRoute$ | async) !== '/suspended'; else nameInit"
    class="text-sm mr-4 text-right">
    Already have an account?
    <button class="ml-3 h-[24px] underline leading-[16px]" (click)="login()">Login</button>
  </span>
  <ng-template #nameInit>
    <a
      *ngIf="(currentRoute$ | async) !== '/suspended' && (userKyc$ | async)?.first_name"
      routerLink="/profile"
      class="bg-yellow-400 rounded-full cursor-pointer text-gray-700 font-medium text-base min-w-[32px] min-h-[32px] flex items-center justify-center">
      {{ (userKyc$ | async)?.first_name + ' ' + (userKyc$ | async)?.last_name | nameInitials }}
    </a>
  </ng-template>
</div>
