<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [ngClass]="classList"
  (click)="onClick.emit()">
  <path
    d="M5.60533 3.86064L5.7225 3.96409L11.9389 10.1808L18.1577 3.96409L18.2749 3.86064C18.7627 3.48132 19.468 3.51601 19.9162 3.96458C20.4016 4.45053 20.4014 5.23819 19.9157 5.72387L13.6981 11.94L19.9157 18.1561C20.4014 18.6418 20.4016 19.4294 19.9162 19.9154C19.468 20.3639 18.7627 20.3986 18.2749 20.0193L18.1577 19.9159L11.9401 13.698L5.7225 19.9159L5.60533 20.0193C5.11744 20.3986 4.41215 20.3639 3.96404 19.9154C3.47859 19.4294 3.47881 18.6418 3.96453 18.1561L10.1809 11.94L3.96453 5.72387C3.47881 5.23819 3.47859 4.45053 3.96404 3.96458C4.41215 3.51601 5.11744 3.48132 5.60533 3.86064Z"
    fill="#4B4B4B" />
</svg>
